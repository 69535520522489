import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './calculationListWeb.style.scss';
import { Breadcrumb, Popup, PopupClassNames } from 'vekalapp-react-utilities';
import CalculationList from './list/list.index';

const CalculationListWeb: React.FC = () => {
    useEffect(() => {
        document.title = 'وکالپ | محاسبات قضایی';
    }, []);

    return (
        <div className="vekalapp-calculation-list-web-page d-md-block d-none">
            <div className="jumbotron flex-column-reverse">
                <div className="content">
                    <Breadcrumb
                        items={[
                            <Link to="/" key="1">
                                خدمات حقوقی
                            </Link>,
                            <Link to="/" key="2">
                                محاسبات قضایی
                            </Link>,
                        ]}
                    />
                    <p className="title">محاسبات قضایی</p>
                    <p className="text">
                        ابزار محاسبات قضائی وکالپ، یکی از ابزارهای بسیار مفید و کاربردی است که به وکلایی که در حوزه
                        حقوقی فعالیت می‌کنند، کمک می‌کند تا به سرعت و با دقت به محاسبات خود بپردازند. این ابزار قابلیت
                        محاسبه مبالغ خسارت، جریمه، هزینه‌های قضایی، سود و زیان، هزینه‌های وکالت و حق الوکاله و بسیاری
                        موارد دیگر را داراست. در سامانه وکالپ، تمامی محاسبات حقوقی را به صورت آنلاین و بسیاری از خدمات
                        به صورت رایگان در اختیار شما قرار داده‌ایم. بدون نیاز به تلاش و زمان برای انجام محاسبات پیچیده،
                        می‌توانید با استفاده از این سامانه به سادگی به محاسبات حقوقی خود دسترسی پیدا کنید. اگر به هر
                        دلیلی نمی‌توانید خودتان محاسبات حقوقی را انجام دهید، نگران نباشید. در وکالپ، کارشناسان و وکلای
                        حرفه‌ای آماده هستند تا به بهترین شکل ممکن به محاسبات حقوقی شما بپردازند. به این ترتیب، در صورت
                        نیاز به راهنمایی و مشاوره حقوقی، می‌توانید به آسانی به کمک تیمی از متخصصین حقوقی مجرب و با تجربه
                        دسترسی پیدا کنید.
                    </p>
                </div>
            </div>
            <CalculationList />
        </div>
    );
};

export default CalculationListWeb;
