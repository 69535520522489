const diyatRate: { year: number; amount: { full: number; haram: number } }[] = [
    {
        year: 1370,
        amount: {
            full: 2000000,
            haram: 2666666,
        },
    },
    {
        year: 1371,
        amount: {
            full: 2200000,
            haram: 2933333,
        },
    },
    {
        year: 1372,
        amount: {
            full: 2750000,
            haram: 3666666,
        },
    },
    {
        year: 1373,
        amount: {
            full: 3370000,
            haram: 4493333,
        },
    },
    {
        year: 1374,
        amount: {
            full: 3600000,
            haram: 4800000,
        },
    },
    {
        year: 1375,
        amount: {
            full: 4175000,
            haram: 5566666,
        },
    },
    {
        year: 1376,
        amount: {
            full: 4800000,
            haram: 6400000,
        },
    },
    {
        year: 1377,
        amount: {
            full: 5600000,
            haram: 7466666,
        },
    },
    {
        year: 1378,
        amount: {
            full: 6100000,
            haram: 8113313,
        },
    },
    {
        year: 1379,
        amount: {
            full: 6400000,
            haram: 8533333,
        },
    },
    {
        year: 1380,
        amount: {
            full: 10000000,
            haram: 13333333,
        },
    },
    {
        year: 1381,
        amount: {
            full: 15000000,
            haram: 20000000,
        },
    },
    {
        year: 1382,
        amount: {
            full: 18000000,
            haram: 24000000,
        },
    },
    {
        year: 1383,
        amount: {
            full: 22000000,
            haram: 29333333,
        },
    },
    {
        year: 1384,
        amount: {
            full: 25000000,
            haram: 33333333,
        },
    },
    {
        year: 1385,
        amount: {
            full: 26250000,
            haram: 35000000,
        },
    },
    {
        year: 1386,
        amount: {
            full: 35000000,
            haram: 46666666,
        },
    },
    {
        year: 1387,
        amount: {
            full: 40000000,
            haram: 53333333,
        },
    },
    {
        year: 1388,
        amount: {
            full: 40000000,
            haram: 53333333,
        },
    },
    {
        year: 1389,
        amount: {
            full: 45000000,
            haram: 60000000,
        },
    },
    {
        year: 1390,
        amount: {
            full: 67500000,
            haram: 90000000,
        },
    },
    {
        year: 1391,
        amount: {
            full: 94500000,
            haram: 126000000,
        },
    },
    {
        year: 1392,
        amount: {
            full: 114000000,
            haram: 152000000,
        },
    },
    {
        year: 1393,
        amount: {
            full: 150000000,
            haram: 200000000,
        },
    },
    {
        year: 1394,
        amount: {
            full: 165000000,
            haram: 220000000,
        },
    },
    {
        year: 1395,
        amount: {
            full: 190000000,
            haram: 253333333,
        },
    },
    {
        year: 1396,
        amount: {
            full: 210000000,
            haram: 280000000,
        },
    },
    {
        year: 1397,
        amount: {
            full: 231000000,
            haram: 308000000,
        },
    },
    {
        year: 1398,
        amount: {
            full: 270000000,
            haram: 360000000,
        },
    },
    {
        year: 1399,
        amount: {
            full: 330000000,
            haram: 440000000,
        },
    },
    {
        year: 1400,
        amount: {
            full: 480000000,
            haram: 640000000,
        },
    },
    {
        year: 1401,
        amount: {
            full: 600000000,
            haram: 640000000,
        },
    },
    {
        year: 1402,
        amount: {
            full: 900000000,
            haram: 640000000,
        },
    },
];

export default diyatRate;
