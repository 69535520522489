import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { AuthStatus, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import CalculationForm1 from './calculationForm/calculationForm1/calculationForm1.index';
import CalculationForm2 from './calculationForm/calculationForm2/calculationForm2.index';
import CalculationForm3 from './calculationForm/calculationForm3/calculationForm3.index';
import CalculationForm4 from './calculationForm/calculationForm4/calculationForm4.index';
import CalculationForm5 from './calculationForm/calculationForm5/calculationForm5.index';
import CalculationForm7 from './calculationForm/calculationForm7/calculationForm7.index';
import CalculationForm8 from './calculationForm/calculationForm8/calculationForm8.index';
import CalculationForm6 from './calculationForm/calculationForm6/calculationForm6.index';
import CalculationForm9 from './calculationForm/calculationForm9/calculationForm9.index';
import CalculationForm10 from './calculationForm/calculationForm10/calculationForm10.index';
import { BottomNavigation, Button, Header } from 'vekalapp-react-utilities';
import { authToken } from '../scripts/storage';
import { setAuthStatus, setUserData } from '../redux/actions';
import VekalappInit from './vekalapp.script';
import Authorization from './authorization/authorization.index';
import CalculationForm11 from './calculationForm/calculationForm11/calculationForm11.index';
import CalculationForm12 from './calculationForm/calculationForm12/calculationForm12.index';
import { post, responseValidator } from '../scripts/api';
import { API } from '../data';
import CalculationList from './calculationList/calculationList.index';
import { toast } from 'react-toastify';
import useEventListener from 'scripts/eventListener';
import logo from 'assets/images/vekalappLogo.svg';

const Vekalapp: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    const { pathname } = useLocation();
    const [install, setInstall] = useState<boolean>(/iPad|iPhone|iPod/.test(navigator.userAgent));
    useEffect(() => {
        VekalappInit(props.dispatch);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const nav: any = window.navigator;

    return (
        <>
            <Route path={'/oauth'} component={Authorization} />
            {props.isAuth !== AuthStatus.pending && (
                <Header
                    items={[
                        { name: 'پروفایل', onClick: () => console.log('profile clicked!') },
                        {
                            name: 'تنظیمات',
                            onClick: () => console.log('settings clicked!'),
                        },
                        {
                            name: 'خروج',
                            onClick: () => {
                                post(API.account.logout, {}, true).then((res) => {
                                    if (responseValidator(res.status)) {
                                        authToken.remove();
                                        props.dispatch(setUserData(null));
                                        props.dispatch(setAuthStatus(AuthStatus.inValid));
                                    }
                                });
                            },
                        },
                    ]}
                    forceLogin={props.isAuth === AuthStatus.inValid}
                    myData={props.userData}
                    userType={'user'}
                    onLogin={(e) => {
                        authToken.set(e);
                        VekalappInit(props.dispatch);
                    }}
                    onFailLogin={(e) => {
                        if (e) {
                            toast.error('خطایی رخ داده است');
                        }
                    }}
                />
            )}

            {props.isAuth === AuthStatus.valid && props.userData && (
                <Switch>
                    <Route path="/fivepercent" component={CalculationForm1} />
                    <Route path="/dowry" component={CalculationForm2} />
                    <Route path="/arbitratorwage" component={CalculationForm3} />
                    <Route path="/bloodmoney" component={CalculationForm4} />
                    <Route path="/expertwage" component={CalculationForm5} />
                    <Route path="/officecosts" component={CalculationForm6} />
                    <Route path="/honorarium" component={CalculationForm7} />
                    <Route path="/taxstamp" component={CalculationForm8} />
                    <Route path="/deadlines" component={CalculationForm9} />
                    <Route path="/latepaymentdamage" component={CalculationForm10} />
                    <Route path="/costofhearing" component={CalculationForm11} />
                    <Route path="/inheritance" component={CalculationForm12} />
                    <Route path="/authorization/" component={Authorization} />
                    <Route path="/" component={CalculationList} />
                </Switch>
            )}
            {/* {props.userData?.is_lawyer ? <BottomNavigation isLawyer={true} active="CALCULATIONS" /> : null} */}
            {install && 'standalone' in nav && nav.standalone !== true ? (
                <div className="mobile-pwa-install">
                    <img src={logo} alt="" />
                    <div>
                        <p>
                            <span>۱- </span>
                            <span>از نوار پایین دکمه</span>
                            <svg height="24" viewBox="0 0 24 24" width="24">
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path
                                    d="M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z"
                                    fill="#336df9"
                                />
                            </svg>
                            <span>«Share»</span>
                            <span>را انتخاب کنید.</span>
                        </p>
                        <p>
                            <span>۲- </span>
                            <span>از منو باز شده، گزینه</span>
                            <span>«</span>
                            <span>Add to home screen</span>
                            <svg fill="#7c7d9a" width="18px" height="18px" viewBox="0 0 32 32">
                                <path d="M0 26.016q0 2.496 1.76 4.224t4.256 1.76h20q2.464 0 4.224-1.76t1.76-4.224v-20q0-2.496-1.76-4.256t-4.224-1.76h-20q-2.496 0-4.256 1.76t-1.76 4.256v20zM4 26.016v-20q0-0.832 0.576-1.408t1.44-0.608h20q0.8 0 1.408 0.608t0.576 1.408v20q0 0.832-0.576 1.408t-1.408 0.576h-20q-0.832 0-1.44-0.576t-0.576-1.408zM8 16q0 0.832 0.576 1.44t1.44 0.576h4v4q0 0.832 0.576 1.408t1.408 0.576 1.408-0.576 0.608-1.408v-4h4q0.8 0 1.408-0.576t0.576-1.44-0.576-1.408-1.408-0.576h-4v-4q0-0.832-0.608-1.408t-1.408-0.608-1.408 0.608-0.576 1.408v4h-4q-0.832 0-1.44 0.576t-0.576 1.408z"></path>
                            </svg>
                            <span>»</span>
                            <span>را انتخاب کنید.</span>
                        </p>
                        <p>
                            <span>۳- </span>
                            <span>در مرحله بعد</span>
                            <span>«Add»</span>
                            <span>را انتخاب کنید.</span>
                        </p>
                    </div>
                    <Button
                        onClick={(e) => {
                            setInstall(false);
                        }}
                    >
                        متوجه شدم
                    </Button>
                </div>
            ) : null}
        </>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    isAuth: state.authStatus,
    userData: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(Vekalapp);
