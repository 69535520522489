import React, { useEffect } from 'react';
import CalculationMobileCard from 'vekalapp/components/calculationMobileCard/calculationMobileCard.index';
import './calculationListMobile.style.scss';
import { CalculationForm } from '../calculationList.data';

const CalculationListMobile: React.FC = () => {
    useEffect(() => {
        document.title = 'وکالپ | محاسبات قضایی';
    }, []);

    return (
        <div className="calculation-list-mobile-page d-md-none">
            <div className="mobile-header">
                <h3>محاسبات حقوقی</h3>
                <i className="material-icons back-button d-flex d-md-none">chevron_right</i>
            </div>
            <div className="cards">
                {CalculationForm &&
                    CalculationForm.map((item, index) => (
                        <CalculationMobileCard
                            key={index}
                            title={item.title}
                            icon={item.icon}
                            color={item.color}
                            path={item.buttons[0].path}
                        />
                    ))}
                <div className="grid-protector"></div>
                <div className="grid-protector"></div>
                <div className="grid-protector"></div>
                <div className="grid-protector"></div>
                <div className="grid-protector"></div>
            </div>
        </div>
    );
};

export default CalculationListMobile;
