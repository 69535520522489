const data: { year: number; items: { title: string; amount: string | number }[] }[] = [
    {
        year: 1400,
        items: [
            {
                title: 'تنظیم اظهارنامه',
                amount: 25000,
            },
            {
                title: 'تنظیم شکوائیه/درخواست/دادخواست بدوی و اعتراضی',
                amount: 30000,
            },
            {
                title: 'تنظیم واخواست اسناد تجاری',
                amount: 24000,
            },
            {
                title: 'ثبت قرارداد و اعطای وکالت',
                amount: 24000,
            },
            {
                title: 'تنظیم و ثبت لایحه/اعلام نظریه کارشناسی',
                amount: 25000,
            },
            {
                title: 'ثبت اطلاعات و ارائه شناسه قضایی',
                amount: 14000,
            },
            {
                title: 'افزودن اطلاعات افراد ذی سمت پرونده و اظهارنامه، وکیل، نماینده هر نفر',
                amount: 3500,
            },
            {
                title: 'افزودن پیوست (هر صفحه)',
                amount: 3500,
            },
            {
                title: 'تایپ هر صفحه شرح اضافه',
                amount: 3500,
            },
            {
                title: 'پرداخت الکترونیکی درآمد عمومی و اختصاصی و سپرده های قضایی',
                amount: 3500,
            },
            {
                title: 'چاپ و تحویل هر برگ اوراق قضایی',
                amount: 1750,
            },
            {
                title: 'پیگیری اظهارنامه، شکوائیه، دادخواست',
                amount: 3000,
            },
            {
                title: 'اطلاع رسانی وضعیت پرونده',
                amount: 3350,
            },
            {
                title: 'ویرایش اطلاعات در سامانه ثنا',
                amount: 5500,
            },
            {
                title: 'ورود به صفحه شخصی در سامانه ابلاغ و رویت آن',
                amount: 3000,
            },
            {
                title: 'تحویل رمز حساب کاربری',
                amount: 2500,
            },
            {
                title: 'تقاضای گواهی عدم سوءپیشینه',
                amount: 5000,
            },
            {
                title: 'دریافت هزینه تمدید پروانه وکلا/کارشناسان/مترجمین',
                amount: 3500,
            },
            {
                title: 'اطلاع رسانی فرآیند دادرسی از طریق پیامک برای هر فرد',
                amount: 700,
            },
            {
                title: 'دریافت نوبت مراجعه به واحدهای قضایی',
                amount: 2000,
            },
            {
                title: 'شرکت در جلسه تحقیق و رسیدگی الکترونیک (به ازای هر جلسه ۴۵ دقیقه ای)',
                amount: 50000,
            },
            {
                title: 'تکمیل فرآیند احراز هویت',
                amount: 3000,
            },
            {
                title: 'درخواست گواهی پیشینه محکومیت های مالی',
                amount: 5000,
            },
            {
                title: 'ملاقات از راه دور خانواده زندانیان (به ازای هر جلسه ۲۰ دقیقه ای)',
                amount: 20000,
            },
            {
                title: 'ارائه خدمات سیار',
                amount: 'معادل سه برابر خدمت ارائه شده',
            },
        ],
    },
    {
        year: 1399,
        items: [
            {
                title: 'تنظیم اظهارنامه',
                amount: 19838,
            },
            {
                title: 'ثبت اطلاعات افراد ذی سمت پرونده و اظهارنامه،‌ وکیل، نماینده هر نفر',
                amount: 2909,
            },
            {
                title: 'تنظیم دادخواست/شکوائیه',
                amount: 23805,
            },
            {
                title: 'تنظیم دادخواست تقابل',
                amount: 23805,
            },
            {
                title: 'تنظیم دادخواست جلب ثالث',
                amount: 23805,
            },
            {
                title: 'تنظیم درخواست مطالبه خسارت ایام بازداشت',
                amount: 20700,
            },
            {
                title: 'تنظیم دعاوی دیوان عدالت اداری (بدوی و تجدیدنظر)',
                amount: 23805,
            },
            {
                title: 'تنظیم دادخواست فرجام خواهی',
                amount: 23805,
            },
            {
                title: 'تنظیم دادخواست اعاده دادرسی مدنی',
                amount: 23805,
            },
            {
                title: 'تنظیم دادخواست اعتراض ثالث',
                amount: 23805,
            },
            {
                title: 'تنظیم دادخواست اعاده دادرسی کیفری',
                amount: 23805,
            },
            {
                title: 'شورای حل اختلاف (تجدیدنظرخواهی - واخواهی)',
                amount: 23805,
            },
            {
                title: 'اعتراض به قرار دادسرا',
                amount: 23805,
            },
            {
                title: 'تنظیم و ثبت لایحه',
                amount: 19838,
            },
            {
                title: 'پرداخت الکترونیکی وجوه',
                amount: 1454,
            },
            {
                title: 'اعطای وکالت',
                amount: 20000,
            },
            {
                title: 'تقاضای تودیع سپرده',
                amount: 2530,
            },
            {
                title: 'دریافت هزینه تمبر مالیاتی وکلا',
                amount: 2909,
            },
            {
                title: 'اعلام نظریه کارشناسان (لایحه)',
                amount: 19838,
            },
            {
                title: 'تنظیم واخواست اسناد تجاری',
                amount: 19838,
            },
            {
                title: 'ثبت اطلاعات و ارائه شناسه قضایی',
                amount: 11500,
            },
            {
                title: 'افزودن پیوست (هر صفحه)',
                amount: 2909,
            },
            {
                title: 'تایپ هر صفحه شرح اضافه',
                amount: 2909,
            },
            {
                title: 'چاپ و تحویل هر برگ اوراق قضایی',
                amount: 1454,
            },
            {
                title: 'پیگیری اظهارنامه، شکوائیه، دادخواست',
                amount: 2530,
            },
            {
                title: 'اطلاع رسانی وضعیت پرونده',
                amount: 2783,
            },
            {
                title: 'ویرایش اطلاعات و ارائه شناسه ثنا',
                amount: 4600,
            },
            {
                title: 'ورود به صفحه شخصی در سامانه ابلاغ و رویت آن',
                amount: 2530,
            },
            {
                title: 'دریافت و چاپ تصاویر اوراق پرونده بابت هر برگ',
                amount: 1392,
            },
            {
                title: 'دریافت هزینه تمدید پروانه وکلا/کارشناسان/گواهی ترجمه',
                amount: 2909,
            },
            {
                title: 'اطلاع رسانی فرآیند دادرسی از طریق پیامک برای هر فرد',
                amount: 575,
            },
            {
                title: 'تقاضای گواهی عدم سوءپیشینه',
                amount: 2530,
            },
        ],
    },
    {
        year: 1397,
        items: [
            {
                title: 'تنظیم اظهارنامه',
                amount: 17250,
            },
            {
                title: 'ثبت اطلاعات افراد ذی سمت پرونده و اظهارنامه،‌ وکیل، نماینده هر نفر',
                amount: 2530,
            },
            {
                title: 'تنظیم دادخواست/شکوائیه',
                amount: 20700,
            },
            {
                title: 'تنظیم دادخواست تقابل',
                amount: 20700,
            },
            {
                title: 'تنظیم دادخواست جلب ثالث',
                amount: 20700,
            },
            {
                title: 'تنظیم دادخواست ورود ثالث',
                amount: 20700,
            },
            {
                title: 'تنظیم درخواست مطالبه خسارت ایام بازداشت',
                amount: 20700,
            },
            {
                title: 'تنظیم دعاوی دیوان عدالت اداری (بدوی و تجدیدنظر)',
                amount: 20700,
            },
            {
                title: 'تنظیم دادخواست فرجام خواهی',
                amount: 20700,
            },
            {
                title: 'تنظیم دادخواست اعاده دادرسی مدنی',
                amount: 20700,
            },
            {
                title: 'تنظیم دادخواست اعتراض ثالث',
                amount: 20700,
            },
            {
                title: 'تنظیم دادخواست اعاده دادرسی کیفری',
                amount: 20700,
            },
            {
                title: 'شورای حل اختلاف (تجدیدنظرخواهی - واخواهی)',
                amount: 20700,
            },
            {
                title: 'اعتراض به قرار دادسرا',
                amount: 20700,
            },
            {
                title: 'تنظیم و ثبت لایحه',
                amount: 17250,
            },
            {
                title: 'پرداخت الکترونیکی وجوه',
                amount: 1265,
            },
            {
                title: 'اعطای وکالت',
                amount: 20000,
            },
            {
                title: 'تقاضای تودیع سپرده',
                amount: 2530,
            },
            {
                title: 'دریافت هزینه تمبر مالیاتی وکلا',
                amount: 2530,
            },
            {
                title: 'اعلام نظریه کارشناسان (لایحه)',
                amount: 17250,
            },
            {
                title: 'تنظیم واخواست اسناد تجاری',
                amount: 17250,
            },
            {
                title: 'ثبت اطلاعات و ارائه شناسه قضایی',
                amount: 11500,
            },
            {
                title: 'افزودن پیوست (هر صفحه)',
                amount: 2530,
            },
            {
                title: 'تایپ هر صفحه شرح اضافه',
                amount: 2530,
            },
            {
                title: 'چاپ و تحویل هر برگ اوراق قضایی',
                amount: 1265,
            },
            {
                title: 'پیگیری اظهارنامه، شکوائیه، دادخواست',
                amount: 2530,
            },
            {
                title: 'اطلاع رسانی وضعیت پرونده',
                amount: 2530,
            },
            {
                title: 'ویرایش اطلاعات و ارائه شناسه ثنا',
                amount: 4600,
            },
            {
                title: 'تحویل رمز در صورت درخواست متقاضی',
                amount: 2300,
            },
            {
                title: 'ورود به صفحه شخصی در سامانه ابلاغ و رویت آن',
                amount: 2530,
            },
            {
                title: 'دریافت و چاپ تصاویر اوراق پرونده بابت هر برگ',
                amount: 1265,
            },
            {
                title: 'دریافت هزینه تمدید پروانه وکلا/کارشناسان/گواهی ترجمه',
                amount: 2530,
            },
            {
                title: 'اطلاع رسانی فرآیند دادرسی از طریق پیامک برای هر فرد',
                amount: 500,
            },
            {
                title: 'تقاضای گواهی عدم سوءپیشینه',
                amount: 17250,
            },
        ],
    },
    {
        year: 1396,
        items: [
            {
                title: 'تنظیم اظهارنامه',
                amount: 15000,
            },
            {
                title: 'ثبت اطلاعات افراد ذی سمت پرونده و اظهارنامه،‌ وکیل، نماینده هر نفر',
                amount: 2200,
            },
            {
                title: 'تنظیم دادخواست/شکوائیه',
                amount: 18000,
            },
            {
                title: 'ثبت اطلاعات و ارائه شناسه قضایی',
                amount: 10000,
            },
            {
                title: 'افزودن پیوست (هر صفحه)',
                amount: 2200,
            },
            {
                title: 'تایپ هر صفحه شرح اضافه',
                amount: 2200,
            },
            {
                title: 'چاپ و تحویل هر برگ اوراق قضایی',
                amount: 1100,
            },
            {
                title: 'پیگیری اظهارنامه، شکوائیه، دادخواست',
                amount: 2200,
            },
            {
                title: 'اطلاع رسانی وضعیت پرونده',
                amount: 2200,
            },
            {
                title: 'ویرایش اطلاعات و ارائه شناسه ثنا',
                amount: 4000,
            },
            {
                title: 'ورود به صفحه شخصی در سامانه ابلاغ و رویت آن',
                amount: 2200,
            },
        ],
    },
];

export default data;
