import ENV from 'env.json';

type apiParams = string | number;

export const __API = {
    account: {
        userData: '/account/me/',
        logout: '/account/user/logout/',
    },
};
