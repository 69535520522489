import { ExtraParams } from '../../../interface';

function article11(extraParams?: number) {
    let answer = 3000000;
    if (extraParams && extraParams >= 0 && extraParams <= 50000000) {
        answer = 3000000;
    } else if (extraParams && extraParams > 50000000 && extraParams <= 150000000) {
        answer = 3000000 + ((extraParams - 50000000) * 0.5) / 100;
    } else if (extraParams && extraParams > 150000000 && extraParams <= 250000000) {
        answer = 3500000 + ((extraParams - 150000000) * 0.4) / 100;
    } else if (extraParams && extraParams > 250000000 && extraParams <= 1000000000) {
        answer = 3900000 + ((extraParams - 250000000) * 0.3) / 100;
    } else if (extraParams && extraParams > 1000000000 && extraParams <= 10000000000) {
        answer = 6150000 + ((extraParams - 1000000000) * 0.125) / 100;
    } else if (extraParams && extraParams > 10000000000 && extraParams <= 150000000000) {
        answer = 17400000 + ((extraParams - 10000000000) * 0.06) / 100;
    } else if (extraParams && extraParams > 150000000000 && extraParams <= 500000000000) {
        answer = 101400000 + ((extraParams - 150000000000) * 0.03) / 100;
    } else if (extraParams && extraParams > 500000000000 && extraParams <= 650000000000) {
        answer = 206400000 + ((extraParams - 500000000000) * 0.025) / 100;
    } else if (extraParams && extraParams > 650000000000 && extraParams <= 1000000000000) {
        answer = 243900000 + ((extraParams - 650000000000) * 0.015) / 100;
    } else if (extraParams && extraParams > 1000000000000 && extraParams <= 3000000000000) {
        answer = 296400000 + ((extraParams - 1000000000000) * 0.012) / 100;
    } else if (extraParams && extraParams > 3000000000000) {
        answer = 536400000 + ((extraParams - 3000000000000) * 0.01) / 100;
    }
    return answer;
}

export const ExpertSalaryData = [
    {
        id: 1,
        payload: 'ارزیابی',
        children: [
            {
                id: 1,
                payload: 'ارزیابی کلیه رشته ها',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
        ],
    },
    {
        id: 2,
        payload: 'مهندسی آب و معادن شامل رشته های: آب، مواد و معادن',
        children: [
            {
                id: 1,
                payload: 'اندازه گیری آب و حقابه ها',
                extraParam: ExtraParams.waterFlow,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 3000000;
                    if (extraParams && extraParams >= 0 && extraParams <= 50) {
                        answer = 3000000;
                    } else if (extraParams && extraParams > 50 && extraParams <= 1000) {
                        answer = 3000000 + (extraParams - 50) * 2000;
                    } else if (extraParams && extraParams > 1000) {
                        answer = 4900000 + (extraParams - 1000) * 800;
                    }
                    answer = answer > 35000000 ? 35000000 : answer;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 2,
                payload: 'تعیین کیفیت شیمیایی و آلودگی آب با توجه به مسائل زیست محیطی',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 3000000;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 3,
                payload: 'بررسی فنی و تعیین مشخصات سفره های سطحی و زیرزمینی آب از هر لحاظ',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 8000000;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 4,
                payload: 'ارزیابی قنوات، چاه ها و منصوبات آن، شبکه های آبیاری و زهکشی آب و فاضلاب',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 5,
                payload: 'تعیین نوع و بررسی کیفیت حفاری چاه ها و قنواات و بهسازی چشمه ها',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 7000000;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 6,
                payload: 'تعیین حریم کانالها و انهار تا عرض بستر ۱۲ متر',
                extraParam: ExtraParams.length,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 3000000;
                    const max = 30000000;
                    if (extraParams && extraParams > 0 && extraParams <= 12) {
                        answer = extraParams * 3000000;
                    }
                    answer = answer > max ? max : answer;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 7,
                payload: 'تعیین حریم رودخانه و کانالهای با عرض بیش از ۱۲ متر',
                extraParam: ExtraParams.length,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 4000000;
                    const max = 50000000;
                    if (extraParams && extraParams >= 0) {
                        answer = extraParams * 4000000;
                    }
                    answer = answer > max ? max : answer;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 8,
                payload: 'رسیدگی به وضع پس آبها به لحاظ تشخصی بار آلودگی بر حسب کمیت و کیفیت',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 5000000;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 9,
                payload: 'ارزیابی فلزات',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 10,
                payload: 'ملاک و مشخصات کیفی و عملکردی تشخیص فلزات',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'حداقل ۱۰ میلیون ریال و حداکثر ۲۵ میلیون ریال';
                },
            },
            {
                id: 11,
                payload: 'ارزیابی ذخائر معدنی (احتمالی - ممکن - قطعی)',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 12,
                payload: 'ارزیابی ذخائر معدنی (احتمالی - ممکن - قطعی) - مربوطه به معادن زیرزمینی',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    const temp = article11(extraParams);
                    let answer = temp * 1.35;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 13,
                payload: 'در صورتی که انجام بررسی های کارشناسی در معادن منتج به ارزیابی نشود',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'به پیشنهاد کارشناس و موافقت مقام ارجاع کننده – کانون یا مرکز تعیین می شود و حداقل دستمزد ۳،۰۰۰،۰۰۰ ریال خواهد بود';
                },
            },
        ],
    },
    {
        id: 3,
        payload: 'اموال منقول',
        children: [
            {
                id: 1,
                payload: 'آثار هنری و اشیاء نفیسه و کتب خطی - اشیاء عتیه و احجار کریمه، ساعت و جواهرات و فرش',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 2,
                payload: 'لوازم خانگی و اداری',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    const temp = article11(extraParams);
                    let answer = temp * 1.2;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
        ],
    },
    {
        id: 4,
        payload: 'امور پزشکی و غذایی',
        children: [
            {
                id: 1,
                payload: 'ارزیابی رشته های پزشکی، داروسازی و سم شناسی، مواد غذایی و مسمومیتهای ناشی از آن',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    const max = 30000000;
                    answer = answer > max ? max : answer;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 2,
                payload: 'رشته های پزشکی، داروسازی و سم شناسی، مواد غذایی و مسمومیتهای ناشی از آن (غیر از ارزیابی)',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'به پیشنهاد کارشناس و موافقت مقام ارجاع کننده – کانون یا مرکز تعیین می شود و حداقل دستمزد ۳،۰۰۰،۰۰۰ ریال خواهد بود';
                },
            },
        ],
    },
    {
        id: 5,
        payload: 'امور مالی',
        children: [
            {
                id: 1,
                payload: 'امور حسابرسی نسبت به جمع ارقام حسابهای مورد رسیدگی',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    const temp = article11(extraParams);
                    let answer = temp * 1.5;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 2,
                payload:
                    'ارزش سهام و سهم الشرکه با توجه به مجموع دارائی های جاری و بدهی ها و ذخائر رسیدگی شده به اضافه قیمت تمام شده دفتری دارائی های ثابت',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 3,
                payload: 'رسیدگی و حسابرسی ترازنامه و حساب سود و زیان و امور توقف و ورشکستگی',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 4,
                payload: 'تعیین قیمت تمام شده کالاها با توجه به ارقام تشکیل دهنده قیمت تمام شده',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 5,
                payload: 'ارزیابی امور بازرگانی',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 6,
                payload: 'امور بازرگانی (غیرارزیابی)',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'حداقل ۳،۰۰۰،۰۰۰ و حداکثر ۴۰،۰۰۰،۰۰۰ ریال';
                },
            },
            {
                id: 7,
                payload: 'تعیین نفقه جاریه زوجه و اقارب',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'حداقل ۲،۰۰۰،۰۰۰ و حداکثر ۳۰،۰۰۰،۰۰۰ ریال';
                },
            },
            {
                id: 8,
                payload: 'ارزیابی رشته سرمایه گذاری پولی و اعتباری و رشته بیمه',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 9,
                payload: 'رشته سرمایه گذاری پولی و اعتباری و رشته بیمه (غیر ارزیابی)',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'به پیشنهاد کارشناس و موافقت مقام ارجاع کننده – کانون یا مرکز تعیین می شود و حداقل دستمزد ۳،۰۰۰،۰۰۰ ریال خواهد بود';
                },
            },
            {
                id: 10,
                payload: 'رشته آمار',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'حداقل ۳،۰۰۰،۰۰۰ و حداکثر ۲۰،۰۰۰،۰۰۰ ریال';
                },
            },
        ],
    },
    {
        id: 6,
        payload: 'امور وسائل نقلیه',
        children: [
            {
                id: 1,
                payload: 'رسیدگی به اصالت خودرو',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return '۲،۵۰۰،۰۰۰ ریال';
                },
            },
            {
                id: 2,
                payload: 'رسیدگی به تصادفات',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return '۳،۰۰۰،۰۰۰ ریال';
                },
            },
            {
                id: 3,
                payload: 'اظهارنظر فنی تعیین تناژ و مدل',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return '۳،۰۰۰،۰۰۰ ریال';
                },
            },
            {
                id: 4,
                payload: 'مسئولیت صادر کننده اسناد حمل با توجه به مبلغ خواسته',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 5,
                payload: 'تعیین ارزش وسائط نقلیه آبی',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 6,
                payload: 'تعیین علت در صورت تصادم بین قایقهای تفریحی و مسافربری در آبهای داخلی',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return '۵،۰۰۰،۰۰۰ ریال';
                },
            },
            {
                id: 7,
                payload:
                    'تعیین علت در تصادم دریایی یا تصادم در آبهای داخلی (برای شناورهای با ظرفیت بار کمتر از ۵۰۰ تن)',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return '۱۰،۰۰۰،۰۰۰ ریال';
                },
            },
            {
                id: 8,
                payload:
                    'تعیین علت در تصادم دریایی یا تصادم در آبهای داخلی (برای شناورهای با ظرفیت بار بالاتر از ۵۰۰ تن)',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return '۲۰،۰۰۰،۰۰۰ ریال';
                },
            },
            {
                id: 9,
                payload: 'غواصی و عملیات زیر آبی',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'به پیشنهاد کارشناس و موافقت مقام ارجاع کننده – کانون یا مرکز تعیین می شود و حداقل دستمزد ۳،۰۰۰،۰۰۰ ریال خواهد بود';
                },
            },
            {
                id: 10,
                payload: 'تعیین خسارت و ارزیابی های وسائل نقلیه هوایی، ریلی و زمینی',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
        ],
    },
    {
        id: 7,
        payload: 'راه و ساختمان و نقشه برداری',
        children: [
            {
                id: 1,
                payload: 'ارزیابی اراضی غیر مزروعی و ابنیه و مصالح ساختمانی و تعیین حقوق کسب و پیشه و تجارت و سرقفلی',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 2,
                payload: 'امور معماری داخلی و تزئینات',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'به پیشنهاد کارشناس و موافقت مقام ارجاع کننده – کانون یا مرکز تعیین می شود و حداقل دستمزد ۳،۰۰۰،۰۰۰ ریال خواهد بود';
                },
            },
            {
                id: 3,
                payload: 'تطبیق نقشه های معماری یا سازه با وضعیت محل و بنا',
                extraParam: ExtraParams.area,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 4000000;
                    const max = 40000000;
                    extraParams && (answer = extraParams > 400 ? extraParams * 10000 : 4000000);
                    answer = answer > max ? max : answer;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 4,
                payload: 'دستمزد تطبیق مشخصات قراردادی ساختمان ها با وضعیت محل و بنا',
                extraParam: ExtraParams.area,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 4000000;
                    const max = 40000000;
                    extraParams && (answer = extraParams > 400 ? extraParams * 10000 : 4000000);
                    answer = answer > max ? max : answer;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 5,
                payload: 'تهیه نقشه های معماری و سازه یا گزارش فنی استحکام بنای ساختمان های موجود (معماری کلی)',
                extraParam: ExtraParams.area,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 4000000;
                    const max = 60000000;
                    extraParams && (answer = extraParams > 500 ? extraParams * 8000 : 4000000);
                    answer = answer > max ? max : answer;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 6,
                payload: 'تهیه نقشه های معماری و سازه یا گزارش فنی استحکام بنای ساختمان های موجود (سازه)',
                extraParam: ExtraParams.area,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 4000000;
                    const max = 80000000;
                    extraParams && (answer = extraParams > 400 ? extraParams * 10000 : 4000000);
                    answer = answer > max ? max : answer;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 7,
                payload:
                    'تهیه نقشه های معماری و سازه یا گزارش فنی استحکام بنای ساختمان های موجود (گزارش فنی استحکام بنا)',
                extraParam: ExtraParams.area,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 4000000;
                    const max = 60000000;
                    extraParams && (answer = extraParams > 500 ? extraParams * 8000 : 4000000);
                    answer = answer > max ? max : answer;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 8,
                payload:
                    'افراز و قطعه بندی مستلزم تهیه نقشه دقیق قطعات افرازی و صورت ارزیابی آنها، طرح قطعات مفروزه بطور واضح و روشن',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    const temp = article11(extraParams);
                    let answer = 1.2 * temp;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 9,
                payload: 'تشخیص زمین مسبوق به احیاء از نظر ساختمانی',
                extraParam: ExtraParams.area,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 3000000;
                    extraParams &&
                        (answer = extraParams <= 1000 ? 3000000 : Math.floor((extraParams - 1000) / 1000) * 1000000);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 10,
                payload: 'پیاده کردن محدوده پلاک ثبتی در نقشه و عکس هوایی (گذربند مشخص باشد)',
                extraParam: ExtraParams.area,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 4000000;
                    const max = 80000000;
                    if (extraParams && extraParams >= 0 && extraParams <= 1000) {
                        answer = 4000000;
                    } else if (extraParams && extraParams > 1000 && extraParams <= 100000) {
                        answer = 4000000 + (extraParams - 1000) * 400;
                    } else if (extraParams && extraParams > 100000) {
                        answer = 43600000 + (extraParams - 100000) * 200;
                    }
                    answer = answer > max ? max : answer;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 11,
                payload: 'پیاده کردن محدوده پلاک ثبتی در نقشه و عکس هوایی (گذربند مشخص نباشد)',
                extraParam: ExtraParams.area,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 10000000;
                    const max = 100000000;
                    if (extraParams && extraParams >= 0 && extraParams <= 1000) {
                        answer = 10000000;
                    } else if (extraParams && extraParams > 1000 && extraParams <= 100000) {
                        answer = 10000000 + (extraParams - 1000) * 400;
                    } else if (extraParams && extraParams > 100000) {
                        answer = 49600000 + (extraParams - 100000) * 200;
                    }
                    answer = answer > max ? max : answer;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 12,
                payload: 'نقشه برداری اراضی و تهیه پروفیل و غیره',
                extraParam: ExtraParams.area,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 10000000;
                    const max = 100000000;
                    if (extraParams && extraParams >= 0 && extraParams <= 1000) {
                        answer = 10000000;
                    } else if (extraParams && extraParams > 1000 && extraParams <= 100000) {
                        answer = 10000000 + (extraParams - 1000) * 400;
                    } else if (extraParams && extraParams > 100000) {
                        answer = 49600000 + (extraParams - 100000) * 200;
                    }
                    answer = answer > max ? max : answer;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 13,
                payload: 'مطالعه پرونده ثبتی و تعیین محل پلاک مورد نظر در املاک',
                extraParam: ExtraParams.area,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 3000000;
                    const max = 60000000;
                    if (extraParams && extraParams >= 0 && extraParams <= 1000) {
                        answer = 3000000;
                    } else if (extraParams && extraParams > 1000 && extraParams <= 5000) {
                        answer = 3000000 + (extraParams - 1000) * 1500;
                    } else if (extraParams && extraParams > 5000 && extraParams <= 50000) {
                        answer = 9000000 + (extraParams - 5000) * 800;
                    } else if (extraParams && extraParams > 50000) {
                        answer = 45000000 + (extraParams - 50000) * 400;
                    }
                    answer = answer > max ? max : answer;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 14,
                payload:
                    'نقشه برداری و تهیه نقشه موقعیت قنوات و مسیر انهاروکانالها و رودخانه ها با مقیاس ۱/۱۰۰۰ به صورت پیمایش با تعیین محل چاه ها',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'حداقل ۵،۰۰۰،۰۰۰ ریال است و حداکثر طبق ماده ۹ این تعرفه است.';
                },
            },
            {
                id: 15,
                payload:
                    'مطالعه پرونده ثبتی و پیاده نمودن پلاک مورد نظر و تشخیص حدود آن در املاک مزروعی (خارج از محدوده شهر ها)',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'تا یک هکتار حداقل ۴،۰۰۰،۰۰۰ ریال و به ازاء هر هکتار مازاد، بیست درصد به دستمزد اضافه می شود و حداکثر بیست میلیون ریال';
                },
            },
            {
                id: 16,
                payload: 'بررسی انطباق طرح های تفکیک اراضی شهری با ضوابط و مقررات شهرسازی',
                extraParam: ExtraParams.pieces,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 15000000;
                    if (extraParams && extraParams >= 0 && extraParams <= 2) {
                        answer = 15000000;
                    } else if (extraParams && extraParams >= 3 && extraParams <= 10) {
                        answer = 15000000 + (extraParams - 2) * 2000000;
                    } else if (extraParams && extraParams > 10) {
                        answer = 31000000 + (extraParams - 10) * 600000;
                    }
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 17,
                payload: 'بررسی انطباق کاربری اراضی شهری با طرح های توسعه شهری',
                extraParam: ExtraParams.pieces,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 10000000;
                    if (extraParams && extraParams >= 0 && extraParams <= 2) {
                        answer = 10000000;
                    } else if (extraParams && extraParams >= 3 && extraParams <= 10) {
                        answer = 10000000 + (extraParams - 2) * 15000000;
                    } else if (extraParams && extraParams > 10) {
                        answer = 22000000 + (extraParams - 10) * 600000;
                    }
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 18,
                payload: 'انطباق شهری ساختمان ها به ضوابط و مقررات شهرسازی',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 10000000;
                    if (extraParams && extraParams >= 0 && extraParams <= 2) {
                        answer = 10000000;
                    } else if (extraParams && extraParams >= 3 && extraParams <= 10) {
                        answer = 10000000 + (extraParams - 2) * 15000000;
                    } else if (extraParams && extraParams > 10) {
                        answer = 22000000 + (extraParams - 10) * 600000;
                    }
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 19,
                payload: 'سایر زیرگروه های رشته راه و ساختمان و نقشه برداری',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'به پیشنهاد کارشناس و موافقت مقام ارجاع کننده – کانون یا مرکز تعیین می شود و حداقل دستمزد ۳،۰۰۰،۰۰۰ ریال خواهد بود';
                },
            },
        ],
    },
    {
        id: 8,
        payload: 'صنعت و فن',
        children: [
            {
                id: 1,
                payload:
                    'کارشناسی و ارزیابی رشته های الکتروشیمی، پتروشیمی و فرآورده های شیمیایی، امور انرژی هسته ای، برق، الکترونیک و مخابرات، برق، ماشین و تاسیسات کارخانجات ، تاسیسات ساختمانی، کامپیوتر و فناوری اطلاعات، صنایع گاز و گازرسانی، صنایع نفت، صنایع هوایی و فضایی، مهندسی پزشکی، نساجی و رنگرزی',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 2,
                payload: 'تعیین مشخصات فنی ماشین آلات تولیدی و صنعتی و استهلاکات آنها و تعیین عمر مفید باقیمانده',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    const temp = article11(extraParams);
                    let answer = temp * 0.4;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 3,
                payload: 'نرم افزار کامپیوتر',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    const temp = article11(extraParams);
                    let answer = temp * 1.5;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
        ],
    },
    {
        id: 9,
        payload: 'فنون هنری',
        children: [
            {
                id: 1,
                payload:
                    'کارشناسی رشته تشخیص اصالت خط و امضاء و اثر انگشت در پرونده های غیر مالی و در هر مورد بررسی ( هر مستند )',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'حداقل ۴،۰۰۰،۰۰۰ و حداکثر ۳۰،۰۰۰،۰۰۰ ریال';
                },
            },
            {
                id: 2,
                payload: 'کارشناسی رشته تشخیص اصالت خط و امضاء و اثر انگشت در پرونده های مالی',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    const max = 50000000;
                    answer = answer > max ? max : answer;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 3,
                payload:
                    'کارشناسی مسائل فنی عکس و فیلم و اختلاف رنگها، شرایط نوری، کمپوزیسیون( ترکیب بندی )، پرسپکتیو، ظهور و چاپ   ',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'حداقل ۴،۰۰۰،۰۰۰ و حداکثر ۴۰،۰۰۰،۰۰۰ ریال';
                },
            },
            {
                id: 4,
                payload:
                    'رسیدگی به موارد اختلاف فیمابین کارفرمایان و تهیه کنندگان اعم از نویسنده،کارگردان، تصویربردار، تدوین کننده، لابراتوار و صدابردار (برنامه های کوتاه مدت و مستند)',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'حداقل ۵،۰۰۰،۰۰۰ ریال';
                },
            },
            {
                id: 5,
                payload:
                    'رسیدگی به موارد اختلاف فیمابین کارفرمایان و تهیه کنندگان اعم از نویسنده،کارگردان، تصویربردار، تدوین کننده، لابراتوار و صدابردار (برنامه های بلند مدت سینمایی بیشتر از یک ساعت)',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'بر حسب مدت فیلم و حداکثر ۴۰،۰۰۰،۰۰۰ ریال';
                },
            },
            {
                id: 6,
                payload: 'ارزیابی فیلم، وسایل و لوازم عکاسی و فیلمبرداری و سینما و ویدئو',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 7,
                payload: 'کارشناس در امور ورزشی بدون موارد جرح یا فوتی',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return '۵،۰۰۰،۰۰۰ ریال';
                },
            },
            {
                id: 8,
                payload: 'کارشناس در امور ورزشی با موارد جرح یا فوتی',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'حداکثر ۴۰،۰۰۰،۰۰۰';
                },
            },
            {
                id: 9,
                payload: 'کارشناسی رشته چاپ و چاپخانه',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'حداقل ۴،۰۰۰،۰۰۰ و حداکثر ۳۰،۰۰۰،۰۰۰ ریال';
                },
            },
            {
                id: 10,
                payload: 'کارشناسی رشته طراحی و گرافیک (غیرمالی)',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'حداقل ۴،۰۰۰،۰۰۰ ریال';
                },
            },
            {
                id: 11,
                payload: 'کارشناسی رشته طراحی و گرافیک (مالی)',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 12,
                payload: 'کارشناسی رشته طراحی و گرافیک (سایر موارد)',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'به پیشنهاد کارشناس و موافقت مقام ارجاع کننده – کانون یا مرکز تعیین می شود و حداقل دستمزد ۳،۰۰۰،۰۰۰ ریال خواهد بود';
                },
            },
        ],
    },
    {
        id: 10,
        payload: 'کشاورزی و منابع طبیعی',
        children: [
            {
                id: 1,
                payload:
                    'ارزیابی رشته های آبزیان و شیلات، دامپروری و دامپزشکی، صنایع چوب،کشاورزی و منابع طبیعی، گیاه پزشکی، محصولات دامی ( پوست، چرم – سالامبور و روده )، محیط زیست طبیعی',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 2,
                payload:
                    'تعیین حق آبادانی و حق ریشه و زراعت و نسق زارعانه و تعیین هزینه احیاء و اصلاح و تسطیح زمینهای کشاورزی و باغات و حق غارسی',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 3,
                payload:
                    'نمونه برداری به منظور تعیین کیفیت و طبقه بندی محصولات زراعی، باغی، دامی، آبزیان، خوراک دام،مکمل و داروهای مصرفی دامی',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'به پیشنهاد کارشناس و موافقت مقام ارجاع کننده – کانون یا مرکز تعیین می شود و حداقل دستمزد ۳،۰۰۰،۰۰۰ ریال خواهد بود';
                },
            },
            {
                id: 4,
                payload: 'تشخیص اراضی ( دایر و بایر و موات ) ',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'خراب';
                },
            },
            {
                id: 5,
                payload: 'افراز املاک کشاورزی',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    const temp = article11(extraParams);
                    let answer = temp * 1.2;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 6,
                payload:
                    'ارزیابی تجهیزات مرتبط و مواد اولیه و محصولات و فرآورده های کشاورزی و دامی و جنگلی و شیلات و زنبورداری و غیره موجود در انبارها',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
        ],
    },
    {
        id: 11,
        payload: 'خدمات اداری و عمومی',
        children: [
            {
                id: 1,
                payload:
                    'رشته های امور آموزشی، امور اداری و استخدامی ، امور خبرنگاری و روزنامه نگاری ، امورگمرکی ، ایرانگردی و جهانگردی ، ثبت شرکتها، علائم تجاری و اختراعات ، زبانهای خارجی ، کتاب و کتابداری (غیر از ارزیابی و خسارت)',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'حداقل ۴،۰۰۰،۰۰۰ ریال';
                },
            },
            {
                id: 2,
                payload:
                    'رشته های امور آموزشی، امور اداری و استخدامی ، امور خبرنگاری و روزنامه نگاری ، امورگمرکی ، ایرانگردی و جهانگردی ، ثبت شرکتها، علائم تجاری و اختراعات ، زبانهای خارجی ، کتاب و کتابداری (ارزیابی و خسارت)',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = article11(extraParams);
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
        ],
    },
    {
        id: 12,
        payload: 'ایمنی و حوادث',
        children: [
            {
                id: 1,
                payload:
                    'رشته های امور آتش سوزی و آتش نشانی ، امور اسلحه و مهمات ، امنیت عمومی ، حوادث ناشی از کار ، مواد محترقه و منفجره',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'حداقل ۵،۰۰۰،۰۰۰ ریال';
                },
            },
        ],
    },
    {
        id: 13,
        payload: 'بیو و نانوتکنولوژی',
        children: [
            {
                id: 1,
                payload: 'کارشناسی گروه بیو و نانو تکنولوژی',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'به پیشنهاد کارشناس و موافقت مقام ارجاع کننده – کانون یا مرکز تعیین می شود و حداقل دستمزد ۳،۰۰۰،۰۰۰ ریال خواهد بود';
                },
            },
        ],
    },
    {
        id: 14,
        payload: 'سایر',
        children: [
            {
                id: 1,
                payload: 'تأمین دلیل',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'حداقل ۲،۰۰۰،۰۰۰ ریال';
                },
            },
            {
                id: 2,
                payload:
                    'بررسی به اختلاف فی مابین کارفرمایان و پیمانکاران و مهندسان مشاور و همچنین قراردادهای مربوط و دستمزد تعیین و کنترل صورت وضعیت برای کلیه رشته ها، بر مبنای مبلغ قرارداد',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    const temp = article11(extraParams);
                    let answer = temp * 2;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 3,
                payload: 'تعیین علل و مسببین و تعیین خسارت برای کلیه رشته ها بر مبنای میزان خسارت تعیین شده',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    const temp = article11(extraParams);
                    let answer = temp * 1.5;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 4,
                payload: 'تشخیص تخلف از مفاد قرارداد و یا رسیدگی به تأخیر قرارداد',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    const temp = article11(extraParams);
                    let answer = temp * 1.5;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 5,
                payload:
                    'ارزیابی قطعات یدکی،کالا و لوازم مستعمل، فرآورده های غذایی و دارویی و بهداشتی، کلیه ضایعات، ابزارآلات، ماشین آلات صنعتی و تجهیزات برقی و مکانیکی، اجزای ریز الکترونیک و مخابرات، در هر کار ارجاعی بصورت مستقل و یکجا',
                extraParam: ExtraParams.costs,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    const temp = article11(extraParams);
                    let answer = temp * 1.5;
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 6,
                payload: 'تعیین اجاره بهاء برای کلیه رشته ها جهت هر واحد یا مجموعه مستقل',
                extraParam: ExtraParams.rent,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 3000000;
                    if (extraParams && extraParams >= 0 && extraParams <= 15000000) {
                        answer = 3000000;
                    } else if (extraParams && extraParams > 15000000 && extraParams <= 50000000) {
                        answer = 3000000 + (extraParams - 15000000) / 10;
                    } else if (extraParams && extraParams > 50000000 && extraParams <= 100000000) {
                        answer = 6500000 + (extraParams - 50000000) / 20;
                    } else if (extraParams && extraParams > 100000000) {
                        answer = 9000000 + (extraParams - 100000000) / 50;
                    }
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 7,
                payload: 'تعیین اجرت المثل یا اجور گذشته',
                extraParam: ExtraParams.rent,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    let answer = 3750000;
                    if (extraParams && extraParams >= 0 && extraParams <= 15000000) {
                        answer = 3750000;
                    } else if (extraParams && extraParams > 15000000 && extraParams <= 50000000) {
                        answer = 3750000 + ((extraParams - 15000000) / 10) * 1.25;
                    } else if (extraParams && extraParams > 50000000 && extraParams <= 100000000) {
                        answer = 8125000 + ((extraParams - 50000000) / 20) * 1.25;
                    } else if (extraParams && extraParams > 100000000) {
                        answer = 11250000 + ((extraParams - 100000000) / 50) * 1.25;
                    }
                    answer = isSeveralExperts ? answer * 0.7 : answer;
                    days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
                    return answer;
                },
            },
            {
                id: 8,
                payload:
                    'تفسیر عکس های هوائی و ماهواره ای ارائه شده برای هر پلاک ثبتی و تعیین موقعیت ثبتی آن در عکس های مزبور',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return '۳،۰۰۰،۰۰۰ ریال';
                },
            },
            {
                id: 9,
                payload: 'تفسیر عکسهای هوایی و ماهواره ای مربوط به سالهای گذشته',
                extraParam: ExtraParams.none,
                calculate: (isSeveralExperts: boolean, days: number, isInCity: boolean, extraParams?: number) => {
                    return 'برای هر پلاک ثبتی و تعیین موقعیت ثبتی آن در عکس های مزبور مقطوعاً مبلغ ۳،۰۰۰،۰۰۰ ریال است و برای تفسیرعکس های مذکور مربوط به سالهای گذشته، به ازای هر سال تصویر و عکس اظهار نظر شده مبلغ پانصد هزار ریال اضافه خواهد شد';
                },
            },
        ],
    },
];
