export function emailValidation(value: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value.toLowerCase());
}

export function passwordValidation(value: string) {
    const re = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
    return re.test(value);
}

export function urlValidation(value: string) {
    const re = /[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    return re.test(value);
}

export function usernameValidation(value: string) {
    const re = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/i;
    return re.test(value);
}

export function getValidUrl(url = '') {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, '');

    if (/^(:\/\/)/.test(newUrl)) {
        return `http${newUrl}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `http://${newUrl}`;
    }
    return newUrl;
}

export function numbersSeparator(num: any, sep: undefined | string) {
    const number = typeof num === 'number' ? num.toString() : num,
        separator = typeof sep === 'undefined' ? ',' : sep;
    return number?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + separator);
}
