import React, { useState } from 'react';
import './item.style.scss';

const FractionComponentItem: React.FC<{ onChange: (e: { up: number; down: number }) => void }> = (props: {
    onChange: (e: { up: number; down: number }) => void;
}) => {
    const [up, setUp] = useState<number>(1);
    const [down, setDown] = useState<number>(2);
    function onChangeHandler(type: 'up' | 'down', _value: string) {
        const value = parseInt(_value);
        if (type === 'up') {
            setUp(value);
            props.onChange({ up: value, down });
        } else if (type === 'down') {
            setDown(value);
            props.onChange({ up, down: value });
        }
    }
    return (
        <div className="vekalapp-fraction-component-item">
            <input value={up} onChange={(e) => onChangeHandler('up', e.target.value)} type="number" />
            <div className="border" />
            <input value={down} onChange={(e) => onChangeHandler('down', e.target.value)} type="number" />
        </div>
    );
};

export default FractionComponentItem;
