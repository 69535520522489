import { Button } from 'vekalapp-react-utilities';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CalculationCardWebProps } from './calculationWebCard.interface';
import './calculationWebCard.style.scss';

const CalculationWebCard: React.FC<CalculationCardWebProps> = (props: CalculationCardWebProps) => {
    function hexToRgb(hex: string) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16),
              }
            : null;
    }
    const history = useHistory();
    return (
        <div className="vekalapp-calculation-card-list">
            <div className="card-content">
                <p className="calculate">محاسبه</p>
                <h4 className="title">{props.title}</h4>
                <p className="text">{props.text}</p>
            </div>
            <div className="card-footer">
                {props.buttons.map((btn, key) => (
                    <Link to={btn.path} key={key}>
                        <Button>{btn.title}</Button>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default CalculationWebCard;
