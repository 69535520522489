import { Form11 } from '../../../interface';
import { numbersSeparator } from '../../../scripts/validation';

function handleFirst(priceReq) {
    let result = 0;
    if (priceReq >= 0 && priceReq <= 200000000) {
        result = (priceReq * 2.5) / 100;
    } else if (priceReq > 200000000) {
        result = 5000000 + ((priceReq - 200000000) * 3.5) / 100;
    }
    return result;
}

export const LitigationCost = [
    {
        id: 1,
        title: 'مالی',
        param1: Form11.priceRequested, //بهای خواسته یا محکومً به
        param2: [
            {
                title: 'همه موارد',
                payload: 7,
                checkbox: null,
            },
            {
                title: 'بدوی',
                payload: 1,
                checkbox: 'دعوا در شورای حل اختلاف مطرح خواهد شد',
            },
            {
                title: 'واخواهی',
                payload: 2,
                checkbox: 'دعوا در شورای حل اختلاف مطرح بوده است',
            },
            {
                title: 'تجدیدنظر',
                payload: 3,
                checkbox: 'دعوا در شورای حل اختلاف مطرح بوده است',
            },
            {
                title: 'اعاده دادرسی',
                payload: 4,
                checkbox: 'دعوا در شورای حل اختلاف مطرح بوده است',
            },
            {
                title: 'فرجام خواهی',
                payload: 5,
                checkbox: null,
            },
            {
                title: 'اعتراض ثالث',
                payload: 6,
                checkbox: 'دعوا در شورای حل اختلاف مطرح بوده است',
            },
        ], //مرحله
        param3: null, //نوع غیرمالی
        param4: null, //مبلغ چک
        param5: null, //قیمت سکه
        param6: null, //تعداد سکه
        param7: null, //موضوع
        calculate: (year, priceReq, level, checkbox1, checkbox2) => {
            let answer = 0;
            let result: any = '';
            if (level === 1) {
                answer = checkbox1 ? handleFirst(priceReq) / 2 : handleFirst(priceReq);
            } else if (level === 2) {
                answer = checkbox2 ? (priceReq * 4.5) / 200 : (priceReq * 4.5) / 100;
            } else if (level === 3) {
                answer = checkbox2 ? (priceReq * 4.5) / 200 : (priceReq * 4.5) / 100;
            } else if (level === 4) {
                answer = checkbox2 ? (priceReq * 5.5) / 200 : (priceReq * 5.5) / 100;
            } else if (level === 5) {
                answer = (priceReq * 5.5) / 100;
            } else if (level === 6) {
                answer = checkbox2 ? (priceReq * 5.5) / 200 : (priceReq * 5.5) / 100;
            } else if (level === 7) {
                const temp = checkbox1 ? handleFirst(priceReq) / 2 : handleFirst(priceReq);
                const temp2 = checkbox2 ? (priceReq * 4.5) / 200 : (priceReq * 4.5) / 100;
                const temp3 = checkbox2 ? (priceReq * 5.5) / 200 : (priceReq * 5.5) / 100;
                result =
                    'هزینه دادرسی در مرحله بدوی ' +
                    temp +
                    ' | ' +
                    'هزینه دادرسی در مرحله واخواهی ' +
                    temp2 +
                    ' | ' +
                    'هزینه دادرسی در مرحله تجدیدنظر ' +
                    temp2 +
                    ' | ' +
                    'هزینه دادرسی در مرحله اعاده دادرسی ' +
                    temp3 +
                    ' | ' +
                    'هزینه دادرسی در مرحله فرجام خواهی ' +
                    (priceReq * 5.5) / 100 +
                    ' | ' +
                    'هزینه دادرسی در مرحله اعتراض ثالث ' +
                    temp3;
            }
            if (level === 7) {
                return result;
            } else {
                return answer;
            }
        },
        documentationText: (year, level, checkbox1, checkbox2) => {
            if (year === 1402) {
                if (level === 1) {
                    if (!checkbox1) return 'قانون بودجه سال ۱۴۰۲ کل کشور';
                    else
                        return 'قانون بودجه سال ۱۴۰۲ کل کشور از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                } else if (level === 2) {
                    if (!checkbox2) return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه';
                    else
                        return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                } else if (level === 3) {
                    if (!checkbox2) return 'قانون بودجه سال ۱۴۰۲ کل کشور';
                    else
                        return 'قانون بودجه سال ۱۴۰۲ کل کشور از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                } else if (level === 4) {
                    if (!checkbox2) return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه';
                    else
                        return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                } else if (level === 5) {
                    return 'قانون بودجه سال ۱۴۰۲ کل کشور';
                } else if (level === 6) {
                    if (!checkbox2) return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه';
                    else
                        return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                }
            } else if (year === 1401) {
                if (level === 1) {
                    if (!checkbox1) return 'قانون بودجه سال ۱۴۰۰ و ۱۴۰۱ کل کشور';
                    else
                        return 'قانون بودجه سال ۱۴۰۰ و ۱۴۰۱ کل کشور از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                } else if (level === 2) {
                    if (!checkbox2) return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه';
                    else
                        return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                } else if (level === 3) {
                    if (!checkbox2) return 'قانون بودجه سال ۱۴۰۰ و ۱۴۰۱ کل کشور';
                    else
                        return 'قانون بودجه سال ۱۴۰۰ و ۱۴۰۱ کل کشور از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                } else if (level === 4) {
                    if (!checkbox2) return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه';
                    else
                        return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                } else if (level === 5) {
                    return 'قانون بودجه سال ۱۴۰۰ و ۱۴۰۱ کل کشور';
                } else if (level === 6) {
                    if (!checkbox2) return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه';
                    else
                        return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                }
            } else if (year === 1400) {
                if (level === 1) {
                    if (!checkbox1) return 'قانون بودجه سال ۱۴۰۰ کل کشور';
                    else
                        return 'قانون بودجه سال ۱۴۰۰ کل کشور از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                } else if (level === 2) {
                    if (!checkbox2) return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه';
                    else
                        return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                } else if (level === 3) {
                    if (!checkbox2) return 'قانون بودجه سال ۱۴۰۰ کل کشور';
                    else
                        return 'قانون بودجه سال ۱۴۰۰ کل کشور از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                } else if (level === 4) {
                    if (!checkbox2) return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه';
                    else
                        return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                } else if (level === 5) {
                    return 'قانون بودجه سال ۱۴۰۰ کل کشور';
                } else if (level === 6) {
                    if (!checkbox2) return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه';
                    else
                        return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                }
            } else if (year === 1399) {
                if (level === 1) {
                    if (!checkbox1) return 'قانون بودجه سال ۱۳۹۹ کل کشور';
                    else
                        return 'قانون بودجه سال ۱۳۹۹ کل کشور از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                } else if (level === 2) {
                    if (!checkbox2) return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه';
                    else
                        return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                } else if (level === 3) {
                    if (!checkbox2) return 'قانون بودجه سال ۱۳۹۹ کل کشور';
                    else
                        return 'قانون بودجه سال ۱۳۹۹ کل کشور از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                } else if (level === 4) {
                    if (!checkbox2) return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه';
                    else
                        return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
                } else if (level === 5) {
                    return 'قانون بودجه سال ۱۳۹۹ کل کشور';
                } else if (level === 6) {
                    if (!checkbox2) return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه';
                    else
                        return 'هزینه دادرسی محاکم دادگستری در مرحله اعتراض ثالث معادل پنج و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
                }
            }
        },
        descriptionText: (year, level, checkbox1, checkbox2) => {
            if (year === 1402) {
                if (level === 1)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله بدوی تا مبلغ دویست میلیون ریال معادل دو و نیم درصد و نسبت به مازاد بر آن معادل سه و نیم درصد بهای خواسته محاسبه می گردد.';
                else if (level === 2)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله واخواهی معادل چهار و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
                else if (level === 3)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله تجدیدنظر معادل چهار و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
                else if (level === 4)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله اعاده دادرسی معادل پنج و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
                else if (level === 5)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله فرجام خواهی معادل پنج و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
                else if (level === 6)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله اعتراض ثالث معادل پنج و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
            } else if (year === 1401) {
                if (level === 1)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله بدوی تا مبلغ دویست میلیون ریال معادل دو و نیم درصد و نسبت به مازاد بر آن معادل سه و نیم درصد بهای خواسته محاسبه می گردد.';
                else if (level === 2)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله واخواهی معادل چهار و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
                else if (level === 3)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله تجدیدنظر معادل چهار و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
                else if (level === 4)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله اعاده دادرسی معادل پنج و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
                else if (level === 5)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله فرجام خواهی معادل پنج و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
                else if (level === 6)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله اعتراض ثالث معادل پنج و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
            } else if (year === 1400) {
                if (level === 1)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله بدوی تا مبلغ دویست میلیون ریال معادل دو و نیم درصد و نسبت به مازاد بر آن معادل سه و نیم درصد بهای خواسته محاسبه می گردد.';
                else if (level === 2)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله واخواهی معادل چهار و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
                else if (level === 3)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله تجدیدنظر معادل چهار و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
                else if (level === 4)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله اعاده دادرسی معادل پنج و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
                else if (level === 5)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله فرجام خواهی معادل پنج و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
                else if (level === 6)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله اعتراض ثالث معادل پنج و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
            } else if (year === 1399) {
                if (level === 1)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله بدوی تا مبلغ دویست میلیون ریال معادل دو و نیم درصد و نسبت به مازاد بر آن معادل سه و نیم درصد بهای خواسته محاسبه می گردد.';
                else if (level === 2)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله واخواهی معادل چهار و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
                else if (level === 3)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله تجدیدنظر معادل چهار و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
                else if (level === 4)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله اعاده دادرسی معادل پنج و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
                else if (level === 5)
                    return 'هزینه دادرسی محاکم دادگستری در مرحله فرجام خواهی معادل پنج و نیم درصد بهای خواسته یا محکومً به محاسبه می گردد.';
                else if (level === 6)
                    return 'بخشنامه مورخ ۹۶/۱/۲۸ رئیس قوه قضائیه از قانون شوراهای حل اختلاف (مصوب ١٣٩۴/٨/١۰ مجلس شورای اسلامی) : ماده ۲۳ - هزینه رسیدگی شورای حل اختلاف در کلیه مراحل، در دعاوی کیفری و غیرمالی معادل هزینه دادرسی در محاکم دادگستری و در دعاوی مالی معادل پنجاه درصد (۵۰%) آن است. رسیدگی به دعاوی مشمول ماده (۸) این قانون بدون هزینه دادرسی است.';
            }
        },
    },
    {
        id: 2,
        title: 'غیرمالی',
        param1: null, //بهای خواسته یا محکومً به
        param2: [
            {
                title: 'همه موارد',
                payload: 7,
                checkbox: null,
            },
            {
                title: 'بدوی',
                payload: 1,
            },
            {
                title: 'واخواهی',
                payload: 2,
            },
            {
                title: 'تجدیدنظر',
                payload: 3,
            },
            {
                title: 'اعاده دادرسی',
                payload: 4,
            },
            {
                title: 'فرجام خواهی',
                payload: 5,
            },
            {
                title: 'اعتراض ثالث',
                payload: 6,
            },
        ], //مرحله
        param3: [
            {
                title: 'مربوط به اموال غیرمنقول',
                payload: 1,
            },
            {
                title: 'مربوط به امور تجاری و شرکتها',
                payload: 2,
            },
            {
                title: 'مربوط به اسناد سجلی',
                payload: 3,
            },
            {
                title: 'امور حسبی',
                payload: 4,
            },
            {
                title:
                    'دعاوی مربوط به امور خانواده (موضوع صلاحیت دادگاه خانواده) و تامین خواسته و دستور موقت راجع به آن به استثناء دعاوی قابل فرجام',
                payload: 5,
            },
            {
                title: 'درخواست تامین خواسته و دستور موقت، به استثناء امور خانواده',
                payload: 6,
            },
            {
                title: 'اعتراض به آراء مراجع غیر دادگستری',
                payload: 7,
            },
            {
                title: 'دعاوی قابل فرجام',
                payload: 8,
            },
            {
                title: 'سایر دعاوی',
                payload: 9,
            },
        ], //نوع غیرمالی
        param4: null, //مبلغ چک
        param5: null, //قیمت سکه
        param6: null, //تعداد سکه
        param7: null, //موضوع
        calculate: (year, nonFinancialType, level) => {
            let answer;
            if (nonFinancialType === 1 || nonFinancialType === 2 || nonFinancialType === 9) {
                if (level === 1) {
                    answer = 1000000;
                } else if (level === 2) {
                    answer = 1200000;
                } else if (level === 3) {
                    answer = 1500000;
                } else if (level === 4) {
                    answer = 1800000;
                } else if (level === 5) {
                    answer = 'موجود نیست';
                } else if (level === 6) {
                    answer = 1800000;
                } else if (level === 7) {
                    answer =
                        'هزینه دادرسی در مرحله بدوی ۱,۰۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله واخواهی ۱,۲۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله تجدیدنظر ۱,۵۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله اعاده دادرسی ۱,۸۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله فرجام خواهی موجود نیست' +
                        ' | ' +
                        'هزینه دادرسی در مرحله اعتراض ثالث ۱,۸۰۰,۰۰۰';
                }
            } else if (nonFinancialType === 3) {
                if (level === 1) {
                    answer = 750000;
                } else if (level === 2) {
                    answer = 1200000;
                } else if (level === 3) {
                    answer = 1500000;
                } else if (level === 4) {
                    answer = 1800000;
                } else if (level === 5) {
                    answer = 'موجود نیست';
                } else if (level === 6) {
                    answer = 1800000;
                } else if (level === 7) {
                    answer =
                        'هزینه دادرسی در مرحله بدوی ۷۵۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله واخواهی ۱,۲۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله تجدیدنظر ۱,۵۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله اعاده دادرسی ۱,۸۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله فرجام خواهی موجود نیست' +
                        ' | ' +
                        'هزینه دادرسی در مرحله اعتراض ثالث ۱,۸۰۰,۰۰۰';
                }
            } else if (nonFinancialType === 4) {
                if (level === 1) {
                    answer = 750000;
                } else if (level === 2) {
                    answer = 1200000;
                } else if (level === 3) {
                    answer = 1500000;
                } else if (level === 4) {
                    answer = 1800000;
                } else if (level === 5) {
                    answer = 1500000;
                } else if (level === 6) {
                    answer = 1800000;
                } else if (level === 7) {
                    answer =
                        'هزینه دادرسی در مرحله بدوی ۷۵۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله واخواهی ۱,۲۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله تجدیدنظر ۱,۵۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله اعاده دادرسی ۱,۸۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله فرجام خواهی ۱,۵۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله اعتراض ثالث ۱,۸۰۰,۰۰۰';
                }
            } else if (nonFinancialType === 5) {
                if (level === 1) {
                    answer = 400000;
                } else if (level === 2) {
                    answer = 600000;
                } else if (level === 3) {
                    answer = 1000000;
                } else if (level === 4) {
                    answer = 1500000;
                } else if (level === 5) {
                    answer = 'موجود نیست';
                } else if (level === 6) {
                    answer = 1500000;
                } else if (level === 7) {
                    answer =
                        'هزینه دادرسی در مرحله بدوی ۴۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله واخواهی ۶۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله تجدیدنظر ۱,۰۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله اعاده دادرسی ۱,۵۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله فرجام خواهی موجود نیست' +
                        ' | ' +
                        'هزینه دادرسی در مرحله اعتراض ثالث ۱,۵۰۰,۰۰۰';
                }
            } else if (nonFinancialType === 6) {
                if (level === 1) {
                    answer = 1000000;
                } else if (level === 2) {
                    answer = 0;
                } else if (level === 3) {
                    answer = 1500000;
                } else if (level === 4) {
                    answer = 1800000;
                } else if (level === 5) {
                    answer = 'موجود نیست';
                } else if (level === 6) {
                    answer = 1800000;
                } else if (level === 7) {
                    answer =
                        'هزینه دادرسی در مرحله بدوی ۱,۰۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله واخواهی ۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله تجدیدنظر ۱,۵۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله اعاده دادرسی ۱,۸۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله فرجام خواهی موجود نیست' +
                        ' | ' +
                        'هزینه دادرسی در مرحله اعتراض ثالث ۱,۸۰۰,۰۰۰';
                }
            } else if (nonFinancialType === 7) {
                if (level === 1) {
                    answer = 1200000;
                } else if (level === 2) {
                    answer = 1500000;
                } else if (level === 3) {
                    answer = 1500000;
                } else if (level === 4) {
                    answer = 1800000;
                } else if (level === 5) {
                    answer = 'موجود نیست';
                } else if (level === 6) {
                    answer = 1800000;
                } else if (level === 7) {
                    answer =
                        'هزینه دادرسی در مرحله بدوی ۱,۲۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله واخواهی ۱,۵۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله تجدیدنظر ۱,۵۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله اعاده دادرسی ۱,۸۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله فرجام خواهی موجود نیست' +
                        ' | ' +
                        'هزینه دادرسی در مرحله اعتراض ثالث ۱,۸۰۰,۰۰۰';
                }
            } else if (nonFinancialType === 8) {
                if (level === 1) {
                    answer = 1000000;
                } else if (level === 2) {
                    answer = 1500000;
                } else if (level === 3) {
                    answer = 1500000;
                } else if (level === 4) {
                    answer = 1800000;
                } else if (level === 5) {
                    answer = 1500000;
                } else if (level === 6) {
                    answer = 1800000;
                } else if (level === 7) {
                    answer =
                        'هزینه دادرسی در مرحله بدوی ۱,۰۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله واخواهی ۱,۵۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله تجدیدنظر ۱,۵۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله اعاده دادرسی ۱,۸۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله فرجام خواهی ۱,۵۰۰,۰۰۰' +
                        ' | ' +
                        'هزینه دادرسی در مرحله اعتراض ثالث ۱,۸۰۰,۰۰۰';
                }
            }
            return answer;
        },
    },
    {
        id: 3,
        title: 'چک (کیفری)',
        param1: null, //بهای خواسته یا محکومً به
        param2: null, //مرحله
        param3: null, //نوع غیرمالی
        param4: Form11.checkAmount, //مبلغ چک
        param5: null, //قیمت سکه
        param6: null, //تعداد سکه
        param7: null, //موضوع
        calculate: (year, checkAmount) => {
            let answer = 0;
            if (year !== 1395) {
                if (checkAmount > 0 && checkAmount <= 1000000) {
                    answer = 60000;
                } else if (checkAmount > 1000000 && checkAmount <= 10000000) {
                    answer = 360000;
                } else if (checkAmount > 10000000 && checkAmount <= 100000000) {
                    answer = 760000;
                } else if (checkAmount > 100000000) {
                    answer = 760000 + checkAmount / 1000;
                }
            } else if (year === 1395) {
                if (checkAmount > 0 && checkAmount <= 1000000) {
                    answer = 50000;
                } else if (checkAmount > 1000000 && checkAmount <= 10000000) {
                    answer = 250000;
                } else if (checkAmount > 10000000 && checkAmount <= 100000000) {
                    answer = 550000;
                } else if (checkAmount > 100000000) {
                    answer = 550000 + checkAmount / 1000;
                }
            }
            return answer;
        },
    },
    {
        id: 4,
        title: 'مهریه (سکه)',
        param1: null, //بهای خواسته یا محکومً به
        param2: [
            {
                title: 'همه موارد',
                payload: 7,
                checkbox: null,
            },
            {
                title: 'بدوی',
                payload: 1,
                checkbox: 'دعوا در شورای حل اختلاف مطرح خواهد شد',
            },
            {
                title: 'واخواهی',
                payload: 2,
                checkbox: 'دعوا در شورای حل اختلاف مطرح بوده است',
            },
            {
                title: 'تجدیدنظر',
                payload: 3,
                checkbox: 'دعوا در شورای حل اختلاف مطرح بوده است',
            },
            {
                title: 'اعاده دادرسی',
                payload: 4,
                checkbox: 'دعوا در شورای حل اختلاف مطرح بوده است',
            },
            {
                title: 'فرجام خواهی',
                payload: 5,
                checkbox: null,
            },
            {
                title: 'اعتراض ثالث',
                payload: 6,
                checkbox: 'دعوا در شورای حل اختلاف مطرح بوده است',
            },
        ], //مرحله
        param3: null, //نوع غیرمالی
        param4: null, //مبلغ چک
        param5: Form11.coinPrice, //قیمت سکه
        param6: Form11.coinCount, //تعداد سکه
        param7: null, //موضوع
        calculate: (year, coinPrice, coinCount, level, checkbox1, checkbox2) => {
            let answer = 0;
            let result = '';
            const total = coinCount * coinPrice;
            if (level === 1) {
                answer = checkbox1 ? handleFirst(total) / 2 : handleFirst(total);
            } else if (level === 2) {
                answer = checkbox2 ? (total * 4.5) / 200 : (total * 4.5) / 100;
            } else if (level === 3) {
                answer = checkbox2 ? (total * 4.5) / 200 : (total * 4.5) / 100;
            } else if (level === 4) {
                answer = checkbox2 ? (total * 5.5) / 200 : (total * 5.5) / 100;
            } else if (level === 5) {
                answer = (total * 5.5) / 100;
            } else if (level === 6) {
                answer = checkbox2 ? (total * 5.5) / 200 : (total * 5.5) / 100;
            } else if (level === 7) {
                const temp = checkbox1 ? handleFirst(total) / 2 : handleFirst(total);
                const temp2 = checkbox2 ? (total * 4.5) / 200 : (total * 4.5) / 100;
                const temp3 = checkbox2 ? (total * 5.5) / 200 : (total * 5.5) / 100;
                result =
                    'هزینه دادرسی در مرحله بدوی ' +
                    numbersSeparator(temp, ',') +
                    ' | ' +
                    'هزینه دادرسی در مرحله واخواهی ' +
                    numbersSeparator(temp2, ',') +
                    ' | ' +
                    'هزینه دادرسی در مرحله تجدیدنظر ' +
                    numbersSeparator(temp2, ',') +
                    ' | ' +
                    'هزینه دادرسی در مرحله اعاده دادرسی ' +
                    numbersSeparator(temp3, ',') +
                    ' | ' +
                    'هزینه دادرسی در مرحله فرجام خواهی ' +
                    numbersSeparator((total * 5.5) / 100, ',') +
                    ' | ' +
                    'هزینه دادرسی در مرحله اعتراض ثالث ' +
                    numbersSeparator(temp3, ',');
                return result;
            }
            return answer;
        },
    },
    {
        id: 5,
        title: 'سایر',
        param1: null, //بهای خواسته یا محکومً به
        param2: null, //مرحله
        param3: null, //نوع غیرمالی
        param4: null, //مبلغ چک
        param5: null, //قیمت سکه
        param6: null, //تعداد سکه
        param7: [
            {
                id: 1402,
                children: [
                    {
                        title:
                            'بهای اوراق دادخواست و اظهارنامه و برگ اجرائیه دادگاه ها و هیات های حل اختلاف موضوع قانون کار برای هر برگ',
                        payload: 1,
                    },
                    {
                        title:
                            'در هر مورد که در قوانین حداکثر مجازات کمتر از نود و یک روز حبس و یا مجازات تعزیری موضوع تخلفات رانندگی یا مجازات جایگزین حبس باشد',
                        payload: 2,
                    },
                    {
                        title:
                            'هر گاه حداقل مجازات کمتر از نود و یک روز حبس و حداکثر آن یک سال یا مجازات جایگزین حبس باشد و به اختیار دادگاه جزای نقدی مورد حکم واقع شود',
                        payload: 3,
                    },
                    {
                        title:
                            'هر گاه مجازات کمتر از نود و یک روز حبس و حداکثر آن بیشتر از یک سال یا مجازات جایگزین حبس باشد و به اختیار دادگاه جزای نقدی مورد حکم واقع شود',
                        payload: 4,
                    },
                    {
                        title: 'تقدیم شکایت کیفری',
                        payload: 5,
                    },
                    {
                        title: 'تقدیم شکایت به دادسرای انتظامی قضات',
                        payload: 6,
                    },
                    {
                        title: 'گواهی امضای مترجم دادگستری برای هر مورد',
                        payload: 7,
                    },
                    {
                        title: 'پلمپ اوراق ترجمه شده توسط مترجم رسمی دادگستری',
                        payload: 8,
                    },
                    {
                        title: 'پلمپ گواهی صحت ترجمه در هر مورد',
                        payload: 9,
                    },
                    {
                        title: 'هزینه مطابقت رونوشت و تصویر با اصل در هر مورد',
                        payload: 10,
                    },
                    {
                        title: 'پروانه وکالت پایه ۱',
                        payload: 11,
                    },
                    {
                        title: 'پروانه مترجم رسمی، کارشناس رسمی، کارآموزان وکالت و کارگشایی مقیم مرکز استان در هر مورد',
                        payload: 12,
                    },
                    {
                        title: 'پروانه کارآموزان وکالت و کارگشایان در سایر شهرستان ها',
                        payload: 13,
                    },
                    {
                        title: 'پروانه وکالت اتفاقی',
                        payload: 14,
                    },
                    {
                        title: 'تقدیم دادخواست به دیوان عدالت اداری : شعب دیوان',
                        payload: 15,
                    },
                    {
                        title: 'تقدیم دادخواست به دیوان عدالت اداری : شعب تجدیدنظر',
                        payload: 16,
                    },
                    {
                        title: 'در صورتی که قیمت خواسته در دعاوی مالی در موقع تقدیم دادخواست مشخص نباشد',
                        payload: 17,
                    },
                    {
                        title: 'اجرای موقت احکام در کلیه مراجع قضایی',
                        payload: 18,
                    },
                    {
                        title: 'اعتراض و درخواست تجدیدنظر از قرارهای قابل تجدیدنظر در دادگاه و دیوان عالی کشور',
                        payload: 19,
                    },
                    {
                        title: 'تطبیق اوراق با اصل آنها در در دفاتر دادگاه ها و دیوان عدالت اداری و سایر مراجع قضایی',
                        payload: 20,
                    },
                    {
                        title: 'صدور گواهی از دفاتر مراجع قضایی',
                        payload: 21,
                    },
                    {
                        title: 'ابلاغ اظهارنامه و واخواست نامه در هر مورد',
                        payload: 22,
                    },
                    {
                        title: 'صدور گواهی عدم سوء پیشینه کیفری - نسخه اول',
                        payload: 23,
                    },
                    {
                        title: 'صدور گواهی عدم سوء پیشینه کیفری - نسخه های بعدی',
                        payload: 24,
                    },
                    {
                        title: 'دادرسی در مرحله تجدیدنظر از احکام کیفری',
                        payload: 25,
                    },
                    {
                        title:
                            'اجرای احکام دعاوی غیر مالی و احکامی که محکوم به آن تقویم نشده و هزینه اجرای آرا و تصمیمات مراجع غیردادگستری',
                        payload: 26,
                    },
                    {
                        title: 'درخواست اجرای ماده ۴۷۷ قانون آیین دادرسی کیفری مصوب ۹۲/۱۲/۴',
                        payload: 27,
                    },
                    {
                        title: 'اعتراض شخص ثالث به اجرای احکام مدنی',
                        payload: 28,
                    },
                    {
                        title: 'اعتراض به قرارهای قابل اعتراض دادسرا',
                        payload: 29,
                    },
                    {
                        title: 'درخواست تصویر از اوراق پرونده',
                        payload: 30,
                    },
                    {
                        title: 'اعاده دادرسی کیفری در دیوان عالی کشور',
                        payload: 31,
                    },
                    {
                        title: 'کارشناسان رسمی بدون فعالیت و درآمد',
                        payload: 32,
                    },
                    {
                        title: 'کارشناسان رسمی از صفر تا ۱۰۰،۰۰۰،۰۰۰ ریال تمام درآمد',
                        payload: 33,
                    },
                    {
                        title: 'کارشناسان رسمی از ۱۰۰،۰۰۰،۰۰۱ تا ۲۰۰،۰۰۰،۰۰۰ ریال تمام درآمد',
                        payload: 34,
                    },
                    {
                        title: 'کارشناسان رسمی مازاد بر ۲۰۰،۰۰۰،۰۰۰ درآمد',
                        payload: 35,
                    },
                ],
            },
            {
                id: 1401,
                children: [
                    {
                        title:
                            'بهای اوراق دادخواست و اظهارنامه و برگ اجرائیه دادگاه ها و هیات های حل اختلاف موضوع قانون کار برای هر برگ',
                        payload: 1,
                    },
                    {
                        title:
                            'در هر مورد که در قوانین حداکثر مجازات کمتر از نود و یک روز حبس و یا مجازات تعزیری موضوع تخلفات رانندگی یا مجازات جایگزین حبس باشد',
                        payload: 2,
                    },
                    {
                        title:
                            'هر گاه حداقل مجازات کمتر از نود و یک روز حبس و حداکثر آن یک سال یا مجازات جایگزین حبس باشد و به اختیار دادگاه جزای نقدی مورد حکم واقع شود',
                        payload: 3,
                    },
                    {
                        title:
                            'هر گاه مجازات کمتر از نود و یک روز حبس و حداکثر آن بیشتر از یک سال یا مجازات جایگزین حبس باشد و به اختیار دادگاه جزای نقدی مورد حکم واقع شود',
                        payload: 4,
                    },
                    {
                        title: 'تقدیم شکایت کیفری',
                        payload: 5,
                    },
                    {
                        title: 'تقدیم شکایت به دادسرای انتظامی قضات',
                        payload: 6,
                    },
                    {
                        title: 'گواهی امضای مترجم دادگستری برای هر مورد',
                        payload: 7,
                    },
                    {
                        title: 'پلمپ اوراق ترجمه شده توسط مترجم رسمی دادگستری',
                        payload: 8,
                    },
                    {
                        title: 'پلمپ گواهی صحت ترجمه در هر مورد',
                        payload: 9,
                    },
                    {
                        title: 'هزینه مطابقت رونوشت و تصویر با اصل در هر مورد',
                        payload: 10,
                    },
                    {
                        title: 'پروانه وکالت پایه ۱',
                        payload: 11,
                    },
                    {
                        title: 'پروانه مترجم رسمی، کارشناس رسمی، کارآموزان وکالت و کارگشایی مقیم مرکز استان در هر مورد',
                        payload: 12,
                    },
                    {
                        title: 'پروانه کارآموزان وکالت و کارگشایان در سایر شهرستان ها',
                        payload: 13,
                    },
                    {
                        title: 'پروانه وکالت اتفاقی',
                        payload: 14,
                    },
                    {
                        title: 'تقدیم دادخواست به دیوان عدالت اداری : شعب دیوان',
                        payload: 15,
                    },
                    {
                        title: 'تقدیم دادخواست به دیوان عدالت اداری : شعب تجدیدنظر',
                        payload: 16,
                    },
                    {
                        title: 'در صورتی که قیمت خواسته در دعاوی مالی در موقع تقدیم دادخواست مشخص نباشد',
                        payload: 17,
                    },
                    {
                        title: 'اجرای موقت احکام در کلیه مراجع قضایی',
                        payload: 18,
                    },
                    {
                        title: 'اعتراض و درخواست تجدیدنظر از قرارهای قابل تجدیدنظر در دادگاه و دیوان عالی کشور',
                        payload: 19,
                    },
                    {
                        title: 'تطبیق اوراق با اصل آنها در در دفاتر دادگاه ها و دیوان عدالت اداری و سایر مراجع قضایی',
                        payload: 20,
                    },
                    {
                        title: 'صدور گواهی از دفاتر مراجع قضایی',
                        payload: 21,
                    },
                    {
                        title: 'ابلاغ اظهارنامه و واخواست نامه در هر مورد',
                        payload: 22,
                    },
                    {
                        title: 'صدور گواهی عدم سوء پیشینه کیفری - نسخه اول',
                        payload: 23,
                    },
                    {
                        title: 'صدور گواهی عدم سوء پیشینه کیفری - نسخه های بعدی',
                        payload: 24,
                    },
                    {
                        title: 'دادرسی در مرحله تجدیدنظر از احکام کیفری',
                        payload: 25,
                    },
                    {
                        title:
                            'اجرای احکام دعاوی غیر مالی و احکامی که محکوم به آن تقویم نشده و هزینه اجرای آرا و تصمیمات مراجع غیردادگستری',
                        payload: 26,
                    },
                    {
                        title: 'درخواست اجرای ماده ۴۷۷ قانون آیین دادرسی کیفری مصوب ۹۲/۱۲/۴',
                        payload: 27,
                    },
                    {
                        title: 'اعتراض شخص ثالث به اجرای احکام مدنی',
                        payload: 28,
                    },
                    {
                        title: 'اعتراض به قرارهای قابل اعتراض دادسرا',
                        payload: 29,
                    },
                    {
                        title: 'درخواست تصویر از اوراق پرونده',
                        payload: 30,
                    },
                    {
                        title: 'اعاده دادرسی کیفری در دیوان عالی کشور',
                        payload: 31,
                    },
                    {
                        title: 'کارشناسان رسمی بدون فعالیت و درآمد',
                        payload: 32,
                    },
                    {
                        title: 'کارشناسان رسمی از صفر تا ۱۰۰،۰۰۰،۰۰۰ ریال تمام درآمد',
                        payload: 33,
                    },
                    {
                        title: 'کارشناسان رسمی از ۱۰۰،۰۰۰،۰۰۱ تا ۲۰۰،۰۰۰،۰۰۰ ریال تمام درآمد',
                        payload: 34,
                    },
                    {
                        title: 'کارشناسان رسمی مازاد بر ۲۰۰،۰۰۰،۰۰۰ درآمد',
                        payload: 35,
                    },
                ],
            },
            {
                id: 1400,
                children: [
                    {
                        title:
                            'بهای اوراق دادخواست و اظهارنامه و برگ اجرائیه دادگاه ها و هیات های حل اختلاف موضوع قانون کار برای هر برگ',
                        payload: 1,
                    },
                    {
                        title:
                            'در هر مورد که در قوانین حداکثر مجازات کمتر از نود و یک روز حبس و یا مجازات تعزیری موضوع تخلفات رانندگی یا مجازات جایگزین حبس باشد',
                        payload: 2,
                    },
                    {
                        title:
                            'هر گاه حداقل مجازات کمتر از نود و یک روز حبس و حداکثر آن یک سال یا مجازات جایگزین حبس باشد و به اختیار دادگاه جزای نقدی مورد حکم واقع شود',
                        payload: 3,
                    },
                    {
                        title:
                            'هر گاه مجازات کمتر از نود و یک روز حبس و حداکثر آن بیشتر از یک سال یا مجازات جایگزین حبس باشد و به اختیار دادگاه جزای نقدی مورد حکم واقع شود',
                        payload: 4,
                    },
                    {
                        title: 'تقدیم شکایت کیفری',
                        payload: 5,
                    },
                    {
                        title: 'تقدیم شکایت به دادسرای انتظامی قضات',
                        payload: 6,
                    },
                    {
                        title: 'گواهی امضای مترجم دادگستری برای هر مورد',
                        payload: 7,
                    },
                    {
                        title: 'پلمپ اوراق ترجمه شده توسط مترجم رسمی دادگستری',
                        payload: 8,
                    },
                    {
                        title: 'پلمپ گواهی صحت ترجمه در هر مورد',
                        payload: 9,
                    },
                    {
                        title: 'هزینه مطابقت رونوشت و تصویر با اصل در هر مورد',
                        payload: 10,
                    },
                    {
                        title: 'پروانه وکالت پایه ۱',
                        payload: 11,
                    },
                    {
                        title: 'پروانه مترجم رسمی، کارشناس رسمی، کارآموزان وکالت و کارگشایی مقیم مرکز استان در هر مورد',
                        payload: 12,
                    },
                    {
                        title: 'پروانه کارآموزان وکالت و کارگشایان در سایر شهرستان ها',
                        payload: 13,
                    },
                    {
                        title: 'پروانه وکالت اتفاقی',
                        payload: 14,
                    },
                    {
                        title: 'تقدیم دادخواست به دیوان عدالت اداری : شعب دیوان',
                        payload: 15,
                    },
                    {
                        title: 'تقدیم دادخواست به دیوان عدالت اداری : شعب تجدیدنظر',
                        payload: 16,
                    },
                    {
                        title: 'در صورتی که قیمت خواسته در دعاوی مالی در موقع تقدیم دادخواست مشخص نباشد',
                        payload: 17,
                    },
                    {
                        title: 'اجرای موقت احکام در کلیه مراجع قضایی',
                        payload: 18,
                    },
                    {
                        title: 'اعتراض و درخواست تجدیدنظر از قرارهای قابل تجدیدنظر در دادگاه و دیوان عالی کشور',
                        payload: 19,
                    },
                    {
                        title: 'تطبیق اوراق با اصل آنها در در دفاتر دادگاه ها و دیوان عدالت اداری و سایر مراجع قضایی',
                        payload: 20,
                    },
                    {
                        title: 'صدور گواهی از دفاتر مراجع قضایی',
                        payload: 21,
                    },
                    {
                        title: 'ابلاغ اظهارنامه و واخواست نامه در هر مورد',
                        payload: 22,
                    },
                    {
                        title: 'صدور گواهی عدم سوء پیشینه کیفری - نسخه اول',
                        payload: 23,
                    },
                    {
                        title: 'صدور گواهی عدم سوء پیشینه کیفری - نسخه های بعدی',
                        payload: 24,
                    },
                    {
                        title: 'دادرسی در مرحله تجدیدنظر از احکام کیفری',
                        payload: 25,
                    },
                    {
                        title:
                            'اجرای احکام دعاوی غیر مالی و احکامی که محکوم به آن تقویم نشده و هزینه اجرای آرا و تصمیمات مراجع غیردادگستری',
                        payload: 26,
                    },
                    {
                        title: 'درخواست اجرای ماده ۴۷۷ قانون آیین دادرسی کیفری مصوب ۹۲/۱۲/۴',
                        payload: 27,
                    },
                    {
                        title: 'اعتراض شخص ثالث به اجرای احکام مدنی',
                        payload: 28,
                    },
                    {
                        title: 'اعتراض به قرارهای قابل اعتراض دادسرا',
                        payload: 29,
                    },
                    {
                        title: 'درخواست تصویر از اوراق پرونده',
                        payload: 30,
                    },
                    {
                        title: 'اعاده دادرسی کیفری در دیوان عالی کشور',
                        payload: 31,
                    },
                    {
                        title: 'کارشناسان رسمی بدون فعالیت و درآمد',
                        payload: 32,
                    },
                    {
                        title: 'کارشناسان رسمی از صفر تا ۱۰۰،۰۰۰،۰۰۰ ریال تمام درآمد',
                        payload: 33,
                    },
                    {
                        title: 'کارشناسان رسمی از ۱۰۰،۰۰۰،۰۰۱ تا ۲۰۰،۰۰۰،۰۰۰ ریال تمام درآمد',
                        payload: 34,
                    },
                    {
                        title: 'کارشناسان رسمی مازاد بر ۲۰۰،۰۰۰،۰۰۰ درآمد',
                        payload: 35,
                    },
                ],
            },
            {
                id: 1399,
                children: [
                    {
                        title:
                            'بهای اوراق دادخواست و اظهارنامه و برگ اجرائیه دادگاه ها و هیات های حل اختلاف موضوع قانون کار برای هر برگ',
                        payload: 1,
                    },
                    {
                        title:
                            'در هر مورد که در قوانین حداکثر مجازات کمتر از نود و یک روز حبس و یا مجازات تعزیری موضوع تخلفات رانندگی یا مجازات جایگزین حبس باشد',
                        payload: 2,
                    },
                    {
                        title:
                            'هر گاه حداقل مجازات کمتر از نود و یک روز حبس و حداکثر آن یک سال یا مجازات جایگزین حبس باشد و به اختیار دادگاه جزای نقدی مورد حکم واقع شود',
                        payload: 3,
                    },
                    {
                        title:
                            'هر گاه مجازات کمتر از نود و یک روز حبس و حداکثر آن بیشتر از یک سال یا مجازات جایگزین حبس باشد و به اختیار دادگاه جزای نقدی مورد حکم واقع شود',
                        payload: 4,
                    },
                    {
                        title: 'تقدیم شکایت کیفری',
                        payload: 5,
                    },
                    {
                        title: 'تقدیم شکایت به دادسرای انتظامی قضات',
                        payload: 6,
                    },
                    {
                        title: 'گواهی امضای مترجم دادگستری برای هر مورد',
                        payload: 7,
                    },
                    {
                        title: 'پلمپ اوراق ترجمه شده توسط مترجم رسمی دادگستری',
                        payload: 8,
                    },
                    {
                        title: 'پلمپ گواهی صحت ترجمه در هر مورد',
                        payload: 9,
                    },
                    {
                        title: 'هزینه مطابقت رونوشت و تصویر با اصل در هر مورد',
                        payload: 10,
                    },
                    {
                        title: 'پروانه وکالت پایه ۱',
                        payload: 11,
                    },
                    {
                        title: 'پروانه مترجم رسمی، کارشناس رسمی، کارآموزان وکالت و کارگشایی مقیم مرکز استان در هر مورد',
                        payload: 12,
                    },
                    {
                        title: 'پروانه کارآموزان وکالت و کارگشایان در سایر شهرستان ها',
                        payload: 13,
                    },
                    {
                        title: 'پروانه وکالت اتفاقی',
                        payload: 14,
                    },
                    {
                        title: 'تقدیم دادخواست به دیوان عدالت اداری : شعب دیوان',
                        payload: 15,
                    },
                    {
                        title: 'تقدیم دادخواست به دیوان عدالت اداری : شعب تجدیدنظر',
                        payload: 16,
                    },
                    {
                        title: 'در صورتی که قیمت خواسته در دعاوی مالی در موقع تقدیم دادخواست مشخص نباشد',
                        payload: 17,
                    },
                    {
                        title: 'اجرای موقت احکام در کلیه مراجع قضایی',
                        payload: 18,
                    },
                    {
                        title: 'اعتراض و درخواست تجدیدنظر از قرارهای قابل تجدیدنظر در دادگاه و دیوان عالی کشور',
                        payload: 19,
                    },
                    {
                        title: 'تطبیق اوراق با اصل آنها در در دفاتر دادگاه ها و دیوان عدالت اداری و سایر مراجع قضایی',
                        payload: 20,
                    },
                    {
                        title: 'صدور گواهی از دفاتر مراجع قضایی',
                        payload: 21,
                    },
                    {
                        title: 'ابلاغ اظهارنامه و واخواست نامه در هر مورد',
                        payload: 22,
                    },
                    {
                        title: 'صدور گواهی عدم سوء پیشینه کیفری - نسخه اول',
                        payload: 23,
                    },
                    {
                        title: 'صدور گواهی عدم سوء پیشینه کیفری - نسخه های بعدی',
                        payload: 24,
                    },
                    {
                        title: 'دادرسی در مرحله تجدیدنظر از احکام کیفری',
                        payload: 25,
                    },
                    {
                        title:
                            'اجرای احکام دعاوی غیر مالی و احکامی که محکوم به آن تقویم نشده و هزینه اجرای آرا و تصمیمات مراجع غیردادگستری',
                        payload: 26,
                    },
                    {
                        title: 'درخواست اجرای ماده ۴۷۷ قانون آیین دادرسی کیفری مصوب ۹۲/۱۲/۴',
                        payload: 27,
                    },
                    {
                        title: 'اعتراض شخص ثالث به اجرای احکام مدنی',
                        payload: 28,
                    },
                    {
                        title: 'اعتراض به قرارهای قابل اعتراض دادسرا',
                        payload: 29,
                    },
                    {
                        title: 'درخواست تصویر از اوراق پرونده',
                        payload: 30,
                    },
                    {
                        title: 'اعاده دادرسی کیفری در دیوان عالی کشور',
                        payload: 31,
                    },
                ],
            },
            {
                id: 1398,
                children: [
                    {
                        title:
                            'بهای اوراق دادخواست و اظهارنامه و برگ اجرائیه دادگاه ها و هیات های حل اختلاف موضوع قانون کار برای هر برگ',
                        payload: 1,
                    },
                    {
                        title:
                            'در هر مورد که در قوانین حداکثر مجازات کمتر از نود و یک روز حبس و یا مجازات تعزیری موضوع تخلفات رانندگی یا مجازات جایگزین حبس باشد',
                        payload: 2,
                    },
                    {
                        title:
                            'هر گاه حداقل مجازات کمتر از نود و یک روز حبس و حداکثر آن یک سال یا مجازات جایگزین حبس باشد و به اختیار دادگاه جزای نقدی مورد حکم واقع شود',
                        payload: 3,
                    },
                    {
                        title:
                            'هر گاه مجازات کمتر از نود و یک روز حبس و حداکثر آن بیشتر از یک سال یا مجازات جایگزین حبس باشد و به اختیار دادگاه جزای نقدی مورد حکم واقع شود',
                        payload: 4,
                    },
                    {
                        title: 'تقدیم شکایت کیفری',
                        payload: 5,
                    },
                    {
                        title: 'تقدیم شکایت به دادسرای انتظامی قضات',
                        payload: 6,
                    },
                    {
                        title: 'گواهی امضای مترجم دادگستری برای هر مورد',
                        payload: 7,
                    },
                    {
                        title: 'پلمپ اوراق ترجمه شده توسط مترجم رسمی دادگستری',
                        payload: 8,
                    },
                    {
                        title: 'پلمپ گواهی صحت ترجمه در هر مورد',
                        payload: 9,
                    },
                    {
                        title: 'هزینه مطابقت رونوشت و تصویر با اصل در هر مورد',
                        payload: 10,
                    },
                    {
                        title: 'پروانه وکالت پایه ۱',
                        payload: 11,
                    },
                    {
                        title: 'پروانه مترجم رسمی، کارشناس رسمی، کارآموزان وکالت و کارگشایی مقیم مرکز استان در هر مورد',
                        payload: 12,
                    },
                    {
                        title: 'پروانه کارآموزان وکالت و کارگشایان در سایر شهرستان ها',
                        payload: 13,
                    },
                    {
                        title: 'پروانه وکالت اتفاقی',
                        payload: 14,
                    },
                    {
                        title: 'تقدیم دادخواست به دیوان عدالت اداری : شعب دیوان',
                        payload: 15,
                    },
                    {
                        title: 'تقدیم دادخواست به دیوان عدالت اداری : شعب تجدیدنظر',
                        payload: 16,
                    },
                    {
                        title: 'در صورتی که قیمت خواسته در دعاوی مالی در موقع تقدیم دادخواست مشخص نباشد',
                        payload: 17,
                    },
                    {
                        title: 'اجرای موقت احکام در کلیه مراجع قضایی',
                        payload: 18,
                    },
                    {
                        title: 'اعتراض و درخواست تجدیدنظر از قرارهای قابل تجدیدنظر در دادگاه و دیوان عالی کشور',
                        payload: 19,
                    },
                    {
                        title: 'تطبیق اوراق با اصل آنها در در دفاتر دادگاه ها و دیوان عدالت اداری و سایر مراجع قضایی',
                        payload: 20,
                    },
                    {
                        title: 'صدور گواهی از دفاتر مراجع قضایی',
                        payload: 21,
                    },
                    {
                        title: 'ابلاغ اظهارنامه و واخواست نامه در هر مورد',
                        payload: 22,
                    },
                    {
                        title: 'صدور گواهی عدم سوء پیشینه کیفری - نسخه اول',
                        payload: 23,
                    },
                    {
                        title: 'صدور گواهی عدم سوء پیشینه کیفری - نسخه های بعدی',
                        payload: 24,
                    },
                    {
                        title: 'دادرسی در مرحله تجدیدنظر از احکام کیفری',
                        payload: 25,
                    },
                    {
                        title:
                            'اجرای احکام دعاوی غیر مالی و احکامی که محکوم به آن تقویم نشده و هزینه اجرای آرا و تصمیمات مراجع غیردادگستری',
                        payload: 26,
                    },
                    {
                        title: 'درخواست اجرای ماده ۴۷۷ قانون آیین دادرسی کیفری مصوب ۹۲/۱۲/۴',
                        payload: 27,
                    },
                    {
                        title: 'اعتراض شخص ثالث به اجرای احکام مدنی',
                        payload: 28,
                    },
                    {
                        title: 'اعتراض به قرارهای قابل اعتراض دادسرا',
                        payload: 29,
                    },
                    {
                        title: 'درخواست تصویر از اوراق پرونده',
                        payload: 30,
                    },
                    {
                        title: 'اعاده دادرسی کیفری در دیوان عالی کشور',
                        payload: 31,
                    },
                ],
            },
            {
                id: 1397,
                children: [
                    {
                        title:
                            'بهای اوراق دادخواست و اظهارنامه و برگ اجرائیه دادگاه ها و هیات های حل اختلاف موضوع قانون کار برای هر برگ',
                        payload: 1,
                    },
                    {
                        title:
                            'در هر مورد که در قوانین حداکثر مجازات کمتر از نود و یک روز حبس و یا مجازات تعزیری موضوع تخلفات رانندگی یا مجازات جایگزین حبس باشد',
                        payload: 2,
                    },
                    {
                        title:
                            'هر گاه حداقل مجازات کمتر از نود و یک روز حبس و حداکثر آن یک سال یا مجازات جایگزین حبس باشد و به اختیار دادگاه جزای نقدی مورد حکم واقع شود',
                        payload: 3,
                    },
                    {
                        title:
                            'هر گاه مجازات کمتر از نود و یک روز حبس و حداکثر آن بیشتر از یک سال یا مجازات جایگزین حبس باشد و به اختیار دادگاه جزای نقدی مورد حکم واقع شود',
                        payload: 4,
                    },
                    {
                        title: 'تقدیم شکایت کیفری',
                        payload: 5,
                    },
                    {
                        title: 'تقدیم شکایت به دادسرای انتظامی قضات',
                        payload: 6,
                    },
                    {
                        title: 'گواهی امضای مترجم دادگستری برای هر مورد',
                        payload: 7,
                    },
                    {
                        title: 'پلمپ اوراق ترجمه شده توسط مترجم رسمی دادگستری',
                        payload: 8,
                    },
                    {
                        title: 'پروانه وکالت پایه ۱',
                        payload: 9,
                    },
                    {
                        title: 'پروانه مترجم رسمی، کارشناس رسمی، کارآموزان وکالت و کارگشایی مقیم مرکز استان در هر مورد',
                        payload: 10,
                    },
                    {
                        title: 'پروانه کارآموزان وکالت و کارگشایان در سایر شهرستان ها',
                        payload: 11,
                    },
                    {
                        title: 'پروانه وکالت اتفاقی',
                        payload: 12,
                    },
                    {
                        title: 'تقدیم دادخواست به دیوان عدالت اداری : شعب دیوان',
                        payload: 13,
                    },
                    {
                        title: 'تقدیم دادخواست به دیوان عدالت اداری : شعب تجدیدنظر',
                        payload: 14,
                    },
                    {
                        title: 'در صورتی که قیمت خواسته در دعاوی مالی در موقع تقدیم دادخواست مشخص نباشد',
                        payload: 15,
                    },
                    {
                        title: 'اجرای موقت احکام در کلیه مراجع قضایی',
                        payload: 16,
                    },
                    {
                        title: 'اعتراض و درخواست تجدیدنظر از قرارهای قابل تجدیدنظر در دادگاه و دیوان عالی کشور',
                        payload: 17,
                    },
                    {
                        title: 'تطبیق اوراق با اصل آنها در در دفاتر دادگاه ها و دیوان عدالت اداری و سایر مراجع قضایی',
                        payload: 18,
                    },
                    {
                        title: 'صدور گواهی از دفاتر مراجع قضایی',
                        payload: 19,
                    },
                    {
                        title: 'ابلاغ اظهارنامه و واخواست نامه در هر مورد',
                        payload: 20,
                    },
                    {
                        title: 'صدور گواهی عدم سوء پیشینه کیفری - نسخه اول',
                        payload: 21,
                    },
                    {
                        title: 'صدور گواهی عدم سوء پیشینه کیفری - نسخه های بعدی',
                        payload: 22,
                    },
                    {
                        title: 'دادرسی در مرحله تجدیدنظر از احکام کیفری',
                        payload: 23,
                    },
                    {
                        title:
                            'اجرای آراء و تصمیمات مراجع غیر دادگستری در دادگستری و هزینه اجرای سایر احکام مقرر در بند ۲۲ ماده ۳  قانون وصول برخی از درآمدهای دولت و مصرف آن در موارد معین، مصوب ۱۳۷۳ (موضوع صلاحیت دادگاه خانواده)',
                        payload: 24,
                    },
                    {
                        title:
                            'اجرای آراء و تصمیمات مراجع غیر دادگستری در دادگستری و هزینه اجرای سایر احکام مقرر در بند ۲۲ ماده ۳  قانون وصول برخی از درآمدهای دولت و مصرف آن در موارد معین، مصوب ۱۳۷۳ (تصمیمات و سایر آرا)',
                        payload: 25,
                    },
                    {
                        title: 'تعرفه اجرای ماده ۴۷۷ قانون آیین دادرسی کیفری مصوب ۹۲/۱۲/۴',
                        payload: 26,
                    },
                    {
                        title: 'اعتراض شخص ثالث به اجرای احکام مدنی',
                        payload: 27,
                    },
                    {
                        title: 'اعتراض به قرارهای قابل اعتراض دادسرا',
                        payload: 28,
                    },
                    {
                        title: 'درخواست تصویر از اوراق پرونده',
                        payload: 29,
                    },
                    {
                        title: 'اعاده دادرسی کیفری در دیوان عالی کشور',
                        payload: 30,
                    },
                ],
            },
            {
                id: 1396,
                children: [
                    {
                        title:
                            'بهای اوراق دادخواست و اظهارنامه و برگ اجرائیه دادگاه ها و هیات های حل اختلاف موضوع قانون کار برای هر برگ',
                        payload: 1,
                    },
                    {
                        title:
                            'در هر مورد که در قوانین حداکثر مجازات کمتر از نود و یک روز حبس و یا مجازات تعزیری موضوع تخلفات رانندگی یا مجازات جایگزین حبس باشد',
                        payload: 2,
                    },
                    {
                        title:
                            'هر گاه حداقل مجازات کمتر از نود و یک روز حبس و حداکثر آن یک سال یا مجازات جایگزین حبس باشد و به اختیار دادگاه جزای نقدی مورد حکم واقع شود',
                        payload: 3,
                    },
                    {
                        title:
                            'هر گاه مجازات کمتر از نود و یک روز حبس و حداکثر آن بیشتر از یک سال یا مجازات جایگزین حبس باشد و به اختیار دادگاه جزای نقدی مورد حکم واقع شود',
                        payload: 4,
                    },
                    {
                        title: 'تقدیم شکایت کیفری',
                        payload: 5,
                    },
                    {
                        title: 'تقدیم شکایت به دادسرای انتظامی قضات',
                        payload: 6,
                    },
                    {
                        title: 'گواهی امضای مترجم دادگستری برای هر مورد',
                        payload: 7,
                    },
                    {
                        title: 'پلمپ اوراق ترجمه شده توسط مترجم رسمی دادگستری',
                        payload: 8,
                    },
                    {
                        title: 'پروانه وکالت پایه ۱',
                        payload: 9,
                    },
                    {
                        title: 'پروانه مترجم رسمی، کارشناس رسمی، کارآموزان وکالت و کارگشایی مقیم مرکز استان در هر مورد',
                        payload: 10,
                    },
                    {
                        title: 'پروانه کارآموزان وکالت و کارگشایان در سایر شهرستان ها',
                        payload: 11,
                    },
                    {
                        title: 'پروانه وکالت اتفاقی',
                        payload: 12,
                    },
                    {
                        title: 'تقدیم دادخواست به دیوان عدالت اداری : شعب دیوان',
                        payload: 13,
                    },
                    {
                        title: 'تقدیم دادخواست به دیوان عدالت اداری : شعب تجدیدنظر',
                        payload: 14,
                    },
                    {
                        title: 'در صورتی که قیمت خواسته در دعاوی مالی در موقع تقدیم دادخواست مشخص نباشد',
                        payload: 15,
                    },
                    {
                        title: 'اجرای موقت احکام در کلیه مراجع قضایی',
                        payload: 16,
                    },
                    {
                        title: 'اعتراض و درخواست تجدیدنظر از قرارهای قابل تجدیدنظر در دادگاه و دیوان عالی کشور',
                        payload: 17,
                    },
                    {
                        title: 'تطبیق اوراق با اصل آنها در در دفاتر دادگاه ها و دیوان عدالت اداری و سایر مراجع قضایی',
                        payload: 18,
                    },
                    {
                        title: 'صدور گواهی از دفاتر مراجع قضایی',
                        payload: 19,
                    },
                    {
                        title: 'ابلاغ اظهارنامه و واخواست نامه در هر مورد',
                        payload: 20,
                    },
                    {
                        title: 'صدور گواهی عدم سوء پیشینه کیفری - نسخه اول',
                        payload: 21,
                    },
                    {
                        title: 'صدور گواهی عدم سوء پیشینه کیفری - نسخه های بعدی',
                        payload: 22,
                    },
                    {
                        title: 'دادرسی در مرحله تجدیدنظر از احکام کیفری',
                        payload: 23,
                    },
                    {
                        title:
                            'اجرای آراء و تصمیمات مراجع غیر دادگستری در دادگستری و هزینه اجرای سایر احکام مقرر در بند ۲۲ ماده ۳  قانون وصول برخی از درآمدهای دولت و مصرف آن در موارد معین، مصوب ۱۳۷۳ (موضوع صلاحیت دادگاه خانواده)',
                        payload: 24,
                    },
                    {
                        title:
                            'اجرای آراء و تصمیمات مراجع غیر دادگستری در دادگستری و هزینه اجرای سایر احکام مقرر در بند ۲۲ ماده ۳  قانون وصول برخی از درآمدهای دولت و مصرف آن در موارد معین، مصوب ۱۳۷۳ (تصمیمات و سایر آرا)',
                        payload: 25,
                    },
                    {
                        title: 'تعرفه اجرای ماده ۴۷۷ قانون آیین دادرسی کیفری مصوب ۹۲/۱۲/۴',
                        payload: 26,
                    },
                    {
                        title: 'اعتراض شخص ثالث به اجرای احکام مدنی',
                        payload: 27,
                    },
                    {
                        title: 'اعتراض به قرارهای قابل اعتراض دادسرا',
                        payload: 28,
                    },
                    {
                        title: 'درخواست تصویر از اوراق پرونده',
                        payload: 29,
                    },
                    {
                        title: 'اعاده دادرسی کیفری در دیوان عالی کشور',
                        payload: 30,
                    },
                ],
            },
            {
                id: 1395,
                children: [
                    {
                        title:
                            'بهای اوراق دادخواست و اظهارنامه و برگ اجرائیه دادگاه ها و هیات های حل اختلاف موضوع قانون کار برای هر برگ',
                        payload: 1,
                    },
                    {
                        title:
                            'در هر مورد که در قوانین حداکثر مجازات کمتر از نود و یک روز حبس و یا مجازات تعزیری موضوع تخلفات رانندگی یا مجازات جایگزین حبس باشد',
                        payload: 2,
                    },
                    {
                        title:
                            'هر گاه حداقل مجازات کمتر از نود و یک روز حبس و حداکثر آن یک سال یا مجازات جایگزین حبس باشد و به اختیار دادگاه جزای نقدی مورد حکم واقع شود',
                        payload: 3,
                    },
                    {
                        title:
                            'هر گاه مجازات کمتر از نود و یک روز حبس و حداکثر آن بیشتر از یک سال یا مجازات جایگزین حبس باشد و به اختیار دادگاه جزای نقدی مورد حکم واقع شود',
                        payload: 4,
                    },
                    {
                        title: 'تقدیم دادخواست و درخواست تعقیب کیفری به مراجع قضایی علاوه بر حقوق مقرر',
                        payload: 5,
                    },
                    {
                        title: 'تقدیم شکایت به دادسرای انتظامی قضات',
                        payload: 6,
                    },
                    {
                        title: 'گواهی امضای مترجم دادگستری برای هر مورد',
                        payload: 7,
                    },
                    {
                        title: 'پلمپ اوراق ترجمه شده توسط مترجم رسمی دادگستری',
                        payload: 8,
                    },
                    {
                        title: 'هزینه پلمپ گواهی صحت ترجمه یا مطابقت رونوشت و تصویر با اصل در هر مورد',
                        payload: 9,
                    },
                    {
                        title: 'پروانه وکالت پایه ۱ ',
                        payload: 10,
                    },
                    {
                        title: 'پروانه مترجم رسمی، کارشناس رسمی،وکالت درجه ۲ و کارگشایی مقیم مرکز استان در هر مورد',
                        payload: 11,
                    },
                    {
                        title: 'پروانه وکالت درجه ۳',
                        payload: 12,
                    },
                    {
                        title: 'پروانه کارگشایی در سایر شهرستانها',
                        payload: 13,
                    },
                    {
                        title: 'پروانه وکالت اتفاقی',
                        payload: 14,
                    },
                    {
                        title: 'تقدیم دادخواست به دیوان عدالت اداری : شعب دیوان',
                        payload: 15,
                    },
                    {
                        title: 'تقدیم دادخواست به دیوان عدالت اداری : شعب تجدیدنظر',
                        payload: 16,
                    },
                    {
                        title: 'طرح پرونده حقوقی در شورای حل اختلاف',
                        payload: 17,
                    },
                    {
                        title: 'طرح پرونده کیفری در شورای حل اختلاف',
                        payload: 18,
                    },
                    {
                        title: 'هزینه دادرسی در صورتی که قیمت خواسته در دعاوی مالی در موقع تقدیم دادخواست مشخص نباشد',
                        payload: 19,
                    },
                    {
                        title: 'هزینه اجرای موقت احکام در کلیه مراجع قضایی',
                        payload: 20,
                    },
                    {
                        title: 'هزینه درخواست تجدیدنظر از قرارهای قابل تجدیدنظر در دادگاه و دیوان عالی کشور',
                        payload: 21,
                    },
                    {
                        title:
                            'هزینه تطبیق اوراق با اصل آنها در در دفاتر دادگاه ها و دیوان عدالت اداری و سایر مراجع قضایی و گواهی صادره از دفاتر کلیه مراجع مذکور',
                        payload: 22,
                    },
                    {
                        title: 'هزینه ابلاغ اظهارنامه و واخواست نامه در هر مورد',
                        payload: 23,
                    },
                    {
                        title: 'هزینه صدور گواهی عدم سوء پیشینه کیفری - نسخه اول',
                        payload: 24,
                    },
                    {
                        title: 'هزینه صدور گواهی عدم سوء پیشینه کیفری - نسخه های بعدی',
                        payload: 25,
                    },
                    {
                        title: 'هزینه دادرسی در مرحله تجدیدنظر از احکام کیفری',
                        payload: 26,
                    },
                    {
                        title: 'تعرفه هر شکایت کیفری',
                        payload: 27,
                    },
                    {
                        title: 'تعرفه هر شکایت کیفری در مرحله تجدیدنظر',
                        payload: 28,
                    },
                    {
                        title: 'طرح دعوای غیرمالی و امور حسبی',
                        payload: 29,
                    },
                    {
                        title: 'طرح دعوای غیرمالی و امور حسبی در مرحله تجدیدنظر',
                        payload: 30,
                    },
                    {
                        title:
                            'هزینه اجرای احکام دعاوی غیرمالی و احکامی که محکوم به آن تقویم نشده و هزینه اجرای آرا و تصمیمات مراجع غیردادگستری در دادگستری (آراء موضوع صلاحیت دادگاه خانواده)',
                        payload: 31,
                    },
                    {
                        title:
                            'هزینه اجرای احکام دعاوی غیرمالی و احکامی که محکوم به آن تقویم نشده و هزینه اجرای آرا و تصمیمات مراجع غیردادگستری در دادگستری (تصمیمات و سایر آراء)',
                        payload: 32,
                    },
                    {
                        title: 'تعرفه اجرای ماده ۴۷۷ قانون آیین دادرسی کیفری مصوب ۹۲/۱۲/۴',
                        payload: 33,
                    },
                ],
            },
        ], //موضوع
        calculate: (year, topic) => {
            let answer;
            if (year === 1400 || year === 1401 || year === 1402) {
                if (topic === 1) {
                    answer = 20000;
                } else if (topic === 2) {
                    answer = 'از ۳،۳۰۰،۰۰۰ تا ۳۳،۰۰۰،۰۰۰ ریال\n';
                } else if (topic === 3) {
                    answer = 'از ۳۳،۰۰۰،۰۰۰ تا ۹۹،۰۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 4) {
                    answer = 'از ۹۹،۰۰۰،۰۰۰ تا ۹۹۰،۰۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 5) {
                    answer = 100000;
                } else if (topic === 6) {
                    answer = 300000;
                } else if (topic === 7) {
                    answer = 300000;
                } else if (topic === 8) {
                    answer = 300000;
                } else if (topic === 9) {
                    answer = 300000;
                } else if (topic === 10) {
                    answer = 300000;
                } else if (topic === 11) {
                    answer = 8000000;
                } else if (topic === 12) {
                    answer = 5000000;
                } else if (topic === 13) {
                    answer = 850000;
                } else if (topic === 14) {
                    answer = 600000;
                } else if (topic === 15) {
                    answer = 200000;
                } else if (topic === 16) {
                    answer = 400000;
                } else if (topic === 17) {
                    answer = 1500000;
                } else if (topic === 18) {
                    answer = 300000;
                } else if (topic === 19) {
                    answer = 300000;
                } else if (topic === 20) {
                    answer = 15000;
                } else if (topic === 21) {
                    answer = 50000;
                } else if (topic === 22) {
                    answer = 100000;
                } else if (topic === 23) {
                    answer = 200000;
                } else if (topic === 24) {
                    answer = 150000;
                } else if (topic === 25) {
                    answer = 300000;
                } else if (topic === 26) {
                    answer = 'از ۴۰۰،۰۰۰ تا ۱،۸۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 27) {
                    answer = 300000;
                } else if (topic === 28) {
                    answer = 200000;
                } else if (topic === 29) {
                    answer = 200000;
                } else if (topic === 30) {
                    answer = 50000;
                } else if (topic === 31) {
                    answer = 500000;
                } else if (topic === 32) {
                    answer = 500000;
                } else if (topic === 33) {
                    answer = 2250000;
                } else if (topic === 34) {
                    answer = 3500000;
                } else if (topic === 35) {
                    answer = 5000000;
                }
            } else if (year === 1399) {
                if (topic === 1) {
                    answer = 20000;
                } else if (topic === 2) {
                    answer = 'از ۳،۳۰۰،۰۰۰ تا ۳۳،۰۰۰،۰۰۰ ریال\n';
                } else if (topic === 3) {
                    answer = 'از ۳۳،۰۰۰،۰۰۰ تا ۹۹،۰۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 4) {
                    answer = 'از ۹۹،۰۰۰،۰۰۰ تا ۹۹۰،۰۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 5) {
                    answer = 100000;
                } else if (topic === 6) {
                    answer = 300000;
                } else if (topic === 7) {
                    answer = 300000;
                } else if (topic === 8) {
                    answer = 300000;
                } else if (topic === 9) {
                    answer = 300000;
                } else if (topic === 10) {
                    answer = 300000;
                } else if (topic === 11) {
                    answer = 8000000;
                } else if (topic === 12) {
                    answer = 5000000;
                } else if (topic === 13) {
                    answer = 850000;
                } else if (topic === 14) {
                    answer = 600000;
                } else if (topic === 15) {
                    answer = 200000;
                } else if (topic === 16) {
                    answer = 400000;
                } else if (topic === 17) {
                    answer = 1500000;
                } else if (topic === 18) {
                    answer = 300000;
                } else if (topic === 19) {
                    answer = 300000;
                } else if (topic === 20) {
                    answer = 15000;
                } else if (topic === 21) {
                    answer = 50000;
                } else if (topic === 22) {
                    answer = 100000;
                } else if (topic === 23) {
                    answer = 200000;
                } else if (topic === 24) {
                    answer = 150000;
                } else if (topic === 25) {
                    answer = 300000;
                } else if (topic === 26) {
                    answer = 'از ۴۰۰،۰۰۰ تا ۱،۸۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 27) {
                    answer = 300000;
                } else if (topic === 28) {
                    answer = 200000;
                } else if (topic === 29) {
                    answer = 200000;
                } else if (topic === 30) {
                    answer = 50000;
                } else if (topic === 31) {
                    answer = 500000;
                }
            } else if (year === 1398) {
                if (topic === 1) {
                    answer = 20000;
                } else if (topic === 2) {
                    answer = 'از ۳،۳۰۰،۰۰۰ تا ۳۳،۰۰۰،۰۰۰ ریال\n';
                } else if (topic === 3) {
                    answer = 'از ۳۳،۰۰۰،۰۰۰ تا ۹۹،۰۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 4) {
                    answer = 'از ۹۹،۰۰۰،۰۰۰ تا ۹۹۰،۰۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 5) {
                    answer = 100000;
                } else if (topic === 6) {
                    answer = 300000;
                } else if (topic === 7) {
                    answer = 300000;
                } else if (topic === 8) {
                    answer = 300000;
                } else if (topic === 9) {
                    answer = 300000;
                } else if (topic === 10) {
                    answer = 300000;
                } else if (topic === 11) {
                    answer = 8000000;
                } else if (topic === 12) {
                    answer = 5000000;
                } else if (topic === 13) {
                    answer = 850000;
                } else if (topic === 14) {
                    answer = 600000;
                } else if (topic === 15) {
                    answer = 200000;
                } else if (topic === 16) {
                    answer = 400000;
                } else if (topic === 17) {
                    answer = 1500000;
                } else if (topic === 18) {
                    answer = 300000;
                } else if (topic === 19) {
                    answer = 300000;
                } else if (topic === 20) {
                    answer = 15000;
                } else if (topic === 21) {
                    answer = 50000;
                } else if (topic === 22) {
                    answer = 100000;
                } else if (topic === 23) {
                    answer = 200000;
                } else if (topic === 24) {
                    answer = 150000;
                } else if (topic === 25) {
                    answer = 300000;
                } else if (topic === 26) {
                    answer = 'از ۴۰۰،۰۰۰ تا ۱،۸۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 27) {
                    answer = 300000;
                } else if (topic === 28) {
                    answer = 200000;
                } else if (topic === 29) {
                    answer = 200000;
                } else if (topic === 30) {
                    answer = 50000;
                } else if (topic === 31) {
                    answer = 500000;
                }
            } else if (year === 1397) {
                if (topic === 1) {
                    answer = 20000;
                } else if (topic === 2) {
                    answer = 'از ۳،۳۰۰،۰۰۰ تا ۳۳،۰۰۰،۰۰۰ ریال\n';
                } else if (topic === 3) {
                    answer = 'از ۳۳،۰۰۰،۰۰۰ تا ۹۹،۰۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 4) {
                    answer = 'از ۹۹،۰۰۰،۰۰۰ تا ۹۹۰،۰۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 5) {
                    answer = 100000;
                } else if (topic === 6) {
                    answer = 300000;
                } else if (topic === 7) {
                    answer = 300000;
                } else if (topic === 8) {
                    answer = 300000;
                } else if (topic === 9) {
                    answer = 8000000;
                } else if (topic === 10) {
                    answer = 5000000;
                } else if (topic === 11) {
                    answer = 850000;
                } else if (topic === 12) {
                    answer = 600000;
                } else if (topic === 13) {
                    answer = 200000;
                } else if (topic === 14) {
                    answer = 400000;
                } else if (topic === 15) {
                    answer = 1500000;
                } else if (topic === 16) {
                    answer = 300000;
                } else if (topic === 17) {
                    answer = 300000;
                } else if (topic === 18) {
                    answer = 15000;
                } else if (topic === 19) {
                    answer = 50000;
                } else if (topic === 20) {
                    answer = 100000;
                } else if (topic === 21) {
                    answer = 200000;
                } else if (topic === 22) {
                    answer = 150000;
                } else if (topic === 23) {
                    answer = 300000;
                } else if (topic === 24) {
                    answer = 400000;
                } else if (topic === 25) {
                    answer = 'از ۱،۰۰۰،۰۰۰ تا ۱،۸۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 26) {
                    answer = 300000;
                } else if (topic === 27) {
                    answer = 200000;
                } else if (topic === 28) {
                    answer = 200000;
                } else if (topic === 29) {
                    answer = 50000;
                } else if (topic === 30) {
                    answer = 500000;
                }
            } else if (year === 1396) {
                if (topic === 1) {
                    answer = 20000;
                } else if (topic === 2) {
                    answer = 'از ۳،۳۰۰،۰۰۰ تا ۳۳،۰۰۰،۰۰۰ ریال\n';
                } else if (topic === 3) {
                    answer = 'از ۳۳،۰۰۰،۰۰۰ تا ۹۹،۰۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 4) {
                    answer = 'از ۹۹،۰۰۰،۰۰۰ تا ۹۹۰،۰۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 5) {
                    answer = 100000;
                } else if (topic === 6) {
                    answer = 300000;
                } else if (topic === 7) {
                    answer = 300000;
                } else if (topic === 8) {
                    answer = 300000;
                } else if (topic === 9) {
                    answer = 8000000;
                } else if (topic === 10) {
                    answer = 5000000;
                } else if (topic === 11) {
                    answer = 850000;
                } else if (topic === 12) {
                    answer = 600000;
                } else if (topic === 13) {
                    answer = 200000;
                } else if (topic === 14) {
                    answer = 400000;
                } else if (topic === 15) {
                    answer = 1500000;
                } else if (topic === 16) {
                    answer = 300000;
                } else if (topic === 17) {
                    answer = 300000;
                } else if (topic === 18) {
                    answer = 15000;
                } else if (topic === 19) {
                    answer = 50000;
                } else if (topic === 20) {
                    answer = 100000;
                } else if (topic === 21) {
                    answer = 200000;
                } else if (topic === 22) {
                    answer = 150000;
                } else if (topic === 23) {
                    answer = 300000;
                } else if (topic === 24) {
                    answer = 400000;
                } else if (topic === 25) {
                    answer = 'از ۱،۰۰۰،۰۰۰ تا ۱،۸۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 26) {
                    answer = 300000;
                } else if (topic === 27) {
                    answer = 200000;
                } else if (topic === 28) {
                    answer = 200000;
                } else if (topic === 29) {
                    answer = 50000;
                } else if (topic === 30) {
                    answer = 500000;
                }
            } else if (year === 1395) {
                if (topic === 1) {
                    answer = 15000;
                } else if (topic === 2) {
                    answer = '۳،۰۰۰،۰۰۰ تا ۳۰،۰۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 3) {
                    answer = 'از ۳۰،۰۰۰،۰۰۰ تا ۹۰،۰۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 4) {
                    answer = 'از ۹۰،۰۰۰،۰۰۰ تا ۹۰۰،۰۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 5) {
                    answer = 50000;
                } else if (topic === 6) {
                    answer = 200000;
                } else if (topic === 7) {
                    answer = 300000;
                } else if (topic === 8) {
                    answer = 300000;
                } else if (topic === 9) {
                    answer = 300000;
                } else if (topic === 10) {
                    answer = 7000000;
                } else if (topic === 11) {
                    answer = 4500000;
                } else if (topic === 12) {
                    answer = 2500000;
                } else if (topic === 13) {
                    answer = 750000;
                } else if (topic === 14) {
                    answer = 500000;
                } else if (topic === 15) {
                    answer = 150000;
                } else if (topic === 16) {
                    answer = 300000;
                } else if (topic === 17) {
                    answer = 150000;
                } else if (topic === 18) {
                    answer = 50000;
                } else if (topic === 19) {
                    answer = 1000000;
                } else if (topic === 20) {
                    answer = 200000;
                } else if (topic === 21) {
                    answer = 300000;
                } else if (topic === 22) {
                    answer = 10000;
                } else if (topic === 23) {
                    answer = 75000;
                } else if (topic === 24) {
                    answer = 150000;
                } else if (topic === 25) {
                    answer = 100000;
                } else if (topic === 26) {
                    answer = 200000;
                } else if (topic === 27) {
                    answer = 50000;
                } else if (topic === 28) {
                    answer = 100000;
                } else if (topic === 29) {
                    answer = 50000;
                } else if (topic === 30) {
                    answer = 100000;
                } else if (topic === 31) {
                    answer = 300000;
                } else if (topic === 32) {
                    answer = '۷۵۰،۰۰۰ تا ۱،۵۰۰،۰۰۰ ریال\n' + '\n';
                } else if (topic === 33) {
                    answer = 200000;
                }
            }
            return answer;
        },
    },
];
