import React, { useRef, useState } from 'react';
import { Button, CostPicker, IconButton, SingleDropdown, SingleDropdownOption } from 'vekalapp-react-utilities';
import './1398.style.scss';
import { enNumToFaNum } from '../../../../scripts/string';
import { TaxStamp } from './1398.data';
import { Form8_1398 } from '../../../../interface';

const Form8_Year1398: React.FC = () => {
    const titleRef = useRef<any>();
    const subjectRef = useRef<any>();
    const numberInputRef1 = useRef<any>();
    const docTypeRef = useRef<any>();
    const numberInputRef2 = useRef<any>();
    const fightResultRef = useRef<any>();
    const layerLicenseRef = useRef<any>();
    const checkbox1Ref = useRef<any>();
    const rangeRef = useRef<any>();
    const checkbox2Ref = useRef<any>();
    const checkbox3Ref = useRef<any>();
    const checkbox4Ref = useRef<any>();
    const crimeTypeRef = useRef<any>();
    const categoryRef = useRef<any>();
    const titleOption: SingleDropdownOption[] = [];
    const subjectOption: SingleDropdownOption[] = [];
    const layerLicenseOption: SingleDropdownOption[] = [
        {
            title: 'کانون وکلای دادگستری',
            payload: 1,
        },
        {
            title: 'مرکز وکلا، کارشناسان رسمی و مشاوران خانواده',
            payload: 2,
        },
    ];

    TaxStamp.map((item, index) => {
        subjectOption.push({ title: item.title, payload: item.id });
    });

    const [fightResult, setFightResult] = useState<SingleDropdownOption>({ title: 'هیچکدام', payload: 1 });
    const [days, setDays] = useState<number>(0);
    const missionPlace = useRef<any>(undefined);
    const [missionLocation, setMissionLocation] = useState<SingleDropdownOption>({ title: 'داخل استان', payload: 1 });
    const [layerLicense, setLayerLicense] = useState<SingleDropdownOption>({
        title: 'کانون وکلای دادگستری',
        payload: 1,
    });
    const [crimes, setCrimes] = useState<number>(0);
    const [crimeType, setCrimeType] = useState<SingleDropdownOption>({
        title: 'جرایم مستوجب مجازات های سلب حیات، قطع عضو، حبس ابد یا حبس تعزیری درجه یک',
        payload: 1,
    });
    const [category, setCategory] = useState<SingleDropdownOption>({
        title: 'مطلق دعاوی خانوادگی یا دعاوی مالی ناشی از زوجیت و همچنین امور حسبی',
        payload: 1,
    });
    const [subject, setSubject] = useState<SingleDropdownOption>({ title: 'تعرفه', payload: 1 });
    const [title, setTitle] = useState<SingleDropdownOption>({ title: 'مالی', payload: 1 });
    const [docType, setDocType] = useState<SingleDropdownOption>({ title: 'حقوقی', payload: 1 });
    const [extraParams1, setExtraParams1] = useState<number | undefined>(undefined);
    const [extraParams2, setExtraParams2] = useState<number | undefined>(undefined);
    const [selected, setSelected] = useState<any>(TaxStamp.find((item) => item.title == subjectOption[0].title));
    const [answer, setAnswer] = useState<string | number | undefined>(undefined);
    const [range, setRange] = useState<SingleDropdownOption>({ title: 'حداقل', payload: 1 });

    function handleMissionLocation(e) {
        setMissionLocation(e);
    }

    function handleSubject(e) {
        setSubject(e);
        setSelected(TaxStamp.find((item) => item.title == e.title));
    }

    selected &&
        selected.title === 'تعرفه' &&
        selected.param3.map((item) => {
            titleOption.push({
                title: item.title,
                payload: item.id,
            });
        });

    const [titleSelected, setTitleSelected] = useState<any>(
        selected && selected.param3 && selected.param3.find((item) => item.title == titleOption[0].title),
    );

    function handleTitle(e) {
        setTitle(e);
        setTitleSelected(selected.param3.find((item) => item.title == e.title));
    }

    function handleFightResult(e) {
        setFightResult(e);
    }

    function handleCalculate() {
        if (selected && selected.title === 'حق الوکاله') {
            if (extraParams1) {
                setAnswer(selected.calculate(layerLicense.payload, docType.payload, extraParams1));
                responseRef.current?.scrollIntoView(true);
            } else {
                numberInputRef1.current.setError(Form8_1398.price + ' وارد نشده است');
            }
        } else if (selected && selected.title === 'تعرفه') {
            if (titleSelected && titleSelected.title === 'مالی') {
                if (extraParams2) {
                    setAnswer(
                        titleSelected.calculate(
                            checkbox1Ref.current.checked,
                            checkbox2Ref.current.checked,
                            checkbox3Ref.current.checked,
                            days,
                            missionLocation.title === 'داخل استان',
                            fightResult.payload,
                            layerLicense.payload,
                            extraParams2,
                        ),
                    );
                    responseRef.current?.scrollIntoView(true);
                } else {
                    numberInputRef2.current.setError(Form8_1398.priceRequested + ' وارد نشده است');
                }
            } else if (titleSelected && titleSelected.title === 'امور حسبی، دعاوی خانوادگی و غیرمالی') {
                setAnswer(
                    titleSelected.calculate(
                        checkbox1Ref.current.checked,
                        checkbox2Ref.current.checked,
                        checkbox3Ref.current.checked,
                        days,
                        missionLocation.title === 'داخل استان',
                        category.payload,
                        range.payload,
                        fightResult.payload,
                        layerLicense.payload,
                    ),
                );
                responseRef.current?.scrollIntoView(true);
            } else if (titleSelected && titleSelected.title === 'کیفری') {
                setAnswer(
                    titleSelected.calculate(
                        checkbox1Ref.current.checked,
                        checkbox2Ref.current.checked,
                        checkbox3Ref.current.checked,
                        days,
                        missionLocation.title === 'داخل استان',
                        category.payload,
                        range.payload,
                        crimeType.payload,
                        crimes,
                        layerLicense.payload,
                    ),
                );
                responseRef.current?.scrollIntoView(true);
            } else if (titleSelected && titleSelected.title === 'سایر') {
                if (category.payload === 5 && range.payload === 2) {
                    if (extraParams2) {
                        setAnswer(
                            titleSelected.calculate(
                                checkbox1Ref.current.checked,
                                checkbox2Ref.current.checked,
                                checkbox3Ref.current.checked,
                                days,
                                missionLocation.title === 'داخل استان',
                                category.payload,
                                range.payload,
                                layerLicense.payload,
                                extraParams2,
                            ),
                        );
                        responseRef.current?.scrollIntoView(true);
                    } else {
                        numberInputRef2.current.setError(Form8_1398.priceRequested + ' وارد نشده است');
                    }
                } else {
                    setAnswer(
                        titleSelected.calculate(
                            checkbox1Ref?.current?.checked,
                            checkbox2Ref?.current?.checked,
                            checkbox3Ref?.current?.checked,
                            days,
                            missionLocation?.title === 'داخل استان',
                            category?.payload,
                            range?.payload,
                            layerLicense?.payload,
                        ),
                    );
                    responseRef.current?.scrollIntoView(true);
                }
            }
        }
    }

    function handleCategory(e) {
        setCategory(e);
        if (titleSelected.title === 'کیفری') {
            if (e.payload < 3) {
                setCrimeType(titleSelected.param5[e.payload - 1].options[0]);
            }
        }
    }

    function handleCrimeType(e) {
        setCrimeType(e);
    }

    function handleLayerLicense(e) {
        setLayerLicense(e);
    }

    function handleRange(e) {
        setRange(e);
    }

    function handleNumberInput1(e) {
        setExtraParams1(e);
    }

    function handleNumberInput2(e) {
        setExtraParams2(e);
    }

    function handleDocType(e) {
        setDocType(e);
    }

    const responseRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <div className="custom-row">
                <SingleDropdown
                    options={layerLicenseOption}
                    label="نوع پروانه وکیل"
                    placeholder="نوع پروانه وکیل را انتخاب کنید"
                    ref={layerLicenseRef}
                    onChange={(e) => handleLayerLicense(e)}
                    value={layerLicense}
                    background={'#fff'}
                />
            </div>
            <div className="custom-row">
                <SingleDropdown
                    options={subjectOption}
                    label="بر اساس"
                    placeholder="بر اساس را انتخاب کنید"
                    ref={subjectRef}
                    onChange={(e) => handleSubject(e)}
                    value={subject}
                    background={'#fff'}
                />
            </div>
            {selected && selected.title === 'تعرفه' && (
                <div className="custom-row">
                    <SingleDropdown
                        options={titleOption}
                        label="موضوع وکالت"
                        placeholder="موضوع وکالت را انتخاب کنید"
                        ref={titleRef}
                        onChange={(e) => handleTitle(e)}
                        value={title}
                        background={'#fff'}
                    />
                </div>
            )}
            {titleSelected && titleSelected.param4 !== null && (
                <div className="custom-row">
                    <SingleDropdown
                        options={titleSelected.param4}
                        label="دسته بندی"
                        placeholder="دسته بندی را انتخاب کنید"
                        ref={categoryRef}
                        onChange={(e) => handleCategory(e)}
                        value={titleSelected && titleSelected.param4[0]}
                        background={'#fff'}
                    />
                </div>
            )}
            {titleSelected && titleSelected.param5 !== null && category.payload < 3 && (
                <div className="custom-row">
                    <SingleDropdown
                        options={
                            category.payload === 1 ? titleSelected.param5[0].options : titleSelected.param5[1].options
                        }
                        label="نوع جرم"
                        placeholder="نوع جرم را انتخاب کنید"
                        ref={crimeTypeRef}
                        onChange={(e) => handleCrimeType(e)}
                        value={
                            category.payload === 1
                                ? titleSelected.param5[0].options[0]
                                : titleSelected.param5[1].options[0]
                        }
                        background={'#fff'}
                    />
                </div>
            )}
            {titleSelected && titleSelected.param6 !== null && (
                <div className="custom-row align-items-center">
                    <div className="days">
                        <p className="days-title">تعداد جرایم اضافه :</p>
                        <div className="counter">
                            <IconButton onClick={() => setCrimes(crimes + 1)} color="blue">
                                <i className="material-icons">add</i>
                            </IconButton>
                            <p className="days-counter">{crimes.toLocaleString('fa')}</p>
                            <IconButton
                                onClick={() => (crimes >= 1 ? setCrimes(crimes - 1) : setCrimes(crimes))}
                                color="blue"
                            >
                                <i className="material-icons">remove</i>
                            </IconButton>
                        </div>
                    </div>
                </div>
            )}
            {titleSelected && titleSelected.param3 !== null && (
                <div className="custom-row">
                    <SingleDropdown
                        options={titleSelected.param3}
                        label="محدوده تعرفه"
                        placeholder="محدوده تعرفه را انتخاب کنید"
                        ref={rangeRef}
                        onChange={(e) => handleRange(e)}
                        value={range}
                        background={'#fff'}
                    />
                </div>
            )}
            {selected && selected.title === 'تعرفه' && titleSelected && titleSelected.param1 !== null && (
                <div className="custom-row">
                    <SingleDropdown
                        options={titleSelected.param1}
                        label="نتیجه دعوا"
                        placeholder="نتیجه دعوا را انتخاب کنید"
                        ref={fightResultRef}
                        onChange={(e) => handleFightResult(e)}
                        value={fightResult}
                        background={'#fff'}
                    />
                </div>
            )}
            {selected && selected.title !== 'حق الوکاله' && (
                <>
                    <div className="custom-row align-items-center">
                        <label className="conditions-container" htmlFor="checkbox1">
                            <input id="checkbox1" type="checkbox" ref={checkbox1Ref} className="conditions" />
                            وکیل پس از نقض رأی وکالت را به عهده گرفته است
                        </label>
                    </div>
                    <div className="custom-row align-items-center">
                        <label className="conditions-container" htmlFor="checkbox2">
                            <input id="checkbox2" type="checkbox" ref={checkbox2Ref} className="conditions" />
                            وکالت از نوع تسخیری یا معاضدتی است
                        </label>
                    </div>
                    <div className="custom-row align-items-center">
                        <label className="conditions-container" htmlFor="checkbox3">
                            <input id="checkbox3" type="checkbox" ref={checkbox3Ref} className="conditions" />
                            وکیل دارای گواهی وکالت تخصصی است
                        </label>
                    </div>
                    <div className="custom-row align-items-center">
                        <label className="conditions-container" htmlFor="checkbox4">
                            <input id="checkbox4" type="checkbox" ref={checkbox4Ref} className="conditions" />
                            موضوع دعوا ورود، جلب یا اعتراض ثالث یا تقابل است
                        </label>
                    </div>
                    <div className="custom-row align-items-center">
                        <div className="days">
                            <p className="days-title">فوق العاده مأموریت (روز) :</p>
                            <div className="counter">
                                <IconButton onClick={() => setDays(days + 1)} color="blue">
                                    <i className="material-icons">add</i>
                                </IconButton>
                                <p className="days-counter">{days.toLocaleString('fa')}</p>
                                <IconButton
                                    onClick={() => (days >= 1 ? setDays(days - 1) : setDays(days))}
                                    color="blue"
                                >
                                    <i className="material-icons">remove</i>
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    {days > 0 && (
                        <div className="custom-row">
                            <SingleDropdown
                                options={[
                                    { title: 'داخل استان', payload: 1 },
                                    { title: 'خارج استان', payload: 2 },
                                ]}
                                label="محل ماموریت"
                                placeholder="محل ماموریت خود را انتخاب کنید"
                                ref={missionPlace}
                                onChange={(e) => handleMissionLocation(e)}
                                value={missionLocation}
                                background={'#fff'}
                            />
                        </div>
                    )}
                </>
            )}
            {selected && selected.title === 'حق الوکاله' && (
                <div className="custom-row">
                    <SingleDropdown
                        options={selected.param2}
                        label="نوع پرونده"
                        placeholder="نوع پرونده خود را انتخاب کنید"
                        ref={docTypeRef}
                        onChange={(e) => handleDocType(e)}
                        value={docType}
                        background={'#fff'}
                    />
                </div>
            )}
            {((selected &&
                selected.title !== 'حق الوکاله' &&
                titleSelected &&
                titleSelected.param2 !== Form8_1398.none) ||
                (titleSelected && titleSelected.title === 'سایر' && category.payload === 5 && range.payload === 2)) && (
                <div className="custom-row">
                    <CostPicker
                        ref={numberInputRef2}
                        onChange={(e) => handleNumberInput2(e)}
                        label={Form8_1398.priceRequested}
                        placeholder={Form8_1398.priceRequested + ' را وارد کنید'}
                        background={'#fff'}
                    />
                </div>
            )}
            {selected && selected.title === 'حق الوکاله' && (
                <div className="custom-row">
                    <CostPicker
                        ref={numberInputRef1}
                        onChange={(e) => handleNumberInput1(e)}
                        label={Form8_1398.price}
                        placeholder={Form8_1398.price + ' را وارد کنید'}
                        background={'#fff'}
                    />
                </div>
            )}
            <div
                className={
                    (subject.payload === 1 && title.payload === 3 && days === 0) ||
                    (subject.payload === 1 && title.payload === 3 && category.payload > 2) ||
                    (subject.payload === 1 && title.payload === 4 && category.payload !== 5)
                        ? 'custom-row align-items-center'
                        : 'custom-row'
                }
            >
                <Button
                    onClick={handleCalculate}
                    className={
                        subject.payload === 2 ||
                        (subject.payload === 1 && title.payload === 1 && days === 0) ||
                        (subject.payload === 1 && title.payload === 2 && days > 0) ||
                        (subject.payload === 1 && title.payload === 3 && category.payload > 2 && days > 0) ||
                        (subject.payload === 1 &&
                            title.payload === 4 &&
                            category.payload === 5 &&
                            range.payload === 2 &&
                            days > 0)
                            ? 'calculate activeStyle'
                            : 'calculate'
                    }
                >
                    محاسبه کنید!
                </Button>
            </div>
            <div className="custom-row d-none" ref={responseRef} />
            {answer && (
                <div className="answer">
                    {typeof answer === 'number' && ` ${enNumToFaNum(answer.toLocaleString().toString())} ریال می باشد.`}
                    {typeof answer === 'string' && `${enNumToFaNum(answer.toLocaleString().toString())}`}
                </div>
            )}
        </>
    );
};

export default Form8_Year1398;
