import { stat } from 'fs';

export function handleInheritanceCalculation(
    maritalStatus,
    wifeNumber,
    checkbox1,
    checkbox2,
    boys,
    girls,
    checkbox3,
    checkbox4,
    checkbox5,
    checkbox6,
    state1,
    state2,
    state3,
    state4,
    state5,
    state6,
    state7,
    state8,
    state9,
    state10,
    state11,
    state12,
    state13,
    state14,
    state15,
    state16,
    state17,
    state18,
) {
    let result = '';
    if (maritalStatus === 1) {
        if (
            !checkbox1 &&
            !checkbox2 &&
            !checkbox3 &&
            !checkbox4 &&
            !checkbox5 &&
            !checkbox6 &&
            girls === 0 &&
            boys === 0 &&
            state1 === 0 &&
            state2 === 0 &&
            state3 === 0 &&
            state4 === 0 &&
            state5 === 0 &&
            state6 === 0 &&
            state7 === 0 &&
            state8 === 0 &&
            state9 === 0 &&
            state10 === 0 &&
            state11 === 0 &&
            state12 === 0 &&
            state13 === 0 &&
            state14 === 0 &&
            state15 === 0 &&
            state16 === 0 &&
            state17 === 0 &&
            state18 === 0
        ) {
            // none of them
            result = 'حاکم : ۱۰۰ ٪ معادل ۱/۱';
        } else if (checkbox1 || checkbox2 || girls > 0 || boys > 0) {
            // level 1
            if (!checkbox1 && !checkbox2) {
                if (boys >= 1 && girls === 0) {
                    const temp = boys * 2 + girls;
                    result = 'هر فرزند پسر : ' + ((2 / temp) * 100).toFixed(2) + ' ٪ معادل ۱/' + boys + '';
                } else if (boys === 0 && girls >= 1) {
                    const temp = boys * 2 + girls;
                    result = 'هر فرزند دختر : ' + ((1 / temp) * 100).toFixed(2) + ' ٪ معادل ۱/' + girls + '';
                } else if (boys >= 1 && girls >= 1) {
                    const temp = boys * 2 + girls;
                    result =
                        'هر فرزند پسر : ' +
                        ((2 / temp) * 100).toFixed(2) +
                        ' ٪ معادل ۲/' +
                        temp +
                        '\n' +
                        ' و هر فرزند دختر : ' +
                        ((1 / temp) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp +
                        '';
                }
            } else if (checkbox1 && !checkbox2) {
                if (boys === 0 && girls === 0) {
                    result = 'پدر : ۱۰۰ ٪ معادل ۱/۱';
                } else if (boys === 1 && girls === 0) {
                    result = 'پدر : ۱۶.۶۷ ٪ معادل ۱/۶\n' + 'فرزند پسر : ۸۳.۳۳ ٪ معادل ۵/۶\n' + '\n';
                } else if (boys > 1 && girls === 0) {
                    const temp = 6 * boys;
                    result =
                        'پدر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                        'و هر فرزند پسر : ' +
                        ((5 / temp) * 100).toFixed(2) +
                        ' ٪ معادل ۵/' +
                        temp;
                } else if (boys === 0 && girls === 1) {
                    result = 'پدر : ۲۵ ٪ معادل ۱/۴\n' + 'فرزند دختر : ۷۵ ٪ معادل ۳/۴';
                } else if (boys === 0 && girls > 1) {
                    const temp = 5 * girls;
                    result =
                        'پدر     : ۲۰ ٪ معادل ۱/۵\n' +
                        'و هر فرزند دختر : ' +
                        ((4 / temp) * 100).toFixed(2) +
                        ' ٪ معادل ۴/' +
                        temp;
                } else if (girls >= 1 && boys >= 1) {
                    const end = boys * 2 + girls;
                    result =
                        'پدر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                        '، هر فرزند پسر : ' +
                        ((((2 / end) * 5) / 6) * 100).toFixed(2) +
                        ' ٪ معادل ' +
                        2 * 5 +
                        '/' +
                        end * 6 +
                        '\n' +
                        'و هر فرزند دختر : ' +
                        ((((1 / end) * 5) / 6) * 100).toFixed(2) +
                        ' ٪ معادل ' +
                        5 +
                        '/' +
                        end * 6 +
                        '';
                }
            } else if (!checkbox1 && checkbox2) {
                if (boys === 0 && girls === 0) {
                    result = 'مادر : ۱۰۰ ٪ معادل ۱/۱';
                } else if (boys === 1 && girls === 0) {
                    result = 'مادر : ۱۶.۶۷ ٪ معادل ۱/۶\n' + 'فرزند پسر : ۸۳.۳۳ ٪ معادل ۵/۶';
                } else if (boys > 1 && girls === 0) {
                    const temp = 6 * boys;
                    result =
                        'مادر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                        'و هر فرزند پسر : ' +
                        ((5 / temp) * 100).toFixed(2) +
                        ' ٪ معادل ۵/' +
                        temp;
                } else if (boys === 0 && girls === 1) {
                    result = 'مادر : ۲۵ ٪ معادل ۱/۴\n' + 'فرزند دختر : ۷۵ ٪ معادل ۳/۴';
                } else if (boys === 0 && girls > 1) {
                    const temp = 5 * girls;
                    result =
                        'مادر : ۲۰ ٪ معادل ۱/۵\n' +
                        'و هر فرزند دختر : ' +
                        ((4 / temp) * 100).toFixed(2) +
                        ' ٪ معادل ۴/' +
                        temp;
                } else if (girls >= 1 && boys >= 1) {
                    const end = boys * 2 + girls;
                    result =
                        'مادر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                        '، هر فرزند پسر : ' +
                        ((((2 / end) * 5) / 6) * 100).toFixed(2) +
                        ' ٪ معادل ' +
                        2 * 5 +
                        '/' +
                        end * 6 +
                        '\n' +
                        'و هر فرزند دختر : ' +
                        ((((1 / end) * 5) / 6) * 100).toFixed(2) +
                        ' ٪ معادل ' +
                        5 +
                        '/' +
                        end * 6 +
                        '';
                }
            } else if (checkbox1 && checkbox2) {
                if (boys === 0 && girls === 0) {
                    result = 'پدر : ۶۶.۶۷ ٪ معادل ۲/۳\n' + ' و مادر : ۳۳.۳۳ ٪ معادل ۱/۳\n' + '\n';
                } else if (boys === 1 && girls === 0) {
                    result =
                        'پدر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                        ' ، مادر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                        ' و فرزند پسر : ۶۶.۶۷ ٪ معادل ۲/۳';
                } else if (boys > 1 && girls === 0) {
                    const temp = 3 * boys;
                    result =
                        'پدر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                        ' ، مادر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                        ' و هر فرزند پسر : ' +
                        ((2 / temp) * 100).toFixed(2) +
                        ' ٪ معادل ۲/' +
                        temp +
                        '';
                } else if (boys === 0 && girls === 1) {
                    result =
                        'پدر : ۲۰ ٪ معادل ۱/۵\n' +
                        ' ، مادر : ۲۰ ٪ معادل ۱/۵\n' +
                        ' و فرزند دختر : ۶۰ ٪ معادل ۳/۵\n' +
                        '\n';
                } else if (boys === 0 && girls > 1) {
                    const temp = 3 * girls;
                    result =
                        'پدر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                        ' ، مادر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                        ' و هر فرزند دختر : ' +
                        ((2 / temp) * 100).toFixed(2) +
                        ' ٪ معادل ۲/' +
                        temp +
                        '';
                } else if (girls >= 1 && boys >= 1) {
                    const temp = boys * 2 + girls;
                    result =
                        'پدر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                        ' ، مادر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                        ' ، هر فرزند پسر : ' +
                        ((2 / 3 / temp) * 100 * 2).toFixed(2) +
                        ' ٪ معادل ' +
                        2 * 2 +
                        '/' +
                        temp * 3 +
                        '\n' +
                        ' و هر فرزند دختر : ' +
                        ((2 / 3 / temp) * 100).toFixed(2) +
                        ' ٪ معادل ' +
                        2 +
                        '/' +
                        temp * 3;
                }
            }
        } else if (
            !checkbox1 &&
            !checkbox2 &&
            boys === 0 &&
            girls === 0 &&
            (checkbox3 ||
                checkbox4 ||
                checkbox5 ||
                checkbox6 ||
                state1 > 0 ||
                state2 > 0 ||
                state3 > 0 ||
                state4 > 0 ||
                state5 > 0 ||
                state6 > 0)
        ) {
            //level 2
            if (
                state1 === 0 &&
                state2 === 0 &&
                state3 === 0 &&
                state4 === 0 &&
                state5 === 0 &&
                state6 === 0 &&
                (checkbox3 || checkbox4 || checkbox5 || checkbox6)
            ) {
                // just grandparents
                const checkboxes = [checkbox3, checkbox4, checkbox5, checkbox6];
                const name = checkbox3 ? 'جد ابی' : checkbox4 ? 'جده ابی' : checkbox5 ? 'جد امی' : 'جده امی';
                if (checkboxes.filter((item) => item === true).length === 1) {
                    result = '' + name + ' : ۱۰۰ ٪ معادل ۱/۱';
                }
                if (checkboxes.filter((item) => item === true).length > 1) {
                    if (checkbox3 && checkbox4 && !checkbox5 && !checkbox6) {
                        result = 'جد ابی : ۶۶.۶۷ ٪ معادل ۲/۳\n' + ' و جده ابی : ۳۳.۳۳ ٪ معادل ۱/۳';
                    } else if (!checkbox3 && !checkbox4 && checkbox5 && checkbox6) {
                        result = '\n' + 'جد امی : ۵۰ ٪ معادل ۱/۲\n' + ' و جده امی : ۵۰ ٪ معادل ۱/۲';
                    } else {
                        if (checkbox5 || checkbox6) {
                            if (checkbox5 && !checkbox6) {
                                result = 'جد امی : ۳۳.۳۳ ٪ معادل ۱/۳\n';
                            } else if (!checkbox5 && checkbox6) {
                                result = 'جده امی : ۳۳.۳۳ ٪ معادل ۱/۳\n';
                            } else if (checkbox5 && checkbox6) {
                                result = 'جد امی : ۱۶.۶۷ ٪ معادل ۱/۶\n' + ' و جده امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                            }
                        }
                        if (checkbox3 || checkbox4) {
                            if (checkbox3 && !checkbox4) {
                                result += ' و جد ابی : ۶۶.۶۷ ٪ معادل ۲/۳\n';
                            } else if (!checkbox3 && checkbox4) {
                                result += ' و جده ابی : ۶۶.۶۷ ٪ معادل ۲/۳\n';
                            } else if (checkbox3 && checkbox4) {
                                result += ' و جد ابی : ۴۴.۴۴ ٪ معادل ۴/۹\n' + ' و جده ابی : ۲۲.۲۲ ٪ معادل ۲/۹';
                            }
                        }
                    }
                }
            }
            if (!checkbox3 && !checkbox4 && !checkbox5 && !checkbox6) {
                //none of the checkboxes
                if (state1 >= 1 && state2 === 0 && state3 === 0 && state4 === 0 && state5 === 0 && state6 === 0) {
                    result = 'برادر ابوینی : ' + ((1 / state1) * 100).toFixed(2) + ' ٪ معادل ۱/' + state1 + '';
                } else if (
                    state1 === 0 &&
                    state2 >= 1 &&
                    state3 === 0 &&
                    state4 === 0 &&
                    state5 === 0 &&
                    state6 === 0
                ) {
                    result = 'خواهر ابوینی : ' + ((1 / state2) * 100).toFixed(2) + ' ٪ معادل ۱/' + state2 + '';
                } else if (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 === 0 && state6 === 0) {
                    const temp = state1 * 2 + state2;
                    result =
                        'برادر ابوینی : ' +
                        ((2 / temp) * 100).toFixed(2) +
                        ' ٪ معادل ۲/' +
                        temp +
                        '\n' +
                        ' و خواهر ابوینی : ' +
                        ((1 / temp) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp +
                        '';
                } else if (
                    state1 === 0 &&
                    state2 === 0 &&
                    state3 >= 1 &&
                    state4 === 0 &&
                    state5 === 0 &&
                    state6 === 0
                ) {
                    result = 'برادر ابی  : ' + ((1 / state3) * 100).toFixed(2) + ' ٪ معادل ۱/' + state3 + '';
                } else if (
                    state1 === 0 &&
                    state2 === 0 &&
                    state3 === 0 &&
                    state4 >= 1 &&
                    state5 === 0 &&
                    state6 === 0
                ) {
                    result = 'خواهر ابی  : ' + ((1 / state4) * 100).toFixed(2) + ' ٪ معادل ۱/' + state4 + '';
                } else if (state1 === 0 && state2 === 0 && state3 >= 1 && state4 >= 1 && state5 === 0 && state6 === 0) {
                    const temp = state3 * 2 + state4;
                    result =
                        'برادر ابی : ' +
                        ((2 / temp) * 100).toFixed(2) +
                        ' ٪ معادل ۲/' +
                        temp +
                        '\n' +
                        ' و خواهر ابی : ' +
                        ((1 / temp) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp +
                        '';
                } else if (
                    state1 === 0 &&
                    state2 === 0 &&
                    state3 === 0 &&
                    state4 === 0 &&
                    state5 >= 1 &&
                    state6 === 0
                ) {
                    result = 'برادر امی  : ' + ((1 / state5) * 100).toFixed(2) + ' ٪ معادل ۱/' + state5 + '';
                } else if (
                    state1 === 0 &&
                    state2 === 0 &&
                    state3 === 0 &&
                    state4 === 0 &&
                    state5 === 0 &&
                    state6 >= 1
                ) {
                    result = 'خواهر امی  : ' + ((1 / state6) * 100).toFixed(2) + ' ٪ معادل ۱/' + state6 + '';
                } else if (state1 === 0 && state2 === 0 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 >= 1) {
                    const temp = state5 + state6;
                    result =
                        'برادر امی : ' +
                        ((1 / temp) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp +
                        '\n' +
                        ' و خواهر امی : ' +
                        ((1 / temp) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp +
                        '';
                } else if (
                    (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 === 0) ||
                    (state1 >= 1 && state2 === 0 && state3 === 0 && state4 >= 1 && state5 === 0 && state6 === 0) ||
                    (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 >= 1 && state5 === 0 && state6 === 0)
                ) {
                    result = 'برادر ابوینی  : ' + ((1 / state1) * 100).toFixed(2) + ' ٪ معادل ۱/' + state1 + '';
                } else if (
                    (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 === 0) ||
                    (state1 === 0 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 === 0 && state6 === 0) ||
                    (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 === 0 && state6 === 0)
                ) {
                    result = 'خواهر ابوینی  : ' + ((1 / state2) * 100).toFixed(2) + ' ٪ معادل ۱/' + state2 + '';
                } else if (
                    (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 === 0) ||
                    (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 === 0 && state6 === 0) ||
                    (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 === 0 && state6 === 0)
                ) {
                    const temp = 2 * state1 + state2;
                    result =
                        'برادر ابوینی : ' +
                        ((2 / temp) * 100).toFixed(2) +
                        ' ٪ معادل ۲/' +
                        temp +
                        '\n' +
                        ' و خواهر ابوینی : ' +
                        ((1 / temp) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp +
                        '';
                } else if (
                    (state1 >= 1 && state2 === 0 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 === 0) ||
                    (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 === 0) ||
                    (state1 >= 1 && state2 === 0 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 === 0) ||
                    (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 === 0)
                ) {
                    const temp1 = state5 === 1 ? 6 : state5 * 3;
                    const temp2 = state5 === 1 ? state1 * 6 : state1 * 3;
                    if (state5 === 1) {
                        result =
                            'هر برادر ابوینی : ' +
                            ((5 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 +
                            '\n' +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else {
                        result =
                            'هر برادر ابوینی : ' +
                            ((2 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۲/' +
                            temp2 +
                            '\n' +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    }
                } else if (
                    (state1 >= 1 && state2 === 0 && state3 === 0 && state4 === 0 && state5 === 0 && state6 >= 1) ||
                    (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 >= 1) ||
                    (state1 >= 1 && state2 === 0 && state3 === 0 && state4 >= 1 && state5 === 0 && state6 >= 1) ||
                    (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 >= 1 && state5 === 0 && state6 >= 1)
                ) {
                    const temp1 = state6 === 1 ? 6 : state6 * 3;
                    const temp2 = state6 === 1 ? state1 * 6 : state1 * 3;
                    if (state6 === 1) {
                        result =
                            'هر برادر ابوینی : ' +
                            ((5 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else {
                        result =
                            'هر برادر ابوینی : ' +
                            ((2 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۲/' +
                            temp2 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    }
                } else if (
                    (state1 >= 1 && state2 === 0 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                    (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                    (state1 >= 1 && state2 === 0 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 >= 1) ||
                    (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 >= 1)
                ) {
                    const temp1 = 3 * (state6 + state5);
                    const temp2 = 3 * state1;
                    result =
                        'هر برادر ابوینی : ' +
                        ((2 / temp2) * 100).toFixed(2) +
                        ' ٪ معادل ۲/' +
                        temp2 +
                        '\n' +
                        ' ، هر برادر امی : ' +
                        ((1 / temp1) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp1 +
                        '\n' +
                        ' و هر خواهر امی : ' +
                        ((1 / temp1) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp1 +
                        '';
                } else if (
                    (state1 === 0 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 === 0) ||
                    (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 === 0) ||
                    (state1 === 0 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 === 0) ||
                    (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 === 0)
                ) {
                    const temp1 = state5 === 1 ? 6 : state5 * 3;
                    const temp2 = state5 === 1 ? state2 * 6 : state2 * 3;
                    if (state5 === 1) {
                        result =
                            'هر خواهر ابوینی : ' +
                            ((5 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 +
                            '\n' +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else {
                        result =
                            'هر خواهر ابوینی : ' +
                            ((2 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۲/' +
                            temp2 +
                            '\n' +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    }
                } else if (
                    (state1 === 0 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 === 0 && state6 >= 1) ||
                    (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 >= 1) ||
                    (state1 === 0 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                    (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 >= 1)
                ) {
                    const temp1 = state6 === 1 ? 6 : state6 * 3;
                    const temp2 = state6 === 1 ? state2 * 6 : state2 * 3;
                    if (state6 === 1) {
                        result =
                            'هر خواهر ابوینی : ' +
                            ((5 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else {
                        result =
                            'هر خواهر ابوینی : ' +
                            ((2 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۲/' +
                            temp2 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    }
                } else if (
                    (state1 === 0 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                    (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                    (state1 === 0 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 >= 1) ||
                    (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 >= 1)
                ) {
                    const temp1 = 3 * (state6 + state5);
                    const temp2 = 3 * state2;
                    result =
                        'هر خواهر ابوینی : ' +
                        ((2 / temp2) * 100).toFixed(2) +
                        ' ٪ معادل ۲/' +
                        temp2 +
                        '\n' +
                        ' ، هر برادر امی : ' +
                        ((1 / temp1) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp1 +
                        '\n' +
                        ' و هر خواهر امی : ' +
                        ((1 / temp1) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp1 +
                        '';
                } else if (
                    (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 === 0) ||
                    (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 === 0) ||
                    (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 === 0) ||
                    (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 === 0)
                ) {
                    const temp1 = state5 === 1 ? 6 : state5 * 3;
                    const temp2 = state5 === 1 ? (2 * state1 + state2) * 6 : (2 * state1 + state2) * 3;
                    if (state5 === 1) {
                        result =
                            'هر برادر ابوینی : ' +
                            ((10 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۱۰/' +
                            temp2 +
                            '\n' +
                            'هر خواهر ابوینی : ' +
                            ((5 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 +
                            '\n' +
                            'هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else {
                        result =
                            'هر برادر ابوینی : ' +
                            ((4 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۴/' +
                            temp2 +
                            '\n' +
                            'هر خواهر ابوینی : ' +
                            ((2 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۲/' +
                            temp2 +
                            '\n' +
                            'هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    }
                } else if (
                    (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 === 0 && state6 >= 1) ||
                    (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 >= 1) ||
                    (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 === 0 && state6 >= 1) ||
                    (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 === 0 && state6 >= 1)
                ) {
                    const temp1 = state6 === 1 ? 6 : state6 * 3;
                    const temp2 = state6 === 1 ? (2 * state1 + state2) * 6 : (2 * state1 + state2) * 3;
                    if (state6 === 1) {
                        result =
                            'هر برادر ابوینی : ' +
                            ((10 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۱۰/' +
                            temp2 +
                            '\n' +
                            'هر خواهر ابوینی : ' +
                            ((5 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 +
                            '\n' +
                            'هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else {
                        result =
                            'هر برادر ابوینی : ' +
                            ((4 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۴/' +
                            temp2 +
                            '\n' +
                            'هر خواهر ابوینی : ' +
                            ((2 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۲/' +
                            temp2 +
                            '\n' +
                            'هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    }
                } else if (
                    (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                    (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                    (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 >= 1) ||
                    (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 >= 1)
                ) {
                    const temp1 = 3 * (state5 + state6);
                    const temp2 = 3 * (2 * state1 + state2);
                    result =
                        'هر برادر ابوینی : ' +
                        ((4 / temp2) * 100).toFixed(2) +
                        ' ٪ معادل ۴/' +
                        temp2 +
                        '\n' +
                        ' ، هر خواهر ابوینی : ' +
                        ((2 / temp2) * 100).toFixed(2) +
                        ' ٪ معادل ۲/' +
                        temp2 +
                        '\n' +
                        ' ، هر برادر امی : ' +
                        ((1 / temp1) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp1 +
                        '\n' +
                        ' و هر خواهر امی : ' +
                        ((1 / temp1) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp1 +
                        '\n';
                } else if (state1 === 0 && state2 === 0 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 === 0) {
                    const temp1 = state5 === 1 ? 6 : state5 * 3;
                    const temp2 = state5 === 1 ? state3 * 6 : state3 * 3;
                    if (state5 === 1) {
                        result =
                            'هر برادر ابی : ' +
                            ((5 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 +
                            '\n' +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else {
                        result =
                            'هر برادر ابی : ' +
                            ((2 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۲/' +
                            temp2 +
                            '\n' +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    }
                } else if (state1 === 0 && state2 === 0 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 >= 1) {
                    const temp1 = state6 === 1 ? 6 : state6 * 3;
                    const temp2 = state6 === 1 ? state3 * 6 : state3 * 3;
                    if (state6 === 1) {
                        result =
                            'هر برادر ابی : ' +
                            ((5 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else {
                        result =
                            'هر برادر ابی : ' +
                            ((2 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۲/' +
                            temp2 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    }
                } else if (state1 === 0 && state2 === 0 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 >= 1) {
                    const temp1 = 3 * (state6 + state5);
                    const temp2 = 3 * state3;
                    result =
                        'هر برادر ابی : ' +
                        ((2 / temp2) * 100).toFixed(2) +
                        ' ٪ معادل ۲/' +
                        temp2 +
                        '\n' +
                        ' ، هر برادر امی : ' +
                        ((1 / temp1) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp1 +
                        '\n' +
                        ' و هر خواهر امی : ' +
                        ((1 / temp1) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp1 +
                        '';
                } else if (state1 === 0 && state2 === 0 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 === 0) {
                    const temp1 = state5 === 1 ? 6 : state5 * 3;
                    const temp2 = state5 === 1 ? state4 * 6 : state4 * 3;
                    if (state5 === 1) {
                        result =
                            'هر خواهر ابی : ' +
                            ((5 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 +
                            '\n' +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else {
                        result =
                            'هر خواهر ابی : ' +
                            ((2 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۲/' +
                            temp2 +
                            '\n' +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    }
                } else if (state1 === 0 && state2 === 0 && state3 === 0 && state4 >= 1 && state5 === 0 && state6 >= 1) {
                    const temp1 = state6 === 1 ? 6 : state6 * 3;
                    const temp2 = state6 === 1 ? state4 * 6 : state4 * 3;
                    if (state6 === 1) {
                        result =
                            'هر خواهر ابی : ' +
                            ((5 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else {
                        result =
                            'هر خواهر ابی : ' +
                            ((2 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۲/' +
                            temp2 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    }
                } else if (state1 === 0 && state2 === 0 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 >= 1) {
                    const temp1 = 3 * (state6 + state5);
                    const temp2 = 3 * state4;
                    result =
                        'هر خواهر ابی : ' +
                        ((2 / temp2) * 100).toFixed(2) +
                        ' ٪ معادل ۲/' +
                        temp2 +
                        '\n' +
                        ' ، هر برادر امی : ' +
                        ((1 / temp1) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp1 +
                        '\n' +
                        ' و هر خواهر امی : ' +
                        ((1 / temp1) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp1 +
                        '';
                } else if (state1 === 0 && state2 === 0 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 === 0) {
                    const temp1 = state5 === 1 ? 6 : state5 * 3;
                    const temp2 = state5 === 1 ? (2 * state3 + state4) * 6 : (2 * state3 + state4) * 3;
                    if (state5 === 1) {
                        result =
                            'هر برادر ابی : ' +
                            ((10 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۱۰/' +
                            temp2 +
                            '\n' +
                            'هر خواهر ابی : ' +
                            ((5 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 +
                            '\n' +
                            'هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else {
                        result =
                            'هر برادر ابی : ' +
                            ((4 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۴/' +
                            temp2 +
                            '\n' +
                            'هر خواهر ابی : ' +
                            ((2 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۲/' +
                            temp2 +
                            '\n' +
                            'هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    }
                } else if (state1 === 0 && state2 === 0 && state3 >= 1 && state4 >= 1 && state5 === 0 && state6 >= 1) {
                    const temp1 = state6 === 1 ? 6 : state6 * 3;
                    const temp2 = state6 === 1 ? (2 * state3 + state4) * 6 : (2 * state3 + state4) * 3;
                    if (state6 === 1) {
                        result =
                            'هر برادر ابی : ' +
                            ((10 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۱۰/' +
                            temp2 +
                            '\n' +
                            'هر خواهر ابی : ' +
                            ((5 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 +
                            '\n' +
                            'هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else {
                        result =
                            'هر برادر ابی : ' +
                            ((4 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۴/' +
                            temp2 +
                            '\n' +
                            'هر خواهر ابی : ' +
                            ((2 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۲/' +
                            temp2 +
                            '\n' +
                            'هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    }
                } else if (state1 === 0 && state2 === 0 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 >= 1) {
                    const temp1 = 3 * (state5 + state6);
                    const temp2 = 3 * (2 * state3 + state4);
                    result =
                        'هر برادر ابی : ' +
                        ((4 / temp2) * 100).toFixed(2) +
                        ' ٪ معادل ۴/' +
                        temp2 +
                        '\n' +
                        ' ، هر خواهر ابی : ' +
                        ((2 / temp2) * 100).toFixed(2) +
                        ' ٪ معادل ۲/' +
                        temp2 +
                        '\n' +
                        ' ، هر برادر امی : ' +
                        ((1 / temp1) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp1 +
                        '\n' +
                        ' و هر خواهر امی : ' +
                        ((1 / temp1) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp1 +
                        '\n';
                }
            }
            if (
                state5 + state6 === 1 &&
                (state1 + state2 >= 1 || state3 + state4 >= 1) &&
                (checkbox3 || checkbox4) &&
                !checkbox5 &&
                !checkbox6
            ) {
                console.log('Entered');
                if (state5 + state6 === 1) {
                    if (state5 === 1 && state6 === 0) {
                        result += ' برادر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                    } else if (state5 === 0 && state6 === 1) {
                        result += ' خواهر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                    }
                }
                if (state1 + state2 >= 1) {
                    let temp = 2 * state1 + state2;
                    if (checkbox3) {
                        temp = temp + 2;
                    }
                    if (checkbox4) {
                        temp = temp + 1;
                    }
                    if (checkbox3) {
                        result +=
                            ' و جد ابی : ' + ((5 / (3 * temp)) * 100).toFixed(2) + ' ٪ معادل ۵/' + 3 * temp + '\n';
                    }
                    if (checkbox4) {
                        result += ' و جده ابی : ' + ((5 / (6 * temp)) * 100).toFixed(2) + ' ٪ معادل ۵/' + 6 * temp;
                    }
                    if (state1 !== 0) {
                        result += ' و برادر ابوینی : ' + ((5 / (3 * temp)) * 100).toFixed(2) + ' ٪ معادل ۵/' + 3 * temp;
                    }
                    if (state2 !== 0) {
                        result += ' و خواهر ابوینی : ' + ((5 / (6 * temp)) * 100).toFixed(2) + ' ٪ معادل ۵/' + 6 * temp;
                    }
                } else if (state3 + state4 >= 1) {
                    let temp = state4 + 2 * state3;
                    if (checkbox3) {
                        temp = temp + 2;
                    }
                    if (checkbox4) {
                        temp = temp + 1;
                    }
                    if (checkbox3) {
                        result +=
                            ' و جد ابی : ' + ((5 / (3 * temp)) * 100).toFixed(2) + ' ٪ معادل ۵/' + 3 * temp + '\n';
                    }
                    if (checkbox4) {
                        result += ' و جده ابی : ' + ((5 / (6 * temp)) * 100).toFixed(2) + ' ٪ معادل ۵/' + 6 * temp;
                    }
                    if (state3 !== 0) {
                        result += ' و برادر ابی : ' + ((5 / (3 * temp)) * 100).toFixed(2) + ' ٪ معادل ۵/' + 3 * temp;
                    }
                    if (state4 !== 0) {
                        result += ' و خواهر ابی : ' + ((5 / (6 * temp)) * 100).toFixed(2) + ' ٪ معادل ۵/' + 6 * temp;
                    }
                }
            }
            if (
                (checkbox3 || checkbox4 || checkbox5 || checkbox6) &&
                state5 + state6 >= 1 &&
                (state1 + state2 >= 1 || state3 + state4 >= 1)
            ) {
                if (state5 + state6 >= 1) {
                    let temp = state6 + state5;
                    if (checkbox5) {
                        temp = temp + 1;
                    }
                    if (checkbox6) {
                        temp = temp + 1;
                    }
                    if (checkbox5) {
                        result += 'جد امی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 3 * temp;
                    }
                    if (checkbox6) {
                        result += ' و جده امی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 3 * temp;
                    }
                    if (state5 !== 0) {
                        result += ' و برادر امی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 3 * temp;
                    }
                    if (state6 !== 0) {
                        result += ' و خواهر امی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 3 * temp;
                    }
                }

                if (state1 + state2 >= 1) {
                    let temp = state1 * 2 + state2;
                    if (checkbox3) {
                        temp = temp + 2;
                    }
                    if (checkbox4) {
                        temp = temp + 1;
                    }
                    if (checkbox3) {
                        result += ' و جد ابی : ' + (400 / (3 * temp)).toFixed(2) + ' ٪ معادل ۴/' + 3 * temp;
                    }
                    if (checkbox4) {
                        result += ' و جده ابی : ' + (200 / (3 * temp)).toFixed(2) + ' ٪ معادل ۲/' + 3 * temp;
                    }
                    if (state1 !== 0) {
                        result += ' و برادر ابوینی : ' + (400 / (3 * temp)).toFixed(2) + ' ٪ معادل ۴/' + 3 * temp;
                    }
                    if (state2 !== 0) {
                        result += ' و خواهر ابوینی : ' + (200 / (3 * temp)).toFixed(2) + ' ٪ معادل ۲/' + 3 * temp;
                    }
                } else if (state3 + state4 >= 1) {
                    let temp = state3 * 2 + state4;
                    if (checkbox3) {
                        temp = temp + 2;
                    }
                    if (checkbox4) {
                        temp = temp + 1;
                    }
                    if (checkbox3) {
                        result += ' و جد ابی : ' + (400 / (3 * temp)).toFixed(2) + ' ٪ معادل ۴/' + 3 * temp;
                    }
                    if (checkbox4) {
                        result += ' و جده ابی : ' + (200 / (3 * temp)).toFixed(2) + ' ٪ معادل ۲/' + 3 * temp;
                    }
                    if (state3 !== 0) {
                        result += ' و برادر ابی : ' + (400 / (3 * temp)).toFixed(2) + ' ٪ معادل ۴/' + 3 * temp;
                    }
                    if (state4 !== 0) {
                        result += ' و خواهر ابی : ' + (200 / (3 * temp)).toFixed(2) + ' ٪ معادل ۲/' + 3 * temp;
                    }
                }
            }
        } else if (
            !checkbox1 &&
            !checkbox2 &&
            boys === 0 &&
            girls === 0 &&
            !checkbox3 &&
            !checkbox4 &&
            !checkbox5 &&
            !checkbox6 &&
            state1 === 0 &&
            state2 === 0 &&
            state3 === 0 &&
            state4 === 0 &&
            state5 === 0 &&
            state6 === 0 &&
            (state7 > 0 ||
                state8 > 0 ||
                state9 > 0 ||
                state10 > 0 ||
                state11 > 0 ||
                state12 > 0 ||
                state13 > 0 ||
                state14 > 0 ||
                state15 > 0 ||
                state16 > 0 ||
                state17 > 0 ||
                state18 > 0)
        ) {
            //level 3
            const level3 = [
                {
                    id: 'عموی ابوینی',
                    st: state7,
                    ref: 'اعمام',
                    label: 'ابوینی',
                },
                {
                    id: 'عمه ابوینی',
                    st: state8,
                    ref: 'اعمام',
                    label: 'ابوینی',
                },
                {
                    id: 'دایی ابوینی',
                    st: state9,
                    ref: 'اخوال',
                    label: 'ابوینی',
                },
                {
                    id: 'خاله ابوینی',
                    st: state10,
                    ref: 'اخوال',
                    label: 'ابوینی',
                },
                {
                    id: 'عموی ابی',
                    st: state11,
                    ref: 'اعمام',
                    label: 'ابی',
                },
                {
                    id: 'عمه ابی',
                    st: state12,
                    ref: 'اعمام',
                    label: 'ابی',
                },
                {
                    id: 'دایی ابی',
                    st: state13,
                    ref: 'اخوال',
                    label: 'ابی',
                },
                {
                    id: 'خاله ابی',
                    st: state14,
                    ref: 'اخوال',
                    label: 'ابی',
                },
                {
                    id: 'عموی امی',
                    st: state15,
                    ref: 'اعمام',
                    label: 'امی',
                },
                {
                    id: 'عمه امی',
                    st: state16,
                    ref: 'اعمام',
                    label: 'امی',
                },
                {
                    id: 'دایی امی',
                    st: state17,
                    ref: 'اخوال',
                    label: 'امی',
                },
                {
                    id: 'خاله امی',
                    st: state18,
                    ref: 'اخوال',
                    label: 'امی',
                },
            ];
            if (level3.filter((item) => item.st >= 1).length === 1) {
                level3.map((item, index) => {
                    if (item.st === 1) {
                        console.log('avali');
                        result = item.id + ' : ' + ((1 / item.st) * 100).toFixed(2) + ' ٪ معادل ۱/' + item.st;
                    }
                    if (item.st > 1 && item.ref === 'اعمام') {
                        console.log('dovomi');
                        result = ' هر ' + item.id + ' : ' + ((1 / item.st) * 100).toFixed(2) + ' ٪ معادل ۱/' + item.st;
                    }
                });
            } else if (
                state16 === 0 &&
                state15 === 0 &&
                state9 === 0 &&
                state10 === 0 &&
                state13 === 0 &&
                state14 === 0 &&
                state17 === 0 &&
                state18 === 0 &&
                ((state7 >= 1 && state8 >= 1) || (state11 >= 1 && state12 >= 1))
            ) {
                console.log('sevomi');
                console.log(state15, state16);
                let total;
                if (state7 >= 1 && state8 >= 1) {
                    total = state7 * 2 + state8;
                    result =
                        'عموی ابوینی : ' +
                        ((2 / total) * 100).toFixed(2) +
                        ' ٪ معادل ۲/' +
                        total +
                        '\n' +
                        ' و عمه ابوینی : ' +
                        ((1 / total) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        total +
                        '';
                } else if (state11 >= 1 && state12 >= 1) {
                    total = state11 * 2 + state12;
                    result =
                        'عموی ابی : ' +
                        ((2 / total) * 100).toFixed(2) +
                        ' ٪ معادل ۲/' +
                        total +
                        '\n' +
                        ' و عمه ابی : ' +
                        ((1 / total) * 100).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        total +
                        '';
                }
            } else if (
                state9 === 0 &&
                state7 === 0 &&
                state8 === 0 &&
                state11 === 0 &&
                state12 === 0 &&
                state10 === 0 &&
                state13 === 0 &&
                state14 === 0 &&
                state17 === 0 &&
                state18 === 0 &&
                state15 + state16 >= 1
            ) {
                console.log('charomi');
                if (state15 + state16 >= 1) {
                    if (state15 !== 0) {
                        result +=
                            ' عموی امی : ' +
                            (100 / (state15 + state16)).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            (state15 + state16);
                    }
                    if (state16 !== 0) {
                        result +=
                            ' و عمه امی : ' +
                            (100 / (state15 + state16)).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            (state15 + state16);
                    }
                }
            } else if (
                state15 >= 1 &&
                state16 >= 1 &&
                state9 === 0 &&
                state10 === 0 &&
                state13 === 0 &&
                state14 === 0 &&
                state17 === 0 &&
                state18 === 0 &&
                ((state7 >= 1 && state8 >= 1) || (state11 >= 1 && state12 >= 1))
            ) {
                console.log('panjomi');
                if (state15 >= 1 || state16 >= 1) {
                    const temp = state16 + state15;
                    result =
                        'عموی امی : ' +
                        (100 / (temp * 3)).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        3 * temp +
                        ' و عمه امی : ' +
                        (100 / (temp * 3)).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        3 * temp;
                }
                if (state7 >= 1 && state8 >= 1) {
                    const temp = state7 * 2 + state8;
                    result +=
                        ' و عموی ابوینی : ' +
                        ((4 / (temp * 3)) * 100).toFixed(2) +
                        ' ٪ معادل ' +
                        4 +
                        '/' +
                        3 * temp +
                        '\n' +
                        ' و عمه ابوینی : ' +
                        ((2 / (temp * 3)) * 100).toFixed(2) +
                        ' ٪ معادل ' +
                        2 +
                        '/' +
                        3 * temp +
                        '';
                } else if (state11 >= 1 && state12 >= 1) {
                    const temp = state11 * 2 + state12;
                    result +=
                        ' و عموی ابی : ' +
                        ((4 / (temp * 3)) * 100).toFixed(2) +
                        ' ٪ معادل ' +
                        4 +
                        '/' +
                        3 * temp +
                        '\n' +
                        ' و عمه ابی : ' +
                        ((2 / (temp * 3)) * 100).toFixed(2) +
                        ' ٪ معادل ' +
                        2 +
                        '/' +
                        3 * temp +
                        '';
                }
            } else if (
                ((state15 === 1 && state16 === 0) || (state15 === 0 && state16 === 1)) &&
                ((state7 >= 1 && state8 >= 1) || (state11 >= 1 && state12 >= 1)) &&
                state9 === 0 &&
                state10 === 0 &&
                state13 === 0 &&
                state14 === 0 &&
                state17 === 0 &&
                state18 === 0
            ) {
                console.log('shishomi');
                if (state15 === 1) {
                    result = 'عموی امی : ۱۶.۶۷ ٪ معادل ۱/۶\n' + '\n';
                }
                if (state16 === 1) {
                    result = 'عمه امی : ۱۶.۶۷ ٪ معادل ۱/۶\n' + '\n';
                }
                if (state7 >= 1 && state8 >= 1) {
                    const temp = state7 * 2 + state8;
                    result +=
                        ' و عموی ابوینی : ' +
                        ((4 / (temp * 3)) * 100).toFixed(2) +
                        ' ٪ معادل ' +
                        10 +
                        '/' +
                        6 * temp +
                        '\n' +
                        ' و عمه ابوینی : ' +
                        ((2 / (temp * 3)) * 100).toFixed(2) +
                        ' ٪ معادل ' +
                        5 +
                        '/' +
                        6 * temp +
                        '';
                } else if (state11 >= 1 && state12 >= 1) {
                    const temp = state11 * 2 + state12;
                    result +=
                        ' و عموی ابی : ' +
                        ((4 / (temp * 3)) * 100).toFixed(2) +
                        ' ٪ معادل ' +
                        10 +
                        '/' +
                        6 * temp +
                        '\n' +
                        ' و عمه ابی : ' +
                        ((2 / (temp * 3)) * 100).toFixed(2) +
                        ' ٪ معادل ' +
                        5 +
                        '/' +
                        6 * temp +
                        '';
                }
            } else if (
                (state9 >= 1 &&
                    state10 >= 1 &&
                    state17 === 0 &&
                    state18 === 0 &&
                    state15 === 0 &&
                    state16 === 0 &&
                    state14 === 0 &&
                    state7 === 0 &&
                    state8 === 0 &&
                    state13 === 0 &&
                    state12 === 0 &&
                    state11 === 0) ||
                (state13 >= 1 &&
                    state14 >= 1 &&
                    state9 === 0 &&
                    state10 === 0 &&
                    state17 === 0 &&
                    state18 === 0 &&
                    state15 === 0 &&
                    state16 === 0 &&
                    state7 === 0 &&
                    state8 === 0 &&
                    state12 === 0 &&
                    state11 === 0) ||
                (state17 >= 1 &&
                    state18 >= 1 &&
                    state13 === 0 &&
                    state14 === 0 &&
                    state9 === 0 &&
                    state10 === 0 &&
                    state15 === 0 &&
                    state16 === 0 &&
                    state7 === 0 &&
                    state8 === 0 &&
                    state12 === 0 &&
                    state11 === 0)
            ) {
                console.log('haftomi');
                if (state9 >= 1 && state10 >= 1) {
                    const temp = state9 + state10;
                    result =
                        'دایی ابوینی : ' +
                        (100 / temp).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp +
                        '\n' +
                        ' و خاله ابوینی : ' +
                        (100 / temp).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp +
                        '';
                }
                if (state13 >= 1 && state14 >= 1) {
                    const temp = state13 + state14;
                    result =
                        ' دایی ابی : ' +
                        (100 / temp).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp +
                        '\n' +
                        ' و خاله ابی : ' +
                        (100 / temp).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp +
                        '';
                }
                if (state17 >= 1 && state18 >= 1) {
                    const temp = state17 + state18;
                    result =
                        ' دایی امی : ' +
                        (100 / temp).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp +
                        '\n' +
                        ' و خاله امی : ' +
                        (100 / temp).toFixed(2) +
                        ' ٪ معادل ۱/' +
                        temp;
                }
            } else if (
                (state9 + state10 > 1 &&
                    state13 + state14 + state17 + state18 + state8 + state7 + state11 + state12 + state15 + state16 ===
                        0) ||
                (state13 + state14 > 1 &&
                    state10 + state9 + state17 + state18 + state8 + state7 + state11 + state12 + state15 + state16 ===
                        0) ||
                (state17 + state18 > 1 &&
                    state13 + state14 + state9 + state10 + state8 + state7 + state11 + state12 + state15 + state16 ===
                        0)
            ) {
                console.log('hashtomi');
                if (state9 + state10 > 1) {
                    const temp = state10 + state9;
                    if (state9 !== 0) {
                        result = ' هر دایی ابوینی : ' + (100 / temp).toFixed(2) + ' ٪ معادل ۱/' + temp;
                    }
                    if (state10 !== 0) {
                        result += ' و هر خاله ابوینی : ' + (100 / temp).toFixed(2) + ' ٪ معادل ۱/' + temp;
                    }
                } else if (state13 + state14 > 1) {
                    const temp = state13 + state14;
                    if (state13 !== 0) {
                        result += ' هر دایی ابی : ' + (100 / temp).toFixed(2) + ' ٪ معادل ۱/' + temp;
                    }
                    if (state14 !== 0) {
                        result += ' و هر خاله ابی : ' + (100 / temp).toFixed(2) + ' ٪ معادل ۱/' + temp;
                    }
                } else if (state17 + state18 > 1) {
                    const temp = state17 + state18;
                    if (state17 !== 0) {
                        result += ' هر دایی امی : ' + (100 / temp).toFixed(2) + ' ٪ معادل ۱/' + temp;
                    }
                    if (state18 !== 0) {
                        result += ' و هر خاله امی : ' + (100 / temp).toFixed(2) + ' ٪ معادل ۱/' + temp;
                    }
                }
            } else if (
                state17 + state18 > 1 &&
                (state9 + state10 > 1 || state14 + state13 > 1) &&
                state7 === 0 &&
                state8 === 0 &&
                state11 === 0 &&
                state12 === 0 &&
                state15 === 0 &&
                state16 === 0
            ) {
                console.log('nohomi');
                if (state17 + state18 > 1) {
                    const temp = state17 + state18;
                    if (state17 !== 0) {
                        result += ' هر دایی امی : ' + (100 / (temp * 3)).toFixed(2) + ' ٪ معادل ۱/' + temp * 3;
                    }
                    if (state18 !== 0) {
                        result += ' و هر خاله امی : ' + (100 / (temp * 3)).toFixed(2) + ' ٪ معادل ۱/' + temp * 3;
                    }
                }
                if (state9 + state10 > 1) {
                    const temp = state10 + state9;
                    if (state9 !== 0) {
                        result += ' و هر دایی ابوینی : ' + (200 / (temp * 3)).toFixed(2) + ' ٪ معادل ۲/' + temp * 3;
                    }
                    if (state10 !== 0) {
                        result += ' و هر خاله ابوینی : ' + (200 / (temp * 3)).toFixed(2) + ' ٪ معادل ۲/' + temp * 3;
                    }
                } else if (state13 + state14 > 1) {
                    const temp = state13 + state14;
                    if (state13 !== 0) {
                        result += ' و هر دایی ابی : ' + (200 / (temp * 3)).toFixed(2) + ' ٪ معادل ۲/' + temp * 3;
                    }
                    if (state14 !== 0) {
                        result += ' و هر خاله ابی : ' + (200 / (temp * 3)).toFixed(2) + ' ٪ معادل ۲/' + temp * 3;
                    }
                }
            } else if (
                ((state17 === 1 && state18 === 0) || (state17 === 0 && state18 === 1)) &&
                (state9 + state10 > 1 || state13 + state14 > 1) &&
                state7 === 0 &&
                state8 === 0 &&
                state11 === 0 &&
                state12 === 0 &&
                state15 === 0 &&
                state16 === 0
            ) {
                console.log('dahomi');
                if (state17 !== 0) {
                    result += ' دایی امی : ' + (100 / 6).toFixed(2) + ' ٪ معادل ۱/' + 6;
                }
                if (state18 !== 0) {
                    result += ' خاله امی : ' + (100 / 6).toFixed(2) + ' ٪ معادل ۱/' + 6;
                }
                if (state9 + state10 > 1) {
                    const temp = state10 + state9;
                    if (state9 !== 0) {
                        result += ' و هر دایی ابوینی : ' + (500 / (temp * 6)).toFixed(2) + ' ٪ معادل ۵/' + temp * 6;
                    }
                    if (state10 !== 0) {
                        result += ' و هر خاله ابوینی : ' + (500 / (temp * 6)).toFixed(2) + ' ٪ معادل ۵/' + temp * 6;
                    }
                } else if (state13 + state14 > 1) {
                    const temp = state13 + state14;
                    if (state13 !== 0) {
                        result += ' و هر دایی ابی : ' + (500 / (temp * 6)).toFixed(2) + ' ٪ معادل ۵/' + temp * 6;
                    }
                    if (state14 !== 0) {
                        result += ' و هر خاله ابی : ' + (500 / (temp * 6)).toFixed(2) + ' ٪ معادل ۵/' + temp * 6;
                    }
                }
            } else {
                console.log('yazdahomi');
                if (level3.filter((item, index) => item.ref === 'اخوال' && item.st > 0)) {
                    //اخوال
                    if (
                        level3
                            .filter((item, index) => item.ref === 'اخوال' && item.st > 0)
                            .filter((res) => res.label === 'امی').length === 1
                    ) {
                        if (state17 === 1 && state18 === 0) {
                            result = 'دایی امی : ۵.۵۶ ٪ معادل ۱/۱۸';
                        } else if (state17 === 0 && state18 === 1) {
                            result = 'خاله امی : ۵.۵۶ ٪ معادل ۱/۱۸';
                        }
                        if (state9 + state10 > 0) {
                            const temp = state9 + state10;
                            if (state9 !== 0) {
                                result +=
                                    ' و دایی ابوینی : ' +
                                    (500 / (18 * temp)).toFixed(2) +
                                    ' ٪ معادل ' +
                                    5 +
                                    '/' +
                                    18 * temp;
                            }
                            if (state10 !== 0) {
                                result +=
                                    ' و خاله ابوینی : ' +
                                    (500 / (18 * temp)).toFixed(2) +
                                    ' ٪ معادل ' +
                                    5 +
                                    '/' +
                                    18 * temp;
                            }
                        } else if (state13 + state14 > 0 && state9 + state10 === 0) {
                            const temp = state13 + state14;
                            if (state13 !== 0) {
                                result +=
                                    ' و دایی ابی : ' +
                                    (500 / (18 * temp)).toFixed(2) +
                                    ' ٪ معادل ' +
                                    5 +
                                    '/' +
                                    18 * temp;
                            }
                            if (state14 !== 0) {
                                result +=
                                    ' و خاله ابی : ' +
                                    (500 / (18 * temp)).toFixed(2) +
                                    ' ٪ معادل ' +
                                    5 +
                                    '/' +
                                    18 * temp;
                            }
                        }
                    } else if (
                        level3
                            .filter((item, index) => item.ref === 'اخوال' && item.st > 0)
                            .filter((res) => res.label === 'امی').length > 1
                    ) {
                        if (state17 + state18 > 0) {
                            const temp = state17 + state18;
                            if (state17 !== 0) {
                                result += ' و دایی امی : ' + (100 / (9 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 9 * temp;
                            }
                            if (state18 !== 0) {
                                result += ' و خاله امی : ' + (100 / (9 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 9 * temp;
                            }
                        }
                        if (state9 + state10 > 0 && state13 + state14 > 0) {
                            const temp = state9 + state10;
                            if (state9 !== 0) {
                                result +=
                                    ' و دایی ابوینی : ' +
                                    (200 / (9 * temp)).toFixed(2) +
                                    ' ٪ معادل ' +
                                    2 +
                                    '/' +
                                    9 * temp;
                            }
                            if (state10 !== 0) {
                                result +=
                                    ' و خاله ابوینی : ' +
                                    (200 / (9 * temp)).toFixed(2) +
                                    ' ٪ معادل ' +
                                    2 +
                                    '/' +
                                    9 * temp;
                            }
                        } else if (state13 + state14 > 0 && state9 + state10 === 0) {
                            const temp = state13 + state14;
                            if (state13 !== 0) {
                                result +=
                                    ' و دایی ابی : ' + (200 / (9 * temp)).toFixed(2) + ' ٪ معادل ' + 2 + '/' + 9 * temp;
                            }
                            if (state14 !== 0) {
                                result +=
                                    ' و خاله ابی : ' + (200 / (9 * temp)).toFixed(2) + ' ٪ معادل ' + 2 + '/' + 9 * temp;
                            }
                        }
                    }
                }
                if (level3.filter((item, index) => item.ref === 'اعمام' && item.st > 0)) {
                    if (
                        level3
                            .filter((item, index) => item.ref === 'اعمام' && item.st > 0)
                            .filter((res) => res.label === 'امی').length === 1
                    ) {
                        if (state15 === 1 && state16 === 0) {
                            result += ' و عمو امی : ۱۱.۱۱ ٪ معادل ۱/۹\n';
                        } else if (state15 === 0 && state16 === 1) {
                            result += ' و عمه امی : ۱۱.۱۱ ٪ معادل ۱/۹\n';
                        }
                        if (state7 + state8 >= 1) {
                            const temp = state7 * 2 + state8;
                            if (state7 !== 0) {
                                result +=
                                    ' و عموی ابوینی : ' +
                                    ((2 * 500) / (9 * temp)).toFixed(2) +
                                    ' ٪ معادل ۱۰/' +
                                    9 * temp;
                            }
                            if (state8 !== 0) {
                                result += ' و عمه ابوینی : ' + (500 / (9 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 9 * temp;
                            }
                        } else if (state11 + state12 >= 1 && state7 + state8 === 0) {
                            const temp = state11 * 2 + state12;
                            if (state11 !== 0) {
                                result +=
                                    ' و عموی ابی : ' + ((2 * 500) / (9 * temp)).toFixed(2) + ' ٪ معادل ۱۰/' + 9 * temp;
                            }
                            if (state12 !== 0) {
                                result += ' و عمه ابی : ' + (500 / (9 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 9 * temp;
                            }
                        }
                    } else if (
                        level3
                            .filter((item, index) => item.ref === 'اعمام' && item.st > 0)
                            .filter((res) => res.label === 'امی').length > 1
                    ) {
                        if (state15 + state16 > 0) {
                            const temp = state15 + state16;
                            if (state15 !== 0) {
                                result += ' و عمو امی : ' + (200 / (9 * temp)).toFixed(2) + ' ٪ معادل ۲/' + 9 * temp;
                            }
                            if (state16 !== 0) {
                                result += ' و عمه امی : ' + (200 / (9 * temp)).toFixed(2) + ' ٪ معادل ۲/' + 9 * temp;
                            }
                        }
                        if (state7 + state8 >= 1) {
                            const temp = state7 * 2 + state8;
                            if (state7 !== 0) {
                                result +=
                                    ' و عموی ابوینی : ' +
                                    ((2 * 400) / (9 * temp)).toFixed(2) +
                                    ' ٪ معادل ۸/' +
                                    9 * temp;
                            }
                            if (state8 !== 0) {
                                result += ' و عمه ابوینی : ' + (400 / (9 * temp)).toFixed(2) + ' ٪ معادل ۴/' + 9 * temp;
                            }
                        } else if (state11 + state12 >= 1 && state7 + state8 === 0) {
                            const temp = state11 * 2 + state12;
                            if (state11 !== 0) {
                                result +=
                                    ' و عموی ابی : ' + ((2 * 400) / (9 * temp)).toFixed(2) + ' ٪ معادل ۸/' + 9 * temp;
                            }
                            if (state12 !== 0) {
                                result += ' و عمه ابی : ' + (400 / (9 * temp)).toFixed(2) + ' ٪ معادل ۴/' + 9 * temp;
                            }
                        }
                    }
                }
            }
        }
    } else if (maritalStatus === 2 || maritalStatus === 3) {
        console.log('Entered marital status 2 || 3');
        if (
            !checkbox1 &&
            !checkbox2 &&
            !checkbox3 &&
            !checkbox4 &&
            !checkbox5 &&
            !checkbox6 &&
            girls === 0 &&
            boys === 0 &&
            state1 === 0 &&
            state2 === 0 &&
            state3 === 0 &&
            state4 === 0 &&
            state5 === 0 &&
            state6 === 0 &&
            state7 === 0 &&
            state8 === 0 &&
            state9 === 0 &&
            state10 === 0 &&
            state11 === 0 &&
            state12 === 0 &&
            state13 === 0 &&
            state14 === 0 &&
            state15 === 0 &&
            state16 === 0 &&
            state17 === 0 &&
            state18 === 0
        ) {
            // none of them
            if (maritalStatus === 2) {
                result = 'زوج (شوهر) : ۱۰۰ ٪ معادل ۱/۱';
            }
            if (maritalStatus === 3) {
                const temp = wifeNumber <= 4 ? wifeNumber : 4;
                result =
                    wifeNumber === 1
                        ? ' زوجه (زن) : ۱۰۰ ٪ معادل ۱/۱'
                        : ' هر زوجه (زن) : ' + (100 / temp).toFixed(2) + ' ٪ معادل ۱/' + temp;
            }
        } else if (
            (checkbox1 || checkbox2 || girls >= 0 || boys >= 0) &&
            !checkbox3 &&
            !checkbox4 &&
            !checkbox5 &&
            !checkbox6 &&
            state1 === 0 &&
            state2 === 0 &&
            state3 === 0 &&
            state4 === 0 &&
            state5 === 0 &&
            state6 === 0 &&
            state7 === 0 &&
            state8 === 0 &&
            state9 === 0 &&
            state10 === 0 &&
            state11 === 0 &&
            state12 === 0 &&
            state13 === 0 &&
            state14 === 0 &&
            state15 === 0 &&
            state16 === 0 &&
            state17 === 0 &&
            state18 === 0
        ) {
            console.log('Entered level 1');
            // level 1
            if (boys + girls > 0 && !checkbox1 && !checkbox2) {
                if (maritalStatus === 2) {
                    result += ' زوج (شوهر) : ۲۵ ٪ معادل ۱/۴';
                    const temp = boys * 2 + girls;
                    if (boys !== 0) {
                        result += ' و هر فرزند پسر : ' + (300 / (2 * temp)).toFixed(2) + ' ٪ معادل ۳/' + temp * 2;
                    }
                    if (girls !== 0) {
                        result += ' و هر فرزند دختر : ' + (300 / (4 * temp)).toFixed(2) + ' ٪ معادل ۳/' + temp * 4;
                    }
                } else if (maritalStatus === 3) {
                    const wifeNum = wifeNumber <= 4 ? wifeNumber * 8 : 32;
                    result = ' هر زوجه (زن) : ' + (100 / wifeNum).toFixed(2) + ' ٪ معادل ۱/' + wifeNum;
                    const temp = boys * 2 + girls;
                    if (boys !== 0) {
                        result += ' و هر فرزند پسر : ' + (700 / (4 * temp)).toFixed(2) + ' ٪ معادل ۷/' + temp * 4;
                    }
                    if (girls !== 0) {
                        result += ' و هر فرزند دختر : ' + (700 / (8 * temp)).toFixed(2) + ' ٪ معادل ۷/' + temp * 8;
                    }
                }
            } else if (boys + girls === 0 && (checkbox1 || checkbox2)) {
                if (maritalStatus === 2) {
                    result += ' زوج (شوهر) : ۵۰ ٪ معادل ۱/۲';
                    let temp = 0;
                    if (checkbox1) {
                        temp = temp + 2;
                    }
                    if (checkbox2) {
                        temp = temp + 1;
                    }
                    if (checkbox1) {
                        result += ' و پدر : ' + (100 / temp).toFixed(2) + ' ٪ معادل ۱/' + temp;
                    }
                    if (checkbox2) {
                        result += ' و مادر : ' + (100 / (temp * 2)).toFixed(2) + ' ٪ معادل ۱/' + 2 * temp;
                    }
                } else if (maritalStatus === 3) {
                    const wifeNum = wifeNumber <= 4 ? wifeNumber * 4 : 16;
                    result += ' هر زوجه (زن) : ' + (100 / wifeNum).toFixed(2) + ' ٪ معادل ۱/' + wifeNum;
                    let temp = 0;
                    if (checkbox1) {
                        temp = temp + 2;
                    }
                    if (checkbox2) {
                        temp = temp + 1;
                    }
                    if (checkbox1) {
                        result += ' و پدر : ' + (300 / (temp * 2)).toFixed(2) + ' ٪ معادل ۳/' + 2 * temp;
                    }
                    if (checkbox2) {
                        result += ' و مادر : ' + (300 / (temp * 4)).toFixed(2) + ' ٪ معادل ۳/' + 4 * temp;
                    }
                }
            } else if (girls + boys > 0 && (checkbox1 || checkbox2)) {
                if (maritalStatus === 2) {
                    result += ' زوج (شوهر) : ۲۵ ٪ معادل ۱/۴';
                    if (boys === 0 && girls === 1 && checkbox1 && checkbox2) {
                        result +=
                            ' و پدر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                            ' و مادر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                            ' و فرزند دختر : ۴۱.۶۷ ٪ معادل ۵/۱۲';
                    } else if (boys === 0 && girls === 1 && ((checkbox1 && !checkbox2) || (!checkbox1 && checkbox2))) {
                        if (checkbox1 && !checkbox2) {
                            result += ' و پدر : ۱۸.۷۵ ٪ معادل ۳/۱۶\n' + ' و فرزند دختر : ۵۶.۲۵ ٪ معادل ۹/۱۶';
                        } else if (!checkbox1 && checkbox2) {
                            result += ' و مادر : ۱۸.۷۵ ٪ معادل ۳/۱۶\n' + ' و فرزند دختر : ۵۶.۲۵ ٪ معادل ۹/۱۶';
                        }
                    } else if (boys === 0 && checkbox1 && checkbox2 && girls > 1) {
                        result +=
                            ' و پدر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                            ' و مادر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                            ' و هر فرزند دختر : ' +
                            (500 / (12 * girls)).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            12 * girls;
                    } else if (boys === 0 && girls > 1 && ((checkbox1 && !checkbox2) || (!checkbox1 && checkbox2))) {
                        if (checkbox1 && !checkbox2) {
                            result +=
                                ' و پدر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                                ' و هر فرزند دختر : ' +
                                (700 / (12 * girls)).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                12 * girls;
                        } else if (!checkbox1 && checkbox2) {
                            result +=
                                ' و مادر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                                ' و هر فرزند دختر : ' +
                                (700 / (12 * girls)).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                12 * girls;
                        }
                    } else if (girls > 0 && boys > 0 && (checkbox1 || checkbox2)) {
                        const temp = girls + boys * 2;
                        if (checkbox1 && checkbox2) {
                            result += ' و پدر : ۱۶.۶۷ ٪ معادل ۱/۶\n' + ' و مادر : ۱۶.۶۷ ٪ معادل ۱/۶\n';
                            if (boys !== 0) {
                                result += ' و فرزند پسر : ' + (500 / (6 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 6 * temp;
                            }
                            if (girls !== 0) {
                                result +=
                                    ' و فرزند دختر : ' + (500 / (12 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 12 * temp;
                            }
                        } else if (checkbox1 && !checkbox2) {
                            result += ' و پدر : ۱۶.۶۷ ٪ معادل ۱/۶';
                            if (boys !== 0) {
                                result += ' و فرزند پسر : ' + (700 / (6 * temp)).toFixed(2) + ' ٪ معادل ۷/' + 6 * temp;
                            }
                            if (girls !== 0) {
                                result +=
                                    ' و فرزند دختر : ' + (700 / (12 * temp)).toFixed(2) + ' ٪ معادل ۷/' + 12 * temp;
                            }
                        } else if (!checkbox1 && checkbox2) {
                            result += ' و مادر : ۱۶.۶۷ ٪ معادل ۱/۶';
                            if (boys !== 0) {
                                result += ' و فرزند پسر : ' + (700 / (6 * temp)).toFixed(2) + ' ٪ معادل ۷/' + 6 * temp;
                            }
                            if (girls !== 0) {
                                result +=
                                    ' و فرزند دختر : ' + (700 / (12 * temp)).toFixed(2) + ' ٪ معادل ۷/' + 12 * temp;
                            }
                        }
                    } else if (girls === 0 && boys >= 1 && (checkbox1 || checkbox2)) {
                        if (checkbox1 && checkbox2) {
                            result +=
                                ' و پدر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                                ' و مادر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                                ' و فرزند پسر : ' +
                                (500 / (12 * boys)).toFixed(2) +
                                ' ٪ معادل ۵/' +
                                boys * 12;
                        } else if (checkbox1 && !checkbox2) {
                            result +=
                                ' و پدر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                                ' و فرزند پسر : ' +
                                (700 / (boys * 12)).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                boys * 12;
                        } else if (!checkbox1 && checkbox2) {
                            result +=
                                ' و مادر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                                ' و فرزند پسر : ' +
                                (700 / (boys * 12)).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                boys * 12;
                        }
                    }
                } else if (maritalStatus === 3) {
                    const wifeNum = wifeNumber <= 4 ? wifeNumber * 8 : 32;
                    result += ' هر زوجه (زن) : ' + (100 / wifeNum).toFixed(2) + ' ٪ معادل ۱/' + wifeNum;
                    if (boys === 0 && girls === 1 && checkbox1 && checkbox2) {
                        result +=
                            ' و پدر : ۱۷.۵ ٪ معادل ۷/۴۰\n' +
                            ' و مادر : ۱۷.۵ ٪ معادل ۷/۴۰\n' +
                            ' و فرزند دختر : ۵۲.۵ ٪ معادل ۲۱/۴۰';
                    } else if (boys === 0 && girls === 1 && ((checkbox1 && !checkbox2) || (!checkbox1 && checkbox2))) {
                        if (checkbox1 && !checkbox2) {
                            result += ' و پدر : ۲۱.۸۸ ٪ معادل ۷/۳۲\n' + ' و فرزند دختر : ۶۵.۶۳ ٪ معادل ۲۱/۳۲';
                        } else if (!checkbox1 && checkbox2) {
                            result += ' و مادر : ۲۱.۸۸ ٪ معادل ۷/۳۲\n' + ' و فرزند دختر : ۶۵.۶۳ ٪ معادل ۲۱/۳۲';
                        }
                    } else if (boys === 0 && checkbox1 && checkbox2 && girls > 1) {
                        result +=
                            ' و پدر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                            ' و مادر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                            ' و هر فرزند دختر : ' +
                            (1300 / (24 * girls)).toFixed(2) +
                            ' ٪ معادل ۱۳/' +
                            24 * girls;
                    } else if (boys === 0 && girls > 1 && ((checkbox1 && !checkbox2) || (!checkbox1 && checkbox2))) {
                        if (checkbox1 && !checkbox2) {
                            result +=
                                ' و پدر : ۱۷.۵ ٪ معادل ۷/۴۰' +
                                ' و هر فرزند دختر : ' +
                                (700 / (10 * girls)).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                10 * girls;
                        } else if (!checkbox1 && checkbox2) {
                            result +=
                                ' و مادر : ۱۷.۵ ٪ معادل ۷/۴۰' +
                                ' و هر فرزند دختر : ' +
                                (700 / (10 * girls)).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                10 * girls;
                        }
                    } else if (girls > 0 && boys > 0 && (checkbox1 || checkbox2)) {
                        const temp = girls + boys * 2;
                        if (checkbox1 && checkbox2) {
                            result += ' و پدر : ۱۶.۶۷ ٪ معادل ۱/۶\n' + ' و مادر : ۱۶.۶۷ ٪ معادل ۱/۶\n';
                            if (boys !== 0) {
                                result +=
                                    ' و فرزند پسر : ' + (1300 / (12 * temp)).toFixed(2) + ' ٪ معادل ۱۳/' + 12 * temp;
                            }
                            if (girls !== 0) {
                                result +=
                                    ' و فرزند دختر : ' + (1300 / (24 * temp)).toFixed(2) + ' ٪ معادل ۱۳/' + 24 * temp;
                            }
                        } else if (checkbox1 && !checkbox2) {
                            result += ' و پدر : ۱۶.۶۷ ٪ معادل ۱/۶';
                            if (boys !== 0) {
                                result +=
                                    ' و فرزند پسر : ' + (1700 / (12 * temp)).toFixed(2) + ' ٪ معادل ۱۷/' + 12 * temp;
                            }
                            if (girls !== 0) {
                                result +=
                                    ' و فرزند دختر : ' + (1700 / (24 * temp)).toFixed(2) + ' ٪ معادل ۱۷/' + 24 * temp;
                            }
                        } else if (!checkbox1 && checkbox2) {
                            result += ' و مادر : ۱۶.۶۷ ٪ معادل ۱/۶';
                            if (boys !== 0) {
                                result +=
                                    ' و فرزند پسر : ' + (1700 / (12 * temp)).toFixed(2) + ' ٪ معادل ۱۷/' + 12 * temp;
                            }
                            if (girls !== 0) {
                                result +=
                                    ' و فرزند دختر : ' + (1700 / (24 * temp)).toFixed(2) + ' ٪ معادل ۱۷/' + 24 * temp;
                            }
                        }
                    } else if (girls === 0 && boys >= 1 && (checkbox1 || checkbox2)) {
                        if (checkbox1 && checkbox2) {
                            result +=
                                ' و پدر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                                ' و مادر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                                ' و فرزند پسر : ' +
                                (1300 / (24 * boys)).toFixed(2) +
                                ' ٪ معادل ۱۳/' +
                                boys * 24;
                        } else if (checkbox1 && !checkbox2) {
                            result +=
                                ' و پدر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                                ' و فرزند پسر : ' +
                                (1700 / (boys * 24)).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                boys * 24;
                        } else if (!checkbox1 && checkbox2) {
                            result +=
                                ' و مادر : ۱۶.۶۷ ٪ معادل ۱/۶\n' +
                                ' و فرزند پسر : ' +
                                (1700 / (boys * 24)).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                boys * 24;
                        }
                    }
                }
            }
        } else if (
            !checkbox1 &&
            !checkbox2 &&
            boys === 0 &&
            girls === 0 &&
            (checkbox3 ||
                checkbox4 ||
                checkbox5 ||
                checkbox6 ||
                state1 > 0 ||
                state2 > 0 ||
                state3 > 0 ||
                state4 > 0 ||
                state5 > 0 ||
                state6 > 0)
        ) {
            console.log('Entered level 2');
            // level 2
            if (maritalStatus === 2) {
                result += 'زوج (شوهر) : ۵۰ ٪ معادل ۱/۲';
                if (
                    state1 === 0 &&
                    state2 === 0 &&
                    state3 === 0 &&
                    state4 === 0 &&
                    state5 === 0 &&
                    state6 === 0 &&
                    (checkbox3 || checkbox4 || checkbox5 || checkbox6)
                ) {
                    // just grandparents
                    const checkboxes = [checkbox3, checkbox4, checkbox5, checkbox6];
                    const name = checkbox3 ? 'جد ابی' : checkbox4 ? 'جده ابی' : checkbox5 ? 'جد امی' : 'جده امی';
                    if (checkboxes.filter((item) => item === true).length === 1) {
                        result += ' و ' + name + ' : ۵۰ ٪ معادل ۱/۲';
                    }
                    if (checkboxes.filter((item) => item === true).length > 1) {
                        if (checkbox3 && checkbox4 && !checkbox5 && !checkbox6) {
                            result += ' و جد ابی : ۳۳.۳۳ ٪ معادل ۱/۳\n' + ' و جده ابی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else if (!checkbox3 && !checkbox4 && checkbox5 && checkbox6) {
                            result += ' و جد امی : ۲۵ ٪ معادل ۱/۴\n' + ' و جده امی : ۲۵ ٪ معادل ۱/۴';
                        } else {
                            if (checkbox5 || checkbox6) {
                                if (checkbox5 && !checkbox6) {
                                    result += ' و جد امی : ۳۳.۳۳ ٪ معادل ۱/۳\n';
                                } else if (!checkbox5 && checkbox6) {
                                    result += ' و جده امی : ۳۳.۳۳ ٪ معادل ۱/۳\n';
                                } else if (checkbox5 && checkbox6) {
                                    result += ' و جد امی : ۱۶.۶۷ ٪ معادل ۱/۶\n' + ' و جده امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                                }
                            }
                            if (checkbox3 || checkbox4) {
                                if (checkbox3 && !checkbox4) {
                                    result += ' و جد ابی : ۱۶.۶۷ ٪ معادل ۱/۶';
                                } else if (!checkbox3 && checkbox4) {
                                    result += ' و جد ابی : ۱۶.۶۷ ٪ معادل ۱/۶';
                                } else if (checkbox3 && checkbox4) {
                                    result += ' و جد ابی : ۱۱.۱۱ ٪ معادل ۱/۹\n' + ' و جده ابی : ۵.۵۶ ٪ معادل ۱/۱۸';
                                }
                            }
                        }
                    }
                }
                if (!checkbox3 && !checkbox4 && !checkbox5 && !checkbox6) {
                    //none of the checkboxes
                    if (state1 >= 1 && state2 === 0 && state3 === 0 && state4 === 0 && state5 === 0 && state6 === 0) {
                        result += ' و برادر ابوینی : ' + (50 / state1).toFixed(2) + ' ٪ معادل ۱/' + state1 * 2 + '';
                    } else if (
                        state1 === 0 &&
                        state2 >= 1 &&
                        state3 === 0 &&
                        state4 === 0 &&
                        state5 === 0 &&
                        state6 === 0
                    ) {
                        result += ' و خواهر ابوینی : ' + (50 / state2).toFixed(2) + ' ٪ معادل ۱/' + state2 * 2 + '';
                    } else if (
                        state1 >= 1 &&
                        state2 >= 1 &&
                        state3 === 0 &&
                        state4 === 0 &&
                        state5 === 0 &&
                        state6 === 0
                    ) {
                        const temp = state1 * 2 + state2;
                        result +=
                            ' و برادر ابوینی : ' +
                            (100 / temp).toFixed(2) +
                            ' ٪ معادل ۲/' +
                            temp +
                            '\n' +
                            ' و خواهر ابوینی : ' +
                            (50 / temp).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp * 2 +
                            '';
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 >= 1 &&
                        state4 === 0 &&
                        state5 === 0 &&
                        state6 === 0
                    ) {
                        result += ' و برادر ابی  : ' + (50 / state3).toFixed(2) + ' ٪ معادل ۱/' + state3 * 2 + '';
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 === 0 &&
                        state4 >= 1 &&
                        state5 === 0 &&
                        state6 === 0
                    ) {
                        result += ' و خواهر ابی  : ' + (50 / state4).toFixed(2) + ' ٪ معادل ۱/' + 2 * state4 + '';
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 >= 1 &&
                        state4 >= 1 &&
                        state5 === 0 &&
                        state6 === 0
                    ) {
                        const temp = state3 * 2 + state4;
                        result +=
                            ' و برادر ابی : ' +
                            (100 / temp).toFixed(2) +
                            ' ٪ معادل ۲/' +
                            temp +
                            '\n' +
                            ' و خواهر ابی : ' +
                            (50 / temp).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp * 2;
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 === 0 &&
                        state4 === 0 &&
                        state5 >= 1 &&
                        state6 === 0
                    ) {
                        result += ' و برادر امی  : ' + (50 / state5).toFixed(2) + ' ٪ معادل ۱/' + state5 * 2 + '';
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 === 0 &&
                        state4 === 0 &&
                        state5 === 0 &&
                        state6 >= 1
                    ) {
                        result += ' و خواهر امی  : ' + (50 / state6).toFixed(2) + ' ٪ معادل ۱/' + state6 * 2 + '';
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 === 0 &&
                        state4 === 0 &&
                        state5 >= 1 &&
                        state6 >= 1
                    ) {
                        const temp = state5 + state6;
                        result +=
                            ' و برادر امی : ' +
                            (50 / temp).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp * 2 +
                            '\n' +
                            ' و خواهر امی : ' +
                            (50 / temp).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp * 2;
                    } else if (
                        (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 === 0) ||
                        (state1 >= 1 && state2 === 0 && state3 === 0 && state4 >= 1 && state5 === 0 && state6 === 0) ||
                        (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 >= 1 && state5 === 0 && state6 === 0)
                    ) {
                        result += ' و برادر ابوینی  : ' + (50 / state1).toFixed(2) + ' ٪ معادل ۱/' + state1 * 2;
                    } else if (
                        (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 === 0) ||
                        (state1 === 0 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 === 0 && state6 === 0) ||
                        (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 === 0 && state6 === 0)
                    ) {
                        result += ' و خواهر ابوینی  : ' + (50 / state2).toFixed(2) + ' ٪ معادل ۱/' + state2 * 2;
                    } else if (
                        (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 === 0) ||
                        (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 === 0 && state6 === 0) ||
                        (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 === 0 && state6 === 0)
                    ) {
                        const temp = 2 * state1 + state2;
                        result +=
                            ' و برادر ابوینی : ' +
                            (100 / temp).toFixed(2) +
                            ' ٪ معادل ۲/' +
                            temp +
                            ' و خواهر ابوینی : ' +
                            (50 / temp).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp * 2;
                    } else if (
                        (state1 >= 1 && state2 === 0 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 === 0) ||
                        (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 === 0) ||
                        (state1 >= 1 && state2 === 0 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 === 0) ||
                        (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 === 0)
                    ) {
                        if (state5 === 1) {
                            result +=
                                ' و هر برادر ابوینی : ' +
                                (100 / (3 * state1)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state1 +
                                ' و برادر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر برادر ابوینی : ' +
                                (100 / (state1 * 6)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                state1 * 6 +
                                ' و هر برادر امی : ' +
                                (100 / (3 * state5)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state5;
                        }
                    } else if (
                        (state1 >= 1 && state2 === 0 && state3 === 0 && state4 === 0 && state5 === 0 && state6 >= 1) ||
                        (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 >= 1) ||
                        (state1 >= 1 && state2 === 0 && state3 === 0 && state4 >= 1 && state5 === 0 && state6 >= 1) ||
                        (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 >= 1 && state5 === 0 && state6 >= 1)
                    ) {
                        if (state6 === 1) {
                            result +=
                                ' و هر برادر ابوینی : ' +
                                (100 / (3 * state1)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state1 +
                                ' و خواهر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر برادر ابوینی : ' +
                                (100 / (state1 * 6)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                state1 * 6 +
                                ' و هر خواهر امی : ' +
                                (100 / (3 * state6)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state6;
                        }
                    } else if (
                        (state1 >= 1 && state2 === 0 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                        (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                        (state1 >= 1 && state2 === 0 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 >= 1) ||
                        (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 >= 1)
                    ) {
                        const temp1 = 3 * (state6 + state5);
                        const temp2 = 6 * state1;
                        result +=
                            ' و هر برادر ابوینی : ' +
                            ((1 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل 1/' +
                            temp2 +
                            '\n' +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else if (
                        (state1 === 0 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 === 0) ||
                        (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 === 0) ||
                        (state1 === 0 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 === 0) ||
                        (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 === 0)
                    ) {
                        if (state5 === 1) {
                            result +=
                                ' و هر خواهر ابوینی : ' +
                                (100 / (3 * state1)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state1 +
                                ' و برادر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر خواهر ابوینی : ' +
                                (100 / (state1 * 6)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                state1 * 6 +
                                ' و هر برادر امی : ' +
                                (100 / (3 * state5)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state5;
                        }
                    } else if (
                        (state1 === 0 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 === 0 && state6 >= 1) ||
                        (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 >= 1) ||
                        (state1 === 0 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                        (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 >= 1)
                    ) {
                        if (state6 === 1) {
                            result +=
                                ' و هر خواهر ابوینی : ' +
                                (100 / (3 * state1)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state1 +
                                ' و خواهر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر خواهر ابوینی : ' +
                                (100 / (state1 * 6)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                state1 * 6 +
                                ' و هر خواهر امی : ' +
                                (100 / (3 * state6)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state6;
                        }
                    } else if (
                        (state1 === 0 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                        (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                        (state1 === 0 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 >= 1) ||
                        (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 >= 1)
                    ) {
                        const temp1 = 3 * (state6 + state5);
                        const temp2 = 6 * state1;
                        result +=
                            ' و هر خواهر ابوینی : ' +
                            ((1 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل 1/' +
                            temp2 +
                            '\n' +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else if (
                        (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 === 0) ||
                        (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 === 0) ||
                        (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 === 0) ||
                        (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 === 0)
                    ) {
                        const temp1 = state5 === 1 ? 6 : state5 * 3;
                        const temp2 = state5 === 1 ? (2 * state1 + state2) * 3 : (2 * state1 + state2) * 6;
                        if (state5 === 1) {
                            result +=
                                ' و هر برادر ابوینی : ' +
                                ((2 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۲/' +
                                temp2 +
                                '\n' +
                                ' و هر خواهر ابوینی : ' +
                                ((1 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                temp2 +
                                ' و برادر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر برادر ابوینی : ' +
                                ((2 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۲/' +
                                temp2 +
                                '\n' +
                                ' و هر خواهر ابوینی : ' +
                                ((1 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                temp2 +
                                '\n' +
                                ' و هر برادر امی : ' +
                                ((1 / temp1) * 100).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                temp1 +
                                '';
                        }
                    } else if (
                        (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 === 0 && state6 >= 1) ||
                        (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 >= 1) ||
                        (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 === 0 && state6 >= 1) ||
                        (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 === 0 && state6 >= 1)
                    ) {
                        const temp1 = state6 === 1 ? 6 : state6 * 3;
                        const temp2 = state6 === 1 ? (2 * state1 + state2) * 3 : (2 * state1 + state2) * 6;
                        if (state6 === 1) {
                            result +=
                                ' و هر برادر ابوینی : ' +
                                ((2 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۲/' +
                                temp2 +
                                '\n' +
                                ' و هر خواهر ابوینی : ' +
                                ((1 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                temp2 +
                                ' و خواهر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر برادر ابوینی : ' +
                                ((2 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۲/' +
                                temp2 +
                                '\n' +
                                ' و هر خواهر ابوینی : ' +
                                ((1 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                temp2 +
                                '\n' +
                                ' و هر خواهر امی : ' +
                                ((1 / temp1) * 100).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                temp1 +
                                '';
                        }
                    } else if (
                        (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                        (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                        (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 >= 1) ||
                        (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 >= 1)
                    ) {
                        const temp1 = 3 * (state5 + state6);
                        const temp2 = 6 * (2 * state1 + state2);
                        result +=
                            ' و هر برادر ابوینی : ' +
                            ((2 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۲/' +
                            temp2 +
                            '\n' +
                            ' و هر خواهر ابوینی : ' +
                            ((1 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp2 +
                            '\n' +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '\n';
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 >= 1 &&
                        state4 === 0 &&
                        state5 >= 1 &&
                        state6 === 0
                    ) {
                        if (state5 === 1) {
                            result +=
                                ' و هر برادر ابی : ' +
                                (100 / (3 * state3)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state3 +
                                ' و برادر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر برادر ابی : ' +
                                (100 / (state3 * 6)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                state3 * 6 +
                                ' و هر برادر امی : ' +
                                (100 / (3 * state5)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state5;
                        }
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 >= 1 &&
                        state4 === 0 &&
                        state5 === 0 &&
                        state6 >= 1
                    ) {
                        if (state6 === 1) {
                            result +=
                                ' و هر برادر ابی : ' +
                                (100 / (3 * state3)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state3 +
                                ' و خواهر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر برادر ابی : ' +
                                (100 / (state3 * 6)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                state3 * 6 +
                                ' و هر خواهر امی : ' +
                                (100 / (3 * state6)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state6;
                        }
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 >= 1 &&
                        state4 === 0 &&
                        state5 >= 1 &&
                        state6 >= 1
                    ) {
                        const temp1 = 3 * (state6 + state5);
                        const temp2 = 6 * state3;
                        result +=
                            ' و هر برادر ابی : ' +
                            ((1 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل 1/' +
                            temp2 +
                            '\n' +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 === 0 &&
                        state4 >= 1 &&
                        state5 >= 1 &&
                        state6 === 0
                    ) {
                        if (state5 === 1) {
                            result +=
                                ' و هر خواهر ابی : ' +
                                (100 / (3 * state4)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state4 +
                                ' و برادر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر خواهر ابی : ' +
                                (100 / (state4 * 6)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                state4 * 6 +
                                ' و هر برادر امی : ' +
                                (100 / (3 * state5)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state5;
                        }
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 === 0 &&
                        state4 >= 1 &&
                        state5 === 0 &&
                        state6 >= 1
                    ) {
                        if (state6 === 1) {
                            result +=
                                ' و هر خواهر ابی : ' +
                                (100 / (3 * state4)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state4 +
                                ' و خواهر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر خواهر ابی : ' +
                                (100 / (state4 * 6)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                state4 * 6 +
                                ' و هر خواهر امی : ' +
                                (100 / (3 * state6)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state6;
                        }
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 === 0 &&
                        state4 >= 1 &&
                        state5 >= 1 &&
                        state6 >= 1
                    ) {
                        const temp1 = 3 * (state6 + state5);
                        const temp2 = 6 * state4;
                        result +=
                            ' و هر خواهر ابی : ' +
                            ((1 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل 1/' +
                            temp2 +
                            '\n' +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 >= 1 &&
                        state4 >= 1 &&
                        state5 >= 1 &&
                        state6 === 0
                    ) {
                        const temp1 = state5 === 1 ? 6 : state5 * 3;
                        const temp2 = state5 === 1 ? (2 * state3 + state4) * 3 : (2 * state3 + state4) * 6;
                        if (state5 === 1) {
                            result +=
                                ' و هر برادر ابی : ' +
                                ((2 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۲/' +
                                temp2 +
                                '\n' +
                                ' و هر خواهر ابی : ' +
                                ((1 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                temp2 +
                                ' و برادر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر برادر ابی : ' +
                                ((2 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۲/' +
                                temp2 +
                                '\n' +
                                ' و هر خواهر ابی : ' +
                                ((1 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                temp2 +
                                '\n' +
                                ' و هر برادر امی : ' +
                                ((1 / temp1) * 100).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                temp1 +
                                '';
                        }
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 >= 1 &&
                        state4 >= 1 &&
                        state5 === 0 &&
                        state6 >= 1
                    ) {
                        const temp1 = state6 === 1 ? 6 : state6 * 3;
                        const temp2 = state6 === 1 ? (2 * state3 + state4) * 3 : (2 * state3 + state4) * 6;
                        if (state6 === 1) {
                            result +=
                                ' و هر برادر ابی : ' +
                                ((2 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۲/' +
                                temp2 +
                                '\n' +
                                ' و هر خواهر ابی : ' +
                                ((1 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                temp2 +
                                ' و خواهر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر برادر ابی : ' +
                                ((2 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۲/' +
                                temp2 +
                                '\n' +
                                ' و هر خواهر ابی : ' +
                                ((1 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                temp2 +
                                '\n' +
                                ' و هر خواهر امی : ' +
                                ((1 / temp1) * 100).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                temp1 +
                                '';
                        }
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 >= 1 &&
                        state4 >= 1 &&
                        state5 >= 1 &&
                        state6 >= 1
                    ) {
                        const temp1 = 3 * (state5 + state6);
                        const temp2 = 6 * (2 * state3 + state4);
                        result +=
                            ' و هر برادر ابی : ' +
                            ((2 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۲/' +
                            temp2 +
                            '\n' +
                            ' و هر خواهر ابی : ' +
                            ((1 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp2 +
                            '\n' +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '\n';
                    }
                }
                if (
                    state5 + state6 === 1 &&
                    (state1 + state2 >= 1 || state3 + state4 >= 1) &&
                    (checkbox3 || checkbox4) &&
                    !checkbox5 &&
                    !checkbox6
                ) {
                    if (state5 + state6 === 1) {
                        if (state5 === 1 && state6 === 0) {
                            result += ' برادر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else if (state5 === 0 && state6 === 1) {
                            result += ' خواهر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        }
                    }
                    if (state1 + state2 >= 1) {
                        let temp = 2 * state1 + state2;
                        if (checkbox3) {
                            temp = temp + 2;
                        }
                        if (checkbox4) {
                            temp = temp + 1;
                        }
                        if (checkbox3) {
                            result += ' و جد ابی : ' + (200 / (3 * temp)).toFixed(2) + ' ٪ معادل ۲/' + 3 * temp + '\n';
                        }
                        if (checkbox4) {
                            result += ' و جده ابی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 3 * temp;
                        }
                        if (state1 !== 0) {
                            result += ' و برادر ابوینی : ' + (200 / (3 * temp)).toFixed(2) + ' ٪ معادل ۲/' + 3 * temp;
                        }
                        if (state2 !== 0) {
                            result += ' و خواهر ابوینی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 3 * temp;
                        }
                    } else if (state3 + state4 >= 1) {
                        let temp = state4 + 2 * state3;
                        if (checkbox3) {
                            temp = temp + 2;
                        }
                        if (checkbox4) {
                            temp = temp + 1;
                        }
                        if (checkbox3) {
                            result += ' و جد ابی : ' + (200 / (3 * temp)).toFixed(2) + ' ٪ معادل ۲/' + 3 * temp + '\n';
                        }
                        if (checkbox4) {
                            result += ' و جده ابی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 6 * temp;
                        }
                        if (state3 !== 0) {
                            result += ' و برادر ابی : ' + (200 / (3 * temp)).toFixed(2) + ' ٪ معادل ۲/' + 3 * temp;
                        }
                        if (state4 !== 0) {
                            result += ' و خواهر ابی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 6 * temp;
                        }
                    }
                }
                if (
                    (checkbox3 || checkbox4 || checkbox5 || checkbox6) &&
                    state5 + state6 >= 1 &&
                    (state1 + state2 >= 1 || state3 + state4 >= 1)
                ) {
                    if (state5 + state6 >= 1) {
                        let temp = state6 + state5;
                        if (checkbox5) {
                            temp = temp + 1;
                        }
                        if (checkbox6) {
                            temp = temp + 1;
                        }
                        if (checkbox5) {
                            result += ' و جد امی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 3 * temp;
                        }
                        if (checkbox6) {
                            result += ' و جده امی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 3 * temp;
                        }
                        if (state5 !== 0) {
                            result += ' و برادر امی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 3 * temp;
                        }
                        if (state6 !== 0) {
                            result += ' و خواهر امی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 3 * temp;
                        }
                    }

                    if (state1 + state2 >= 1) {
                        let temp = state1 * 2 + state2;
                        if (checkbox3) {
                            temp = temp + 2;
                        }
                        if (checkbox4) {
                            temp = temp + 1;
                        }
                        if (checkbox3) {
                            result += ' و جد ابی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 3 * temp;
                        }
                        if (checkbox4) {
                            result += ' و جده ابی : ' + (100 / (6 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 6 * temp;
                        }
                        if (state1 !== 0) {
                            result += ' و برادر ابوینی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 3 * temp;
                        }
                        if (state2 !== 0) {
                            result += ' و خواهر ابوینی : ' + (100 / (6 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 6 * temp;
                        }
                    } else if (state3 + state4 >= 1) {
                        let temp = state3 * 2 + state4;
                        if (checkbox3) {
                            temp = temp + 2;
                        }
                        if (checkbox4) {
                            temp = temp + 1;
                        }
                        if (checkbox3) {
                            result += ' و جد ابی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 3 * temp;
                        }
                        if (checkbox4) {
                            result += ' و جده ابی : ' + (100 / (6 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 6 * temp;
                        }
                        if (state3 !== 0) {
                            result += ' و برادر ابی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 3 * temp;
                        }
                        if (state4 !== 0) {
                            result += ' و خواهر ابی : ' + (100 / (6 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 6 * temp;
                        }
                    }
                }
            } else if (maritalStatus === 3) {
                console.log('Entred 3');
                const wifeNum = wifeNumber <= 4 ? wifeNumber * 4 : 16;
                result += ' هر زوجه (زن) : ' + (100 / wifeNum).toFixed(2) + ' ٪ معادل ۱/' + wifeNum;
                if (
                    state1 === 0 &&
                    state2 === 0 &&
                    state3 === 0 &&
                    state4 === 0 &&
                    state5 === 0 &&
                    state6 === 0 &&
                    (checkbox3 || checkbox4 || checkbox5 || checkbox6)
                ) {
                    // just grandparents
                    const checkboxes = [checkbox3, checkbox4, checkbox5, checkbox6];
                    const name = checkbox3 ? 'جد ابی' : checkbox4 ? 'جده ابی' : checkbox5 ? 'جد امی' : 'جده امی';
                    if (checkboxes.filter((item) => item === true).length === 1) {
                        result += ' و ' + name + ' : ۷۵ ٪ معادل ۳/۴';
                    }
                    if (checkboxes.filter((item) => item === true).length > 1) {
                        if (checkbox3 && checkbox4 && !checkbox5 && !checkbox6) {
                            result += 'جد ابی : ۵۰ ٪ معادل ۱/۲\n' + 'جده ابی : ۲۵ ٪ معادل ۱/۴';
                        } else if (!checkbox3 && !checkbox4 && checkbox5 && checkbox6) {
                            result += 'جد امی : ۳۷.۵ ٪ معادل ۳/۸\n' + 'جده امی : ۳۷.۵ ٪ معادل ۳/۸';
                        } else {
                            if (checkbox5 || checkbox6) {
                                if (checkbox5 && !checkbox6) {
                                    result += ' و جد امی : ۳۳.۳۳ ٪ معادل ۱/۳\n';
                                } else if (!checkbox5 && checkbox6) {
                                    result += ' و جده امی : ۳۳.۳۳ ٪ معادل ۱/۳\n';
                                } else if (checkbox5 && checkbox6) {
                                    result += ' و جد امی : ۱۶.۶۷ ٪ معادل ۱/۶\n' + ' و جده امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                                }
                            }
                            if (checkbox3 || checkbox4) {
                                if (checkbox3 && !checkbox4) {
                                    result += 'جد ابی : ۴۱.۶۷ ٪ معادل ۵/۱۲';
                                } else if (!checkbox3 && checkbox4) {
                                    result += 'جده ابی : ۴۱.۶۷ ٪ معادل ۵/۱۲';
                                } else if (checkbox3 && checkbox4) {
                                    result += 'جد ابی : ۲۷.۷۸ ٪ معادل ۵/۱۸\n' + 'جده ابی : ۱۳.۸۹ ٪ معادل ۵/۳۶';
                                }
                            }
                        }
                    }
                }
                if (!checkbox3 && !checkbox4 && !checkbox5 && !checkbox6) {
                    //none of the checkboxes
                    if (state1 >= 1 && state2 === 0 && state3 === 0 && state4 === 0 && state5 === 0 && state6 === 0) {
                        result += ' و برادر ابوینی : ' + (75 / state1).toFixed(2) + ' ٪ معادل ۳/' + state1 * 4 + '';
                    } else if (
                        state1 === 0 &&
                        state2 >= 1 &&
                        state3 === 0 &&
                        state4 === 0 &&
                        state5 === 0 &&
                        state6 === 0
                    ) {
                        result += ' و خواهر ابوینی : ' + (75 / state1).toFixed(2) + ' ٪ معادل ۳/' + state1 * 4 + '';
                    } else if (
                        state1 >= 1 &&
                        state2 >= 1 &&
                        state3 === 0 &&
                        state4 === 0 &&
                        state5 === 0 &&
                        state6 === 0
                    ) {
                        const temp = state1 * 2 + state2;
                        result +=
                            ' و برادر ابوینی : ' +
                            (150 / temp).toFixed(2) +
                            ' ٪ معادل ۳/' +
                            temp * 2 +
                            '\n' +
                            ' و خواهر ابوینی : ' +
                            (75 / temp).toFixed(2) +
                            ' ٪ معادل ۳/' +
                            temp * 4 +
                            '';
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 >= 1 &&
                        state4 === 0 &&
                        state5 === 0 &&
                        state6 === 0
                    ) {
                        result += ' و برادر ابی : ' + (75 / state1).toFixed(2) + ' ٪ معادل ۳/' + state1 * 4 + '';
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 === 0 &&
                        state4 >= 1 &&
                        state5 === 0 &&
                        state6 === 0
                    ) {
                        result += ' و خواهر ابی : ' + (75 / state1).toFixed(2) + ' ٪ معادل ۳/' + state1 * 4 + '';
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 >= 1 &&
                        state4 >= 1 &&
                        state5 === 0 &&
                        state6 === 0
                    ) {
                        const temp = state3 * 2 + state4;
                        result +=
                            ' و برادر ابی : ' +
                            (150 / temp).toFixed(2) +
                            ' ٪ معادل ۳/' +
                            temp * 2 +
                            '\n' +
                            ' و خواهر ابی : ' +
                            (75 / temp).toFixed(2) +
                            ' ٪ معادل ۳/' +
                            temp * 4;
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 === 0 &&
                        state4 === 0 &&
                        state5 >= 1 &&
                        state6 === 0
                    ) {
                        result += ' و برادر امی  : ' + (75 / state5).toFixed(2) + ' ٪ معادل ۳/' + state5 * 4 + '';
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 === 0 &&
                        state4 === 0 &&
                        state5 === 0 &&
                        state6 >= 1
                    ) {
                        result += ' و خواهر امی  : ' + (75 / state6).toFixed(2) + ' ٪ معادل ۳/' + state6 * 4 + '';
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 === 0 &&
                        state4 === 0 &&
                        state5 >= 1 &&
                        state6 >= 1
                    ) {
                        const temp = state5 + state6;
                        result +=
                            ' و برادر امی : ' +
                            (75 / temp).toFixed(2) +
                            ' ٪ معادل ۳/' +
                            temp * 4 +
                            '\n' +
                            ' و خواهر امی : ' +
                            (75 / temp).toFixed(2) +
                            ' ٪ معادل ۳/' +
                            temp * 4;
                    } else if (
                        (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 === 0) ||
                        (state1 >= 1 && state2 === 0 && state3 === 0 && state4 >= 1 && state5 === 0 && state6 === 0) ||
                        (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 >= 1 && state5 === 0 && state6 === 0)
                    ) {
                        result += ' و برادر ابوینی : ' + (75 / state1).toFixed(2) + ' ٪ معادل ۳/' + state1 * 4 + '';
                    } else if (
                        (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 === 0) ||
                        (state1 === 0 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 === 0 && state6 === 0) ||
                        (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 === 0 && state6 === 0)
                    ) {
                        result += ' و خواهر ابوینی : ' + (75 / state1).toFixed(2) + ' ٪ معادل ۳/' + state1 * 4 + '';
                    } else if (
                        (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 === 0) ||
                        (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 === 0 && state6 === 0) ||
                        (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 === 0 && state6 === 0)
                    ) {
                        const temp = state1 * 2 + state2;
                        result +=
                            ' و برادر ابوینی : ' +
                            (150 / temp).toFixed(2) +
                            ' ٪ معادل ۳/' +
                            temp * 2 +
                            '\n' +
                            ' و خواهر ابوینی : ' +
                            (75 / temp).toFixed(2) +
                            ' ٪ معادل ۳/' +
                            temp * 4;
                    } else if (
                        (state1 >= 1 && state2 === 0 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 === 0) ||
                        (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 === 0) ||
                        (state1 >= 1 && state2 === 0 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 === 0) ||
                        (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 === 0)
                    ) {
                        if (state5 === 1) {
                            result +=
                                ' و هر برادر ابوینی : ' +
                                (700 / (12 * state1)).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                12 * state1 +
                                ' و برادر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر برادر ابوینی : ' +
                                (500 / (state1 * 12)).toFixed(2) +
                                ' ٪ معادل ۵/' +
                                state1 * 12 +
                                ' و هر برادر امی : ' +
                                (100 / (3 * state5)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state5;
                        }
                    } else if (
                        (state1 >= 1 && state2 === 0 && state3 === 0 && state4 === 0 && state5 === 0 && state6 >= 1) ||
                        (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 >= 1) ||
                        (state1 >= 1 && state2 === 0 && state3 === 0 && state4 >= 1 && state5 === 0 && state6 >= 1) ||
                        (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 >= 1 && state5 === 0 && state6 >= 1)
                    ) {
                        if (state6 === 1) {
                            result +=
                                ' و هر برادر ابوینی : ' +
                                (700 / (12 * state1)).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                12 * state1 +
                                ' و خواهر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر برادر ابوینی : ' +
                                (500 / (state1 * 12)).toFixed(2) +
                                ' ٪ معادل ۵/' +
                                state1 * 12 +
                                ' و هر خواهر امی : ' +
                                (100 / (3 * state6)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state6;
                        }
                    } else if (
                        (state1 >= 1 && state2 === 0 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                        (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                        (state1 >= 1 && state2 === 0 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 >= 1) ||
                        (state1 >= 1 && state2 === 0 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 >= 1)
                    ) {
                        const temp1 = 3 * (state6 + state5);
                        const temp2 = state1 * 12;
                        result +=
                            ' و هر برادر ابوینی : ' +
                            (500 / (state1 * 12)).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else if (
                        (state1 === 0 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 === 0) ||
                        (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 === 0) ||
                        (state1 === 0 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 === 0) ||
                        (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 === 0)
                    ) {
                        if (state5 === 1) {
                            result +=
                                ' و هر خواهر ابوینی : ' +
                                (700 / (12 * state2)).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                12 * state2 +
                                ' و برادر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر خواهر ابوینی : ' +
                                (500 / (state2 * 12)).toFixed(2) +
                                ' ٪ معادل ۵/' +
                                state2 * 12 +
                                ' و هر برادر امی : ' +
                                (100 / (3 * state5)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state5;
                        }
                    } else if (
                        (state1 === 0 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 === 0 && state6 >= 1) ||
                        (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 >= 1) ||
                        (state1 === 0 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                        (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 >= 1)
                    ) {
                        if (state6 === 1) {
                            result +=
                                ' و هر خواهر ابوینی : ' +
                                (700 / (12 * state2)).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                12 * state2 +
                                ' و خواهر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر خواهر ابوینی : ' +
                                (500 / (state2 * 12)).toFixed(2) +
                                ' ٪ معادل ۵/' +
                                state2 * 12 +
                                ' و هر خواهر امی : ' +
                                (100 / (3 * state6)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state6;
                        }
                    } else if (
                        (state1 === 0 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                        (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                        (state1 === 0 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 >= 1) ||
                        (state1 === 0 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 >= 1)
                    ) {
                        const temp1 = 3 * (state6 + state5);
                        const temp2 = state2 * 12;
                        result +=
                            ' و هر خواهر ابوینی : ' +
                            (500 / (state2 * 12)).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else if (
                        (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 === 0) ||
                        (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 === 0) ||
                        (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 === 0) ||
                        (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 === 0)
                    ) {
                        const temp1 = state5 === 1 ? 6 : state5 * 3;
                        const temp2 = state5 === 1 ? (2 * state1 + state2) * 12 : (2 * state1 + state2) * 12;
                        if (state5 === 1) {
                            result +=
                                ' و هر برادر ابوینی : ' +
                                ((14 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                temp2 / 2 +
                                '\n' +
                                ' و هر خواهر ابوینی : ' +
                                ((7 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                temp2 +
                                ' و برادر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر برادر ابوینی : ' +
                                ((10 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۵/' +
                                temp2 / 2 +
                                '\n' +
                                ' و هر خواهر ابوینی : ' +
                                ((5 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۵/' +
                                temp2 +
                                '\n' +
                                ' و هر برادر امی : ' +
                                ((1 / temp1) * 100).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                temp1 +
                                '';
                        }
                    } else if (
                        (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 === 0 && state6 >= 1) ||
                        (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 === 0 && state6 >= 1) ||
                        (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 === 0 && state6 >= 1) ||
                        (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 === 0 && state6 >= 1)
                    ) {
                        const temp1 = state6 === 1 ? 6 : state6 * 3;
                        const temp2 = state6 === 1 ? (2 * state1 + state2) * 12 : (2 * state1 + state2) * 12;
                        if (state6 === 1) {
                            ' و هر برادر ابوینی : ' +
                                ((14 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                temp2 / 2 +
                                ' و هر خواهر ابوینی : ' +
                                ((7 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                temp2 +
                                ' و خواهر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر برادر ابوینی : ' +
                                ((10 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۵/' +
                                temp2 / 2 +
                                '\n' +
                                ' و هر خواهر ابوینی : ' +
                                ((5 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۵/' +
                                temp2 +
                                '\n' +
                                ' و هر خواهر امی : ' +
                                ((1 / temp1) * 100).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                temp1 +
                                '';
                        }
                    } else if (
                        (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                        (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 === 0 && state5 >= 1 && state6 >= 1) ||
                        (state1 >= 1 && state2 >= 1 && state3 === 0 && state4 >= 1 && state5 >= 1 && state6 >= 1) ||
                        (state1 >= 1 && state2 >= 1 && state3 >= 1 && state4 >= 1 && state5 >= 1 && state6 >= 1)
                    ) {
                        const temp1 = 3 * (state5 + state6);
                        const temp2 = 12 * (2 * state1 + state2);
                        result +=
                            ' و هر برادر ابوینی : ' +
                            ((10 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 / 2 +
                            '\n' +
                            ' و هر خواهر ابوینی : ' +
                            ((5 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 +
                            '\n' +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '\n';
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 >= 1 &&
                        state4 === 0 &&
                        state5 >= 1 &&
                        state6 === 0
                    ) {
                        if (state5 === 1) {
                            result +=
                                ' و هر برادر ابی : ' +
                                (700 / (12 * state3)).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                12 * state3 +
                                ' و برادر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر برادر ابی : ' +
                                (500 / (state3 * 12)).toFixed(2) +
                                ' ٪ معادل ۵/' +
                                state3 * 12 +
                                ' و هر برادر امی : ' +
                                (100 / (3 * state5)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state5;
                        }
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 >= 1 &&
                        state4 === 0 &&
                        state5 === 0 &&
                        state6 >= 1
                    ) {
                        if (state6 === 1) {
                            result +=
                                ' و هر برادر ابی : ' +
                                (700 / (12 * state3)).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                12 * state3 +
                                ' و خواهر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر برادر ابی : ' +
                                (500 / (state3 * 12)).toFixed(2) +
                                ' ٪ معادل ۵/' +
                                state3 * 12 +
                                ' و هر خواهر امی : ' +
                                (100 / (3 * state6)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state6;
                        }
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 >= 1 &&
                        state4 === 0 &&
                        state5 >= 1 &&
                        state6 >= 1
                    ) {
                        const temp1 = 3 * (state6 + state5);
                        const temp2 = state3 * 12;
                        result +=
                            ' و هر برادر ابی : ' +
                            (500 / (state1 * 12)).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 === 0 &&
                        state4 >= 1 &&
                        state5 >= 1 &&
                        state6 === 0
                    ) {
                        if (state5 === 1) {
                            result +=
                                ' و هر خواهر ابی : ' +
                                (700 / (12 * state4)).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                12 * state4 +
                                ' و برادر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر خواهر ابی : ' +
                                (500 / (state4 * 12)).toFixed(2) +
                                ' ٪ معادل ۵/' +
                                state4 * 12 +
                                ' و هر برادر امی : ' +
                                (100 / (3 * state5)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state5;
                        }
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 === 0 &&
                        state4 >= 1 &&
                        state5 === 0 &&
                        state6 >= 1
                    ) {
                        if (state6 === 1) {
                            result +=
                                ' و هر خواهر ابی : ' +
                                (700 / (12 * state4)).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                12 * state4 +
                                ' و خواهر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر خواهر ابی : ' +
                                (500 / (state4 * 12)).toFixed(2) +
                                ' ٪ معادل ۵/' +
                                state4 * 12 +
                                ' و هر خواهر امی : ' +
                                (100 / (3 * state6)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                3 * state6;
                        }
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 === 0 &&
                        state4 >= 1 &&
                        state5 >= 1 &&
                        state6 >= 1
                    ) {
                        const temp1 = 3 * (state6 + state5);
                        const temp2 = state4 * 12;
                        result +=
                            ' و هر خواهر ابی : ' +
                            (500 / (state2 * 12)).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '';
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 >= 1 &&
                        state4 >= 1 &&
                        state5 >= 1 &&
                        state6 === 0
                    ) {
                        const temp1 = state5 === 1 ? 6 : state5 * 3;
                        const temp2 = state5 === 1 ? (2 * state3 + state4) * 12 : (2 * state3 + state4) * 12;
                        if (state5 === 1) {
                            result +=
                                ' و هر برادر ابی : ' +
                                ((14 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                temp2 / 2 +
                                '\n' +
                                ' و هر خواهر ابی : ' +
                                ((7 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                temp2 +
                                ' و برادر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر برادر ابی : ' +
                                ((10 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۵/' +
                                temp2 / 2 +
                                '\n' +
                                ' و هر خواهر ابی : ' +
                                ((5 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۵/' +
                                temp2 +
                                '\n' +
                                ' و هر برادر امی : ' +
                                ((1 / temp1) * 100).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                temp1 +
                                '';
                        }
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 >= 1 &&
                        state4 >= 1 &&
                        state5 === 0 &&
                        state6 >= 1
                    ) {
                        const temp1 = state6 === 1 ? 6 : state6 * 3;
                        const temp2 = state6 === 1 ? (2 * state3 + state4) * 12 : (2 * state3 + state4) * 12;
                        if (state6 === 1) {
                            ' و هر برادر ابی : ' +
                                ((14 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                temp2 / 2 +
                                ' و هر خواهر ابی : ' +
                                ((7 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۷/' +
                                temp2 +
                                ' و خواهر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else {
                            result +=
                                ' و هر برادر ابی : ' +
                                ((10 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۵/' +
                                temp2 / 2 +
                                '\n' +
                                ' و هر خواهر ابی : ' +
                                ((5 / temp2) * 100).toFixed(2) +
                                ' ٪ معادل ۵/' +
                                temp2 +
                                '\n' +
                                ' و هر خواهر امی : ' +
                                ((1 / temp1) * 100).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                temp1 +
                                '';
                        }
                    } else if (
                        state1 === 0 &&
                        state2 === 0 &&
                        state3 >= 1 &&
                        state4 >= 1 &&
                        state5 >= 1 &&
                        state6 >= 1
                    ) {
                        const temp1 = 3 * (state5 + state6);
                        const temp2 = 12 * (2 * state3 + state4);
                        result +=
                            ' و هر برادر ابی : ' +
                            ((10 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 / 2 +
                            '\n' +
                            ' و هر خواهر ابی : ' +
                            ((5 / temp2) * 100).toFixed(2) +
                            ' ٪ معادل ۵/' +
                            temp2 +
                            '\n' +
                            ' و هر برادر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '\n' +
                            ' و هر خواهر امی : ' +
                            ((1 / temp1) * 100).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp1 +
                            '\n';
                    }
                }
                if (
                    state5 + state6 === 1 &&
                    (state1 + state2 >= 1 || state3 + state4 >= 1) &&
                    (checkbox3 || checkbox4) &&
                    !checkbox5 &&
                    !checkbox6
                ) {
                    if (state5 + state6 === 1) {
                        if (state5 === 1 && state6 === 0) {
                            result += ' برادر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        } else if (state5 === 0 && state6 === 1) {
                            result += ' خواهر امی : ۱۶.۶۷ ٪ معادل ۱/۶';
                        }
                    }
                    if (state1 + state2 >= 1) {
                        let temp = 2 * state1 + state2;
                        if (checkbox3) {
                            temp = temp + 2;
                        }
                        if (checkbox4) {
                            temp = temp + 1;
                        }
                        if (checkbox3) {
                            result += ' و جد ابی : ' + (700 / (6 * temp)).toFixed(2) + ' ٪ معادل ۷/' + 6 * temp + '\n';
                        }
                        if (checkbox4) {
                            result += ' و جده ابی : ' + (700 / (12 * temp)).toFixed(2) + ' ٪ معادل ۷/' + 12 * temp;
                        }
                        if (state1 !== 0) {
                            result += ' و برادر ابوینی : ' + (700 / (6 * temp)).toFixed(2) + ' ٪ معادل ۷/' + 6 * temp;
                        }
                        if (state2 !== 0) {
                            result += ' و خواهر ابوینی : ' + (700 / (12 * temp)).toFixed(2) + ' ٪ معادل ۷/' + 12 * temp;
                        }
                    } else if (state3 + state4 >= 1) {
                        let temp = state4 + 2 * state3;
                        if (checkbox3) {
                            temp = temp + 2;
                        }
                        if (checkbox4) {
                            temp = temp + 1;
                        }
                        if (checkbox3) {
                            result += ' و جد ابی : ' + (700 / (6 * temp)).toFixed(2) + ' ٪ معادل ۷/' + 6 * temp + '\n';
                        }
                        if (checkbox4) {
                            result += ' و جده ابی : ' + (700 / (12 * temp)).toFixed(2) + ' ٪ معادل ۷/' + 12 * temp;
                        }
                        if (state3 !== 0) {
                            result += ' و برادر ابی : ' + (700 / (6 * temp)).toFixed(2) + ' ٪ معادل ۷/' + 6 * temp;
                        }
                        if (state4 !== 0) {
                            result += ' و خواهر ابی : ' + (700 / (12 * temp)).toFixed(2) + ' ٪ معادل ۷/' + 12 * temp;
                        }
                    }
                }
                if (
                    (checkbox3 || checkbox4 || checkbox5 || checkbox6) &&
                    state5 + state6 >= 1 &&
                    (state1 + state2 >= 1 || state3 + state4 >= 1)
                ) {
                    if (state5 + state6 >= 1) {
                        let temp = state6 + state5;
                        if (checkbox5) {
                            temp = temp + 1;
                        }
                        if (checkbox6) {
                            temp = temp + 1;
                        }
                        if (checkbox5) {
                            result += ' و جد امی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 3 * temp;
                        }
                        if (checkbox6) {
                            result += ' و جده امی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 3 * temp;
                        }
                        if (state5 !== 0) {
                            result += ' و برادر امی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 3 * temp;
                        }
                        if (state6 !== 0) {
                            result += ' و خواهر امی : ' + (100 / (3 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 3 * temp;
                        }
                    }

                    if (state1 + state2 >= 1) {
                        let temp = state1 * 2 + state2;
                        if (checkbox3) {
                            temp = temp + 2;
                        }
                        if (checkbox4) {
                            temp = temp + 1;
                        }
                        if (checkbox3) {
                            result += ' و جد ابی : ' + (500 / (6 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 6 * temp;
                        }
                        if (checkbox4) {
                            result += ' و جده ابی : ' + (500 / (12 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 12 * temp;
                        }
                        if (state1 !== 0) {
                            result += ' و برادر ابوینی : ' + (500 / (6 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 6 * temp;
                        }
                        if (state2 !== 0) {
                            result += ' و خواهر ابوینی : ' + (500 / (12 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 12 * temp;
                        }
                    } else if (state3 + state4 >= 1) {
                        let temp = state3 * 2 + state4;
                        if (checkbox3) {
                            temp = temp + 2;
                        }
                        if (checkbox4) {
                            temp = temp + 1;
                        }
                        if (checkbox3) {
                            result += ' و جد ابی : ' + (500 / (6 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 6 * temp;
                        }
                        if (checkbox4) {
                            result += ' و جده ابی : ' + (500 / (12 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 12 * temp;
                        }
                        if (state3 !== 0) {
                            result += ' و برادر ابی : ' + (500 / (6 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 6 * temp;
                        }
                        if (state4 !== 0) {
                            result += ' و خواهر ابی : ' + (500 / (12 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 12 * temp;
                        }
                    }
                }
            }
        } else if (
            !checkbox1 &&
            !checkbox2 &&
            boys === 0 &&
            girls === 0 &&
            !checkbox3 &&
            !checkbox4 &&
            !checkbox5 &&
            !checkbox6 &&
            state1 === 0 &&
            state2 === 0 &&
            state3 === 0 &&
            state4 === 0 &&
            state5 === 0 &&
            state6 === 0 &&
            (state7 > 0 ||
                state8 > 0 ||
                state9 > 0 ||
                state10 > 0 ||
                state11 > 0 ||
                state12 > 0 ||
                state13 > 0 ||
                state14 > 0 ||
                state15 > 0 ||
                state16 > 0 ||
                state17 > 0 ||
                state18 > 0)
        ) {
            //level 3
            const level3 = [
                {
                    id: 'عموی ابوینی',
                    st: state7,
                    ref: 'اعمام',
                    label: 'ابوینی',
                },
                {
                    id: 'عمه ابوینی',
                    st: state8,
                    ref: 'اعمام',
                    label: 'ابوینی',
                },
                {
                    id: 'دایی ابوینی',
                    st: state9,
                    ref: 'اخوال',
                    label: 'ابوینی',
                },
                {
                    id: 'خاله ابوینی',
                    st: state10,
                    ref: 'اخوال',
                    label: 'ابوینی',
                },
                {
                    id: 'عموی ابی',
                    st: state11,
                    ref: 'اعمام',
                    label: 'ابی',
                },
                {
                    id: 'عمه ابی',
                    st: state12,
                    ref: 'اعمام',
                    label: 'ابی',
                },
                {
                    id: 'دایی ابی',
                    st: state13,
                    ref: 'اخوال',
                    label: 'ابی',
                },
                {
                    id: 'خاله ابی',
                    st: state14,
                    ref: 'اخوال',
                    label: 'ابی',
                },
                {
                    id: 'عموی امی',
                    st: state15,
                    ref: 'اعمام',
                    label: 'امی',
                },
                {
                    id: 'عمه امی',
                    st: state16,
                    ref: 'اعمام',
                    label: 'امی',
                },
                {
                    id: 'دایی امی',
                    st: state17,
                    ref: 'اخوال',
                    label: 'امی',
                },
                {
                    id: 'خاله امی',
                    st: state18,
                    ref: 'اخوال',
                    label: 'امی',
                },
            ];
            if (maritalStatus === 2) {
                result += 'زوج (شوهر) : ۵۰ ٪ معادل ۱/۲';
                if (level3.filter((item) => item.st >= 1).length === 1) {
                    level3.map((item, index) => {
                        if (item.st === 1) {
                            console.log('avali');
                            result += item.id + ' : ' + (50 / item.st).toFixed(2) + ' ٪ معادل ۱/' + 2 * item.st;
                        }
                        if (item.st > 1 && item.ref === 'اعمام') {
                            console.log('dovomi');
                            result +=
                                ' و هر ' + item.id + ' : ' + (50 / item.st).toFixed(2) + ' ٪ معادل ۱/' + 2 * item.st;
                        }
                    });
                } else if (
                    state16 === 0 &&
                    state15 === 0 &&
                    state9 === 0 &&
                    state10 === 0 &&
                    state13 === 0 &&
                    state14 === 0 &&
                    state17 === 0 &&
                    state18 === 0 &&
                    ((state7 >= 1 && state8 >= 1) || (state11 >= 1 && state12 >= 1))
                ) {
                    console.log('sevomi');
                    let total;
                    if (state7 >= 1 && state8 >= 1) {
                        total = state7 * 2 + state8;
                        result +=
                            ' و عموی ابوینی : ' +
                            (100 / total).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            total +
                            ' و عمه ابوینی : ' +
                            (50 / total).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            total * 2;
                    } else if (state11 >= 1 && state12 >= 1) {
                        total = state11 * 2 + state12;
                        result +=
                            ' و عموی ابی : ' +
                            (100 / total).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            total +
                            '\n' +
                            ' و عمه ابی : ' +
                            (50 / total).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            2 * total;
                    }
                } else if (
                    state9 === 0 &&
                    state7 === 0 &&
                    state8 === 0 &&
                    state11 === 0 &&
                    state12 === 0 &&
                    state10 === 0 &&
                    state13 === 0 &&
                    state14 === 0 &&
                    state17 === 0 &&
                    state18 === 0 &&
                    state15 + state16 >= 1
                ) {
                    console.log('charomi');
                    if (state15 + state16 >= 1) {
                        if (state15 !== 0) {
                            result +=
                                ' و عموی امی : ' +
                                (50 / (state15 + state16)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                (state15 + state16) * 2;
                        }
                        if (state16 !== 0) {
                            result +=
                                ' و عمه امی : ' +
                                (50 / (state15 + state16)).toFixed(2) +
                                ' ٪ معادل ۱/' +
                                (state15 + state16) * 2;
                        }
                    }
                } else if (
                    state15 >= 1 &&
                    state16 >= 1 &&
                    state9 === 0 &&
                    state10 === 0 &&
                    state13 === 0 &&
                    state14 === 0 &&
                    state17 === 0 &&
                    state18 === 0 &&
                    ((state7 >= 1 && state8 >= 1) || (state11 >= 1 && state12 >= 1))
                ) {
                    console.log('panjomi');
                    if (state15 >= 1 || state16 >= 1) {
                        const temp = state16 + state15;
                        result =
                            'عموی امی : ' +
                            (100 / (temp * 3)).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            3 * temp +
                            ' و عمه امی : ' +
                            (100 / (temp * 3)).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            3 * temp;
                    }
                    if (state7 >= 1 && state8 >= 1) {
                        const temp = state7 * 2 + state8;
                        result +=
                            ' و عموی ابوینی : ' +
                            (200 / (temp * 6)).toFixed(2) +
                            ' ٪ معادل ' +
                            1 +
                            '/' +
                            3 * temp +
                            '\n' +
                            ' و عمه ابوینی : ' +
                            (100 / (temp * 6)).toFixed(2) +
                            ' ٪ معادل ' +
                            1 +
                            '/' +
                            6 * temp +
                            '';
                    } else if (state11 >= 1 && state12 >= 1) {
                        const temp = state11 * 2 + state12;
                        result +=
                            ' و عموی ابی : ' +
                            (200 / (temp * 6)).toFixed(2) +
                            ' ٪ معادل ' +
                            1 +
                            '/' +
                            3 * temp +
                            '\n' +
                            ' و عمه ابی : ' +
                            (100 / (temp * 6)).toFixed(2) +
                            ' ٪ معادل ' +
                            1 +
                            '/' +
                            6 * temp +
                            '';
                    }
                } else if (
                    ((state15 === 1 && state16 === 0) || (state15 === 0 && state16 === 1)) &&
                    ((state7 >= 1 && state8 >= 1) || (state11 >= 1 && state12 >= 1)) &&
                    state9 === 0 &&
                    state10 === 0 &&
                    state13 === 0 &&
                    state14 === 0 &&
                    state17 === 0 &&
                    state18 === 0
                ) {
                    console.log('shishomi');
                    if (state15 === 1 && state16 === 0) {
                        result = 'عموی امی : ۱۶.۶۷ ٪ معادل ۱/۶\n' + '\n';
                    }
                    if (state16 === 1 && state15 === 0) {
                        result = 'عمه امی : ۱۶.۶۷ ٪ معادل ۱/۶\n' + '\n';
                    }
                    if (state7 >= 1 && state8 >= 1) {
                        const temp = state7 * 2 + state8;
                        result +=
                            ' و عموی ابوینی : ' +
                            (200 / (temp * 3)).toFixed(2) +
                            ' ٪ معادل ' +
                            2 +
                            '/' +
                            3 * temp +
                            '\n' +
                            ' و عمه ابوینی : ' +
                            (100 / (temp * 3)).toFixed(2) +
                            ' ٪ معادل ' +
                            1 +
                            '/' +
                            3 * temp +
                            '';
                    } else if (state11 >= 1 && state12 >= 1) {
                        const temp = state11 * 2 + state12;
                        result +=
                            ' و عموی ابی : ' +
                            (200 / (temp * 3)).toFixed(2) +
                            ' ٪ معادل ' +
                            2 +
                            '/' +
                            3 * temp +
                            '\n' +
                            ' و عمه ابی : ' +
                            (100 / (temp * 3)).toFixed(2) +
                            ' ٪ معادل ' +
                            1 +
                            '/' +
                            3 * temp +
                            '';
                    }
                } else if (
                    (state9 >= 1 &&
                        state10 >= 1 &&
                        state17 === 0 &&
                        state18 === 0 &&
                        state15 === 0 &&
                        state16 === 0 &&
                        state14 === 0 &&
                        state7 === 0 &&
                        state8 === 0 &&
                        state13 === 0 &&
                        state12 === 0 &&
                        state11 === 0) ||
                    (state13 >= 1 &&
                        state14 >= 1 &&
                        state9 === 0 &&
                        state10 === 0 &&
                        state17 === 0 &&
                        state18 === 0 &&
                        state15 === 0 &&
                        state16 === 0 &&
                        state7 === 0 &&
                        state8 === 0 &&
                        state12 === 0 &&
                        state11 === 0) ||
                    (state17 >= 1 &&
                        state18 >= 1 &&
                        state13 === 0 &&
                        state14 === 0 &&
                        state9 === 0 &&
                        state10 === 0 &&
                        state15 === 0 &&
                        state16 === 0 &&
                        state7 === 0 &&
                        state8 === 0 &&
                        state12 === 0 &&
                        state11 === 0)
                ) {
                    console.log('haftomi');
                    if (state9 >= 1 && state10 >= 1) {
                        const temp = state9 + state10;
                        result =
                            ' و دایی ابوینی : ' +
                            (50 / temp).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp * 2 +
                            '\n' +
                            ' و خاله ابوینی : ' +
                            (50 / temp).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp * 2 +
                            '';
                    }
                    if (state13 >= 1 && state14 >= 1) {
                        const temp = state13 + state14;
                        result +=
                            ' و دایی ابی : ' +
                            (50 / temp).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp * 2 +
                            '\n' +
                            ' و خاله ابی : ' +
                            (50 / temp).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp * 2 +
                            '';
                    }
                    if (state17 >= 1 && state18 >= 1) {
                        const temp = state17 + state18;
                        result +=
                            ' و دایی امی : ' +
                            (50 / temp).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp * 2 +
                            '\n' +
                            ' و خاله امی : ' +
                            (50 / temp).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            temp * 2;
                    }
                } else if (
                    state13 + state14 + state17 + state18 + state8 + state7 + state11 + state12 + state15 + state16 ===
                        0 ||
                    (state13 + state14 > 1 &&
                        state10 +
                            state9 +
                            state17 +
                            state18 +
                            state8 +
                            state7 +
                            state11 +
                            state12 +
                            state15 +
                            state16 ===
                            0) ||
                    (state17 + state18 > 1 &&
                        state13 +
                            state14 +
                            state9 +
                            state10 +
                            state8 +
                            state7 +
                            state11 +
                            state12 +
                            state15 +
                            state16 ===
                            0)
                ) {
                    console.log('hashtomi');
                    if (state9 + state10 > 1) {
                        const temp = state10 + state9;
                        if (state9 !== 0) {
                            result += ' و هر دایی ابوینی : ' + (50 / temp).toFixed(2) + ' ٪ معادل ۱/' + 2 * temp;
                        }
                        if (state10 !== 0) {
                            result += ' و هر خاله ابوینی : ' + (50 / temp).toFixed(2) + ' ٪ معادل ۱/' + 2 * temp;
                        }
                    } else if (state13 + state14 > 1) {
                        const temp = state13 + state14;
                        if (state13 !== 0) {
                            result += ' و هر دایی ابی : ' + (50 / temp).toFixed(2) + ' ٪ معادل ۱/' + 2 * temp;
                        }
                        if (state14 !== 0) {
                            result += ' و هر خاله ابی : ' + (50 / temp).toFixed(2) + ' ٪ معادل ۱/' + 2 * temp;
                        }
                    } else if (state17 + state18 > 1) {
                        const temp = state17 + state18;
                        if (state17 !== 0) {
                            result += ' و هر دایی امی : ' + (50 / temp).toFixed(2) + ' ٪ معادل ۱/' + 2 * temp;
                        }
                        if (state18 !== 0) {
                            result += ' و هر خاله امی : ' + (50 / temp).toFixed(2) + ' ٪ معادل ۱/' + 2 * temp;
                        }
                    }
                } else if (
                    state17 + state18 > 1 &&
                    (state9 + state10 > 1 || state14 + state13 > 1) &&
                    state7 === 0 &&
                    state8 === 0 &&
                    state11 === 0 &&
                    state12 === 0 &&
                    state15 === 0 &&
                    state16 === 0
                ) {
                    console.log('nohomi');
                    if (state17 + state18 > 1) {
                        const temp = state17 + state18;
                        if (state17 !== 0) {
                            result += ' و هر دایی امی : ' + (100 / (temp * 3)).toFixed(2) + ' ٪ معادل ۱/' + temp * 3;
                        }
                        if (state18 !== 0) {
                            result += ' و هر خاله امی : ' + (100 / (temp * 3)).toFixed(2) + ' ٪ معادل ۱/' + temp * 3;
                        }
                    }
                    if (state9 + state10 > 1) {
                        const temp = state10 + state9;
                        if (state9 !== 0) {
                            result += ' و هر دایی ابوینی : ' + (50 / (temp * 3)).toFixed(2) + ' ٪ معادل ۱/' + temp * 6;
                        }
                        if (state10 !== 0) {
                            result += ' و هر خاله ابوینی : ' + (50 / (temp * 3)).toFixed(2) + ' ٪ معادل ۱/' + temp * 6;
                        }
                    } else if (state13 + state14 > 1) {
                        const temp = state13 + state14;
                        if (state13 !== 0) {
                            result += ' و هر دایی ابی : ' + (50 / (temp * 3)).toFixed(2) + ' ٪ معادل ۱/' + temp * 6;
                        }
                        if (state14 !== 0) {
                            result += ' و هر خاله ابی : ' + (50 / (temp * 3)).toFixed(2) + ' ٪ معادل ۱/' + temp * 6;
                        }
                    }
                } else if (
                    ((state17 === 1 && state18 === 0) || (state17 === 0 && state18 === 1)) &&
                    (state9 + state10 > 1 || state13 + state14 > 1) &&
                    state7 === 0 &&
                    state8 === 0 &&
                    state11 === 0 &&
                    state12 === 0 &&
                    state15 === 0 &&
                    state16 === 0
                ) {
                    console.log('dahomi');
                    if (state17 !== 0) {
                        result += ' و دایی امی : ' + (100 / 6).toFixed(2) + ' ٪ معادل ۱/' + 6;
                    }
                    if (state18 !== 0) {
                        result += ' و خاله امی : ' + (100 / 6).toFixed(2) + ' ٪ معادل ۱/' + 6;
                    }
                    if (state9 + state10 > 1) {
                        const temp = state10 + state9;
                        if (state9 !== 0) {
                            result += ' و هر دایی ابوینی : ' + (100 / (temp * 3)).toFixed(2) + ' ٪ معادل ۱/' + temp * 3;
                        }
                        if (state10 !== 0) {
                            result += ' و هر خاله ابوینی : ' + (100 / (temp * 3)).toFixed(2) + ' ٪ معادل ۱/' + temp * 3;
                        }
                    } else if (state13 + state14 > 1) {
                        const temp = state13 + state14;
                        if (state13 !== 0) {
                            result += ' و هر دایی ابی : ' + (100 / (temp * 3)).toFixed(2) + ' ٪ معادل ۱/' + temp * 3;
                        }
                        if (state14 !== 0) {
                            result += ' و هر خاله ابی : ' + (100 / (temp * 3)).toFixed(2) + ' ٪ معادل ۱/' + temp * 3;
                        }
                    }
                } else {
                    console.log('yazdahomi');
                    if (level3.filter((item) => item.ref === 'اخوال' && item.st > 0)) {
                        //اخوال
                        if (
                            level3
                                .filter((item) => item.ref === 'اخوال' && item.st > 0)
                                .filter((res) => res.label === 'امی').length === 1
                        ) {
                            if (state17 === 1 && state18 === 0) {
                                result += ' و دایی امی : ۵.۵۶ ٪ معادل ۱/۱۸';
                            } else if (state17 === 0 && state18 === 1) {
                                result += ' و خاله امی : ۵.۵۶ ٪ معادل ۱/۱۸';
                            }
                            if (state9 + state10 > 0) {
                                const temp = state9 + state10;
                                if (state9 !== 0) {
                                    result +=
                                        ' و دایی ابوینی : ' +
                                        (500 / (18 * temp)).toFixed(2) +
                                        ' ٪ معادل ' +
                                        5 +
                                        '/' +
                                        18 * temp;
                                }
                                if (state10 !== 0) {
                                    result +=
                                        ' و خاله ابوینی : ' +
                                        (500 / (18 * temp)).toFixed(2) +
                                        ' ٪ معادل ' +
                                        5 +
                                        '/' +
                                        18 * temp;
                                }
                            } else if (state13 + state14 > 0 && state9 + state10 === 0) {
                                const temp = state13 + state14;
                                if (state13 !== 0) {
                                    result +=
                                        ' و دایی ابی : ' +
                                        (500 / (18 * temp)).toFixed(2) +
                                        ' ٪ معادل ' +
                                        5 +
                                        '/' +
                                        18 * temp;
                                }
                                if (state14 !== 0) {
                                    result +=
                                        ' و خاله ابی : ' +
                                        (500 / (18 * temp)).toFixed(2) +
                                        ' ٪ معادل ' +
                                        5 +
                                        '/' +
                                        18 * temp;
                                }
                            }
                        } else if (
                            level3
                                .filter((item, index) => item.ref === 'اخوال' && item.st > 0)
                                .filter((res) => res.label === 'امی').length > 1
                        ) {
                            if (state17 + state18 > 0) {
                                const temp = state17 + state18;
                                if (state17 !== 0) {
                                    result +=
                                        ' و دایی امی : ' + (100 / (9 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 9 * temp;
                                }
                                if (state18 !== 0) {
                                    result +=
                                        ' و خاله امی : ' + (100 / (9 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 9 * temp;
                                }
                            }
                            if (state9 + state10 > 0 && state13 + state14 > 0) {
                                const temp = state9 + state10;
                                if (state9 !== 0) {
                                    result +=
                                        ' و دایی ابوینی : ' +
                                        (200 / (9 * temp)).toFixed(2) +
                                        ' ٪ معادل ' +
                                        2 +
                                        '/' +
                                        9 * temp;
                                }
                                if (state10 !== 0) {
                                    result +=
                                        ' و خاله ابوینی : ' +
                                        (200 / (9 * temp)).toFixed(2) +
                                        ' ٪ معادل ' +
                                        2 +
                                        '/' +
                                        9 * temp;
                                }
                            } else if (state13 + state14 > 0 && state9 + state10 === 0) {
                                const temp = state13 + state14;
                                if (state13 !== 0) {
                                    result +=
                                        ' و دایی ابی : ' +
                                        (200 / (9 * temp)).toFixed(2) +
                                        ' ٪ معادل ' +
                                        2 +
                                        '/' +
                                        9 * temp;
                                }
                                if (state14 !== 0) {
                                    result +=
                                        ' و خاله ابی : ' +
                                        (200 / (9 * temp)).toFixed(2) +
                                        ' ٪ معادل ' +
                                        2 +
                                        '/' +
                                        9 * temp;
                                }
                            }
                        }
                    }
                    if (level3.filter((item, index) => item.ref === 'اعمام' && item.st > 0)) {
                        if (
                            level3
                                .filter((item, index) => item.ref === 'اعمام' && item.st > 0)
                                .filter((res) => res.label === 'امی').length === 1
                        ) {
                            if (state15 === 1 && state16 === 0) {
                                result += ' و عموی امی : ۲.۷۸ ٪ معادل ۱/۳۶';
                            } else if (state15 === 0 && state16 === 1) {
                                result += ' و عمه امی : ۲.۷۸ ٪ معادل ۱/۳۶';
                            }
                            if (state7 + state8 >= 1) {
                                const temp = state7 * 2 + state8;
                                if (state7 !== 0) {
                                    result +=
                                        ' و عموی ابوینی : ' +
                                        (500 / (18 * temp)).toFixed(2) +
                                        ' ٪ معادل ۵/' +
                                        18 * temp;
                                }
                                if (state8 !== 0) {
                                    result +=
                                        ' و عمه ابوینی : ' + (500 / (36 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 36 * temp;
                                }
                            } else if (state11 + state12 >= 1 && state7 + state8 === 0) {
                                const temp = state11 * 2 + state12;
                                if (state11 !== 0) {
                                    result +=
                                        ' و عموی ابی : ' + (500 / (18 * temp)).toFixed(2) + ' ٪ معادل ۱۰/' + 18 * temp;
                                }
                                if (state12 !== 0) {
                                    result +=
                                        ' و عمه ابی : ' + (500 / (36 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 36 * temp;
                                }
                            }
                        } else if (
                            level3
                                .filter((item, index) => item.ref === 'اعمام' && item.st > 0)
                                .filter((res) => res.label === 'امی').length > 1
                        ) {
                            if (state15 + state16 > 0) {
                                const temp = state15 + state16;
                                if (state15 !== 0) {
                                    result +=
                                        ' و عمو امی : ' + (50 / (9 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 18 * temp;
                                }
                                if (state16 !== 0) {
                                    result +=
                                        ' و عمه امی : ' + (50 / (9 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 18 * temp;
                                }
                            }
                            if (state7 + state8 >= 1) {
                                const temp = state7 * 2 + state8;
                                if (state7 !== 0) {
                                    result +=
                                        ' و عموی ابوینی : ' + (200 / (9 * temp)).toFixed(2) + ' ٪ معادل ۲/' + 9 * temp;
                                }
                                if (state8 !== 0) {
                                    result +=
                                        ' و عمه ابوینی : ' + (100 / (9 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 9 * temp;
                                }
                            } else if (state11 + state12 >= 1 && state7 + state8 === 0) {
                                const temp = state11 * 2 + state12;
                                if (state11 !== 0) {
                                    result +=
                                        ' و عموی ابی : ' + (200 / (9 * temp)).toFixed(2) + ' ٪ معادل ۲/' + 9 * temp;
                                }
                                if (state12 !== 0) {
                                    result +=
                                        ' و عمه ابی : ' + (100 / (9 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 9 * temp;
                                }
                            }
                        }
                    }
                }
            } else if (maritalStatus === 3) {
                console.log(4428);
                const wifeNum = wifeNumber <= 4 ? wifeNumber * 4 : 16;
                result += ' هر زوجه (زن) : ' + (100 / wifeNum).toFixed(2) + ' ٪ معادل ۱/' + wifeNum;
                if (level3.filter((item) => item.st >= 1).length === 1) {
                    level3.map((item, index) => {
                        if (item.st === 1) {
                            console.log('avali');
                            result += item.id + ' : ' + (75 / item.st).toFixed(2) + ' ٪ معادل ۳/' + 4 * item.st;
                        }
                        if (item.st > 1 && item.ref === 'اعمام') {
                            console.log('dovomi');
                            result +=
                                ' و هر ' + item.id + ' : ' + (75 / item.st).toFixed(2) + ' ٪ معادل ۳/' + 4 * item.st;
                        }
                    });
                } else if (
                    state16 === 0 &&
                    state15 === 0 &&
                    state9 === 0 &&
                    state10 === 0 &&
                    state13 === 0 &&
                    state14 === 0 &&
                    state17 === 0 &&
                    state18 === 0 &&
                    ((state7 >= 1 && state8 >= 1) || (state11 >= 1 && state12 >= 1))
                ) {
                    console.log('sevomi');
                    let total;
                    if (state7 >= 1 && state8 >= 1) {
                        total = state7 * 2 + state8;
                        result =
                            ' و عموی ابوینی : ' +
                            (150 / total).toFixed(2) +
                            ' ٪ معادل ۳/' +
                            total * 2 +
                            ' و عمه ابوینی : ' +
                            (75 / total).toFixed(2) +
                            ' ٪ معادل ۳/' +
                            total * 4;
                    } else if (state11 >= 1 && state12 >= 1) {
                        total = state11 * 2 + state12;
                        result +=
                            ' و عموی ابی : ' +
                            (150 / total).toFixed(2) +
                            ' ٪ معادل ۳/' +
                            total * 2 +
                            ' و عمه ابی : ' +
                            (75 / total).toFixed(2) +
                            ' ٪ معادل ۳/' +
                            total * 4 +
                            '';
                    }
                } else if (
                    state9 === 0 &&
                    state7 === 0 &&
                    state8 === 0 &&
                    state11 === 0 &&
                    state12 === 0 &&
                    state10 === 0 &&
                    state13 === 0 &&
                    state14 === 0 &&
                    state17 === 0 &&
                    state18 === 0 &&
                    state15 + state16 >= 1
                ) {
                    console.log('charomi');
                    if (state15 + state16 >= 1) {
                        if (state15 !== 0) {
                            result +=
                                ' و عموی امی : ' +
                                (75 / (state15 + state16)).toFixed(2) +
                                ' ٪ معادل ۳/' +
                                (state15 + state16) * 4;
                        }
                        if (state16 !== 0) {
                            result +=
                                ' و عمه امی : ' +
                                (75 / (state15 + state16)).toFixed(2) +
                                ' ٪ معادل ۳/' +
                                (state15 + state16) * 4;
                        }
                    }
                } else if (
                    state15 >= 1 &&
                    state16 >= 1 &&
                    state9 === 0 &&
                    state10 === 0 &&
                    state13 === 0 &&
                    state14 === 0 &&
                    state17 === 0 &&
                    state18 === 0 &&
                    ((state7 >= 1 && state8 >= 1) || (state11 >= 1 && state12 >= 1))
                ) {
                    console.log('panjomi');
                    if (state15 >= 1 || state16 >= 1) {
                        const temp = state16 + state15;
                        result =
                            'عموی امی : ' +
                            (100 / (temp * 3)).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            3 * temp +
                            ' و عمه امی : ' +
                            (100 / (temp * 3)).toFixed(2) +
                            ' ٪ معادل ۱/' +
                            3 * temp;
                    }
                    if (state7 >= 1 && state8 >= 1) {
                        const temp = state7 * 2 + state8;
                        result +=
                            ' و عموی ابوینی : ' +
                            (250 / (temp * 3)).toFixed(2) +
                            ' ٪ معادل ' +
                            5 +
                            '/' +
                            6 * temp +
                            '\n' +
                            ' و عمه ابوینی : ' +
                            (125 / (temp * 3)).toFixed(2) +
                            ' ٪ معادل ' +
                            5 +
                            '/' +
                            12 * temp +
                            '';
                    } else if (state11 >= 1 && state12 >= 1) {
                        const temp = state11 * 2 + state12;
                        result +=
                            ' و عموی ابی : ' +
                            (250 / (temp * 3)).toFixed(2) +
                            ' ٪ معادل ' +
                            5 +
                            '/' +
                            6 * temp +
                            '\n' +
                            ' و عمه ابی : ' +
                            (125 / (temp * 3)).toFixed(2) +
                            ' ٪ معادل ' +
                            5 +
                            '/' +
                            12 * temp +
                            '';
                    }
                } else if (
                    ((state15 === 1 && state16 === 0) || (state15 === 0 && state16 === 1)) &&
                    ((state7 >= 1 && state8 >= 1) || (state11 >= 1 && state12 >= 1)) &&
                    state9 === 0 &&
                    state10 === 0 &&
                    state13 === 0 &&
                    state14 === 0 &&
                    state17 === 0 &&
                    state18 === 0
                ) {
                    console.log('shishomi');
                    if (state15 === 1) {
                        result = 'عموی امی : ۱۶.۶۷ ٪ معادل ۱/۶\n' + '\n';
                    }
                    if (state16 === 1) {
                        result = 'عمه امی : ۱۶.۶۷ ٪ معادل ۱/۶\n' + '\n';
                    }
                    if (state7 >= 1 && state8 >= 1) {
                        const temp = state7 * 2 + state8;
                        result +=
                            ' و عموی ابوینی : ' +
                            (700 / (temp * 6)).toFixed(2) +
                            ' ٪ معادل ' +
                            7 +
                            '/' +
                            6 * temp +
                            '\n' +
                            ' و عمه ابوینی : ' +
                            (700 / (temp * 12)).toFixed(2) +
                            ' ٪ معادل ' +
                            7 +
                            '/' +
                            12 * temp +
                            '';
                    } else if (state11 >= 1 && state12 >= 1) {
                        const temp = state11 * 2 + state12;
                        result +=
                            ' و عموی ابی : ' +
                            (700 / (temp * 6)).toFixed(2) +
                            ' ٪ معادل ' +
                            7 +
                            '/' +
                            6 * temp +
                            '\n' +
                            ' و عمه ابی : ' +
                            (700 / (temp * 12)).toFixed(2) +
                            ' ٪ معادل ' +
                            7 +
                            '/' +
                            12 * temp +
                            '';
                    }
                } else if (
                    (state9 >= 1 &&
                        state10 >= 1 &&
                        state17 === 0 &&
                        state18 === 0 &&
                        state15 === 0 &&
                        state16 === 0 &&
                        state14 === 0 &&
                        state7 === 0 &&
                        state8 === 0 &&
                        state13 === 0 &&
                        state12 === 0 &&
                        state11 === 0) ||
                    (state13 >= 1 &&
                        state14 >= 1 &&
                        state9 === 0 &&
                        state10 === 0 &&
                        state17 === 0 &&
                        state18 === 0 &&
                        state15 === 0 &&
                        state16 === 0 &&
                        state7 === 0 &&
                        state8 === 0 &&
                        state12 === 0 &&
                        state11 === 0) ||
                    (state17 >= 1 &&
                        state18 >= 1 &&
                        state13 === 0 &&
                        state14 === 0 &&
                        state9 === 0 &&
                        state10 === 0 &&
                        state15 === 0 &&
                        state16 === 0 &&
                        state7 === 0 &&
                        state8 === 0 &&
                        state12 === 0 &&
                        state11 === 0)
                ) {
                    console.log('haftomi');
                    if (state9 >= 1 && state10 >= 1) {
                        const temp = state9 + state10;
                        result +=
                            ' و دایی ابوینی : ' +
                            (75 / temp).toFixed(2) +
                            ' ٪ معادل ۳/' +
                            temp * 4 +
                            '\n' +
                            ' و خاله ابوینی : ' +
                            (75 / temp).toFixed(2) +
                            ' ٪ معادل ۳/' +
                            temp * 4 +
                            '';
                    }
                    if (state13 >= 1 && state14 >= 1) {
                        const temp = state13 + state14;
                        result +=
                            ' و دایی ابی : ' +
                            (75 / temp).toFixed(2) +
                            ' ٪ معادل ۳/' +
                            temp * 4 +
                            '\n' +
                            ' و خاله ابی : ' +
                            (75 / temp).toFixed(2) +
                            ' ٪ معادل ۳/' +
                            temp * 4 +
                            '';
                    }
                    if (state17 >= 1 && state18 >= 1) {
                        const temp = state17 + state18;
                        result +=
                            ' و دایی امی : ' +
                            (75 / temp).toFixed(2) +
                            ' ٪ معادل ۳/' +
                            temp * 4 +
                            '\n' +
                            ' و خاله امی : ' +
                            (75 / temp).toFixed(2) +
                            ' ٪ معادل ۳/' +
                            temp * 4;
                    }
                } else if (
                    (state9 + state10 > 1 &&
                        state13 +
                            state14 +
                            state17 +
                            state18 +
                            state8 +
                            state7 +
                            state11 +
                            state12 +
                            state15 +
                            state16 ===
                            0) ||
                    (state13 + state14 > 1 &&
                        state10 +
                            state9 +
                            state17 +
                            state18 +
                            state8 +
                            state7 +
                            state11 +
                            state12 +
                            state15 +
                            state16 ===
                            0) ||
                    (state17 + state18 > 1 &&
                        state13 +
                            state14 +
                            state9 +
                            state10 +
                            state8 +
                            state7 +
                            state11 +
                            state12 +
                            state15 +
                            state16 ===
                            0)
                ) {
                    console.log('hashtomi');
                    if (state9 + state10 > 1) {
                        const temp = state10 + state9;
                        if (state9 !== 0) {
                            result = ' و هر دایی ابوینی : ' + (75 / temp).toFixed(2) + ' ٪ معادل ۳/' + 4 * temp;
                        }
                        if (state10 !== 0) {
                            result += ' و هر خاله ابوینی : ' + (75 / temp).toFixed(2) + ' ٪ معادل ۳/' + 4 * temp;
                        }
                    } else if (state13 + state14 > 1) {
                        const temp = state13 + state14;
                        if (state13 !== 0) {
                            result += ' و هر دایی ابی : ' + (75 / temp).toFixed(2) + ' ٪ معادل ۳/' + 4 * temp;
                        }
                        if (state14 !== 0) {
                            result += ' و هر خاله ابی : ' + (75 / temp).toFixed(2) + ' ٪ معادل ۳/' + 4 * temp;
                        }
                    } else if (state17 + state18 > 1) {
                        const temp = state17 + state18;
                        if (state17 !== 0) {
                            result += ' و هر دایی امی : ' + (75 / temp).toFixed(2) + ' ٪ معادل ۳/' + 4 * temp;
                        }
                        if (state18 !== 0) {
                            result += ' و هر خاله امی : ' + (75 / temp).toFixed(2) + ' ٪ معادل ۳/' + 4 * temp;
                        }
                    }
                } else if (
                    state17 + state18 > 1 &&
                    (state9 + state10 > 1 || state14 + state13 > 1) &&
                    state7 === 0 &&
                    state8 === 0 &&
                    state11 === 0 &&
                    state12 === 0 &&
                    state15 === 0 &&
                    state16 === 0
                ) {
                    console.log('nohomi');
                    if (state17 + state18 > 1) {
                        const temp = state17 + state18;
                        if (state17 !== 0) {
                            result += ' و هر دایی امی : ' + (100 / (temp * 3)).toFixed(2) + ' ٪ معادل ۱/' + temp * 3;
                        }
                        if (state18 !== 0) {
                            result += ' و هر خاله امی : ' + (100 / (temp * 3)).toFixed(2) + ' ٪ معادل ۱/' + temp * 3;
                        }
                    }
                    if (state9 + state10 > 1) {
                        const temp = state10 + state9;
                        if (state9 !== 0) {
                            result += ' و هر دایی ابوینی : ' + (75 / (temp * 3)).toFixed(2) + ' ٪ معادل ۵/' + temp * 12;
                        }
                        if (state10 !== 0) {
                            result += ' و هر خاله ابوینی : ' + (75 / (temp * 3)).toFixed(2) + ' ٪ معادل ۵/' + temp * 12;
                        }
                    } else if (state13 + state14 > 1) {
                        const temp = state13 + state14;
                        if (state13 !== 0) {
                            result += ' و هر دایی ابی : ' + (75 / (temp * 3)).toFixed(2) + ' ٪ معادل ۵/' + temp * 12;
                        }
                        if (state14 !== 0) {
                            result += ' و هر خاله ابی : ' + (75 / (temp * 3)).toFixed(2) + ' ٪ معادل ۵/' + temp * 12;
                        }
                    }
                } else if (
                    ((state17 === 1 && state18 === 0) || (state17 === 0 && state18 === 1)) &&
                    (state9 + state10 > 1 || state13 + state14 > 1) &&
                    state7 === 0 &&
                    state8 === 0 &&
                    state11 === 0 &&
                    state12 === 0 &&
                    state15 === 0 &&
                    state16 === 0
                ) {
                    console.log('dahomi');
                    if (state17 !== 0) {
                        result += ' و دایی امی : ' + (100 / 6).toFixed(2) + ' ٪ معادل ۱/' + 6;
                    }
                    if (state18 !== 0) {
                        result += ' و خاله امی : ' + (100 / 6).toFixed(2) + ' ٪ معادل ۱/' + 6;
                    }
                    if (state9 + state10 > 1) {
                        const temp = state10 + state9;
                        if (state9 !== 0) {
                            result +=
                                ' و هر دایی ابوینی : ' + (350 / (temp * 6)).toFixed(2) + ' ٪ معادل ۷/' + temp * 12;
                        }
                        if (state10 !== 0) {
                            result +=
                                ' و هر خاله ابوینی : ' + (350 / (temp * 6)).toFixed(2) + ' ٪ معادل ۷/' + temp * 12;
                        }
                    } else if (state13 + state14 > 1) {
                        const temp = state13 + state14;
                        if (state13 !== 0) {
                            result += ' و هر دایی ابی : ' + (350 / (temp * 6)).toFixed(2) + ' ٪ معادل ۷/' + temp * 12;
                        }
                        if (state14 !== 0) {
                            result += ' و هر خاله ابی : ' + (350 / (temp * 6)).toFixed(2) + ' ٪ معادل ۷/' + temp * 12;
                        }
                    }
                } else {
                    console.log('yazdahomi');
                    if (level3.filter((item) => item.ref === 'اخوال' && item.st > 0)) {
                        //اخوال
                        if (
                            level3
                                .filter((item) => item.ref === 'اخوال' && item.st > 0)
                                .filter((res) => res.label === 'امی').length === 1
                        ) {
                            if (state17 === 1 && state18 === 0) {
                                result += ' و دایی امی : ۵.۵۶ ٪ معادل ۱/۱۸';
                            } else if (state17 === 0 && state18 === 1) {
                                result += ' و خاله امی : ۵.۵۶ ٪ معادل ۱/۱۸';
                            }
                            if (state9 + state10 > 0) {
                                const temp = state9 + state10;
                                if (state9 !== 0) {
                                    result +=
                                        ' و دایی ابوینی : ' +
                                        (500 / (18 * temp)).toFixed(2) +
                                        ' ٪ معادل ' +
                                        5 +
                                        '/' +
                                        18 * temp;
                                }
                                if (state10 !== 0) {
                                    result +=
                                        ' و خاله ابوینی : ' +
                                        (500 / (18 * temp)).toFixed(2) +
                                        ' ٪ معادل ' +
                                        5 +
                                        '/' +
                                        18 * temp;
                                }
                            } else if (state13 + state14 > 0 && state9 + state10 === 0) {
                                const temp = state13 + state14;
                                if (state13 !== 0) {
                                    result +=
                                        ' و دایی ابی : ' +
                                        (500 / (18 * temp)).toFixed(2) +
                                        ' ٪ معادل ' +
                                        5 +
                                        '/' +
                                        18 * temp;
                                }
                                if (state14 !== 0) {
                                    result +=
                                        ' و خاله ابی : ' +
                                        (500 / (18 * temp)).toFixed(2) +
                                        ' ٪ معادل ' +
                                        5 +
                                        '/' +
                                        18 * temp;
                                }
                            }
                        } else if (
                            level3
                                .filter((item, index) => item.ref === 'اخوال' && item.st > 0)
                                .filter((res) => res.label === 'امی').length > 1
                        ) {
                            if (state17 + state18 > 0) {
                                const temp = state17 + state18;
                                if (state17 !== 0) {
                                    result +=
                                        ' و دایی امی : ' + (100 / (9 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 9 * temp;
                                }
                                if (state18 !== 0) {
                                    result +=
                                        ' و خاله امی : ' + (100 / (9 * temp)).toFixed(2) + ' ٪ معادل ۱/' + 9 * temp;
                                }
                            }
                            if (state9 + state10 > 0 && state13 + state14 > 0) {
                                const temp = state9 + state10;
                                if (state9 !== 0) {
                                    result +=
                                        ' و دایی ابوینی : ' +
                                        (200 / (9 * temp)).toFixed(2) +
                                        ' ٪ معادل ' +
                                        2 +
                                        '/' +
                                        9 * temp;
                                }
                                if (state10 !== 0) {
                                    result +=
                                        ' و خاله ابوینی : ' +
                                        (200 / (9 * temp)).toFixed(2) +
                                        ' ٪ معادل ' +
                                        2 +
                                        '/' +
                                        9 * temp;
                                }
                            } else if (state13 + state14 > 0 && state9 + state10 === 0) {
                                const temp = state13 + state14;
                                if (state13 !== 0) {
                                    result +=
                                        ' و دایی ابی : ' +
                                        (200 / (9 * temp)).toFixed(2) +
                                        ' ٪ معادل ' +
                                        2 +
                                        '/' +
                                        9 * temp;
                                }
                                if (state14 !== 0) {
                                    result +=
                                        ' و خاله ابی : ' +
                                        (200 / (9 * temp)).toFixed(2) +
                                        ' ٪ معادل ' +
                                        2 +
                                        '/' +
                                        9 * temp;
                                }
                            }
                        }
                    }
                    if (level3.filter((item, index) => item.ref === 'اعمام' && item.st > 0)) {
                        if (
                            level3
                                .filter((item, index) => item.ref === 'اعمام' && item.st > 0)
                                .filter((res) => res.label === 'امی').length === 1
                        ) {
                            if (state15 === 1 && state16 === 0) {
                                result += ' و عموی امی : ۶.۹۴ ٪ معادل ۵/۷۲';
                            } else if (state15 === 0 && state16 === 1) {
                                result += ' و عمه امی : ۶.۹۴ ٪ معادل ۵/۷۲';
                            }
                            if (state7 + state8 >= 1) {
                                const temp = state7 * 2 + state8;
                                if (state7 !== 0) {
                                    result +=
                                        ' و عموی ابوینی : ' +
                                        (625 / (9 * temp)).toFixed(2) +
                                        ' ٪ معادل ۲۵/' +
                                        36 * temp;
                                }
                                if (state8 !== 0) {
                                    result +=
                                        ' و عمه ابوینی : ' +
                                        (500 / (18 * temp)).toFixed(2) +
                                        ' ٪ معادل ۲۵/' +
                                        72 * temp;
                                }
                            } else if (state11 + state12 >= 1 && state7 + state8 === 0) {
                                const temp = state11 * 2 + state12;
                                if (state11 !== 0) {
                                    result +=
                                        ' و عموی ابی : ' + (625 / (9 * temp)).toFixed(2) + ' ٪ معادل ۲۵/' + 36 * temp;
                                }
                                if (state12 !== 0) {
                                    result +=
                                        ' و عمه ابی : ' + (500 / (18 * temp)).toFixed(2) + ' ٪ معادل ۲۵/' + 72 * temp;
                                }
                            }
                        } else if (
                            level3
                                .filter((item, index) => item.ref === 'اعمام' && item.st > 0)
                                .filter((res) => res.label === 'امی').length > 1
                        ) {
                            if (state15 + state16 > 0) {
                                const temp = state15 + state16;
                                if (state15 !== 0) {
                                    result +=
                                        ' و عمو امی : ' + (125 / (9 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 36 * temp;
                                }
                                if (state16 !== 0) {
                                    result +=
                                        ' و عمه امی : ' + (125 / (9 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 36 * temp;
                                }
                            }
                            if (state7 + state8 >= 1) {
                                const temp = state7 * 2 + state8;
                                if (state7 !== 0) {
                                    result +=
                                        ' و عموی ابوینی : ' + (500 / (9 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 9 * temp;
                                }
                                if (state8 !== 0) {
                                    result +=
                                        ' و عمه ابوینی : ' + (500 / (18 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 18 * temp;
                                }
                            } else if (state11 + state12 >= 1 && state7 + state8 === 0) {
                                const temp = state11 * 2 + state12;
                                if (state11 !== 0) {
                                    result +=
                                        ' و عموی ابی : ' + (500 / (9 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 9 * temp;
                                }
                                if (state12 !== 0) {
                                    result +=
                                        ' و عمه ابی : ' + (500 / (18 * temp)).toFixed(2) + ' ٪ معادل ۵/' + 18 * temp;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return result;
}
