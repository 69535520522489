import React, { useEffect, useRef, useState } from 'react';
import { HonorariumData98 } from './1398.data';
import { Button, CostPicker, IconButton, SingleDropdown, SingleDropdownOption } from 'vekalapp-react-utilities';
import './1398.style.scss';
import { Form7_1398 } from '../../../../interface';
import { enNumToFaNum } from '../../../../scripts/string';

const Year1398: React.FC = () => {
    const titleRef = useRef<any>();
    const rangeRef = useRef<any>();
    const crimeTypeRef = useRef<any>();
    const categoryRef = useRef<any>();
    const checkbox1Ref = useRef<any>();
    const checkbox2Ref = useRef<any>();
    const checkbox3Ref = useRef<any>();
    const checkbox4Ref = useRef<any>();
    const titleOption: SingleDropdownOption[] = [];
    const fightResultOptions: SingleDropdownOption[] = [];

    HonorariumData98.map((item, index) => {
        titleOption.push({ title: item.title, payload: item.id });
    });

    const [days, setDays] = useState<number>(0);
    const missionPlace = useRef<any>(undefined);
    const fightResultRef = useRef<any>(undefined);
    const [missionLocation, setMissionLocation] = useState<SingleDropdownOption>({ title: 'داخل استان', payload: 1 });
    const numberInputRef = useRef<any>(undefined);
    const [subject, setSubject] = useState<SingleDropdownOption>({ title: 'مالی', payload: 1 });
    const [extraParams, setExtraParams] = useState<number | undefined>(undefined);
    const [fightResult, setFightResult] = useState<SingleDropdownOption>({ title: 'هیچکدام', payload: 1 });
    const [range, setRange] = useState<SingleDropdownOption>({ title: 'حداقل', payload: 1 });
    const [category, setCategory] = useState<SingleDropdownOption>({
        title: 'مطلق دعاوی خانوادگی یا دعاوی مالی ناشی از زوجیت و همچنین امور حسبی',
        payload: 1,
    });
    const [selected, setSelected] = useState<any>(HonorariumData98.find((item) => item.title == titleOption[0].title));
    const [answer, setAnswer] = useState<string | number | undefined>(undefined);
    const [crimes, setCrimes] = useState<number>(0);
    const [crimeType, setCrimeType] = useState<SingleDropdownOption>({
        title: 'جرایم مستوجب مجازات های سلب حیات، قطع عضو، حبس ابد یا حبس تعزیری درجه یک',
        payload: 1,
    });

    function handleMissionLocation(e) {
        setMissionLocation(e);
    }

    function handleNumberInput(e) {
        setExtraParams(e);
    }

    function handleSubject(e) {
        setSubject(e);
        setSelected(HonorariumData98.find((item) => item.title == e.title));
    }

    function handleCalculate() {
        if (selected.param2 === Form7_1398.priceRequested) {
            if (extraParams) {
                setAnswer(
                    selected.calculate(
                        checkbox1Ref.current.checked,
                        checkbox2Ref.current.checked,
                        checkbox3Ref.current.checked,
                        days,
                        missionLocation.title === 'داخل استان',
                        fightResult.payload,
                        extraParams,
                    ),
                );
                responseRef.current?.scrollIntoView(true);
            } else {
                numberInputRef.current.setError(selected.param2 + ' وارد نشده است');
            }
        } else {
            if (selected.title === 'امور حسبی، دعاوی خانوادگی و غیرمالی') {
                setAnswer(
                    selected.calculate(
                        checkbox1Ref.current.checked,
                        checkbox2Ref.current.checked,
                        checkbox3Ref.current.checked,
                        days,
                        missionLocation.title === 'داخل استان',
                        category.payload,
                        range.payload,
                        fightResult.payload,
                    ),
                );
                responseRef.current?.scrollIntoView(true);
            } else if (selected.title === 'کیفری') {
                setAnswer(
                    selected.calculate(
                        checkbox1Ref.current.checked,
                        checkbox2Ref.current.checked,
                        checkbox3Ref.current.checked,
                        days,
                        missionLocation.title === 'داخل استان',
                        category.payload,
                        range.payload,
                        crimeType.payload,
                        crimes,
                    ),
                );
                responseRef.current?.scrollIntoView(true);
            } else if (selected.title === 'سایر') {
                if (category.payload === 5 && range.payload === 2) {
                    if (extraParams) {
                        setAnswer(
                            selected.calculate(
                                checkbox1Ref.current.checked,
                                checkbox2Ref.current.checked,
                                checkbox3Ref.current.checked,
                                days,
                                missionLocation.title === 'داخل استان',
                                category.payload,
                                range.payload,
                                extraParams,
                            ),
                        );
                        responseRef.current?.scrollIntoView(true);
                    } else {
                        numberInputRef.current.setError(Form7_1398.priceRequested + ' وارد نشده است');
                    }
                } else {
                    setAnswer(
                        selected.calculate(
                            checkbox1Ref.current.checked,
                            checkbox2Ref.current.checked,
                            checkbox3Ref.current.checked,
                            days,
                            missionLocation.title === 'داخل استان',
                            category.payload,
                            range.payload,
                        ),
                    );
                    responseRef.current?.scrollIntoView(true);
                }
            }
        }
    }

    selected &&
        selected.param1 !== null &&
        selected.param1.map((item) => {
            fightResultOptions.push({ title: item.title, payload: item.id });
        });

    function handleFightResult(e) {
        setFightResult(e);
    }

    function handleRange(e) {
        setRange(e);
    }

    function handleCategory(e) {
        setCategory(e);
        if (selected.title === 'کیفری') {
            if (e.payload < 3) {
                setCrimeType(selected.param5[e.payload - 1].options[0]);
            }
        }
    }

    function handleCrimeType(e) {
        setCrimeType(e);
    }

    const responseRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <div className="custom-row">
                <SingleDropdown
                    options={titleOption}
                    label="موضوع وکالت"
                    placeholder="موضوع وکالت را انتخاب کنید"
                    ref={titleRef}
                    onChange={(e) => handleSubject(e)}
                    value={subject}
                    background={'#fff'}
                />
            </div>
            {selected && selected.param4 !== null && (
                <div className="custom-row">
                    <SingleDropdown
                        options={selected && selected.param4}
                        label="دسته بندی"
                        placeholder="دسته بندی را انتخاب کنید"
                        ref={categoryRef}
                        onChange={(e) => handleCategory(e)}
                        value={selected && selected.param4[0]}
                        background={'#fff'}
                    />
                </div>
            )}

            {selected && selected.param5 !== null && category.payload < 3 && (
                <div className="custom-row">
                    <SingleDropdown
                        options={category.payload === 1 ? selected.param5[0].options : selected.param5[1].options}
                        label="نوع جرم"
                        placeholder="نوع جرم را انتخاب کنید"
                        ref={crimeTypeRef}
                        background={'#fff'}
                        onChange={(e) => handleCrimeType(e)}
                        value={category.payload === 1 ? selected.param5[0].options[0] : selected.param5[1].options[0]}
                    />
                </div>
            )}
            {selected && selected.param6 !== null && (
                <div className="custom-row align-items-center">
                    <div className="days">
                        <p className="days-title">تعداد جرایم اضافه :</p>
                        <div className="counter">
                            <IconButton onClick={() => setCrimes(crimes + 1)} color="blue">
                                <i className="material-icons">add</i>
                            </IconButton>
                            <p className="days-counter">{crimes.toLocaleString('fa')}</p>
                            <IconButton
                                onClick={() => (crimes >= 1 ? setCrimes(crimes - 1) : setCrimes(crimes))}
                                color="blue"
                            >
                                <i className="material-icons">remove</i>
                            </IconButton>
                        </div>
                    </div>
                </div>
            )}
            {selected && selected.param3 !== null && (
                <div className="custom-row">
                    <SingleDropdown
                        background={'#fff'}
                        options={selected && selected.param3}
                        label="محدوده تعرفه"
                        placeholder="محدوده تعرفه را انتخاب کنید"
                        ref={rangeRef}
                        onChange={(e) => handleRange(e)}
                        value={range}
                    />
                </div>
            )}
            {selected && selected.param1 !== null && (
                <div className="custom-row">
                    <SingleDropdown
                        options={fightResultOptions}
                        label="نتیجه دعوا"
                        placeholder="نتیجه دعوا را انتخاب کنید"
                        ref={fightResultRef}
                        onChange={(e) => handleFightResult(e)}
                        value={fightResult}
                        background={'#fff'}
                    />
                </div>
            )}
            <div className="custom-row align-items-center">
                <label className="conditions-container" htmlFor="checkbox1">
                    <input id="checkbox1" type="checkbox" ref={checkbox1Ref} className="conditions" />
                    وکیل پس از نقض رأی وکالت را به عهده گرفته است
                </label>
            </div>
            <div className="custom-row align-items-center">
                <label className="conditions-container" htmlFor="checkbox2">
                    <input id="checkbox2" type="checkbox" ref={checkbox2Ref} className="conditions" />
                    وکالت از نوع تسخیری یا معاضدتی است
                </label>
            </div>
            <div className="custom-row align-items-center">
                <label className="conditions-container" htmlFor="checkbox3">
                    <input id="checkbox3" type="checkbox" ref={checkbox3Ref} className="conditions" />
                    وکیل دارای گواهی وکالت تخصصی است
                </label>
            </div>
            {selected && selected.title !== 'سایر' && (
                <div className="custom-row align-items-center">
                    <label className="conditions-container" htmlFor="checkbox4">
                        <input id="checkbox4" type="checkbox" ref={checkbox4Ref} className="conditions" />
                        موضوع دعوا ورود، جلب یا اعتراض ثالث یا تقابل است
                    </label>
                </div>
            )}
            <div className="custom-row align-items-center">
                <div className="days">
                    <p className="days-title">فوق العاده مأموریت (روز) :</p>
                    <div className="counter">
                        <IconButton onClick={() => setDays(days + 1)} color="blue">
                            <i className="material-icons">add</i>
                        </IconButton>
                        <p className="days-counter">{days.toLocaleString('fa')}</p>
                        <IconButton onClick={() => (days >= 1 ? setDays(days - 1) : setDays(days))} color="blue">
                            <i className="material-icons">remove</i>
                        </IconButton>
                    </div>
                </div>
            </div>
            {days > 0 && (
                <div className="custom-row">
                    <SingleDropdown
                        options={[
                            { title: 'داخل استان', payload: 1 },
                            { title: 'خارج استان', payload: 2 },
                        ]}
                        label="محل ماموریت"
                        placeholder="محل ماموریت خود را انتخاب کنید"
                        ref={missionPlace}
                        onChange={(e) => handleMissionLocation(e)}
                        value={missionLocation}
                        background={'#fff'}
                    />
                </div>
            )}
            {((selected && selected.param2 !== Form7_1398.none) ||
                (selected && selected.title === 'سایر' && category.payload === 5 && range.payload === 2)) && (
                <div className="custom-row">
                    <CostPicker
                        ref={numberInputRef}
                        onInput={(e) => handleNumberInput(e)}
                        background={'#fff'}
                        label={Form7_1398.priceRequested}
                        placeholder={Form7_1398.priceRequested + ' را وارد کنید'}
                    />
                </div>
            )}
            <div
                className={
                    (subject.payload === 3 && days === 0) || (subject.payload === 3 && category.payload > 2)
                        ? 'custom-row align-items-center'
                        : 'custom-row'
                }
            >
                <Button
                    onClick={handleCalculate}
                    className={
                        (subject.payload === 1 && days === 0) ||
                        (subject.payload === 2 && days > 0) ||
                        (subject.payload === 3 && category.payload > 2 && days > 0) ||
                        (subject.payload === 4 && days > 0 && range.payload === 1) ||
                        (subject.payload === 4 && range.payload === 2 && days === 0 && category.payload === 5)
                            ? 'calculate activeStyle'
                            : 'calculate'
                    }
                >
                    محاسبه کنید!
                </Button>
            </div>
            <div className="custom-row d-none" ref={responseRef} />
            {answer && (
                <div className="answer">
                    {typeof answer === 'number' &&
                        `حق الوکاله ${enNumToFaNum(answer.toLocaleString().toString())} ریال می باشد.`}
                    {typeof answer === 'string' && `${enNumToFaNum(answer.toLocaleString().toString())}`}
                </div>
            )}
        </>
    );
};

export default Year1398;
