import { Form7_1398 } from '../../../../interface';

function baseFunction(extraParam?: number) {
    let answer = 0;
    if (extraParam && extraParam >= 0 && extraParam <= 3000000) {
        answer = 0.1 * extraParam;
    } else if (extraParam && extraParam > 3000000 && extraParam <= 500000000) {
        answer = extraParam * 0.08;
    } else if (extraParam && extraParam > 500000000 && extraParam <= 2000000000) {
        answer = 40000000 + (extraParam - 500000000) * 0.07;
    } else if (extraParam && extraParam > 2000000000 && extraParam <= 10000000000) {
        answer = 145000000 + (extraParam - 2000000000) * 0.05;
    } else if (extraParam && extraParam > 10000000000 && extraParam <= 30000000000) {
        answer = 545000000 + (extraParam - 10000000000) * 0.04;
    } else if (extraParam && extraParam > 30000000000) {
        answer = 1345000000 + (extraParam - 30000000000) * 0.03;
    }
    return answer;
}

export const HonorariumData98 = [
    {
        id: 1,
        title: 'مالی',
        param1: [
            {
                id: 1,
                title: 'هیچکدام',
            },
            {
                id: 2,
                title: 'قرار ابطال دادخواست پیش از پاسخ به دعوا و دفاع از آن',
            },
            {
                id: 3,
                title: 'قرار رد دادخواست پس از پاسخ به دعوا و دفاع از آن',
            },
            {
                id: 4,
                title: 'قرار سقوط دعوای تجدیدنظر پیش از پاسخ به دعوا و دفاع از آن',
            },
            {
                id: 5,
                title: 'قرار سقوط دعوای تجدیدنظر پس از پاسخ به دعوا و دفاع از آن',
            },
        ], //نتیجه دعوا
        param2: Form7_1398.priceRequested, //بهای خواسته یا محکومً به
        param3: null, //محدوده تعرفه
        param4: null, //دسته بندی
        param5: null, //نوع جرم
        param6: null, //تعداد جرایم اضافه
        calculate: (
            checkbox1: boolean,
            checkbox2: boolean,
            checkbox3: boolean,
            days: number,
            isInCity: boolean,
            fightResult: number,
            extraParam?: number,
        ) => {
            let answer;
            answer = baseFunction(extraParam);
            if (fightResult === 1) {
                answer *= 1;
            } else if (fightResult === 2) {
                answer *= 0.15;
            } else if (fightResult === 3) {
                answer *= 0.3;
            } else if (fightResult === 4) {
                answer *= 0.1;
            } else if (fightResult === 5) {
                answer *= 0.2;
            }
            answer = checkbox1 ? answer * 0.5 : answer;
            answer = checkbox2 ? answer * 2 : answer;
            answer = checkbox3 ? answer * 1.1 : answer;
            days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
            return answer;
        },
    },
    {
        id: 2,
        title: 'امور حسبی، دعاوی خانوادگی و غیرمالی',
        param1: [
            {
                id: 1,
                title: 'هیچکدام',
            },
            {
                id: 2,
                title: 'قرار ابطال دادخواست پیش از پاسخ به دعوا و دفاع از آن',
            },
            {
                id: 3,
                title: 'قرار رد دادخواست پس از پاسخ به دعوا و دفاع از آن',
            },
            {
                id: 4,
                title: 'قرار سقوط دعوای تجدیدنظر پیش از پاسخ به دعوا و دفاع از آن',
            },
            {
                id: 5,
                title: 'قرار سقوط دعوای تجدیدنظر پس از پاسخ به دعوا و دفاع از آن',
            },
        ], //نتیجه دعوا
        param2: Form7_1398.none, //بهای خواسته یا محکومً به
        param3: [
            {
                title: 'حداقل',
                payload: 1,
            },
            {
                title: 'حداکثر',
                payload: 2,
            },
        ], //محدوده تعرفه
        param4: [
            { title: 'مطلق دعاوی خانوادگی یا دعاوی مالی ناشی از زوجیت و همچنین امور حسبی', payload: 1 },
            { title: 'دعاوی که خواسته آنها مالی نیست یا تعیین بهای خواسته به موجب قانون لازم نیست', payload: 2 },
        ], //دسته بندی
        param5: null, //نوع جرم
        param6: null, //تعداد جرایم اضافه
        calculate: (
            checkbox1: boolean,
            checkbox2: boolean,
            checkbox3: boolean,
            days: number,
            isInCity: boolean,
            category: number,
            range: number,
            fightResult: number,
        ) => {
            let answer;
            if (category === 1 && range === 1) {
                answer = 5000000;
            } else if (category === 1 && range === 2) {
                answer = 200000000;
            } else if (category === 2 && range === 1) {
                answer = 4000000;
            } else if (category === 2 && range === 2) {
                answer = 300000000;
            }
            if (fightResult === 1) {
                answer *= 1;
            } else if (fightResult === 2) {
                answer *= 0.15;
            } else if (fightResult === 3) {
                answer *= 0.3;
            } else if (fightResult === 4) {
                answer *= 0.1;
            } else if (fightResult === 5) {
                answer *= 0.2;
            }
            if (checkbox2) {
                if (category === 1) {
                    answer = 10000000;
                    if (fightResult === 1) {
                        answer *= 1;
                    } else if (fightResult === 2) {
                        answer *= 0.15;
                    } else if (fightResult === 3) {
                        answer *= 0.3;
                    } else if (fightResult === 4) {
                        answer *= 0.1;
                    } else if (fightResult === 5) {
                        answer *= 0.2;
                    }
                } else if (category === 2) {
                    answer = 8000000;
                    if (fightResult === 1) {
                        answer *= 1;
                    } else if (fightResult === 2) {
                        answer *= 0.15;
                    } else if (fightResult === 3) {
                        answer *= 0.3;
                    } else if (fightResult === 4) {
                        answer *= 0.1;
                    } else if (fightResult === 5) {
                        answer *= 0.2;
                    }
                }
            }
            answer = checkbox1 ? answer * 0.5 : answer;
            answer = checkbox3 ? answer * 1.1 : answer;
            days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
            return answer;
        },
    },
    {
        id: 3,
        title: 'کیفری',
        param1: null, //نتیجه دعوا
        param2: Form7_1398.none, //بهای خواسته یا محکومً به
        param3: [
            {
                title: 'حداقل',
                payload: 1,
            },
            {
                title: 'حداکثر',
                payload: 2,
            },
        ], //محدوده تعرفه
        param4: [
            {
                title: 'جرایم داخل در صلاحیت رسیدگی دادگاههای کیفری یک، نظامی یک و دادگاه انقلاب',
                payload: 1,
            },
            {
                title: 'جرایم داخل در صلاحیت رسیدگی دادگاههای کیفری دو، نظامی دو و اطفال و نوجوانان',
                payload: 2,
            },
            {
                title: 'اعتراض به قرارهای قابل اعتراض دادسرا',
                payload: 3,
            },
            {
                title: 'فرجامخواهی کیفری، تقاضای اعاده دادرسی و تقاضای اعمال ماده ۴۷۷ آیین دادرسی کیفری',
                payload: 4,
            },
            {
                title: 'دعاوی که فقط وکلای تبصره ماده ۴۸ آیین دادرسی کیفری می توانند در آنها قبول وکالت نمایند',
                payload: 5,
            },
        ], //دسته بندی
        param5: [
            {
                id: 1,
                options: [
                    {
                        title: 'جرایم مستوجب مجازات های سلب حیات، قطع عضو، حبس ابد یا حبس تعزیری درجه یک',
                        payload: 1,
                    },
                    {
                        title: 'جرایم مستوجب مجازات های تعزیری درجه دو و سه',
                        payload: 2,
                    },
                    {
                        title: 'دیگر جرایم',
                        payload: 3,
                    },
                ],
            },
            {
                id: 2,
                options: [
                    {
                        title: 'جرایم مستوجب مجازات های حدود، دیات و تعزیری درجه چهار و پنج',
                        payload: 1,
                    },
                    {
                        title: 'جرایم مستوجب مجازات های تعزیری درجه شش',
                        payload: 2,
                    },
                    {
                        title: 'دیگر جرایم',
                        payload: 3,
                    },
                ],
            },
            {
                id: 3,
                options: null,
            },
            {
                id: 4,
                options: null,
            },
            {
                id: 5,
                options: null,
            },
        ], //نوع جرم
        param6: true, //تعداد جرایم اضافه
        calculate: (
            checkbox1: boolean,
            checkbox2: boolean,
            checkbox3: boolean,
            days: number,
            isInCity: boolean,
            category: number,
            range: number,
            crimeType: number,
            crimes: number,
        ) => {
            let answer;
            if (category === 1) {
                if (crimeType === 1) {
                    answer = range === 1 ? 50000000 : 2000000000;
                } else if (crimeType === 2) {
                    answer = range === 1 ? 30000000 : 1000000000;
                } else if (crimeType === 3) {
                    answer = range === 1 ? 10000000 : 500000000;
                }
            } else if (category === 2) {
                if (crimeType === 1) {
                    answer = range === 1 ? 10000000 : 500000000;
                } else if (crimeType === 2) {
                    answer = range === 1 ? 5000000 : 300000000;
                } else if (crimeType === 3) {
                    answer = range === 1 ? 2000000 : 200000000;
                }
            } else if (category === 3) {
                answer = range === 1 ? 1000000 : 100000000;
            } else if (category === 4) {
                answer = range === 1 ? 2000000 : 200000000;
            } else if (category === 5) {
                answer = range === 1 ? 5000000 : 500000000;
            }
            if (checkbox2) {
                if (category === 1) {
                    if (crimeType === 1) {
                        answer = 100000000;
                    } else if (crimeType === 2) {
                        answer = 60000000;
                    } else if (crimeType === 3) {
                        answer = 20000000;
                    }
                } else if (category === 2) {
                    if (crimeType === 1) {
                        answer = 20000000;
                    } else if (crimeType === 2) {
                        answer = 10000000;
                    } else if (crimeType === 3) {
                        answer = 4000000;
                    }
                } else if (category === 3) {
                    answer = 2000000;
                } else if (category === 4) {
                    answer = 4000000;
                } else if (category === 5) {
                    answer = 100000000;
                }
            }
            answer = checkbox1 ? answer * 0.5 : answer;
            answer = checkbox3 ? answer * 1.1 : answer;
            crimes !== 0 && (answer = answer + 0.2 * crimes * answer);
            days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
            return answer;
        },
    },
    {
        id: 4,
        title: 'سایر',
        param1: null,
        param2: Form7_1398.none, //بهای خواسته یا محکومً به
        param3: [
            {
                title: 'حداقل',
                payload: 1,
            },
            {
                title: 'حداکثر',
                payload: 2,
            },
        ], //محدوده تعرفه
        param4: [
            {
                title: 'اعتراض به قرارهای حقوقی قابل اعتراض',
                payload: 1,
            },
            {
                title:
                    'مربوط به دادسرا و دادگاه عالی انتظامی قضات و دادسرای دیوان عالی کشور و سایر دادسراها و دادگاه های انتظامی ',
                payload: 2,
            },
            {
                title: 'مربوط به دیوان عدالت اداری و سازمان تعزیرات حکومتی',
                payload: 3,
            },
            {
                title: 'مربوط به مراجع غیرقضایی مانند هیأت های موضوع قانون کار مصوب ۱۳۶۹',
                payload: 4,
            },
            {
                title:
                    'امور اجرایی در اجرای احکام دادگستری (حقوقی) و ادارات اجرای اسناد رسمی و لازم الاجرای سازمان ثبت اسناد و املاک کشور',
                payload: 5,
            },
            {
                title: 'امور اجرایی (دیگر موارد)',
                payload: 6,
            },
            {
                title: 'صرف مطالعه پرونده',
                payload: 7,
            },
            {
                title: 'دیگر مواردی که در آیین نامه تعیین تکلیف نشده است',
                payload: 8,
            },
        ], //دسته بندی
        param5: null, //نوع جرم
        param6: null, //تعداد جرایم اضافه
        calculate: (
            checkbox1: boolean,
            checkbox2: boolean,
            checkbox3: boolean,
            days: number,
            isInCity: boolean,
            category: number,
            range: number,
            extraParam?: number,
        ) => {
            let answer;
            if (category === 1) {
                answer = range === 1 ? 1000000 : 100000000;
            } else if (category === 2) {
                answer = range === 1 ? 0 : 20000000;
            } else if (category === 3) {
                answer = range === 1 ? 4000000 : 500000000;
            } else if (category === 4) {
                answer = range === 1 ? 4000000 : 200000000;
            } else if (category === 5) {
                if (range === 1) {
                    answer = 4000000;
                } else {
                    if (extraParam && extraParam >= 0 && extraParam <= 200000000) {
                        answer = 4000000;
                    } else if (extraParam && extraParam > 200000000) {
                        answer = 0.02 * extraParam;
                    }
                }
            } else if (category === 6) {
                answer = range === 1 ? 4000000 : 150000000;
            } else if (category === 7) {
                answer = range === 1 ? 2000000 : 50000000;
            } else if (category === 8) {
                answer = range === 1 ? 10000000 : 250000000;
            }

            if (checkbox2) {
                if (category === 1) {
                    answer = 2000000;
                } else if (category === 2) {
                    answer = 0;
                } else if (category === 3) {
                    answer = 8000000;
                } else if (category === 4) {
                    answer = 8000000;
                } else if (category === 5) {
                    answer = 8000000;
                } else if (category === 6) {
                    answer = 8000000;
                } else if (category === 7) {
                    answer = 4000000;
                } else if (category === 8) {
                    answer = 20000000;
                }
            }

            answer = checkbox1 ? answer * 0.5 : answer;
            answer = checkbox3 ? answer * 1.1 : answer;
            days !== 0 && (answer = isInCity ? answer + 1500000 * days : answer + 3000000 * days);
            return answer;
        },
    },
];
