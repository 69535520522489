export const CalculationForm = [
    {
        title: 'هزینه دادرسی',
        description:
            'آیا به دنبال محاسبه هزینه دادرسی برای دعاوی مالی ، غیر مالی یا سایر دعاوی مطورحه در دادگستری هستید؟ در وب سایت وکالپ می‌توانید به سادگی مبلغ دقیق هزینه دادرسی را برای پرونده خود محاسبه کنید. با اعتماد به هزینه دادرسی، می‌توانید به راحتی اقدامات لازم برای دفاع از حقوق خود را آغاز کنید. به همین منظور، با استفاده از روش محاسبه قانونی، هزینه دادرسی را برای هر دعوا به صورت دقیق و شفاف محاسبه می‌کنیم و به شما ارائه می‌دهیم. برای دیدن هزینه دادرسی در دعواهای مختلف، از فرم زیر استفاده نمایید.',
        buttons: [{ title: 'محاسبه کنید!', path: '/costofhearing' }],
        color: '#880E4F',
        icon: 'attach_money',
    },
    {
        title: 'حق الوکاله',
        description:
            'حق الوکاله، هزینه‌ای است که برای خدمات وکالتی پرداخت می‌شود و می‌تواند بر اساس نوع دعوای حقوقی و سطح سختی پرونده متفاوت باشد. در وب سایت ما، با توجه به قوانین و مقررات مربوطه، حق الوکاله را برای هر دعوای حقوقی به صورت دقیق و شفاف  بر اساس نوع  و میزان خواسته طبق  آئئین نامه های ارائه شده محاسبه و به شما ارائه می‌دهیم.',
        buttons: [{ title: 'محاسبه کنید!', path: '/honorarium' }],
        color: '#ba85c3',
        icon: 'gavel',
    },
    {
        title: 'تمبر مالیاتی',
        description:
            'تمبر مالیاتی، هزینه‌ای است که باید برای انجام اقدامات حقوقی و تجاری مختلف، مانند تنظیم قراردادهای تجاری و انجام تراکنش‌های مالی، پرداخت شود. در وب سایت ما، با استفاده از قوانین و مقررات مربوطه، تمبر مالیاتی را برای دعاوی مالی و غیر مالی و حق الوکاله به صورت دقیق و شفاف محاسبه می‌کنیم و به شما ارائه می‌دهیم. برای محاسبه مبلغ تمبر مالیاتی در دعاوی مختلف، از فرم زیر استفاده نمایید.',
        buttons: [{ title: 'محاسبه کنید!', path: '/taxstamp' }],
        color: '#69179c',
        icon: 'local_atm',
    },
    {
        title: 'خسارت تأخیر تأدیه',
        description:
            'وکالپ با بهره گیری از شاخص تورم بانک مرکزی و قوانین مربوطه، خدمات محاسبه خسارت تاخیر تادیه را ارائه می‌دهد. با استفاده از این خدمت، شما می‌توانید به سرعت و با دقت خسارت تاخیر در هر دعوا را محاسبه کنید و نتیجه نهایی را مشاهده نمائید این راهکار ساده و کارآمد، به شما امکان می‌دهد به طور کامل به مشکلات مربوط محاسبه تاخیر تادیه دیون خود پایان دهید.',
        buttons: [{ title: 'محاسبه کنید!', path: '/latepaymentdamage' }],
        color: '#4FC3F7',
        icon: 'trending_up',
    },
    {
        title: 'مهلت ها و مواعد',
        description:
            'با سرویس مهلت‌ها و مواعد وبسایت وکالپ، می‌توانید به سادگی و با دقت موعد و مهلت‌های مختلف را محاسبه کنید. این سرویس به شما امکان می‌دهد تا با وارد کردن تاریخ شروع ابلاغ و نوع مهلت ، تاریخ آن را محاسبه و حداکثر روز اقدام به همراه تاریخ آن را باری شما محاسبه می نماید.',
        buttons: [{ title: 'محاسبه کنید!', path: '/deadlines' }],
        color: '#1565C0',
        icon: 'schedule',
    },
    {
        title: 'مهریه به نرخ روز',
        description:
            'وبسایت وکالپ  با بهره گیری از آخرین نرخ‌های بازار، خدمات محاسبه مهریه را به شما ارائه می‌دهد. با وارد کردن مبلغ مهریه و تاریخ ازدواج، می‌توانید به سرعت و با دقت مهریه را بر اساس نرخ روز محاسبه کنید. همچنین، شما می‌توانید با استفاده از این سرویس، به طور مداوم نرخ روز مهریه را دنبال کرده وبا استفاده از این سرویس، به راحتی مشکلات مربوط به محاسبه مهریه را برطرف نمایید.',
        buttons: [{ title: 'محاسبه کنید!', path: '/dowry' }],
        color: '#00695C',
        icon: 'today',
    },
    {
        title: 'دفتر خدمات قضایی',
        description:
            'با استفاده از این ابزار، شما می توانید به راحتی هزینه های مربوط به خدمات قضایی الکترونیکی خود را محاسبه کنید و همچنین از هزینه های اضافی جلوگیری کنید. این نرم افزار به شما امکان می دهد تا با استفاده از سامانه های قضایی الکترونیکی میزان هزینه های خود را در هر دعوا به راحتی محاسبه نمایید.',
        buttons: [{ title: 'محاسبه کنید!', path: '/officecosts' }],
        color: '#9CCC65',
        icon: 'apartment',
    },
    {
        title: 'حق الزحمه داور',
        description:
            'با استفاده از این بخش محاسبه حق الزحمه داور شما می‌توانید به راحتی حق الزحمه داوری را برای پرونده خود براساس موازین قانونی به آسانی و سریع محاسبه نمایید.',
        buttons: [{ title: 'محاسبه کنید!', path: '/arbitratorwage' }],
        color: '#ffd243',
        icon: 'groups',
    },
    {
        title: 'دستمزد کارشناس',
        description:
            'با استفاده از این ابزار به راحتی میزان هزینه کارشناس را در دعاوی گوناگون بر اساس آئین نامه تعرفه کارشناسی در بخش های مختلف کارشناسی محاسبه نمایید.',
        buttons: [{ title: 'محاسبه کنید!', path: '/expertwage' }],
        color: '#FF5722',
        icon: 'straighten',
    },
    {
        title: 'محاسبه دیه',
        description:
            'با استفاده از این ابزار، محاسبه دیه به آسانی و با تنها چند کلیک انجام می‌شود. این ابزار قابلیت محاسبه پیچیده دیه را به شما می‌دهد و نتیجه محاسبه میزان دیه را به روز می‌کند. به عبارت دیگر، با استفاده از این ابزار، شما به راحتی و با سرعت بالا، می‌توانید محاسبات دیه را به انجام برسانید و نتیجه نهایی را به دست آورید.',
        buttons: [{ title: 'محاسبه کنید!', path: '/bloodmoney' }],
        color: '#795548',
        icon: 'healing',
    },
    {
        title: 'هزینه اجرای احکام ',
        description:
            'استفاده از ابزار محاسبه هزینه اجرای احکام  وکالپ ، می‌توانید به سرعت و با دقت، مبلغ مورد نیاز خود را محاسبه کنید.',
        buttons: [{ title: 'محاسبه کنید!', path: '/fivepercent' }],
        color: '#BDBDBD',
        icon: 'pie_chart',
    },
    {
        title: 'محاسبه ارث',
        description:
            'با وب سایت وکالپ، محاسبه ارث دیگر یک مسئله پیچیده نیست بلکه بسیار آسان است. با استفاده از ابزار محاسبه ارث ، می‌توانید به سرعت و با دقت، سهم الارث  مورد نظر خود را محاسبه کنید است.',
        buttons: [{ title: 'محاسبه کنید!', path: '/inheritance' }],
        color: '#78909C',
        icon: 'family_restroom',
    },
];
