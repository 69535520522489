const diyatRate: { id: number; name: string; crimes: { title: string; amount: string | number; sex: boolean }[] }[] = [
    {
        id: 1,
        name: 'استخوان',
        crimes: [
            {
                title: 'شکستن استخوان هر عضو',
                amount: 'یک پنجم دیه آن عضو',
                sex: false,
            },
            {
                title: 'شکستن استخوان هر عضو اگر بدون عیب درمان شود',
                amount: 'چهار پنجم دیه شکستن آن',
                sex: false,
            },
            {
                title: 'خرد شدن اسخوان هر عضو',
                amount: 'یک سوم دیه آن عضو',
                sex: false,
            },
            {
                title: 'خرد شدن اسخوان هر عضو اگر بدون عیب درمان شود',
                amount: 'چهار پنجم دیه خرد شدن آن استخوان',
                sex: false,
            },
            {
                title: 'ترک برداشتن استخوان هر عضو',
                amount: 'چهار پنجم دیه شکستن آن عضو',
                sex: false,
            },
            {
                title: 'جراحتی که به استخوان نفوذ کند بدون آنکه موجب شکستگی آن گردد و نیز دیه موضحه آن',
                amount: 'یک چهارم دیه شکستگی آن عضو',
                sex: false,
            },
            {
                title: 'دررفتگی استخوان از مفصل، درصورتی که موجب شلل یا از کارافتادگی کامل عضو نگردد',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'دررفتگی استخوان از مفصل، درصورتی که موجب شلل یا از کارافتادگی کامل عضو گردد',
                amount: 'موجب دو سوم دیه همان عضو',
                sex: false,
            },
            {
                title: 'دررفتگی استخوان از مفصل در صورت درمان بدون عیب',
                amount: 'موجب چهار پنجم از دو سوم دیه آن عضو می باشد',
                sex: false,
            },
            {
                title: 'در اثر جنایتی تکه کوچکی از استخوان از آن جدا شود',
                amount: 'دیه شکستگی ثابت است',
                sex: false,
            },
        ],
    },
    {
        id: 2,
        name: 'مو',
        crimes: [
            {
                title: 'کندن و یا از بین بردن تمام موي سر یا ریش مرد، در صورتی که دیگر نروید',
                amount: 1,
                sex: false,
            },
            {
                title: 'کندن و یا از بین بردن تمام ریش مرد، در صورتی که دوباره بروید',
                amount: 1,
                sex: false,
            },
            {
                title: 'کندن و یا از بین بردن تمام موي سر زن، در صورتی که دیگر نروید',
                amount: 1 / 2,
                sex: false,
            },
            {
                title: 'کندن و یا از بین بردن تمام موي سر زن، در صورتی که دوباره بروید',
                amount: 'موجب مهر المثل است',
                sex: false,
            },
            {
                title: 'قسمتی از موي سر مرد یا زن یا ریش مرد طوري از بین برود که دیگر نروید',
                amount: 'پرداخت دیه به نسبت',
                sex: false,
            },
            {
                title: 'قسمتی از موي سر مرد از بین برود و دوباره بروید',
                amount: 'پرداخت ارش',
                sex: false,
            },
            {
                title: 'قسمتی از ریش مرد از بین برود و دوباره بروید',
                amount: 1 / 3,
                sex: false,
            },
            {
                title: 'قسمتی از موی سر زن از بین برود و دوباره بروید',
                amount: 'به همان نسبت از مهر المثل',
                sex: false,
            },
            {
                title: 'کندن یا از بین بردن هر یک از ابروها بدون روییدن',
                amount: 1 / 4,
                sex: true,
            },
            {
                title: 'کندن یا از بین بردن هر یک از ابروها همراه با روییدن',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'هرگاه موی سر مرد یا زن یا ریش مرد که از بین رفته است با عیب و نقص بروید',
                amount: 'ارش ثابت است',
                sex: false,
            },
        ],
    },
    {
        id: 3,
        name: 'چشم',
        crimes: [
            {
                title: 'درآوردن یا از بین بردن دو چشم بینا',
                amount: 1,
                sex: true,
            },
            {
                title: 'درآوردن یا از بین بردن یک چشم بینا',
                amount: 1 / 2,
                sex: true,
            },
            {
                title: 'از بین بردن مجموع چهار پلک دو چشم',
                amount: 1,
                sex: true,
            },
            {
                title: 'از بین بردن هر یک از پلک های بالا',
                amount: 0.1666666666666667,
                sex: false,
            },
            {
                title: 'از بین بردن هر یک از پلک های پایین',
                amount: 0.25,
                sex: false,
            },
        ],
    },
    {
        id: 4,
        name: 'بینی',
        crimes: [
            {
                title: 'قطع کردن یا از بین بردن تمام بینی یا نرمه ای که پایین استخوان بینی است',
                amount: 1,
                sex: true,
            },
            {
                title: 'شکستن استخوان بینی در صورتی که موجب فساد بینی و از بین رفتن آن شود',
                amount: 1,
                sex: true,
            },
            {
                title: 'شکستن استخوان بینی در صورتی که بدون عیب و نقص اصلاح شود',
                amount: 1,
                sex: false,
            },
            {
                title: 'شکستن استخوان بینی در صورتی که با عیب و نقص بهبود پیدا کند',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'از بین بردن هر یک از پره های بینی یا پرده میان دو سوراخ',
                amount: 0.33,
                sex: true,
            },
            {
                title: 'فلج کردن بینی',
                amount: 0.6666666666666667,
                sex: true,
            },
            {
                title: 'از بین بردن بینی فلج',
                amount: 0.33,
                sex: true,
            },
            {
                title:
                    'سوراخ کردن هر دو طرف بینی و پرده فاصل میان آن خواه با پارگی همراه باشد خواه نباشد در صورتی که باعث از بین رفتن بینی یا نوک آن نشود',
                amount: 0.33,
                sex: true,
            },
            {
                title:
                    'سوراخ کردن هر دو طرف بینی و پرده فاصل میان آن خواه با پارگی همراه باشد خواه نباشد در صورتی که باعث از بین رفتن بینی یا نوک آن نشود و بهبود یابد',
                amount: 0.2,
                sex: true,
            },
            {
                title: 'سوراخ کردن یک طرف بینی در صورتی که بهبود نیابد',
                amount: 0.11,
                sex: false,
            },
            {
                title: 'سوراخ کردن یک طرف بینی در صورتی که بهبود یابد',
                amount: 0.05,
                sex: false,
            },
            {
                title: 'سوراخ کردن یک طرف بینی با پرده وسط آن در صورتی که بهبود نیابد',
                amount: 0.22,
                sex: false,
            },
            {
                title: 'سوراخ کردن یک طرف بینی با پرده وسط آن در صورتی که بهبود یابد',
                amount: 0.666666666666667,
                sex: false,
            },
            {
                title: 'دیه پاره کردن بینی در صورتی که سب از بین رفتن بینی یا نوک آن نشود',
                amount: 0.33,
                sex: true,
            },
            {
                title: 'دیه پاره کردن بینی در صورتی که سب از بین رفتن بینی یا نوک آن نشود و بهبود یابد',
                amount: 0.1,
                sex: true,
            },
            {
                title: 'از بین بردن نوک بینی که محل چکیدن خون است',
                amount: 0.5,
                sex: true,
            },
        ],
    },
    {
        id: 5,
        name: 'لاله گوش',
        crimes: [
            {
                title: 'از بین بردن دو لاله گوش',
                amount: 1,
                sex: true,
            },
            {
                title: 'از بین بردن یک لاله گوش',
                amount: 0.5,
                sex: true,
            },
            {
                title: 'از بین بردن نرمه لاله گوش',
                amount: 0.1666666666666667,
                sex: false,
            },
            {
                title: 'پاره کردن لاله یک گوش',
                amount: 0.1666666666666667,
                sex: false,
            },
            {
                title: 'پاره کردن نرمه یک گوش',
                amount: 0.11,
                sex: false,
            },
            {
                title: 'پاره کردن لاله یا نرمه یک گوش همراه با بهبودی',
                amount: 'ارش ثابت است',
                sex: false,
            },
            {
                title: 'فلج کردن لاله هر گوش',
                amount: 0.6666666666666667,
                sex: true,
            },
            {
                title: 'بریدن لاله گوش فلج شده',
                amount: 0.33,
                sex: true,
            },
            {
                title: 'قطع شدن لاله گوش که استخوان زیر آن ظاهر گردد',
                amount: 'دیه موضحه علاوه بر دیه لاله گوش',
                sex: false,
            },
            {
                title: 'پاره کردن پرده گوش',
                amount: 'موجب ارش است',
                sex: false,
            },
        ],
    },
    {
        id: 6,
        name: 'لب',
        crimes: [
            {
                title: 'از بین بردن دو لب',
                amount: 1,
                sex: true,
            },
            {
                title: 'از بین بردن یک لب',
                amount: 0.5,
                sex: true,
            },
            {
                title: 'از بین بردن مقداری از لب',
                amount: 'به نسبت تمام لب',
                sex: false,
            },
            {
                title: 'جنایتی که باعث جمع شدن یک یا دو لب و یا قسمتی از آن گردد',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'جنایتی که موجب سست و فلج شدن هر یک از لب ها گردد',
                amount: 'موجب یک سوم دیه آن است',
                sex: false,
            },
            {
                title: 'از بین بردن لب سست و فلج شده',
                amount: 0.33,
                sex: true,
            },
            {
                title: 'شکافتن هر دو لب به نحوی که باعث نمایان شدن دندان ها شود',
                amount: 0.33,
                sex: true,
            },
            {
                title: 'شکافتن هر دو لب به نحوی که باعث نمایان شدن دندان ها شود همراه با بهبودی بدون عیب',
                amount: 0.2,
                sex: false,
            },
            {
                title: 'شکافتن یک لب',
                amount: 0.1666666666666667,
                sex: false,
            },
            {
                title: 'شکافتن یک لب همراه با بهبودی بدون عیب',
                amount: 0.1,
                sex: false,
            },
        ],
    },
    {
        id: 7,
        name: 'زبان',
        crimes: [
            {
                title: 'قطع و یا از بین بردن تمام زبان گویا',
                amount: 1,
                sex: true,
            },
            {
                title: 'قطع و یا از بین بردن قسمتی از زبان گویا',
                amount: 'به نسبت گویایی از بین رفته',
                sex: false,
            },
            {
                title: 'قطع و از بین بردن تمام زبان لال',
                amount: 0.33,
                sex: true,
            },
            {
                title: 'قطع و از بین بردن تمام قسمتی از زبان لال',
                amount: 'به نسبت مساحت تمام زبان',
                sex: false,
            },
            {
                title: 'قطع و از بین بردن تمام زبان کودکی که زمان سخن گفتن او فرا نرسیده',
                amount: 1,
                sex: true,
            },
            {
                title: 'قطع کردن قسمتی از زبان کودکی که زمان سخن گفتن او فرا نرسیده است',
                amount: 'به نسبت مساحت قطع شده',
                sex: false,
            },
        ],
    },
    {
        id: 8,
        name: 'دندان',
        crimes: [
            {
                title: 'از بین بردن تمام دندان های دائم بیست و هشتگانه',
                amount: 1,
                sex: true,
            },
            {
                title: 'از بین بردن هر کدام از دندان های جلو',
                amount: 0.05,
                sex: false,
            },
            {
                title: 'از بین بردن هر کدام از دندان های عقب',
                amount: 0.025,
                sex: false,
            },
            {
                title: 'از بین بردن دندان اضافی',
                amount: 'ارش ثابت می گردد',
                sex: false,
            },
            {
                title: 'ایجاد ترک یا لق کردن دندان هر گاه در حکم از بین بردن آن باشد',
                amount: 'موجب دیه همان دندان',
                sex: false,
            },
            {
                title: 'ایجاد ترک یا لق کردن دندان هر گاه در حکم از بین بردن آن نباشد',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'کندن دندان لق یا ترکخورده که منفعت آن باقی است',
                amount: 'موجب دیه همان دندان',
                sex: false,
            },
            {
                title: 'کندن دندان لق یا ترکخورده که منفعت آن باقی نیست',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'شکستن آن مقدار از دندان که نمایان است با بقای ریشه',
                amount: 'دیه همان دندان را دارد',
                sex: false,
            },
            {
                title: 'کندن دندان شیری',
                amount: 0.01,
                sex: false,
            },
            {
                title: 'شکستن، معیوب کردن و شکافتن دندان شیری',
                amount: 'موجب ارش است',
                sex: false,
            },
        ],
    },
    {
        id: 9,
        name: 'گردن',
        crimes: [
            {
                title: 'کج شدن و خمیدگی گردن در اثر شکستگی در صورت عدم بهبودی',
                amount: 1,
                sex: true,
            },
            {
                title: 'کج شدن و خمیدگی گردن در اثر شکستگی در صورت بهبودی',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'شکستگی گردن بدون کچ شدن و خمیدگی',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'جنایت بر گردن که مانع فرو بردن یا جویدن غذا و یا نقص آن یا مانع حرکت گردن شود',
                amount: 'موجب ارش است',
                sex: false,
            },
        ],
    },
    {
        id: 10,
        name: 'فک',
        crimes: [
            {
                title: 'قطع کردن و یا از بین بردن دو استخوان چپ و راست فک که محل رویش دندان های پایین است',
                amount: 1,
                sex: true,
            },
            {
                title: 'قطع کردن و یا از بین بردن یک استخوان چپ یا راست فک که محل رویش دندان های پایین است',
                amount: 1 / 2,
                sex: true,
            },
            {
                title: 'قطع کردن و یا از بین بردن مقداری از استخوان چپ یا راست فک که محل رویش دندان های پایین است',
                amount: 'به همان نسبت دیه دارد',
                sex: false,
            },
            {
                title: 'جنایتی که موجب کندی حرکت فک شود',
                amount: 'ارش دارد',
                sex: false,
            },
            {
                title: 'از بین بردن تمام یا قسمتی از فک بالا',
                amount: 'ارش دارد',
                sex: false,
            },
            {
                title: 'از بین بردن تمام یا قسمتی از فک بالا',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'شکستگی استخوان فک پایین',
                amount: 'مشمول حکم شکستگی استخوان',
                sex: false,
            },
            {
                title: 'شکستگی استخوان فک بالا',
                amount: 'مشمول حکم شکستگی استخوان های سر و صورت است',
                sex: false,
            },
            {
                title: 'فلج کردن فک پایین',
                amount: 2 / 3,
                sex: true,
            },
            {
                title: 'قطع فک فلج',
                amount: 1 / 3,
                sex: true,
            },
        ],
    },
    {
        id: 11,
        name: 'دست و پا',
        crimes: [
            {
                title: 'قطع کردن و یا از بین بردن هر یک از دست ها از مفصل مچ به شرط آنکه دارای انگشتان کامل باشد',
                amount: 1 / 2,
                sex: true,
            },
            {
                title: 'قطع تمام انگشتان یک دست از انتهای انگشتان پا تا مچ',
                amount: 1 / 2,
                sex: true,
            },
            {
                title: 'قطع کردن و یا از بین بردن کف دستی که به هر علت دارای انگشت نمیباشد',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'قطع دست دارای ساعد از آرنج',
                amount: 1 / 2,
                sex: true,
            },
            {
                title: 'قطع دست دارای بازو از شانه',
                amount: 1 / 2,
                sex: true,
            },
            {
                title: 'قطع دست اصلی کسی که از مچ یا آرنج یا شانه دارای دو دست است',
                amount: 1 / 2,
                sex: true,
            },
            {
                title: 'قطع دست زائد',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'دیه هر یک از انگشتان اصلی دست',
                amount: 1 / 10,
                sex: false,
            },
            {
                title: 'بریدن یا از بین بردن هر بند انگشت غیر شست',
                amount: 'یک سوم دیه آن انگشت',
                sex: false,
            },
            {
                title: 'بریدن یا از بین بردن هر بند انگشت شست',
                amount: 'نصف دیه شست',
                sex: false,
            },
            {
                title: 'دیه انگشت زائد',
                amount: 'یک سوم دیه انگشت اصلی',
                sex: false,
            },
            {
                title: 'دیه بندهای انگشت زائد',
                amount: 'یک سوم دیه همان بند اصلی',
                sex: false,
            },
            {
                title: 'دیه بند زائد انگشت اصلی',
                amount: 'یک سوم دیه بند اصلی همان انگشت',
                sex: false,
            },
            {
                title: 'فلج کردن هر دست',
                amount: 'دو سوم دیه دست',
                sex: false,
            },
            {
                title: 'فلج کردن هر انگشت',
                amount: 'دو سوم دیه همان انگشت',
                sex: false,
            },
            {
                title: 'قطع دست فلج',
                amount: 'یک سوم دیه دست',
                sex: false,
            },
            {
                title: 'قطع انگشت فلج',
                amount: 'یک سوم دیه همان انگشت',
                sex: false,
            },
            {
                title: 'از بین بردن ناخن به طوری که دیگر نروید یا فاسد و معیوب بروید',
                amount: 0.01,
                sex: false,
            },
            {
                title: 'از بین بردن ناخن به طوری که بدون عیب بروید',
                amount: 0.005,
                sex: false,
            },
            {
                title: 'پا',
                amount: 'احکام مذکور در دیه دست و انگشتان آن، در پا و انگشتان آن نیز جاری است',
                sex: false,
            },
        ],
    },
    {
        id: 12,
        name: 'ستون فقرات ، نخاع و نشیمنگاه',
        crimes: [
            {
                title: 'شکستن ستون فقرات در صورتی که اصلاً درمان نشود و یا بعد از علاج به صورت خمیده درآید',
                amount: 1,
                sex: true,
            },
            {
                title: 'شکستن ستون فقرات که بیعیب درمان شود ولی موجب از بین رفتن یکی از منافع گردد',
                amount: 1,
                sex: true,
            },
            {
                title: 'شکستن ستون فقرات که درمان نشود و موجب عوارضی شود',
                amount: 'علاوه بر دیه کامل شکستگی ستون فقرات، موجب دیه یا ارش هر یک از عوارض حاصله نیز است',
                sex: false,
            },
            {
                title: 'شکستن ستون فقرات که بدون عیب درمان شود',
                amount: 1 / 10,
                sex: false,
            },
            {
                title: 'شکستن ستون فقرات که موجب فلج و بیحس شدن پاها گردد',
                amount: 'علاوه بر دیه ستون فقرات، موجب دو سوم دیه برای فلج دو پا نیز است',
                sex: false,
            },
            {
                title:
                    'جنایتی که سبب خمیدگی پشت شود بدون آنکه موجب شکستن ستون فقرات گردد در صورتی که خمیدگی درمان نشود',
                amount: 1,
                sex: true,
            },
            {
                title:
                    'جنایتی که سبب خمیدگی پشت شود بدون آنکه موجب شکستن ستون فقرات گردد در صورتی که بدون عیب درمان شود',
                amount: 1 / 10,
                sex: false,
            },
            {
                title: 'قطع نخاع',
                amount: 1,
                sex: true,
            },
            {
                title: 'قطع جزئی از نخاع',
                amount: 'به نسبت مساحت عرض، دیه دارد',
                sex: false,
            },
            {
                title: 'از بین بردن دو کپل به نحوی که به استخوان برسد',
                amount: 1,
                sex: true,
            },
            {
                title: 'از بین بردن یک کپل به نحوی که به استخوان برسد',
                amount: 1 / 2,
                sex: true,
            },
            {
                title: 'از بین بردن قسمتی از کپل',
                amount: 'به همان نسبت دیه دارد',
                sex: false,
            },
            {
                title: 'شکستن استخوان دنبالچه',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'شکستن استخوان دنبالچه به طوری که مجنی علیه قادر به ضبط مدفوع نباشد',
                amount: 1,
                sex: true,
            },
            {
                title: 'صدمه ای که به حد فاصل بیضه ها و مقعد وارد شده است و موجب عدم ضبط ادرار یا مدفوع یا هر دو گردد',
                amount: 1,
                sex: true,
            },
        ],
    },
    {
        id: 13,
        name: 'دنده و ترقوه',
        crimes: [
            {
                title: 'شکستن هر یک از دنده های محیط به قلب که از آن حفاظت می کند',
                amount: 1 / 40,
                sex: false,
            },
            {
                title: 'شکستن هر یک از دنده های دیگر',
                amount: 1 / 100,
                sex: false,
            },
            {
                title: 'کندن دنده',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'دیه موضحه هر یک از دنده ها',
                amount: 'یک چهارم دیه شکستن آن',
                sex: false,
            },
            {
                title: 'ترک خوردن هر یک از دنده های محیط به قلب ',
                amount: 1 / 80,
                sex: false,
            },
            {
                title: 'در رفتگی هر یک از دنده های محیط به قلب ',
                amount: 7.5 / 1000,
                sex: false,
            },
            {
                title: 'ترک خوردن هر یک از دنده های دیگر ',
                amount: 7 / 1000,
                sex: false,
            },
            {
                title: 'در رفتگی هر یک از دنده های دیگر',
                amount: 5 / 1000,
                sex: false,
            },
            {
                title: 'قطع و از بین بردن دو استخوان ترقوه',
                amount: 1,
                sex: true,
            },
            {
                title: 'قطع و از بین بردن یک استخوان ترقوه',
                amount: 1 / 2,
                sex: true,
            },
            {
                title: 'شکستن هر یک از استخوان های ترقوه در صورتی که بدون عیب درمان شود',
                amount: 4 / 100,
                sex: true,
            },
            {
                title: 'شکستن هر یک از استخوان های ترقوه در صورتی که درمان نشود یا با عیب درمان شود',
                amount: 1 / 2,
                sex: true,
            },
            {
                title: 'ترک خوردن هر یک از استخوان های ترقوه',
                amount: 32 / 1000,
                sex: false,
            },
            {
                title: 'دیه موضحه هر یک از استخوان های ترقوه',
                amount: 25 / 1000,
                sex: false,
            },
            {
                title: 'در رفتگی هر یک از استخوان های ترقوه',
                amount: 20 / 1000,
                sex: false,
            },
            {
                title: 'سوراخ شدن هر یک از استخوان های ترقوه',
                amount: 10 / 1000,
                sex: false,
            },
        ],
    },
    {
        id: 14,
        name: 'ازاله بکارت و افضاء',
        crimes: [
            {
                title: 'ازاله بکارت غیرهمسر',
                amount: 1,
                sex: false,
            },
            {
                title: 'افضای همسر بالغ به سببی غیر از مقاربت',
                amount: 'دیه کامل زن',
                sex: false,
            },
            {
                title: 'افضای همسر نابالغ به سبب مقاربت جنسی',
                amount: 'علاوه بر تمام مهر و دیه کامل زن، نفقه نیز تا زمان وفات یکی از زوجین بر عهده زوج است',
                sex: false,
            },
            {
                title: 'افضای غیرهمسر نابالغ یا مکره و افضا به سبب مقاربت',
                amount: 'علاوه بر مهرالمثل و دیه کامل زن، در صورت ازاله بکارت، ارش البکاره نیز ثابت خواهد بود',
                sex: false,
            },
            {
                title: 'افضای غیرهمسر نابالغ یا مکره و افضا به غیر مقاربت',
                amount: 'دیه کامل زن و در صورت ازاله بکارت، مهرالمثل نیز ثابت است',
                sex: false,
            },
            {
                title: 'افضای غیرهمسر بالغ با رضایت و به سبب مقاربت',
                amount: 'تنها پرداخت دیه کامل زن ثابت خواهد بود',
                sex: false,
            },
            {
                title: 'افضای ناشی از وطی به شبهه',
                amount: 'علاوه بر مهرالمثل و دیه، در صورت ازاله بکارت، موجب ارش البکاره نیز می باشد',
                sex: false,
            },
        ],
    },
    {
        id: 15,
        name: 'دیه اندام تناسلی و بیضه',
        crimes: [
            {
                title: 'قطع یا از بین بردن اندام تناسلی مرد تا ختنه گاه و یا بیشتر از آن',
                amount: 1,
                sex: false,
            },
            {
                title: 'قطع یا از بین بردن اندام تناسلی مرد در کمتر از ختنه گاه',
                amount: 'به نسبت ختنه گاه',
                sex: false,
            },
            {
                title: 'قطع اندام تناسلی فلج',
                amount: 1 / 3,
                sex: false,
            },
            {
                title: 'فلج کردن اندام سالم',
                amount: 2 / 3,
                sex: false,
            },
            {
                title: 'قطع اندام عنین',
                amount: 1 / 3,
                sex: false,
            },
            {
                title: 'قطع و از بین بردن هر یک از دو طرف اندام تناسلی زن',
                amount: 1 / 4,
                sex: false,
            },
            {
                title: 'قطع و از بین بردن بخشی از اندام تناسلی زن',
                amount: 'به همان نسبت دیه دارد',
                sex: false,
            },
            {
                title: 'قطع دو بیضه یکباره',
                amount: 1,
                sex: false,
            },
            {
                title: 'قطع بیضه چپ',
                amount: 2 / 3,
                sex: false,
            },
            {
                title: 'قطع بیضه راست',
                amount: 1 / 3,
                sex: false,
            },
            {
                title: 'ورم کردن یک بیضه',
                amount: 2 / 10,
                sex: false,
            },
            {
                title: 'ورم کردن یک بیضه که مانع راه رفتن مفید شود',
                amount: 8 / 10,
                sex: false,
            },
            {
                title: 'قطع بیضه ها یا اندام تناسلی مردانه خنثای ملحق به مرد',
                amount: 1,
                sex: false,
            },
            {
                title: 'قطع بیضه ها یا اندام تناسلی مردانه خنثای ملحق به زن',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'از بین بردن عانه مرد یا زن',
                amount: 'موجب ارش است',
                sex: false,
            },
        ],
    },
    {
        id: 16,
        name: 'پستان',
        crimes: [
            {
                title: 'قطع و از بین بردن هر یک از دو پستان زن',
                amount: 1 / 4,
                sex: false,
            },
            {
                title: 'قطع و از بین بردن مقداری از پستان زن',
                amount: 'به همان نسبت موجب دیه است',
                sex: false,
            },
            {
                title:
                    'قطع کردن شیر پستان زن یا از بین بردن قدرت تولید شیر یا متعذر کردن خروج شیر از پستان یا ایجاد هر نقص دیگری',
                amount: 'ارش ثابت است',
                sex: false,
            },
        ],
    },
    {
        id: 17,
        name: 'عقل',
        crimes: [
            {
                title: 'زائل کردن عقل',
                amount: 1,
                sex: true,
            },
            {
                title: 'ایجاد نقص در عقل',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'ایجاد جنون ادواری',
                amount: 'ارش ثابت است',
                sex: false,
            },
            {
                title: 'زوال و نقصان حافظه و یا اختلال روانی که به حد جنون نرسد',
                amount: 'ارش ثابت است',
                sex: false,
            },
        ],
    },
    {
        id: 18,
        name: 'شنوایی',
        crimes: [
            {
                title: 'از بین بردن شنوایی هر دو گوش',
                amount: 1,
                sex: true,
            },
            {
                title: 'از بین بردن شنوایی یک گوش',
                amount: 1 / 2,
                sex: true,
            },
            {
                title: 'از بین بردن شنوایی گوش شنوای شخصی که یکی از گوش های او نمیشنود',
                amount: 1 / 2,
                sex: true,
            },
            {
                title: 'کاهش شنوایی در صورتی که مقدار آن قابل تشخیص باشد',
                amount: 'به همان نسبت دیه دارد',
                sex: false,
            },
            {
                title: 'هرگاه با قطع یا از بین بردن گوش و یا هر جنایت دیگری شنوایی از بین برود یا نقصان یابد',
                amount: 'هر یک از جنایت ها، دیه یا ارش جداگانه دارد',
                sex: false,
            },
            {
                title: 'ایجاد نقص دائمی',
                amount: 'دیه شنوایی ثابت است',
                sex: false,
            },
            {
                title: 'ایجاد نقص موقتی',
                amount: 'ارش تعیین می شود',
                sex: false,
            },
        ],
    },
    {
        id: 19,
        name: 'بینایی',
        crimes: [
            {
                title: 'از بین بردن بینایی هر دو چشم',
                amount: 1,
                sex: true,
            },
            {
                title: 'از بین بردن بینایی یک چشم',
                amount: 1 / 2,
                sex: true,
            },
            {
                title: 'کاهش بینایی در صورتی که مقدار آن قابل تشخیص باشد',
                amount: 'به همان نسبت دیه دارد',
                sex: false,
            },
            {
                title: 'کاهش بینایی در صورتی که مقدار آن قابل تشخیص نباشد',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title:
                    'از بین بردن بینایی چشم کسی که فقط یک چشم بینا دارد و چشم دیگرش نابینای مادرزادی بوده و یا در اثر علل غیرجنایی از بین رفته باشد',
                amount: 1,
                sex: true,
            },
            {
                title:
                    'از بین بردن بینایی چشم کسی که فقط یک چشم بینا دارد و چشم دیگرش را در اثر قصاص یا جنایتی که استحقاق دیه آن را داشته است، از دست داده باشد',
                amount: 1 / 2,
                sex: true,
            },
        ],
    },
    {
        id: 20,
        name: 'بویایی',
        crimes: [
            {
                title: 'از بین بردن کامل بویایی',
                amount: 1,
                sex: true,
            },
            {
                title: 'از بین بردن قسمتی از بویایی',
                amount: 'ارش دارد',
                sex: false,
            },
        ],
    },
    {
        id: 21,
        name: 'چشایی',
        crimes: [
            {
                title: 'از بین بردن حس چشایی و نقصان آن',
                amount: 'موجب ارش است',
                sex: false,
            },
        ],
    },
    {
        id: 22,
        name: 'صوت و گویایی',
        crimes: [
            {
                title: 'از بین بردن صوت به  طور کامل به گونه ای که شخص نتواند صدایش را آشکار کند',
                amount: 1,
                sex: true,
            },
            {
                title: 'از بین بردن گویایی به  طور کامل و بدون قطع زبان',
                amount: 1,
                sex: true,
            },
            {
                title: 'از بین بردن قدرت ادای برخی از حروف',
                amount: 'به همان نسبت دیه دارد',
                sex: false,
            },
            {
                title: 'جنایتی که موجب پیدایش عیبی در گفتار یا ادای حروف گردد و یا عیب موجود در آن را تشدید کند',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'اگر عرفا عیب تلقی شود موجب ارش است',
                amount: 'موجب ارش',
                sex: false,
            },
            {
                title: 'جنایتی که موجب عیبی در صوت مانند کاهش طنین صدا، گرفتگی آن و یا صحبت کردن از طریق بینی شود',
                amount: 'ارش دارد',
                sex: false,
            },
            {
                title: 'از بین رفتن صوت برخی از حروف',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'در صورتی که جنایت، علاوه بر زوال صوت، موجب زوال نطق نیز گردد',
                amount: 'دو دیه ثابت می شود',
                sex: false,
            },
        ],
    },
    {
        id: 23,
        name: 'دیه سایر منافع',
        crimes: [
            {
                title: 'جنایتی که بطور دائم موجب سلس و ریزش ادرار گردد',
                amount: 1,
                sex: true,
            },
            {
                title: 'جنایتی که موجب ریزش غیردائمی ادرار گردد',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'جنایتی که موجب عدم ضبط دائم مدفوع یا ادرار شود',
                amount: 1,
                sex: true,
            },
            {
                title: 'از بین بردن قدرت انزال یا تولید مثل مرد یا بارداری زن و یا از بین بردن لذت مقاربت زن یا مرد',
                amount: 'موجب ارش است',
                sex: false,
            },
            {
                title: 'از بین بردن کامل قدرت مقاربت',
                amount: 1,
                sex: true,
            },
            {
                title: 'از بین بردن یا نقص دائم یا موقت حواس یا منافع دیگر',
                amount: 'موجب ارش است',
                sex: false,
            },
        ],
    },
    {
        id: 24,
        name: 'جراحات',
        crimes: [
            {
                title: 'حارصه',
                amount: 1 / 100,
                sex: false,
            },
            {
                title: 'دامیه',
                amount: 2 / 100,
                sex: false,
            },
            {
                title: 'متلاحمه',
                amount: 2 / 100,
                sex: false,
            },
            {
                title: 'سمحاق',
                amount: 4 / 100,
                sex: false,
            },
            {
                title: 'موضحه',
                amount: 5 / 100,
                sex: false,
            },
            {
                title: 'هاشمه',
                amount: 10 / 100,
                sex: false,
            },
            {
                title: 'مُنَقَّله',
                amount: 15 / 100,
                sex: false,
            },
            {
                title: 'مأمومه',
                amount: 1 / 3,
                sex: false,
            },
            {
                title: 'دامغه',
                amount: 'علاوه بر دیه مأمومه، موجب ارش پاره شدن کیسه مغز نیز می باشد',
                sex: false,
            },
            {
                title: 'جنایت بر گونه در صورتی  که داخل دهان را نمایان نسازد',
                amount: 1 / 20,
                sex: false,
            },
            {
                title: 'جنایت بر گونه در صورتی  که داخل دهان را نمایان سازد',
                amount: 1 / 5,
                sex: false,
            },
            {
                title: 'هرگاه جنایت موضحه صورت بعد از التیام، اثری از خود بر جای بگذارد',
                amount: 'علاوه بر دیه موضحه، یک  هشتادم دیه کامل نیز ثابت است',
                sex: false,
            },
            {
                title: 'هرگاه جنایت بر صورت در حد موضحه نبوده و بعد از التیام، اثری از آن باقی بماند',
                amount: 'علاوه  بر دیه جنایت، یک  صدم دیه کامل نیز ثابت است',
                sex: false,
            },
            {
                title: 'چنانچه جنایت، شکافی در صورت ایجاد کند',
                amount: 60 / 100,
                sex: false,
            },
            {
                title: 'جائفه',
                amount: 1 / 3,
                sex: false,
            },
            {
                title: 'نافذه (در مرد و در عضوی که دیه آن بیش از یک دهم دیه کامل باشد)',
                amount: 1 / 10,
                sex: false,
            },
            {
                title: 'نافذه (در زن یا در عضوی که دیه آن مساوی یا کمتر از یک دهم دیه کامل باشد)',
                amount: 'ارش ثابت می شود',
                sex: false,
            },
            {
                title: 'سیاه شدن پوست صورت',
                amount: 6 / 1000,
                sex: false,
            },
            {
                title: 'کبود شدن پوست صورت',
                amount: 3 / 1000,
                sex: false,
            },
            {
                title: 'سرخ شدن پوست صورت',
                amount: 1.5 / 1000,
                sex: false,
            },
            {
                title: 'تغییر رنگ پوست سایر اعضا',
                amount: 'حسب مورد نصف مقادیر مذکور در بند الف',
                sex: false,
            },
            {
                title: 'تغییر رنگ پوست سر',
                amount: 'ارش ثابت است',
                sex: false,
            },
            {
                title: 'صدمه ای که موجب تورم بدن، سر یا صورت گردد',
                amount: 'ارش دارد',
                sex: false,
            },
        ],
    },
    {
        id: 25,
        name: 'جنین',
        crimes: [
            {
                title: 'نطفه ای که در رحم مستقر شده',
                amount: 2 / 100,
                sex: false,
            },
            {
                title: 'علقه که در آن جنین به صورت خون بسته درمی آید',
                amount: 4 / 100,
                sex: false,
            },
            {
                title: 'مضغه که در آن جنین به صورت توده گوشتی در میآید',
                amount: 6 / 100,
                sex: false,
            },
            {
                title: 'عظام که در آن جنین به صورت استخوان درآمده لکن هنوز گوشت روییده نشده است',
                amount: 8 / 100,
                sex: false,
            },
            {
                title: 'جنینی که گوشت و استخوان بندی آن تمام شده ولی روح در آن دمیده نشده است',
                amount: 1 / 10,
                sex: false,
            },
            {
                title: 'دیه جنینی که روح در آن دمیده شده است اگر پسر باشد',
                amount: 1,
                sex: false,
            },
            {
                title: 'دیه جنینی که روح در آن دمیده شده است اگر دختر باشد',
                amount: 1 / 2,
                sex: false,
            },
            {
                title: 'دیه جنینی که روح در آن دمیده شده است اگر مشتبه باشد',
                amount: 3 / 4,
                sex: false,
            },
        ],
    },
];

export default diyatRate;
