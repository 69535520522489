import React, { useRef, useState } from 'react';
import './1385.style.scss';
import { Button, CostPicker, IconButton, SingleDropdown, SingleDropdownOption } from 'vekalapp-react-utilities';
import { enNumToFaNum } from '../../../../scripts/string';
import { TaxStamp85 } from './1385.data';
import { Form8_1385 } from '../../../../interface';

const Form8_Year1385: React.FC = () => {
    const titleOption: SingleDropdownOption[] = [];
    const checkbox1Ref = useRef<any>();
    const checkbox2Ref = useRef<any>();
    const checkbox3Ref = useRef<any>();
    const checkbox4Ref = useRef<any>();
    const checkbox5Ref = useRef<any>();
    const checkbox6Ref = useRef<any>();
    const checkbox7Ref = useRef<any>();
    const checkbox8Ref = useRef<any>();
    const [checkbox1, setCheckbox1] = useState<boolean>(false);
    const [checkbox2, setCheckbox2] = useState<boolean>(false);
    const [checkbox3, setCheckbox3] = useState<boolean>(false);
    const [checkbox4, setCheckbox4] = useState<boolean>(false);
    const [checkbox5, setCheckbox5] = useState<boolean>(false);
    const [checkbox6, setCheckbox6] = useState<boolean>(false);
    const [checkbox7, setCheckbox7] = useState<boolean>(false);
    const [checkbox8, setCheckbox8] = useState<boolean>(false);
    const nonFinancialTypeRef = useRef<any>();
    const fightTypeRef = useRef<any>();
    const rangeRef = useRef<any>();
    const titleRef = useRef<any>();
    const degreeRef = useRef<any>();
    const appointmentRef = useRef<any>();
    const levelRef = useRef<any>();
    const placeRef = useRef<any>();
    const numberInputRef = useRef<any>();
    const numberInputRef2 = useRef<any>();
    const [basedOn, setBasedOn] = useState<SingleDropdownOption>({ title: 'تعرفه', payload: 1 });
    const basedOnOptions: SingleDropdownOption[] = [];
    const basedOnRef = useRef<any>();

    TaxStamp85 &&
        TaxStamp85.map((item) => {
            basedOnOptions.push({ title: item.title, payload: item.id });
        });
    const [selected, setSelected] = useState<any>(TaxStamp85.find((item) => item.title == basedOnOptions[0].title));

    selected &&
        selected.param1 !== null &&
        selected.param1.map((item) => {
            titleOption.push({ title: item.title, payload: item.id });
        });

    const [basedOnSelected, setBasedOnSelected] = useState<any>(
        selected && selected.param1 && selected.param1.find((item) => item.title == titleOption[0].title),
    );

    const [title, setTitle] = useState<SingleDropdownOption>({ title: 'مالی', payload: 1 });
    const [appointment, setAppointment] = useState<SingleDropdownOption>({ title: 'هیچکدام', payload: 1 });
    const [level, setLevel] = useState<SingleDropdownOption>({ title: 'نخستین', payload: 1 });
    const [degree, setDegree] = useState<SingleDropdownOption>({ title: 'کارشناسی', payload: 1 });
    const [place, setPlace] = useState<SingleDropdownOption>({ title: 'هیچکدام', payload: 1 });
    const [years, setYears] = useState<number>(0);
    const [crimes, setCrimes] = useState<number>(1);
    const [extraParams, setExtraParams] = useState<number | undefined>(undefined);
    const [extraParams2, setExtraParams2] = useState<number | undefined>(undefined);
    const [answer, setAnswer] = useState<string | number | undefined>(undefined);
    const [range, setRange] = useState<SingleDropdownOption>({ title: 'حداقل', payload: 1 });
    const [nonFinancialType, setNonFinancialType] = useState<SingleDropdownOption>({ title: 'خانوادگی', payload: 1 });
    const [fightType, setFightType] = useState<SingleDropdownOption>({
        title: 'دعاوی مستلزم مجازات شلاق، جزای نقدی، سرقت مشمول حد، قصاص عضو و دیه عضو، حبس كمتر از ده سال',
        payload: 1,
    });

    function handleAppointment(e) {
        setAppointment(e);
    }

    function handleLevel(e) {
        setLevel(e);
    }

    function handleDegree(e) {
        setDegree(e);
    }

    function handlePlace(e) {
        setPlace(e);
    }

    function handleNumberInput(e) {
        setExtraParams(e);
    }

    function handleNumberInput2(e) {
        setExtraParams2(e);
    }

    function handleRange(e) {
        setRange(e);
    }

    function handleNonFinancialType(e) {
        setNonFinancialType(e);
    }

    function handleFightType(e) {
        setFightType(e);
    }

    function handleCheckbox(number: number, value: boolean) {
        if (number === 1 && value) {
            setCheckbox1(value);
            setCheckbox2(false);
            setCheckbox3(false);
            setCheckbox4(false);
            setCheckbox5(false);
            setCheckbox8(false);
        } else if (number === 1 && !value) {
            setCheckbox1(false);
        }
        if (number === 2 && value) {
            setCheckbox2(value);
            setCheckbox1(false);
            setCheckbox3(false);
            setCheckbox8(false);
        } else if (number === 2 && !value) {
            setCheckbox2(false);
        }
        if (number === 3 && value) {
            setCheckbox3(value);
            setCheckbox1(false);
            setCheckbox2(false);
            setCheckbox4(false);
            setCheckbox5(false);
            setCheckbox8(false);
        } else if (number === 3 && !value) {
            setCheckbox3(false);
        }
        if (number === 4 && value) {
            setCheckbox4(value);
            setCheckbox1(false);
            setCheckbox3(false);
            setCheckbox5(false);
        } else if (number === 4 && !value) {
            setCheckbox4(false);
        }
        if (number === 5 && value) {
            setCheckbox5(value);
            setCheckbox1(false);
            setCheckbox4(false);
            setCheckbox3(false);
        } else if (number === 5 && !value) {
            setCheckbox5(false);
        }
        if (number === 6 && value) {
            setCheckbox6(value);
        } else if (number === 6 && !value) {
            setCheckbox6(false);
        }
        if (number === 7 && value) {
            setCheckbox7(value);
        } else if (number === 7 && !value) {
            setCheckbox7(false);
        }
        if (number === 8 && value) {
            setCheckbox8(value);
            setCheckbox1(false);
            setCheckbox2(false);
            setCheckbox3(false);
        } else if (number === 8 && !value) {
            setCheckbox8(false);
        }
    }

    function handleCalculate() {
        if (selected && selected.title === 'حق الوکاله') {
            if (extraParams) {
                setAnswer(selected.calculate(extraParams));
                responseRef.current?.scrollIntoView(true);
            } else {
                numberInputRef.current.setError(Form8_1385.price + ' وارد نشده است');
            }
        } else {
            if (basedOnSelected && basedOnSelected.title === 'مالی') {
                if (extraParams2) {
                    setAnswer(
                        basedOnSelected.calculate(
                            checkbox1,
                            checkbox2,
                            checkbox3,
                            checkbox4,
                            checkbox5,
                            checkbox6,
                            checkbox7,
                            checkbox8,
                            level.payload,
                            appointment.payload,
                            degree.payload,
                            years,
                            place.payload,
                            extraParams2,
                        ),
                    );
                    responseRef.current?.scrollIntoView(true);
                } else {
                    numberInputRef2.current.setError(Form8_1385.priceRequested + ' وارد نشده است');
                }
            } else {
                setAnswer(
                    basedOnSelected.calculate(
                        checkbox1,
                        checkbox2,
                        checkbox3,
                        checkbox4,
                        checkbox5,
                        checkbox6,
                        checkbox7,
                        checkbox8,
                        level.payload,
                        range.payload,
                        nonFinancialType.payload,
                        fightType.payload,
                        crimes,
                        appointment.payload,
                        degree.payload,
                        years,
                        place.payload,
                    ),
                );
                responseRef.current?.scrollIntoView(true);
            }
        }
    }

    function handleBasedOn(e) {
        setBasedOn(e);
        setSelected(TaxStamp85.find((item) => item.title == e.title));
    }

    function handleTitle(e) {
        setTitle(e);
        setBasedOnSelected(selected && selected.param1 && selected.param1.find((item) => item.title == e.title));
    }

    const responseRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <div className="custom-row">
                <SingleDropdown
                    options={basedOnOptions}
                    label="بر اساس"
                    placeholder="بر اساس"
                    ref={basedOnRef}
                    onChange={(e) => handleBasedOn(e)}
                    value={basedOn}
                    background={'#fff'}
                />
            </div>
            {selected && selected.title === 'تعرفه' && (
                <div className="custom-row">
                    <SingleDropdown
                        options={titleOption}
                        label="نوع دعوا"
                        placeholder="نوع دعوا را انتخاب کنید"
                        ref={titleRef}
                        onChange={(e) => handleTitle(e)}
                        value={title}
                        background={'#fff'}
                    />
                </div>
            )}
            {selected && selected.title === 'تعرفه' && !checkbox1 && !checkbox2 && !checkbox3 && (
                <div className="custom-row">
                    <SingleDropdown
                        options={basedOnSelected && basedOnSelected.param1}
                        label="مرحله"
                        placeholder="مرحله را انتخاب کنید"
                        ref={levelRef}
                        onChange={(e) => handleLevel(e)}
                        value={level}
                        background={'#fff'}
                    />
                </div>
            )}
            {/*/!**!/*/}
            {selected &&
                selected.title === 'تعرفه' &&
                basedOnSelected &&
                basedOnSelected.title === 'غیرمالی' &&
                !checkbox3 && (
                    <div className="custom-row">
                        <SingleDropdown
                            options={basedOnSelected && basedOnSelected.param7}
                            label="نوع غیرمالی"
                            placeholder="نوع غیرمالی را انتخاب کنید"
                            ref={nonFinancialTypeRef}
                            onChange={(e) => handleNonFinancialType(e)}
                            value={nonFinancialType}
                            background={'#fff'}
                        />
                    </div>
                )}
            {selected &&
                selected.title === 'تعرفه' &&
                basedOnSelected &&
                basedOnSelected.title === 'غیرمالی' &&
                nonFinancialType.title === 'کیفری' && (
                    <div className="custom-row">
                        <SingleDropdown
                            options={basedOnSelected && basedOnSelected.param8}
                            label="نوع دعوای کیفری"
                            placeholder="نوع دعوای کیفری را انتخاب کنید"
                            ref={fightTypeRef}
                            onChange={(e) => handleFightType(e)}
                            value={fightType}
                            background={'#fff'}
                        />
                    </div>
                )}
            {selected &&
                selected.title === 'تعرفه' &&
                basedOnSelected &&
                basedOnSelected.title === 'غیرمالی' &&
                nonFinancialType.title === 'کیفری' && (
                    <div className="custom-row align-items-center">
                        <div className="years-crimes">
                            <p className="years-crimes-title">تعداد جرایم :</p>
                            <div className="counter">
                                <IconButton onClick={() => setCrimes(crimes + 1)} color="blue">
                                    <i className="material-icons">add</i>
                                </IconButton>
                                <p className="years-crimes-counter">{crimes.toLocaleString('fa')}</p>
                                <IconButton
                                    onClick={() => (crimes >= 2 ? setCrimes(crimes - 1) : setCrimes(crimes))}
                                    color="blue"
                                >
                                    <i className="material-icons">remove</i>
                                </IconButton>
                            </div>
                        </div>
                    </div>
                )}
            {selected &&
                selected.title === 'تعرفه' &&
                basedOnSelected &&
                basedOnSelected.title === 'غیرمالی' &&
                nonFinancialType.title !== 'کیفری' && (
                    <div className="custom-row">
                        <SingleDropdown
                            options={basedOnSelected && basedOnSelected.param10}
                            label="محدوده تعرفه"
                            placeholder="محدوده تعرفه را انتخاب کنید"
                            ref={rangeRef}
                            onChange={(e) => handleRange(e)}
                            value={range}
                            background={'#fff'}
                        />
                    </div>
                )}
            {selected && selected.title !== 'حق الوکاله' && !checkbox1 && !checkbox3 && (
                <div className="custom-row">
                    <SingleDropdown
                        options={basedOnSelected && basedOnSelected.param2}
                        label="قرار صادره"
                        placeholder="قرار صادره را انتخاب کنید"
                        ref={appointmentRef}
                        onChange={(e) => handleAppointment(e)}
                        value={appointment}
                        background={'#fff'}
                    />
                </div>
            )}
            {selected && selected.title !== 'حق الوکاله' && (
                <>
                    <div className="custom-row align-items-center">
                        <label className="conditions-container" htmlFor="checkbox1">
                            <input
                                onClick={() => handleCheckbox(1, !checkbox1)}
                                checked={checkbox1}
                                id="checkbox1"
                                type="checkbox"
                                ref={checkbox1Ref}
                                className="conditions"
                            />
                            حق الوکاله مربوط به دادسرا می باشد
                        </label>
                    </div>
                    <div className="custom-row align-items-center">
                        <label className="conditions-container" htmlFor="checkbox2">
                            <input
                                onClick={() => handleCheckbox(2, !checkbox2)}
                                checked={checkbox2}
                                id="checkbox2"
                                type="checkbox"
                                ref={checkbox2Ref}
                                className="conditions"
                            />
                            حق الوکاله مربوط به دیوان عالی کشور می باشد
                        </label>
                    </div>
                    <div className="custom-row align-items-center">
                        <label className="conditions-container" htmlFor="checkbox3">
                            <input
                                onClick={() => handleCheckbox(3, !checkbox3)}
                                checked={checkbox3}
                                id="checkbox3"
                                type="checkbox"
                                ref={checkbox3Ref}
                                className="conditions"
                            />
                            حق الوکاله مربوط به امور اجرایی می باشد
                        </label>
                    </div>
                    <div className="custom-row align-items-center">
                        <label className="conditions-container" htmlFor="checkbox4">
                            <input
                                onClick={() => handleCheckbox(4, !checkbox4)}
                                checked={checkbox4}
                                id="checkbox4"
                                type="checkbox"
                                ref={checkbox4Ref}
                                className="conditions"
                            />
                            حق الوکاله مربوط به جلب ثالث، تقابل، ورود ثالث یا اعتراض ثالث می باشد
                        </label>
                    </div>
                    <div className="custom-row align-items-center">
                        <label className="conditions-container" htmlFor="checkbox5">
                            <input
                                onClick={() => handleCheckbox(5, !checkbox5)}
                                checked={checkbox5}
                                id="checkbox5"
                                type="checkbox"
                                ref={checkbox5Ref}
                                className="conditions"
                            />
                            حق الوکاله مربوط به دفاع از دعاوی مندرج در گزینه قبل می باشد
                        </label>
                    </div>
                    <div className="custom-row align-items-center">
                        <label className="conditions-container" htmlFor="checkbox6">
                            <input
                                onClick={() => handleCheckbox(6, !checkbox6)}
                                checked={checkbox6}
                                id="checkbox6"
                                type="checkbox"
                                ref={checkbox6Ref}
                                className="conditions"
                            />
                            حق‌الوكاله مربوط به رسیدگی بعد از نقض می باشد
                        </label>
                    </div>
                    <div className="custom-row align-items-center">
                        <label className="conditions-container" htmlFor="checkbox7">
                            <input
                                onClick={() => handleCheckbox(7, !checkbox7)}
                                checked={checkbox7}
                                id="checkbox7"
                                type="checkbox"
                                ref={checkbox7Ref}
                                className="conditions"
                            />
                            دعوا منجر به صدور رأی داور شده یا در شوراهای حل اختلاف رسیدگی گردیده یا به صلح ختم شده
                        </label>
                    </div>
                    <div className="custom-row align-items-center">
                        <label className="conditions-container" htmlFor="checkbox8">
                            <input
                                onClick={() => handleCheckbox(8, !checkbox8)}
                                checked={checkbox8}
                                id="checkbox8"
                                type="checkbox"
                                ref={checkbox8Ref}
                                className="conditions"
                            />
                            حق‌الوكاله مربوط به دیوان عدالت اداری و مراجع غیر قضایی است (از قبیل سازمان تعزیرات حكومتی،
                            هیأتهای مندرج در قانون كار و غیـره)
                        </label>
                    </div>
                </>
            )}
            {/**/}
            {selected && selected.title !== 'حق الوکاله' && (
                <>
                    <div className="custom-row">
                        <SingleDropdown
                            options={basedOnSelected && basedOnSelected.param3}
                            label="مدرک تحصیلی"
                            placeholder="مدرک تحصیلی را انتخاب کنید"
                            ref={degreeRef}
                            onChange={(e) => handleDegree(e)}
                            value={degree}
                            background={'#fff'}
                        />
                    </div>
                    <div className="custom-row align-items-center">
                        <div className="years-crimes">
                            <p className="years-crimes-title">سنوات :</p>
                            <div className="counter">
                                <IconButton
                                    onClick={() => (years >= 30 ? setYears(years) : setYears(years + 1))}
                                    color="blue"
                                >
                                    <i className="material-icons">add</i>
                                </IconButton>
                                <p className="years-crimes-counter">{years.toLocaleString('fa')}</p>
                                <IconButton
                                    onClick={() => (years >= 1 ? setYears(years - 1) : setYears(years))}
                                    color="blue"
                                >
                                    <i className="material-icons">remove</i>
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <div className="custom-row">
                        <SingleDropdown
                            options={basedOnSelected && basedOnSelected.param5}
                            label="محل اشتغال"
                            placeholder="محل اشتغال را انتخاب کنید"
                            ref={placeRef}
                            onChange={(e) => handlePlace(e)}
                            value={place}
                            background={'#fff'}
                        />
                    </div>
                </>
            )}
            {selected && selected.title === 'تعرفه' && basedOnSelected && basedOnSelected.title === 'مالی' && (
                <div className="custom-row">
                    <CostPicker
                        ref={numberInputRef2}
                        onChange={(e) => handleNumberInput2(e)}
                        label={Form8_1385.priceRequested}
                        placeholder={Form8_1385.priceRequested + ' را وارد کنید'}
                        background={'#fff'}
                    />
                </div>
            )}
            {selected && selected.title === 'حق الوکاله' && (
                <div className="custom-row">
                    <CostPicker
                        ref={numberInputRef}
                        onChange={(e) => handleNumberInput(e)}
                        label={Form8_1385.price}
                        placeholder={Form8_1385.price + ' را وارد کنید'}
                        background={'#fff'}
                    />
                </div>
            )}
            <div className="custom-row">
                <Button
                    onClick={handleCalculate}
                    className={
                        basedOn.payload === 2 ||
                        (basedOn.payload === 1 && title.payload === 1 && !checkbox2) ||
                        (basedOn.payload === 1 &&
                            title.payload === 2 &&
                            nonFinancialType.payload === 1 &&
                            (checkbox2 || checkbox3)) ||
                        (basedOn.payload === 1 && title.payload === 2 && nonFinancialType.payload === 2 && checkbox1) ||
                        (basedOn.payload === 1 &&
                            title.payload === 2 &&
                            nonFinancialType.payload === 2 &&
                            !checkbox1) ||
                        (basedOn.payload === 1 &&
                            title.payload === 2 &&
                            nonFinancialType.payload === 3 &&
                            (checkbox2 || checkbox3))
                            ? 'calculate activeStyle'
                            : 'calculate'
                    }
                >
                    محاسبه کنید!
                </Button>
            </div>
            <div className="custom-row d-none" ref={responseRef} />
            {answer && (
                <div className="answer">
                    {typeof answer === 'number' && ` ${enNumToFaNum(answer.toLocaleString().toString())} ریال می باشد.`}
                    {typeof answer === 'string' && `${enNumToFaNum(answer.toLocaleString().toString())}`}
                </div>
            )}
        </>
    );
};

export default Form8_Year1385;
