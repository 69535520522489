export const Holidays = [
    '1397/1/1',
    '1397/1/2',
    '1397/1/4',
    '1397/1/11',
    '1397/1/12',
    '1397/1/13',
    '1397/1/25',
    '1397/2/12',
    '1397/3/14',
    '1397/3/15',
    '1397/3/16',
    '1397/3/26',
    '1397/4/18',
    '1397/5/31',
    '1397/6/8',
    '1397/6/28',
    '1397/6/29',
    '1397/8/8',
    '1397/8/16',
    '1397/8/17',
    '1397/9/4',
    '1397/11/20',
    '1397/11/22',
    '1397/12/29',
    '1398/1/1',
    '1398/1/3',
    '1398/1/4',
    '1398/1/12',
    '1398/1/13',
    '1398/1/14',
    '1398/2/1',
    '1398/3/6',
    '1398/3/14',
    '1398/3/15',
    '1398/3/16',
    '1398/4/8',
    '1398/5/21',
    '1398/5/29',
    '1398/6/18',
    '1398/6/19',
    '1398/7/27',
    '1398/8/5',
    '1398/8/7',
    '1398/8/15',
    '1398/11/9',
    '1398/11/22',
    '1398/12/18',
    '1398/12/29',
    '1399/1/2',
    '1399/1/3',
    '1399/1/4',
    '1399/1/12',
    '1399/1/13',
    '1399/1/21',
    '1399/3/4',
    '1399/3/5',
    '1399/3/14',
    '1399/3/15',
    '1399/3/28',
    '1399/5/18',
    '1399/6/8',
    '1399/6/9',
    '1399/7/17',
    '1399/7/26',
    '1399/8/4',
    '1399/8/13',
    '1399/10/28',
    '1399/11/22',
    '1399/12/7',
    '1399/12/21',
    '1399/12/30',
    '1400/1/1',
    '1400/1/2',
    '1400/1/3',
    '1400/1/4',
    '1400/1/6',
    '1400/1/9',
    '1400/1/12',
    '1400/1/13',
    '1400/2/14',
    '1400/2/23',
    '1400/3/15',
    '1400/3/16',
    '1400/4/30',
    '1400/5/7',
    '1400/5/27',
    '1400/5/28',
    '1400/7/5',
    '1400/7/13',
    '1400/7/15',
    '1400/8/2',
    '1400/10/16',
    '1400/11/26',
    '1400/12/10',
    '1400/12/29',
    '1401/1/1',
    '1401/1/2',
    '1401/1/3',
    '1401/1/4',
    '1401/1/13',
    '1401/2/3',
    '1401/2/13',
    '1401/2/14',
    '1401/3/5',
    '1401/3/14',
    '1401/3/15',
    '1401/4/19',
    '1401/4/27',
    '1401/5/16',
    '1401/5/17',
    '1401/6/26',
    '1401/7/3',
    '1401/7/5',
    '1401/7/13',
    '1401/10/6',
    '1401/11/15',
    '1401/11/22',
    '1401/11/29',
    '1401/12/17',
    '1401/12/29',
    '1402/1/1',
    '1402/1/2',
    '1402/1/3',
    '1402/1/4',
    '1402/1/12',
    '1402/1/13',
    '1402/1/23',
    '1402/2/2',
    '1402/2/3',
    '1402/2/26',
    '1402/3/14',
    '1402/3/15',
    '1402/4/8',
    '1402/5/5',
    '1402/6/15',
    '1402/6/23',
    '1402/6/25',
    '1402/7/2',
    '1402/7/11',
    '1402/9/26',
    '1402/11/5',
    '1402/11/19',
    '1402/11/22',
    '1402/12/6',
    '1402/12/29',
    '1403/1/1',
    '1403/1/2',
    '1403/1/3',
    '1403/1/4',
    '1403/1/12',
    '1403/1/13',
    '1403/1/23',
];
