export interface __Pagination<T> {
    num_of_pages: number;
    count: number;
    data: T[];
}

export interface __Response<T> {
    data: T;
    status: number;
}

export enum __ExtraParams {
    none = '',
    costs = 'ارزش موضوع',
    liter = 'لیتر',
    area = 'مساحت',
    length = 'طول',
    rent = 'مبلغ اجاره',
    pieces = 'تعداد قطعه',
    waterFlow = 'دِبی (شدت جریان آب)',
}

export enum __Form7_1398 {
    none = '',
    priceRequested = 'بهای خواسته یا محکومً به',
}

export enum __Form8_1398 {
    none = '',
    priceRequested = 'بهای خواسته یا محکومً به',
    price = 'مبلغ حق الوکاله',
}

export enum __Form8_1385 {
    priceRequested = 'بهای خواسته یا محکومً به',
    none = '',
    price = 'مبلغ حق الوکاله',
}

export enum __Form11 {
    priceRequested = 'بهای خواسته یا محکومً به',
    none = '',
    checkAmount = 'مبلغ چک',
    coinPrice = 'قیمت سکه',
    coinCount = 'تعداد سکه',
    price = 'مبلغ حق الوکاله',
}
