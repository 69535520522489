import React from 'react';
import CalculationListMobile from './mobile/caclulationListMobile.index';
import CalculationListWeb from './web/calculationListWeb.index';

const CalculationList: React.FC = () => {
    return (
        <>
            <CalculationListWeb />
            <CalculationListMobile />
        </>
    );
};

export default CalculationList;
