import React, { useState } from 'react';
import { IconButton } from 'vekalapp-react-utilities';
import './itemCounter.style.scss';
import { ItemCounterInterface } from './itemCounter.interface';
import { toast } from 'react-toastify';

const ItemCounter: React.FC<ItemCounterInterface> = (props: ItemCounterInterface) => {
    const [state, setState] = useState<number>(props.initialValue ? props.initialValue : 0);

    return (
        <div className="item-counter">
            <p className="title">
                {props.text} :{' '}
                {props.info && (
                    <i onClick={() => toast.info(props.info)} className="material-icons help">
                        help
                    </i>
                )}
            </p>
            <span />
            <div className="counter">
                <IconButton
                    onClick={() => {
                        if (props.maxValue) {
                            if (state + 1 <= props.maxValue) {
                                props.onChange(state + 1);
                                setState(state + 1);
                            } else {
                                props.onChange(state);
                                setState(state);
                            }
                        } else {
                            props.onChange(state + 1);
                            setState(state + 1);
                        }
                    }}
                    color="blue"
                >
                    <i className="material-icons">add</i>
                </IconButton>
                <p className="text-counter">{state.toLocaleString('fa')}</p>
                <IconButton
                    onClick={() => {
                        if (state >= 1) {
                            if (props.initialValue === state) {
                                props.onChange(state);
                                setState(state);
                            } else {
                                props.onChange(state - 1);
                                setState(state - 1);
                            }
                        } else {
                            props.onChange(state);
                            setState(state);
                        }
                    }}
                    color="blue"
                >
                    <i className="material-icons">remove</i>
                </IconButton>
            </div>
        </div>
    );
};

export default ItemCounter;
