export interface __Tokens {
    access_token: string;
    //expire_date: string;
    //refresh: string;
}

export enum __AuthStatus {
    valid = 'valid',
    pending = 'pending',
    inValid = 'inValid',
}

export interface __User {
    full_name: string;
    avatar: string;
    email: string;
    first_name: string;
    id: number;
    is_email_validated: boolean;
    is_lawyer: boolean;
    last_login: string;
    last_name: string;
    phone: string;
    username: string;
    user_id: number;
    city: string;
    province: string;
    gender: string;
}
