import { Form7_1398, Form8_1385 } from '../../../../interface';
import { enNumToFaNum } from '../../../../scripts/string';

function nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType) {
    let answer = 0;
    let maxLevel = 0;
    if (nonFinancialType === 1) {
        answer = range === 1 ? 300000 : 4000000;
        maxLevel = 4000000;
    } else if (nonFinancialType === 2) {
        if (fightType === 1) {
            if (crimes !== 1) {
                if (crimes === 2) {
                    answer = 5500000;
                } else if (crimes === 3) {
                    answer = 6000000;
                } else {
                    answer = 6500000;
                }
            } else {
                answer = 5000000;
            }
            maxLevel = 5000000;
        } else if (fightType === 2) {
            answer = 10000000;
            if (crimes !== 1) {
                if (crimes === 2) {
                    answer = 11000000;
                } else if (crimes === 3) {
                    answer = 12000000;
                } else {
                    answer = 13000000;
                }
            } else {
                answer = 10000000;
            }
            maxLevel = 10000000;
        }
    } else if (nonFinancialType === 3) {
        answer = range === 1 ? 300000 : 4000000;
        maxLevel = 4000000;
    }
    return [answer, maxLevel];
}

function baseFunction(extraParam) {
    let answer = 0;
    let maxState = 0;
    const max = 200000000;
    if (extraParam >= 0 && extraParam <= 1500000) {
        answer = 150000;
        maxState = 150000;
    } else if (extraParam > 1500000 && extraParam <= 3000000) {
        answer = extraParam * 0.1;
        maxState = 300000;
    } else if (extraParam > 3000000 && extraParam <= 100000000) {
        answer = extraParam * 0.06;
        maxState = 6000000;
    } else if (extraParam > 100000000 && extraParam <= 1000000000) {
        answer = 6000000 + (extraParam - 100000000) * 0.04;
        maxState = 42000000;
    } else if (extraParam > 1000000000 && extraParam <= 5000000000) {
        answer = 42000000 + (extraParam - 1000000000) * 0.03;
        maxState = 162000000;
    } else if (extraParam > 5000000000 && extraParam <= 6900000000) {
        answer = 162000000 + (extraParam - 5000000000) * 0.02;
        maxState = 200000000;
    } else if (extraParam > 6900000000) {
        answer = 200000000;
    }
    answer = answer > max ? max : answer;
    return [answer, maxState];
}

function appointmentHandler(appointment: number, ans: number, level: number) {
    let result = 0;
    if (appointment === 1) {
        result = level === 1 ? ans * 0.6 : ans * 0.4;
    } else if (appointment === 2) {
        result = ans * 0.15;
    } else if (appointment === 3) {
        result = ans * 0.3;
    } else if (appointment === 4) {
        result = ans * 0.1;
    } else if (appointment === 5) {
        result = level === 1 ? ans * 0.6 : ans * 0.4;
    } else if (appointment === 6) {
        result = level === 1 ? ans * 0.6 : ans * 0.4;
    } else if (appointment === 7) {
        result = level === 1 ? ans * 0.6 : ans * 0.4;
    } else if (appointment === 8) {
        result = level === 1 ? ans * 0.6 : ans * 0.4;
    } else if (appointment === 9) {
        result = level === 1 ? ans * 0.6 : ans * 0.4;
    } else if (appointment === 10) {
        result = level === 1 ? ans * 0.6 : ans * 0.4;
        result = result * 0.5;
    }
    return result;
}

export const TaxStamp85 = [
    {
        id: 1,
        title: 'تعرفه',
        param1: [
            {
                id: 1,
                title: 'مالی',
                param1: [
                    {
                        title: 'نخستین',
                        payload: 1,
                    },
                    {
                        title: 'تجدیدنظر',
                        payload: 2,
                    },
                ], //مرحله
                param2: [
                    {
                        title: 'هیچکدام',
                        payload: 1,
                    },
                    {
                        title: 'قرار ابطال دادخواست پیش از پاسخ و دفاع از دعوی',
                        payload: 2,
                    },
                    {
                        title: 'قرار ابطال دادخواست پس از پاسخ و دفاع از دعوی',
                        payload: 3,
                    },
                    {
                        title: 'قرار سقوط دعوی تجدیدنظر قبل از پاسخ و دفاع از دعوی',
                        payload: 4,
                    },
                    {
                        title: 'قرار سقوط دعوی تجدیدنظر پس از پاسخ و دفاع از دعوی',
                        payload: 5,
                    },
                    {
                        title: 'قرار رد دعوی به علت قبول ایراد مرور زمان',
                        payload: 6,
                    },
                    {
                        title: 'قرار سقوط دعوی اعتراض بر ثبت',
                        payload: 7,
                    },
                    {
                        title: 'رد تقاضای اعاده دادرسی',
                        payload: 8,
                    },
                    {
                        title: 'قرار رد دعوی بعلت اعتبار امر مختومه',
                        payload: 9,
                    },
                    {
                        title: 'سایر قرارهایی كه مستقلاً قابل تجدیدنظر است',
                        payload: 10,
                    },
                ], //قرار صادره
                param3: [
                    {
                        title: 'کارشناسی',
                        payload: 1,
                    },
                    {
                        title: 'کارشناسی ارشد',
                        payload: 2,
                    },
                    {
                        title: 'دکتری',
                        payload: 3,
                    },
                ], //مدرک تحصیلی
                param4: true, //سنوات
                param5: [
                    {
                        title: 'هیچکدام',
                        payload: 1,
                    },
                    {
                        title: 'استان تهران',
                        payload: 2,
                    },
                    {
                        title: 'مرکز استان',
                        payload: 3,
                    },
                    {
                        title: 'شهرهای بزرگ',
                        payload: 4,
                    },
                ], //محل اشتغال
                param6: Form7_1398.priceRequested, //بهای خواسته یا محکوم به
                param7: null, // نوع غیرمالی
                param8: null, // نوع دعوای کیفری
                param9: null, // تعداد جرایم
                param10: null, //محدوده تعرفه
                calculate: (
                    checkbox1: boolean,
                    checkbox2: boolean,
                    checkbox3: boolean,
                    checkbox4: boolean,
                    checkbox5: boolean,
                    checkbox6: boolean,
                    checkbox7: boolean,
                    checkbox8: boolean,
                    level: number,
                    appointment: number,
                    degree: number,
                    years: number,
                    place: number,
                    extraParam: number,
                ) => {
                    let answer = 0;
                    let temp = true;
                    let maxLevel;

                    if (
                        checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 1
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.5;
                        maxLevel = baseFunction(extraParam)[1] * 0.5;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 2
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        maxLevel = baseFunction(extraParam)[1] * 0.2;
                        answer = baseFunction(extraParam)[0] * 0.2;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 3
                        answer = 0.02 * extraParam;
                        maxLevel = 5000000;
                        answer = answer > 5000000 ? 5000000 : answer;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 4
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0];
                        answer = appointmentHandler(appointment, answer, level);
                        if (appointment !== 2 && appointment !== 3 && appointment !== 4) {
                            maxLevel =
                                level === 1 ? baseFunction(extraParam)[1] * 0.6 : baseFunction(extraParam)[1] * 0.4;
                        } else if (appointment === 2) {
                            maxLevel = baseFunction(extraParam)[1] * 0.15;
                        } else if (appointment === 3) {
                            maxLevel = baseFunction(extraParam)[1] * 0.3;
                        } else if (appointment === 4) {
                            maxLevel = baseFunction(extraParam)[1] * 0.1;
                        }
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 5
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.5;
                        answer = appointmentHandler(appointment, answer, level);
                        if (appointment !== 2 && appointment !== 3 && appointment !== 4) {
                            maxLevel =
                                level === 1
                                    ? baseFunction(extraParam)[1] * 0.5 * 0.6
                                    : baseFunction(extraParam)[1] * 0.4 * 0.5;
                        } else if (appointment === 2) {
                            maxLevel = baseFunction(extraParam)[1] * 0.15 * 0.5;
                        } else if (appointment === 3) {
                            maxLevel = baseFunction(extraParam)[1] * 0.3 * 0.5;
                        } else if (appointment === 4) {
                            maxLevel = baseFunction(extraParam)[1] * 0.1 * 0.5;
                        }
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 6
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.5;
                        answer = appointmentHandler(appointment, answer, level);
                        if (appointment !== 2 && appointment !== 3 && appointment !== 4) {
                            maxLevel =
                                level === 1
                                    ? baseFunction(extraParam)[1] * 0.5 * 0.6
                                    : baseFunction(extraParam)[1] * 0.4 * 0.5;
                        } else if (appointment === 2) {
                            maxLevel = baseFunction(extraParam)[1] * 0.15 * 0.5;
                        } else if (appointment === 3) {
                            maxLevel = baseFunction(extraParam)[1] * 0.3 * 0.5;
                        } else if (appointment === 4) {
                            maxLevel = baseFunction(extraParam)[1] * 0.1 * 0.5;
                        }
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        // 7
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0];
                        answer = 0.6 * answer;
                        maxLevel = 0.6 * baseFunction(extraParam)[1];
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        checkbox8
                    ) {
                        // 8
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0];
                        answer = appointmentHandler(appointment, answer, level);
                        if (appointment !== 2 && appointment !== 3 && appointment !== 4) {
                            maxLevel =
                                level === 1 ? baseFunction(extraParam)[1] * 0.6 : baseFunction(extraParam)[1] * 0.4;
                        } else if (appointment === 2) {
                            maxLevel = baseFunction(extraParam)[1] * 0.15;
                        } else if (appointment === 3) {
                            maxLevel = baseFunction(extraParam)[1] * 0.3;
                        } else if (appointment === 4) {
                            maxLevel = baseFunction(extraParam)[1] * 0.1;
                        }
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // none of them
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0];
                        answer = appointmentHandler(appointment, answer, level);
                        if (appointment !== 2 && appointment !== 3 && appointment !== 4) {
                            maxLevel =
                                level === 1 ? baseFunction(extraParam)[1] * 0.6 : baseFunction(extraParam)[1] * 0.4;
                        } else if (appointment === 2) {
                            maxLevel = baseFunction(extraParam)[1] * 0.15;
                        } else if (appointment === 3) {
                            maxLevel = baseFunction(extraParam)[1] * 0.3;
                        } else if (appointment === 4) {
                            maxLevel = baseFunction(extraParam)[1] * 0.1;
                        }
                    } else if (
                        checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 1, 6
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.5;
                        maxLevel = baseFunction(extraParam)[1] * 0.5;
                    } else if (
                        checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        // 1, 7
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.5;
                        maxLevel = baseFunction(extraParam)[1] * 0.5;
                    } else if (
                        checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        // 1, 6, 7
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.5;
                        maxLevel = baseFunction(extraParam)[1] * 0.5;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 4
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.2;
                        maxLevel = baseFunction(extraParam)[1] * 0.2;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 5
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.2;
                        maxLevel = baseFunction(extraParam)[1] * 0.2;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 6
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.2;
                        maxLevel = baseFunction(extraParam)[1] * 0.2;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 7
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.2;
                        maxLevel = baseFunction(extraParam)[1] * 0.2;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 4, 6
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.2;
                        maxLevel = baseFunction(extraParam)[1] * 0.2;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 4, 7
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.2;
                        maxLevel = baseFunction(extraParam)[1] * 0.2;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 4, 6, 7
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.2;
                        maxLevel = baseFunction(extraParam)[1] * 0.2;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 5, 6
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.2;
                        maxLevel = baseFunction(extraParam)[1] * 0.2;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 5, 7
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.2;
                        maxLevel = baseFunction(extraParam)[1] * 0.2;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 5, 6, 7
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.2;
                        maxLevel = baseFunction(extraParam)[1] * 0.2;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 3, 6
                        if (extraParam > 0 && extraParam <= 250000000) {
                            answer = extraParam * 0.01;
                        } else {
                            answer = 2500000;
                        }
                        maxLevel = 2500000;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        // 3, 7
                        answer = extraParam * 0.012;
                        if (extraParam > 0 && extraParam <= 250000000) {
                            answer = extraParam * 0.012;
                        } else {
                            answer = 3000000;
                        }
                        maxLevel = 3000000;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        //3, 6, 7
                        answer = extraParam * 0.012;
                        if (extraParam > 0 && extraParam <= 250000000) {
                            answer = extraParam * 0.012;
                        } else {
                            answer = 3000000;
                        }
                        maxLevel = 3000000;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 4, 6
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.5;
                        answer = appointmentHandler(appointment, answer, level);
                        if (appointment !== 2 && appointment !== 3 && appointment !== 4) {
                            maxLevel =
                                level === 1
                                    ? baseFunction(extraParam)[1] * 0.5 * 0.6
                                    : baseFunction(extraParam)[1] * 0.4 * 0.5;
                        } else if (appointment === 2) {
                            maxLevel = baseFunction(extraParam)[1] * 0.15 * 0.5;
                        } else if (appointment === 3) {
                            maxLevel = baseFunction(extraParam)[1] * 0.3 * 0.5;
                        } else if (appointment === 4) {
                            maxLevel = baseFunction(extraParam)[1] * 0.1 * 0.5;
                        }
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        //4, 7
                        answer = baseFunction(extraParam)[0] * 0.6;
                        maxLevel = baseFunction(extraParam)[1] * 0.6;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        checkbox8
                    ) {
                        //4, 8
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0];
                        answer = appointmentHandler(appointment, answer, level);
                        if (appointment !== 2 && appointment !== 3 && appointment !== 4) {
                            maxLevel =
                                level === 1 ? baseFunction(extraParam)[1] * 0.6 : baseFunction(extraParam)[1] * 0.4;
                        } else if (appointment === 2) {
                            maxLevel = baseFunction(extraParam)[1] * 0.15;
                        } else if (appointment === 3) {
                            maxLevel = baseFunction(extraParam)[1] * 0.3;
                        } else if (appointment === 4) {
                            maxLevel = baseFunction(extraParam)[1] * 0.1;
                        }
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        //4, 6, 7
                        answer = baseFunction(extraParam)[0] * 0.6;
                        maxLevel = baseFunction(extraParam)[1] * 0.6;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        checkbox8
                    ) {
                        //4, 6, 8
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.5;
                        answer = appointmentHandler(appointment, answer, level);
                        if (appointment !== 2 && appointment !== 3 && appointment !== 4) {
                            maxLevel =
                                level === 1
                                    ? baseFunction(extraParam)[1] * 0.5 * 0.6
                                    : baseFunction(extraParam)[1] * 0.4 * 0.5;
                        } else if (appointment === 2) {
                            maxLevel = baseFunction(extraParam)[1] * 0.15 * 0.5;
                        } else if (appointment === 3) {
                            maxLevel = baseFunction(extraParam)[1] * 0.3 * 0.5;
                        } else if (appointment === 4) {
                            maxLevel = baseFunction(extraParam)[1] * 0.1 * 0.5;
                        }
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        checkbox8
                    ) {
                        //4, 7, 8
                        answer = baseFunction(extraParam)[0] * 0.6;
                        maxLevel = baseFunction(extraParam)[1] * 0.6;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        checkbox8
                    ) {
                        //4, 6, 7, 8
                        answer = baseFunction(extraParam)[0] * 0.6;
                        maxLevel = baseFunction(extraParam)[1] * 0.6;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        //5, 6
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.25;
                        answer = appointmentHandler(appointment, answer, level);
                        if (appointment !== 2 && appointment !== 3 && appointment !== 4) {
                            maxLevel =
                                level === 1
                                    ? baseFunction(extraParam)[1] * 0.25 * 0.6
                                    : baseFunction(extraParam)[1] * 0.4 * 0.25;
                        } else if (appointment === 2) {
                            maxLevel = baseFunction(extraParam)[1] * 0.15 * 0.25;
                        } else if (appointment === 3) {
                            maxLevel = baseFunction(extraParam)[1] * 0.3 * 0.25;
                        } else if (appointment === 4) {
                            maxLevel = baseFunction(extraParam)[1] * 0.1 * 0.25;
                        }
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        //5, 7
                        answer = baseFunction(extraParam)[0] * 0.3;
                        maxLevel = baseFunction(extraParam)[1] * 0.3;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        checkbox8
                    ) {
                        //5, 8
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.5;
                        answer = appointmentHandler(appointment, answer, level);
                        if (appointment !== 2 && appointment !== 3 && appointment !== 4) {
                            maxLevel =
                                level === 1
                                    ? baseFunction(extraParam)[1] * 0.5 * 0.6
                                    : baseFunction(extraParam)[1] * 0.4 * 0.5;
                        } else if (appointment === 2) {
                            maxLevel = baseFunction(extraParam)[1] * 0.15 * 0.5;
                        } else if (appointment === 3) {
                            maxLevel = baseFunction(extraParam)[1] * 0.3 * 0.5;
                        } else if (appointment === 4) {
                            maxLevel = baseFunction(extraParam)[1] * 0.1 * 0.5;
                        }
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        //5, 6, 7
                        answer = baseFunction(extraParam)[0] * 0.3;
                        maxLevel = baseFunction(extraParam)[1] * 0.3;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        checkbox8
                    ) {
                        //5, 6, 8
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.25;
                        answer = appointmentHandler(appointment, answer, level);
                        if (appointment !== 2 && appointment !== 3 && appointment !== 4) {
                            maxLevel =
                                level === 1
                                    ? baseFunction(extraParam)[1] * 0.25 * 0.6
                                    : baseFunction(extraParam)[1] * 0.4 * 0.25;
                        } else if (appointment === 2) {
                            maxLevel = baseFunction(extraParam)[1] * 0.15 * 0.25;
                        } else if (appointment === 3) {
                            maxLevel = baseFunction(extraParam)[1] * 0.3 * 0.25;
                        } else if (appointment === 4) {
                            maxLevel = baseFunction(extraParam)[1] * 0.1 * 0.25;
                        }
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        checkbox8
                    ) {
                        //5, 7, 8
                        answer = baseFunction(extraParam)[0] * 0.3;
                        maxLevel = baseFunction(extraParam)[1] * 0.3;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        checkbox8
                    ) {
                        //5, 6, 7, 8
                        answer = baseFunction(extraParam)[0] * 0.3;
                        maxLevel = baseFunction(extraParam)[1] * 0.3;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        //6, 7
                        answer = baseFunction(extraParam)[0] * 0.6;
                        maxLevel = baseFunction(extraParam)[1] * 0.6;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        checkbox8
                    ) {
                        //6, 8
                        if (baseFunction(extraParam)[0] === baseFunction(extraParam)[1]) {
                            temp = false;
                        }
                        answer = baseFunction(extraParam)[0] * 0.5;
                        answer = appointmentHandler(appointment, answer, level);
                        if (appointment !== 2 && appointment !== 3 && appointment !== 4) {
                            maxLevel =
                                level === 1
                                    ? baseFunction(extraParam)[1] * 0.5 * 0.6
                                    : baseFunction(extraParam)[1] * 0.4 * 0.5;
                        } else if (appointment === 2) {
                            maxLevel = baseFunction(extraParam)[1] * 0.15 * 0.5;
                        } else if (appointment === 3) {
                            maxLevel = baseFunction(extraParam)[1] * 0.3 * 0.5;
                        } else if (appointment === 4) {
                            maxLevel = baseFunction(extraParam)[1] * 0.1 * 0.5;
                        }
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        checkbox8
                    ) {
                        //6, 7, 8
                        answer = baseFunction(extraParam)[0] * 0.6;
                        maxLevel = baseFunction(extraParam)[1] * 0.6;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        checkbox8
                    ) {
                        //7, 8
                        answer = baseFunction(extraParam)[0] * 0.6;
                        maxLevel = baseFunction(extraParam)[1] * 0.6;
                    }

                    if (temp) {
                        if (degree === 1) {
                            answer *= 1;
                        } else if (degree === 2) {
                            answer = answer * 1.05;
                        } else if (degree === 3) {
                            answer = answer * 1.1;
                        }

                        years !== 0 && (answer = years > 20 ? answer * (1 + 20 * 0.01) : (years * 0.01 + 1) * answer);

                        if (place === 1) {
                            answer *= 1;
                        } else if (place === 2 || place === 3) {
                            answer = 1.1 * answer;
                        } else if (place === 4) {
                            answer = 1.05 * answer;
                        }
                    }

                    answer = answer >= maxLevel ? maxLevel : answer;

                    let result;
                    const res1 = answer * 0.05;
                    const res2 = answer * 0.05 * 0.75;
                    if (extraParam !== 0) {
                        result =
                            'هزینه ی تمبر مرحله (بدون سهم صندوق و کانون) برابر است با ' +
                            enNumToFaNum(res1.toLocaleString().toString()) +
                            ' و  سهم صندوق و کانون برابر است با ' +
                            enNumToFaNum(res2.toLocaleString().toString());
                    }
                    return result;
                },
            },
            {
                id: 2,
                title: 'غیرمالی',
                param1: [
                    {
                        title: 'نخستین',
                        payload: 1,
                    },
                    {
                        title: 'تجدیدنظر',
                        payload: 2,
                    },
                ], //مرحله
                param2: [
                    {
                        title: 'هیچکدام',
                        payload: 1,
                    },
                    {
                        title: 'قرار ابطال دادخواست پیش از پاسخ و دفاع از دعوی',
                        payload: 2,
                    },
                    {
                        title: 'قرار ابطال دادخواست پس از پاسخ و دفاع از دعوی',
                        payload: 3,
                    },
                    {
                        title: 'قرار سقوط دعوی تجدیدنظر قبل از پاسخ و دفاع از دعوی',
                        payload: 4,
                    },
                    {
                        title: 'قرار سقوط دعوی تجدیدنظر پس از پاسخ و دفاع از دعوی',
                        payload: 5,
                    },
                    {
                        title: 'قرار رد دعوی به علت قبول ایراد مرور زمان',
                        payload: 6,
                    },
                    {
                        title: 'قرار سقوط دعوی اعتراض بر ثبت',
                        payload: 7,
                    },
                    {
                        title: 'رد تقاضای اعاده دادرسی',
                        payload: 8,
                    },
                    {
                        title: 'قرار رد دعوی بعلت اعتبار امر مختومه',
                        payload: 9,
                    },
                    {
                        title: 'سایر قرارهایی كه مستقلاً قابل تجدیدنظر است',
                        payload: 10,
                    },
                ], //قرار صادره
                param3: [
                    {
                        title: 'کارشناسی',
                        payload: 1,
                    },
                    {
                        title: 'کارشناسی ارشد',
                        payload: 2,
                    },
                    {
                        title: 'دکتری',
                        payload: 3,
                    },
                ], //مدرک تحصیلی
                param4: true, //سنوات
                param5: [
                    {
                        title: 'هیچکدام',
                        payload: 1,
                    },
                    {
                        title: 'استان تهران',
                        payload: 2,
                    },
                    {
                        title: 'مرکز استان',
                        payload: 3,
                    },
                    {
                        title: 'شهرهای بزرگ',
                        payload: 4,
                    },
                ], //محل اشتغال
                param6: null, //بهای خواسته یا محکوم به
                param7: [
                    {
                        title: 'خانوادگی',
                        payload: 1,
                    },
                    {
                        title: 'کیفری',
                        payload: 2,
                    },
                    {
                        title: 'سایر',
                        payload: 3,
                    },
                ], //نوع غیرمالی
                param8: [
                    {
                        title:
                            'دعاوی مستلزم مجازات شلاق، جزای نقدی، سرقت مشمول حد، قصاص عضو و دیه عضو، حبس كمتر از ده سال',
                        payload: 1,
                    },
                    {
                        title: 'دعاوی مستلزم قصاص نفس، محاربه، حدود مستوجب قتل، دیه نفس، حبس ده سال و بیشتر',
                        payload: 2,
                    },
                ], // نوع دعوای کیفری
                param9: true, // تعداد جرایم
                param10: [
                    {
                        title: 'حداقل',
                        payload: 1,
                    },
                    {
                        title: 'حداکثر',
                        payload: 2,
                    },
                ], //محدوده تعرفه
                calculate: (
                    checkbox1,
                    checkbox2,
                    checkbox3,
                    checkbox4,
                    checkbox5,
                    checkbox6,
                    checkbox7,
                    checkbox8,
                    level,
                    range,
                    nonFinancialType,
                    fightType,
                    crimes,
                    appointment,
                    degree,
                    years,
                    place,
                ) => {
                    let answer = 0;
                    if (
                        checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 1
                        answer = nonFinancialTypeHandler(
                            appointment,
                            nonFinancialType,
                            level,
                            range,
                            crimes,
                            fightType,
                        )[0];
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 2
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.4;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 3
                        answer = 0;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 4
                        answer = nonFinancialTypeHandler(
                            appointment,
                            nonFinancialType,
                            level,
                            range,
                            crimes,
                            fightType,
                        )[0];
                        answer = appointmentHandler(appointment, answer, level);
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 5
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.5;
                        answer = appointmentHandler(appointment, answer, level);
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 6
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.5;
                        answer = appointmentHandler(appointment, answer, level);
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        // 7
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.6;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        checkbox8
                    ) {
                        // 8
                        answer = nonFinancialTypeHandler(
                            appointment,
                            nonFinancialType,
                            level,
                            range,
                            crimes,
                            fightType,
                        )[0];
                        answer = appointmentHandler(appointment, answer, level);
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // none of them
                        answer = nonFinancialTypeHandler(
                            appointment,
                            nonFinancialType,
                            level,
                            range,
                            crimes,
                            fightType,
                        )[0];
                        answer = appointmentHandler(appointment, answer, level);
                    } else if (
                        checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 1, 6
                        answer = nonFinancialTypeHandler(
                            appointment,
                            nonFinancialType,
                            level,
                            range,
                            crimes,
                            fightType,
                        )[0];
                    } else if (
                        checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        // 1, 7
                        answer = nonFinancialTypeHandler(
                            appointment,
                            nonFinancialType,
                            level,
                            range,
                            crimes,
                            fightType,
                        )[0];
                    } else if (
                        checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        // 1, 6, 7
                        answer = nonFinancialTypeHandler(
                            appointment,
                            nonFinancialType,
                            level,
                            range,
                            crimes,
                            fightType,
                        )[0];
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 4
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.4;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 5
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.4;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 6
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.4;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 7
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.4;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 4, 6
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.4;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 4, 7
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.4;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 4, 6, 7
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.4;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 5, 6
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.4;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 5, 7
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.4;
                    } else if (
                        !checkbox1 &&
                        checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        // 2, 5, 6, 7
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.4;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 3, 6
                        answer = 0;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        // 3, 7
                        answer = 0;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        //3, 6, 7
                        answer = 0;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        // 4, 6
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.5;
                        answer = appointmentHandler(appointment, answer, level);
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        //4, 7
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.6;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        checkbox8
                    ) {
                        //4, 8
                        answer = nonFinancialTypeHandler(
                            appointment,
                            nonFinancialType,
                            level,
                            range,
                            crimes,
                            fightType,
                        )[0];
                        answer = appointmentHandler(appointment, answer, level);
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        //4, 6, 7
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.6;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        checkbox8
                    ) {
                        //4, 6, 8
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.5;
                        answer = appointmentHandler(appointment, answer, level);
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        checkbox8
                    ) {
                        //4, 7, 8
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.6;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        checkbox8
                    ) {
                        //4, 6, 7, 8
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.6;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        !checkbox8
                    ) {
                        //5, 6
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.5;
                        answer = appointmentHandler(appointment, answer, level);
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        //5, 7
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.3;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        !checkbox6 &&
                        !checkbox7 &&
                        checkbox8
                    ) {
                        //5, 8
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.5;
                        answer = appointmentHandler(appointment, answer, level);
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        //5, 6, 7
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.3;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        checkbox8
                    ) {
                        //5, 6, 8
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.25;
                        answer = appointmentHandler(appointment, answer, level);
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        checkbox8
                    ) {
                        //5, 7, 8
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.3;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        checkbox8
                    ) {
                        //5, 6, 7, 8
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.3;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        !checkbox8
                    ) {
                        //6, 7
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.6;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        !checkbox7 &&
                        checkbox8
                    ) {
                        //6, 8
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.5;
                        answer = appointmentHandler(appointment, answer, level);
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        checkbox6 &&
                        checkbox7 &&
                        checkbox8
                    ) {
                        //6, 7, 8
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.6;
                    } else if (
                        !checkbox1 &&
                        !checkbox2 &&
                        !checkbox3 &&
                        !checkbox4 &&
                        !checkbox5 &&
                        !checkbox6 &&
                        checkbox7 &&
                        checkbox8
                    ) {
                        //7, 8
                        answer =
                            nonFinancialTypeHandler(appointment, nonFinancialType, level, range, crimes, fightType)[0] *
                            0.6;
                    }

                    if (range === 1 && nonFinancialType !== 2) {
                        if (degree === 1) {
                            answer *= 1;
                        } else if (degree === 2) {
                            answer = answer * 1.05;
                        } else if (degree === 3) {
                            answer = answer * 1.1;
                        }

                        years !== 0 && (answer = years > 20 ? answer * (1 + 20 * 0.01) : (years * 0.01 + 1) * answer);

                        if (place === 1) {
                            answer *= 1;
                        } else if (place === 2 || place === 3) {
                            answer = 1.1 * answer;
                        } else if (place === 4) {
                            answer = 1.05 * answer;
                        }
                    }

                    let result;
                    const res1 = answer * 0.05;
                    const res2 = answer * 0.05 * 0.75;
                    if (1) {
                        result =
                            'هزینه ی تمبر مرحله (بدون سهم صندوق و کانون) برابر است با ' +
                            enNumToFaNum(res1.toLocaleString().toString()) +
                            ' و  سهم صندوق و کانون برابر است با ' +
                            enNumToFaNum(res2.toLocaleString().toString());
                    }
                    return result;
                },
            },
        ], //نوع دعوا
        param2: Form8_1385.none, //مبلغ حق الوکاله
    },
    {
        id: 2,
        title: 'حق الوکاله',
        param1: null, //نوع دعوا
        param2: Form8_1385.price, //مبلغ حق الوکاله
        calculate: (extraParam) => {
            let answer;
            const res1 = extraParam * 0.03;
            const res2 = (extraParam * 9) / 400;
            const res3 = extraParam * 0.02;
            if (extraParam !== 0) {
                answer =
                    'هزینه تمبر تجدیدنظر برابر است با ' +
                    enNumToFaNum(res3.toLocaleString().toString()) +
                    '، هزینه صندوق  و کانون برابر است با ' +
                    enNumToFaNum(res2.toLocaleString().toString()) +
                    ' و هزینه تمبر بدوی برابر است با ' +
                    enNumToFaNum(res1.toLocaleString().toString());
            }
            return answer;
        },
    },
];
