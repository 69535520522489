import React, { useEffect } from 'react';
import './authorization.style.scss';
import { ReduxState } from '../../interface';
import { connect, ConnectedProps } from 'react-redux';
import { authToken } from 'scripts/storage';

const Authorization: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    useEffect(() => {
        const e: any = {};
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.forEach((value, key) => {
            e[key] = value;
        });
        authToken.set(e);
    }, []);
    return null;
};
const mapStateToProps = (state: ReduxState) => ({
    isAuth: state.authStatus,
    userData: state.userData,
});

const connector = connect(mapStateToProps);
export default connector(Authorization);
