import React, { useRef } from 'react';
import CalculationWebCard from 'vekalapp/components/calculationWebCard/calculationWebCard.index';
import './list.style.scss';
import { CalculationForm } from '../../calculationList.data';

const CalculationList: React.FC = () => {
    return (
        <div className="vekalapp-calculation-list d-md-block d-none">
            <div className="page-content-list">
                <p className="list-header">انواع محاسبات قضایی</p>
                <div className="cards">
                    {CalculationForm &&
                        CalculationForm.map((item, index) => (
                            <CalculationWebCard
                                title={item.title}
                                text={item.description}
                                buttons={item.buttons}
                                icon={item.icon}
                                color={item.color}
                                key={index}
                            />
                        ))}
                    <div className="grid-protector"></div>
                    <div className="grid-protector"></div>
                    <div className="grid-protector"></div>
                    <div className="grid-protector"></div>
                    <div className="grid-protector"></div>
                </div>
            </div>
        </div>
    );
};

export default CalculationList;
