import React, { useState } from 'react';
import './fraction.style.scss';
import { IconButton } from 'vekalapp-react-utilities';
import FractionComponentItem from './item/item.index';

const FractionComponent: React.FC<{ onChange: (e: { up: number; down: number }) => void }> = (props: {
    onChange: (e: { up: number; down: number }) => void;
}) => {
    const [numbersOfFractions, setNumberOfFractions] = useState<number[]>([0]);
    const [ups, setUps] = useState<number[]>([1]);
    const [downs, setDowns] = useState<number[]>([2]);

    function addHandler() {
        const temp = [...numbersOfFractions];
        temp.push(temp.length);
        setNumberOfFractions(temp);

        const temp2 = [...ups];
        temp2.push(1);
        setUps(temp2);

        const temp3 = [...downs];
        temp3.push(2);
        setDowns(temp3);
        calculate(temp2, temp3);
    }
    function removeHandler() {
        const temp = [...numbersOfFractions];
        if (temp.length !== 1) {
            temp.pop();
            setNumberOfFractions(temp);

            const temp2 = [...ups];
            temp2.pop();
            setUps(temp2);

            const temp3 = [...downs];
            temp3.pop();
            setDowns(temp3);
            calculate(temp2, temp3);
        }
    }

    function onChangeHandler(e: { up: number; down: number }, index: number) {
        const tempUps = [...ups];
        tempUps[index] = e.up;
        setUps(tempUps);

        const tempDowns = [...downs];
        tempDowns[index] = e.down;
        setDowns(tempDowns);

        calculate(tempUps, tempDowns);
    }

    function calculate(ups: number[], downs: number[]) {
        let calculateUps = 1;
        ups.map((item) => (calculateUps *= item));

        let calculateDowns = 1;
        downs.map((item) => (calculateDowns *= item));

        props.onChange({ up: calculateUps, down: calculateDowns });
    }

    return (
        <div className="vekalapp-fraction-component">
            {numbersOfFractions.map((item, index) => (
                <div className="item" key={index}>
                    {index !== 0 && <i className="material-icons">close</i>}
                    <FractionComponentItem onChange={(e) => onChangeHandler(e, index)} />
                </div>
            ))}
            <div className="buttons">
                <IconButton onClick={addHandler} color="blue">
                    <i className="material-icons">add</i>
                </IconButton>
                <div className="border" />
                <IconButton onClick={removeHandler} color="blue">
                    <i className="material-icons">remove</i>
                </IconButton>
            </div>
        </div>
    );
};

export default FractionComponent;
