const data: { id: number; title: string; items: { title: string; days: number }[] }[] = [
    {
        id: 1,
        title: 'آئین دادرسی مدنی',
        items: [
            {
                title: 'طرح دعوا پس از صدور قرار اناطه',
                days: 30,
            },
            {
                title: 'توقف دادرسی در صورت استعفای وکیل',
                days: 30,
            },
            {
                title: 'رفع نقص دادخواست',
                days: 10,
            },
            {
                title: 'اعتراض به قرار رد دادخواست',
                days: 10,
            },
            {
                title: 'فاصله ابلاغ تا جلسه رسیدگی',
                days: 5,
            },
            {
                title: 'فاصله آگهی تا جلسه رسیدگی',
                days: 30,
            },
            {
                title: 'طرح دعوا پس از صدور قرار تأمین',
                days: 10,
            },
            {
                title: 'اعتراض به قرار تأمین',
                days: 10,
            },
            {
                title: 'مطالبه خسارت ناشی از اجرای قرار تامین',
                days: 20,
            },
            {
                title: 'ارائه دفاع در مقابل مطالبه خسارت ناشی از اجرای قرار تامین',
                days: 10,
            },
            {
                title: 'درخواست جلب شخص ثالث',
                days: 3,
            },
            {
                title: 'دادخواست ادعای مالکیت مورد حکم تصرف عدوانی',
                days: 30,
            },
            {
                title: 'تسلیم سند موضوع ادعای جعل',
                days: 10,
            },
            {
                title: 'فاصله ابلاغ به گواه و جلسه رسیدگی',
                days: 6,
            },
            {
                title: 'ایداع دستمزد کارشناس',
                days: 7,
            },
            {
                title: 'اعتراض به نظر کارشناس',
                days: 7,
            },
            {
                title: 'انشاء و اعلام رأی',
                days: 7,
            },
            {
                title: 'پاکنویس و امضای رأی',
                days: 5,
            },
            {
                title: 'واخواهی',
                days: 20,
            },
            {
                title: 'واخواهی +‌ تجدیدنظر',
                days: 20,
            },
            {
                title: 'تقدیم دادخواست پس از صدور دستور موقت',
                days: 20,
            },
            {
                title: 'مطالبه خسارت ناشی از اجرای دستور موقت',
                days: 30,
            },
            {
                title: 'تجدیدنظرخواهی',
                days: 20,
            },
            {
                title: 'اعتراض به رد دادخواست تجدیدنظر',
                days: 20,
            },
            {
                title: 'رفع نقص دادخواست تجدیدنظر',
                days: 10,
            },
            {
                title: 'پاسخ به دادخواست تجدیدنظر',
                days: 10,
            },
            {
                title: 'اعتراض محکومٌ علیه غایب به رأی تجدیدنظر',
                days: 20,
            },
            {
                title: 'رفع نقص دادخواست فرجام‌خواهی',
                days: 20,
            },
            {
                title: 'اعتراض به رد دادخواست فرجام‌خواهی',
                days: 20,
            },
            {
                title: 'پاسخ به دادخواست فرجام‌خواهی',
                days: 20,
            },
            {
                title: 'فرجام‌خواهی',
                days: 20,
            },
            {
                title: 'فرجام تبعی',
                days: 20,
            },
            {
                title: 'اعاده دادرسی',
                days: 20,
            },
            {
                title: 'اعاده دادرسی طاری',
                days: 3,
            },
            {
                title: 'معرفی یا اظهارنظر در مورد داور',
                days: 10,
            },
            {
                title: 'داوری',
                days: 90,
            },
            {
                title: 'تصحیح رأی داور',
                days: 20,
            },
            {
                title: 'اجرای رأی داور',
                days: 20,
            },
            {
                title: 'درخواست ابطال رأی داور',
                days: 20,
            },
        ],
    },
    {
        id: 2,
        title: 'آئین دادرسی کیفری',
        items: [
            {
                title: 'معرفی وکیل برای مجنون',
                days: 5,
            },
            {
                title: 'طرح دعوا پس از صدور قرار اناطه',
                days: 30,
            },
            {
                title: 'اعتراض به قرار بایگانی پرونده',
                days: 10,
            },
            {
                title: 'اعتراض به قرار تعلیق تعقیب',
                days: 10,
            },
            {
                title: 'تقدیم دادخواست ضرر و زیان هنگام کیفرخواست شفاهی',
                days: 5,
            },
            {
                title: 'اعتراض به قرار رد درخواست دسترسی به پرونده',
                days: 3,
            },
            {
                title: 'اعتراض به جلوگیری از فعالیت تولیدی و خدماتی',
                days: 5,
            },
            {
                title: 'ملاحظه نظر کارشناس',
                days: 7,
            },
            {
                title: 'فاصله ابلاغ و حضور نزد بازپرس',
                days: 5,
            },
            {
                title: 'اعتراض به اصل قرار منتهی به بازداشت یا عدم پذیرش کفیل یا وثیقه',
                days: 10,
            },
            {
                title: 'اعتراض به اخذ وجه التزام، وجه الکفاله و یا ضبط وثیقه',
                days: 10,
            },
            {
                title: 'تحویل متهم توسط کفیل یا وثیقه گذار',
                days: 30,
            },
            {
                title: 'واریز وجه قرار تأمین به صندوق دولت',
                days: 10,
            },
            {
                title: 'حل اختلاف بازپرس و دادستان در مورد بازداشت موقت',
                days: 10,
            },
            {
                title: 'اظهارنظر در مورد فک یا تبدیل قرار بازداشت',
                days: 5,
            },
            {
                title: 'اعتراض به رد درخواست فک یا تبدیل قرار بازداشت',
                days: 10,
            },
            {
                title: 'اعتراض به ابقاء قرار بازداشت موقت',
                days: 10,
            },
            {
                title: 'اعتراض به قرار نظارت قضایی',
                days: 10,
            },
            {
                title: 'درخواست جبران خسارت به کمیسیون استانی',
                days: 180,
            },
            {
                title: 'اعتراض به رد درخواست جبران خسارت',
                days: 20,
            },
            {
                title: 'اظهارنظر بازپرس پس از پایان تحقیقات',
                days: 5,
            },
            {
                title: 'اظهارنظر دادستان در مورد قرار نهایی بازپرس',
                days: 3,
            },
            {
                title: 'صدور کیفرخواست',
                days: 2,
            },
            {
                title: 'اعتراض به قرارهای قابل اعتراض بازپرس',
                days: 10,
            },
            {
                title: 'بررسی پرونده پس از ارجاع به دادگاه',
                days: 30,
            },
            {
                title: 'فاصله ابلاغ و جلسه رسیدگی',
                days: 7,
            },
            {
                title: 'انشای رأی',
                days: 7,
            },
            {
                title: 'پاکنویس یا تایپ رأی',
                days: 3,
            },
            {
                title: 'انتخاب وکیل در جرایم مهم',
                days: 10,
            },
            {
                title: 'تسلیم ایرادها و اعتراض ها',
                days: 10,
            },
            {
                title: 'تهیه گزارش مبسوط پرونده',
                days: 10,
            },
            {
                title: 'صدور رأی در دادگاه کیفری یک',
                days: 7,
            },
            {
                title: 'واخواهی',
                days: 20,
            },
            {
                title: 'واخواهی + تجدیدنظر یا فرجام',
                days: 20,
            },
            {
                title: 'اعتراض به قرار رد ایراد رد دادرس',
                days: 10,
            },
            {
                title: 'تجدیدنظر یا فرجام',
                days: 20,
            },
            {
                title: 'رفع نقص دادخواست تجدیدنظر یا درخواست فرجام‌خواهی',
                days: 10,
            },
            {
                title: 'صدور رأی در دادگاه تجدیدنظر',
                days: 7,
            },
            {
                title: 'اعتراض به رأی غیابی دادگاه تجدیدنظر',
                days: 20,
            },
            {
                title: 'درخواست انتشار حکم برائت',
                days: 180,
            },
            {
                title: 'استفاده از تخفیف جزای نقدی',
                days: 10,
            },
            {
                title: 'اعلام تصمیم درباره اجرای حکم',
                days: 90,
            },
            {
                title: 'اعلام اجرای حکم سلب حیات',
                days: 6,
            },
            {
                title: 'پیشنهاد کاهش مدت تعلیق',
                days: 180,
            },
            {
                title: 'اعتراض به قرار تأمینی شخص حقوقی',
                days: 10,
            },
        ],
    },
];

export default data;
