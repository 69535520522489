import React from 'react';
import { Link } from 'react-router-dom';
import { CalculationCardMobileProps } from './calculationMobileCard.interface';
import './calculationMobileCard.style.scss';

const CalculationMobileCard: React.FC<CalculationCardMobileProps> = (props: CalculationCardMobileProps) => {
    function hexToRgb(hex: string) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16),
              }
            : null;
    }

    return (
        <Link to={props.path} className="vekalapp-calculation-mobile-card">
            <div
                className="card-icon"
                style={{
                    backgroundColor: `rgba(${hexToRgb(props.color)?.r}, ${hexToRgb(props.color)?.g}, ${
                        hexToRgb(props.color)?.b
                    }, 0.3`,
                }}
            >
                <i className="material-icons" style={{ color: props.color }}>
                    {props.icon}
                </i>
            </div>
            <h4>{props.title}</h4>
        </Link>
    );
};

export default CalculationMobileCard;
